import { useMemo } from 'react';
import copy from '@copy/Copy';

const createFileFromUrl = async (options: { url: string; filename: string; metadata?: FilePropertyBag }) => {
    const { url, filename, metadata } = options;
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], filename, metadata);
};

const getPlutoEmbedHTML = ({
    src,
    title = copy.companyName,
    width = 600,
    height = 600,
}: {
    title?: string;
    src: string;
    width?: string | number;
    height?: string | number;
}) => {
    return `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>${title}</title>
  </head>
  <body>
    <iframe src="${src}" frameborder="0" style="border: none" width="${width}" height="${height}" sandbox="allow-scripts allow-same-origin allow-popups">Pluto Bio embed requires iframes, this site does not allow iframes.</iframe>
  </body>
</html>`;
};

const createFileFromString = ({
    content,
    filename,
    metadata,
}: {
    content: string;
    filename: string;
    metadata?: FilePropertyBag;
}) => {
    return new File([content], filename, metadata);
};

const useFileDownloader = () => {
    const { createPlutoEmbedHTMLFile } = useMemo(() => {
        return {
            createPlutoEmbedHTMLFile: async ({
                filename,
                src,
                title,
                height,
                width,
            }: {
                filename: string;
                title?: string;
                src: string;
                width?: string | number;
                height?: string | number;
            }) => {
                const htmlString = getPlutoEmbedHTML({ src, title, height, width });
                return createFileFromString({ content: htmlString, filename, metadata: { type: 'text/html' } });
            },
        };
    }, []);
    return { createFileFromUrl, createPlutoEmbedHTMLFile };
};

export default useFileDownloader;

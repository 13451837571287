import React, { useMemo } from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import BarBoxPlotBuilder from '@components/analysisCategories/summary/plots/builders/BarBoxPlotBuilder';
import useStatisticalTest from '@hooks/useStatisticalTest';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import { SummaryAnalysisPlotData } from '@models/ExperimentData';
import { processSummaryPlotData } from '@components/plots/PlotUtil';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import { BaseAssaySummaryExperimentAnalysis } from '@/src/models/analysis/ExperimentAnalysis';

export type Props = {
    plot: Plot;
    experiment: Experiment;
    customPlotStylingOptions: CustomPlotStylingOptions | null;
};

const BoxPlotView = ({ plot, experiment, customPlotStylingOptions }: Props) => {
    const { visiblePlotStats } = useStatisticalTest({ experiment, analysis: plot.analysis });
    const analysis = plot.analysis as BaseAssaySummaryExperimentAnalysis | null;

    const { groups } = useExperimentPlotGroups({
        experiment,
        plot,
        variables: plot.analysis?.variables,
    });

    const drawChart = useMemo<DrawChartFn>(
        () =>
            ({ svgSelection: _svg, size, context, tooltipId }) => {
                const { plotData, plot, publicationMode, analysisParameters, idPrefix } = context;
                _svg.selectAll('g').remove();

                const data = plotData as SummaryAnalysisPlotData | null | undefined;
                const processedData = processSummaryPlotData(data);
                if (!processedData) {
                    return;
                }

                const { getTargetById } = getAnalysisParametersHelpers(analysisParameters);
                const getGroupById = (group_id: number) => {
                    return groups.find((g) => g.id === group_id) ?? null;
                };

                const svg = _svg.append<SVGSVGElement>('g');
                svg.selectAll('g').remove();
                const builder = BarBoxPlotBuilder.make({
                    svg,
                    plot,
                    data: processedData,
                    experiment,
                    getTargetById,
                    getGroupById,
                    visiblePlotStats,
                    width: size.width,
                    height: size.height,
                    publicationMode,
                    idPrefix,
                    tooltipId,
                    stylingOptions: customPlotStylingOptions,
                });
                builder.draw();
            },
        [visiblePlotStats, groups, customPlotStylingOptions, analysis?.group_display_order],
    );

    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default BoxPlotView;

import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import { SeuratDifferentialExpressionAnalysisParameters } from '@models/AnalysisParameters';
import { SeuratDifferentialExpressionAnalysisFormValues } from '@models/analysis/SeuratDifferentialExpressionAnalysis';
import SeuratDifferentialVariablesField from '@components/experiments/analyses/fields/SeuratDifferentialVariablesField';
import { Collapse, Switch, Tooltip } from '@mui/material';
import { ChevronRightIcon } from '@heroicons/react/outline';
import SimpleSelectField, { ChoiceItem } from '../../plotDisplay/fields/SimpleSelectField';
import { StatTestSelectOptions } from '@models/preprocesses/ClusterSeuratObject';
import TextInput from '@components/forms/TextInput';
import cn from 'classnames';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';
import SeuratDifferentialLatentVariablesField from '../fields/SeuratDifferentialLatentVariablesField';
import SeuratDifferentialGroupsPickerFields from '../fields/SeuratDifferentialGroupsPickerFields';
import { useSwitchStyles } from '@components/SwitchStyles';
import { useExperimentAnnotationContext } from '@contexts/ExperimentAnnotationContext';

type Props = { plot: Plot; analysisParameters: SeuratDifferentialExpressionAnalysisParameters; experiment: Experiment };
const SeuratDifferentialExpressionAnalysisFormFields = ({ plot, analysisParameters, experiment }: Props) => {
    const { values, setFieldValue } = useFormikContext<SeuratDifferentialExpressionAnalysisFormValues>();
    const [statisticsExpanded, setStatisticsExpanded] = useState(false);
    // const [expanded, setExpanded] = useState(false);
    const [splitByCluster, setSplitByCluster] = useState(Boolean(values?.annotation_set_id) ?? false);
    const switchStyles = useSwitchStyles();
    const { annotationSets } = useExperimentAnnotationContext();

    const showGroupsPicker = Boolean(
        values.latent_variable_id || values.annotation_set_id || (values.variable_ids ?? []).length > 0,
    );

    const isCleanedWorkflow = experiment?.is_cleaned_workflow ?? false;

    const ClusterAnnotationSetItems: ChoiceItem[] =
        annotationSets
            ?.sort((a, b) => a.resolution - b.resolution)
            .map((c) => {
                return {
                    label: (
                        <div className="flex flex-col">
                            <p className="text-dark">{c.display_name}</p>
                            <div className="flex flex-row text-sm text-gray-500">
                                {isCleanedWorkflow ? null : (
                                    <>
                                        <p>Resolution: {c.resolution.toFixed(1)}</p>{' '}
                                    </>
                                )}
                                <p
                                    className={cn({
                                        'ml-4': !isCleanedWorkflow,
                                    })}
                                >
                                    Clusters: {c.clusters.length}
                                </p>
                            </div>
                        </div>
                    ),
                    value: c.uuid,
                };
            }) ?? [];

    // useEffect(() => {
    //     setExpanded(false);
    // }, [values?.annotation_set_id]);

    return (
        <>
            <section className="mb-8 space-y-4">
                <p className="mb-4">
                    Build your comparison by selecting at least one cluster annotation set, variable, or latent variable
                    to group cells by
                </p>
                <div className="form-field !flex flex-row items-center justify-between">
                    <span className="field-label">Group by cluster</span>
                    <Switch
                        sx={switchStyles}
                        checked={splitByCluster}
                        name="split_by_cluster"
                        onChange={(e) => {
                            setSplitByCluster((prev) => !prev);
                            if (!e.target.checked) {
                                setFieldValue('annotation_set_id', undefined);
                            }
                        }}
                    />
                </div>
                {splitByCluster && (
                    <SimpleSelectField
                        name="annotation_set_id"
                        label="Cluster annotation set"
                        // @Post-MVP: Adding expand button and modal
                        // label={
                        //     <>
                        //         <span>Cluster annotation set</span>
                        //         <Button
                        //             variant="text"
                        //             color="primary"
                        //             onClick={() => setExpanded(true)}
                        //             endIcon={<ArrowsExpandIcon className="h-4 w-4" />}
                        //         >
                        //             Expand
                        //         </Button>
                        //     </>
                        // }
                        // labelClassName="w-full flex items-center justify-between"
                        value={values.annotation_set_id}
                        onChange={(e) => setFieldValue('annotation_set_id', e.target.value)}
                        items={ClusterAnnotationSetItems}
                        placeholder="Select an annotation set..."
                    />
                )}
                <SeuratDifferentialVariablesField
                    plot={plot}
                    analysisParameters={analysisParameters}
                    experiment={experiment}
                />
                <SeuratDifferentialLatentVariablesField
                    plot={plot}
                    analysisParameters={analysisParameters}
                    experiment={experiment}
                />
                {showGroupsPicker && <SeuratDifferentialGroupsPickerFields experiment={experiment} plot={plot} />}
            </section>

            <section>
                <h4
                    className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                    onClick={() => setStatisticsExpanded(!statisticsExpanded)}
                >
                    <span>Statistics</span>
                    <span>
                        <ChevronRightIcon
                            width={20}
                            className={cn(
                                'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                { 'rotate-90': statisticsExpanded },
                            )}
                        />
                    </span>
                </h4>
                <Collapse in={statisticsExpanded}>
                    <div className="space-y-6">
                        <SimpleSelectField
                            name="stat_test"
                            label={'Test to use'}
                            value={values.stat_test}
                            onChange={(e) => setFieldValue('stat_test', e.target.value)}
                            items={StatTestSelectOptions}
                        />
                        <TextInput
                            name="min_pct"
                            label="Gene expression threshold"
                            type="number"
                            value={values.min_pct}
                            labelRight={
                                <div className="ml-1 flex flex-1 items-center justify-start">
                                    <p className="flex items-center space-x-2 font-semibold text-gray-900">
                                        <Tooltip
                                            title="Set a threshold to determine the fraction of cells in either group that a gene has to be expressed in to be included in the analysis."
                                            placement="top"
                                            arrow
                                        >
                                            <div>
                                                <HelpCircleIcon height={16} width={16} color="#3343AB" />
                                            </div>
                                        </Tooltip>
                                    </p>
                                </div>
                            }
                            useNumericFormat
                            onValueChange={(value, _, __) => setFieldValue('min_pct', value ?? 0.01)}
                            allowNegativeValue={false}
                            step={0.01}
                            min={0.01}
                            max={1.0}
                            decimalScale={2}
                            decimalsLimit={2}
                        />
                    </div>
                </Collapse>
            </section>
        </>
    );
};

export default SeuratDifferentialExpressionAnalysisFormFields;

import React, { useEffect, useMemo, useState } from 'react';
import Experiment from '@models/Experiment';
import LoadingMessage from '@components/LoadingMessage';
import ButtonGroup, { ButtonGroupItem } from '@components/ButtonGroup';
import PlotDisplayForm from '@components/experiments/plotDisplay/PlotDisplayForm';
import { ScrollableSidebarContainer } from '@components/experiments/ScrollableSidebarContent';
import AnalysisView from '@components/experiments/analyses/AnalysisView';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import { EditPlotPanelName, useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { isDefined } from '@util/TypeGuards';
import Plot from '@models/Plot';
import PlotStatisticsView from '@components/experiments/PlotStatisticsView';
import { PipelineStatusAnalysis } from '@models/analysis/ExperimentAnalysis';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import Logger from '@util/Logger';
import useAnalysisPipelineVersion from '@hooks/useAnalysisPipelineVersion';
import { ArrowCircleUpIcon } from '@heroicons/react/outline';
import { Tooltip } from '@mui/material';
import { getCategoryForAnalysis } from '@models/analysis/AnalysisType';
import ConfirmModal from '@components/ConfirmModal';
import { currentChangeNameMap, prettifyCurrentChangeName } from '@/src/util/ExperimentUtil';
import { validateAnalysisInputsPlotDataReady } from './analyses/inputs/AnalysisInputFormUtil';

const logger = Logger.make('EditPlotView');

export type Props = {
    experiment: Experiment;
    plotId: string;
    className?: string;
};

const EditPlotView = ({ plotId, experiment, className }: Props) => {
    const {
        currentChanges,
        setCurrentChanges,
        nestedCurrentChanges,
        setNestedCurrentChanges,
        plotAnalysisOverrides,
        plotDisplayOptionOverrides,
        currentPlotPanel,
        setCurrentPlotPanel,
        selectedPlot,
        unsavedChangesConfirmOpen,
        setUnsavedChangesConfirmOpen,
        setExperimentModalOpen,
        tmpSelectedPlot,
        handleChangeSelectedPlot,
    } = useExperimentDetailViewContext();
    const analysisIdOverride = plotAnalysisOverrides[plotId] ?? undefined;
    const displayIdOverride = plotDisplayOptionOverrides[plotId] ?? undefined;
    const [plotPollingInterval, setPlotPollingInterval] = useState<number>(0);
    const [selectedPanel, setSelectedPanel] = useState<EditPlotPanelName | null>(null);
    const { data: plot, error: plotError } = useSWR<Plot>(
        Endpoints.lab.experiment.plot.base(
            {
                experimentId: experiment.uuid,
                plotId,
            },
            { display_id: displayIdOverride, analysis_id: analysisIdOverride },
        ),
        {
            refreshInterval: plotPollingInterval,
            fallbackData: selectedPlot ?? undefined,
            revalidateOnMount: true,
        },
    );
    const loading = !plot && !plotError;

    const hasAnalysis = isDefined(plot?.analysis?.uuid);
    const hasValidAnalysisInputs =
        plot?.analysis_form_type === 'analysis_input' ? validateAnalysisInputsPlotDataReady(plot.analysis) : true;
    const isPipelineLoading = (plot?.analysis as PipelineStatusAnalysis)?.pipeline_status === 'in_progress';
    const plotPipelineStatus = (plot?.analysis as PipelineStatusAnalysis | undefined)?.pipeline_status;

    const { upgradeAvailable: isPipelineUpdateAvailable, latestVersion } = useAnalysisPipelineVersion({
        analysis: plot?.analysis,
        experiment,
    });

    useEffect(() => {
        if (plotPipelineStatus === 'in_progress') {
            setPlotPollingInterval(10_000);
        } else {
            setPlotPollingInterval(0);
        }
    }, [plotPipelineStatus]);

    const panels = useMemo<ButtonGroupItem<EditPlotPanelName>[]>(() => {
        const isContent =
            plot?.analysis?.category?.shortname === 'content' ||
            (plot && getCategoryForAnalysis(plot.analysis_type) === 'content');
        const analysisText = isContent ? 'Set-up' : 'Analysis';
        const displayText = isContent ? 'Appearance' : 'Plot';
        const analysisPanel: ButtonGroupItem<EditPlotPanelName> = {
            value: 'analysis',
            label: isPipelineUpdateAvailable ? (
                <span className="flex items-center space-x-1">
                    <span>{analysisText}</span>
                    <Tooltip
                        title={`A new version of this pipeline is available\xa0(v${latestVersion})`}
                        arrow
                        placement="bottom-start"
                    >
                        <ArrowCircleUpIcon width={18} className="text-success" />
                    </Tooltip>
                </span>
            ) : (
                <span>{analysisText}</span>
            ),
        };

        const statisticPanel: ButtonGroupItem<EditPlotPanelName> = {
            value: 'statistics',
            label: 'Statistics',
            disabled: !hasAnalysis || isPipelineLoading || !hasValidAnalysisInputs,
            tooltip:
                hasAnalysis && !isPipelineLoading
                    ? undefined
                    : {
                          title: isPipelineLoading
                              ? 'Statistics will be available once data has finished processing'
                              : 'Submit analysis before proceeding',
                          arrow: true,
                          placement: 'bottom',
                      },
        };
        const plotPanel: ButtonGroupItem<EditPlotPanelName> = {
            value: 'plot',
            label: displayText,
            disabled: !hasAnalysis || isPipelineLoading || !hasValidAnalysisInputs,
            tooltip:
                hasAnalysis && !isPipelineLoading && hasValidAnalysisInputs
                    ? undefined
                    : {
                          title: isPipelineLoading
                              ? 'Plot settings will be available once data has finished processing'
                              : 'Submit analysis before proceeding',
                          arrow: true,
                          placement: 'bottom',
                      },
        };

        let panels: ButtonGroupItem<EditPlotPanelName>[];
        switch (plot?.analysis_type) {
            case 'assay_summary':
            case 'assay_summary_cpm_normalized':
                panels = [analysisPanel, plotPanel, statisticPanel];
                break;
            default:
                panels = [analysisPanel, plotPanel];
                break;
        }

        return panels;
    }, [hasAnalysis, plot, isPipelineLoading, isPipelineUpdateAvailable, latestVersion]);

    useEffect(() => {
        // Ensure the current panel is actually available and if not fall back to the analysis tab.
        // This is useful for when someone might try to edit a plot that isn't ready yet,
        // or if the last panel selected was Statistics and is not a valid option for the current plot
        const hasPanel = panels.some((p) => p.value === currentPlotPanel && !p.disabled);
        if (!hasPanel && !loading) {
            logger.warn('[useEffect] panels and currentPlotPanel - setting current panel to analysis', {
                panels,
                currentPlotPanel,
            });
            setCurrentPlotPanel(panels[0]?.value ?? 'analysis');
        }
    }, [panels, currentPlotPanel, loading]);

    const getPanelContent = () => {
        if (!plot || loading || !experiment) {
            return (
                <div>
                    <LoadingMessage message="Loading..." />
                </div>
            );
        }

        const isPanelEnabled = panels.some((p) => p.value === currentPlotPanel && !p.disabled);

        if (!isPanelEnabled) {
            logger.warn('panel is not yet enabled:', currentPlotPanel);
            return 'This panel is not yet enabled.';
        }

        switch (currentPlotPanel) {
            case 'analysis':
                return <AnalysisView key={`${plotId}_analysis`} plot={plot} experiment={experiment} />;
            case 'plot':
                return <PlotDisplayForm key={`${plotId}_plot`} plot={plot} experiment={experiment} />;
            case 'statistics':
                return <PlotStatisticsView key={`${plotId}_statistics`} plot={plot} experiment={experiment} />;
            default:
                return <div>No panel found for tab {currentPlotPanel}</div>;
        }
    };

    useEffect(
        () => () => {
            setCurrentChanges(null);
            setNestedCurrentChanges(null);
        },
        [],
    );

    return (
        <ScrollableSidebarContainer key={plot?.uuid} data-cy="edit-plot-sidebar" className={className}>
            <div className="px-8 pb-4 pt-4 xl:pt-8">
                <ButtonGroup
                    cyId={'plot-tabs'}
                    items={panels}
                    onChange={(panel) => {
                        setSelectedPanel(panel ?? 'analysis');
                        if ((!currentChanges && !nestedCurrentChanges) || currentPlotPanel === 'statistics') {
                            setCurrentChanges(null);
                            setNestedCurrentChanges(null);
                            setCurrentPlotPanel(panel ?? 'analysis');
                        } else {
                            setUnsavedChangesConfirmOpen('changePlotPanel');
                        }
                    }}
                    toggle={false}
                    value={currentPlotPanel}
                />
            </div>
            <PlutoErrorBoundary>{getPanelContent()}</PlutoErrorBoundary>
            <ConfirmModal
                open={!!unsavedChangesConfirmOpen}
                onConfirm={() => {
                    switch (unsavedChangesConfirmOpen) {
                        case 'changePlotPanel':
                            setCurrentPlotPanel(selectedPanel ?? 'analysis');
                            break;
                        case 'changeSelectedPlot':
                            handleChangeSelectedPlot(tmpSelectedPlot);
                            break;
                        case 'createAnalysis':
                            handleChangeSelectedPlot(null);
                            setCurrentPlotPanel('analysis');
                            setExperimentModalOpen?.(true);
                            break;
                        case 'closeModal':
                            setExperimentModalOpen?.(false);
                            handleChangeSelectedPlot(null);
                        default:
                            break;
                    }
                    setUnsavedChangesConfirmOpen('');
                    setTimeout(() => {
                        setCurrentChanges(null);
                        setNestedCurrentChanges(null);
                    }, 300);
                }}
                onCancel={() => {
                    setUnsavedChangesConfirmOpen('');
                }}
                title="Unsaved Changes"
                message={
                    <>
                        <p>
                            You have unsaved changes to:{' '}
                            {nestedCurrentChanges ? (
                                <span className="font-bold capitalize">{nestedCurrentChanges}</span>
                            ) : (
                                <span className="font-bold capitalize">
                                    {currentChanges &&
                                        Object.keys(currentChanges)
                                            .map((field) => {
                                                const prettifiedNameIfApplicable = prettifyCurrentChangeName(
                                                    field as keyof typeof currentChangeNameMap,
                                                );
                                                return prettifiedNameIfApplicable.replaceAll('_', ' ');
                                            })
                                            .join(', ')}
                                </span>
                            )}
                            .
                        </p>
                        <p>Are you sure you want to leave this tab?</p>
                    </>
                }
                confirmText="Yes, discard my changes"
                cancelText="No"
            />
        </ScrollableSidebarContainer>
    );
};

export default EditPlotView;

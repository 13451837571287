import { useEffect } from 'react';

type FormikListenerProps = {
    values: any;
    callback: (values: any) => void;
};
const FormikListener = ({ values, callback }: FormikListenerProps): null => {
    useEffect(() => {
        callback(values);
    }, [callback, values]);

    return null;
};

export default FormikListener;

import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import DifferentialExpressionAnalysisPickerField from '@components/experiments/analyses/fields/DifferentialExpressionAnalysisPickerField';
import TFTargetCollectionPickerField from '@components/experiments/analyses/fields/TFTargetCollectionPickerField';
import React from 'react';
import { useFormikContext } from 'formik';
import { TranscriptionFactorEnrichmentAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import {
    DifferentialExpressionPickerFieldTypes,
    TranscriptionFactorEnrichmentAnalysisParameters,
} from '@models/AnalysisParameters';
import SimpleCollapse from '@components/experiments/SimpleCollapse';
import PValueField from '@components/experiments/plotDisplay/fields/PValueField';
import TextInputField from '@components/forms/TextInputField';
import SingleSelectField from '@components/filters/fields/SingleSelectField';
import { formatSmallNumber } from '@util/StringUtil';

type Props = {
    plot: Plot;
    analysisParameters: TranscriptionFactorEnrichmentAnalysisParameters;
    experiment: Experiment;
};
const TranscriptionFactorEnrichmentAnalysisFormFields = ({ plot, experiment }: Props) => {
    const { values } = useFormikContext<TranscriptionFactorEnrichmentAnalysisFormValues>();
    return (
        <div className="space-y-8">
            <div className="space-y-6">
                <TFTargetCollectionPickerField
                    experiment={experiment}
                    plot={plot}
                    name="tf_target_collection_shortname"
                    tooltip={{
                        title: 'Curated TF-target gene set libraries assembled by ChEA3. \nTap to learn more.',
                        href: 'https://maayanlab.cloud/chea3/index.html#content4-o',
                    }}
                />
                <div className="rounded-xl border border-indigo-500 p-4">
                    <DifferentialExpressionAnalysisPickerField
                        experimentId={experiment.uuid}
                        name="differential_analysis_id"
                        analysisTypeFieldName="differential_analysis_shortname"
                        filter={{ analysis_types: DifferentialExpressionPickerFieldTypes }}
                        tooltip={{
                            title: 'Differential analyses you have created will appear in this list. \nTap to learn more.',
                            href: 'https://help.pluto.bio/en/articles/6013736-adding-comparisons-for-gene-set-enrichment-analysis',
                        }}
                    />
                    <SimpleCollapse label={'Thresholds'} initialOpen={false}>
                        <div className="space-y-4">
                            <PValueField
                                name="adj_pval_threshold"
                                hideSectionLabel
                                showHint
                                noMargin
                                label={
                                    <span>
                                        Adjusted <i>p</i>-value threshold
                                    </span>
                                }
                            />

                            <TextInputField
                                name="log2_fc_threshold"
                                type="number"
                                step={0.1}
                                noMargin
                                label={
                                    <span>
                                        Absolute log<sub>2</sub> fold change threshold
                                    </span>
                                }
                                hint={
                                    <p>
                                        <span className="block">
                                            Positive fold change ={' '}
                                            {Number.parseFloat(
                                                formatSmallNumber({ value: Math.pow(2, values.log2_fc_threshold) }),
                                            )}
                                        </span>
                                        <span className="block">
                                            Negative fold change ={' '}
                                            {Number.parseFloat(
                                                formatSmallNumber({ value: Math.pow(2, -values.log2_fc_threshold) }),
                                            )}
                                        </span>
                                    </p>
                                }
                            />
                            <SingleSelectField
                                name="log2_fc_direction"
                                label={'Direction'}
                                options={[
                                    {
                                        value: 'both',
                                        label: 'Both positive and negative',
                                    },
                                    { value: 'positive', label: 'Positive only' },
                                    { value: 'negative', label: 'Negative only' },
                                ]}
                            />
                        </div>
                    </SimpleCollapse>
                </div>
            </div>
        </div>
    );
};

export default TranscriptionFactorEnrichmentAnalysisFormFields;

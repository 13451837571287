import { capitalize, Dialog, DialogContent } from '@mui/material';
import cn from 'classnames';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import Experiment from '@models/Experiment';
import EditPlotModalView from '@components/experiments/EditPlotModalView';
import useExperimentSettings from '@hooks/useExperimentSettings';
import AnalysisIcon from '@components/experiments/analyses/AnalysisIcon';
import { defaultTextLightClass } from '@components/icons/CustomIcons';
import PreprocessFormModalView from '@components/experiments/preprocesses/PreprocessFormModalView';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import AnnotationFormModalView from './experiments/annotations/AnnotationFormModalView';
import { useExperimentAnnotationContext } from '@contexts/ExperimentAnnotationContext';
import DataTableModalView from './experiments/DataTableModalView';
import AnalysisCategoryNavigation from './experiments/analysisModal/AnalysisCategoryNavigation';
import { useExperimentCanvasContext } from '@contexts/ExperimentCanvasContext';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import useFetchExperiment from '../hooks/useFetchExperiment';
import { useActiveExperimentContext } from '../contexts/ActiveExperimentContext';
import { useEffect } from 'react';
import { hasPermission } from '../util/PermissionUtil';
import { PermissionName } from '../models/Permission';

type Props = {
    hideEditForm?: boolean;
    onClose: () => void;
    open: boolean;
    openOnComments?: boolean;
    openOnPlot?: boolean;
    targetExperimentId: string | null;
};

const ExperimentModal = ({
    hideEditForm = false,
    onClose,
    open,
    openOnComments = false,
    openOnPlot = false,
    targetExperimentId,
}: Props) => {
    const { selectedPlot, experiment: contextExperiment } = useExperimentDetailViewContext();
    const { selectedNode } = useExperimentCanvasContext();
    const { selectedPreprocess } = useExperimentWorkflowContext();
    const { selectedAnnotationSet } = useExperimentAnnotationContext();
    const { experiment: fetchedTargetExperiment } = useFetchExperiment({
        experimentId: targetExperimentId || null,
    });
    const { experiment: fetchedLinkedExperiment } = useFetchExperiment({
        experimentId: selectedPlot?.linked_experiment_id || null,
    });

    const { activeExperiment, setActiveExperiment } = useActiveExperimentContext();

    const experiment = fetchedTargetExperiment || fetchedLinkedExperiment || activeExperiment || contextExperiment;
    const canEdit = hasPermission(experiment, {
        requires: [PermissionName.edit_experiment],
    });

    useEffect(() => {
        if (fetchedTargetExperiment) {
            return setActiveExperiment(fetchedTargetExperiment);
        }
        if (fetchedLinkedExperiment) {
            return setActiveExperiment(fetchedLinkedExperiment);
        }
    }, []);

    const { getAnalysisInfo } = useExperimentSettings(experiment, {
        skip: !!(selectedPlot?.linked_experiment_id && !fetchedLinkedExperiment),
    });
    const analysis = selectedPlot?.analysis_type ? getAnalysisInfo(selectedPlot.analysis_type) : '';

    if (targetExperimentId && !fetchedTargetExperiment) return;
    if (selectedPlot?.linked_experiment_id && !fetchedLinkedExperiment) return;

    const getTitle = () => {
        if (selectedPreprocess) {
            return selectedPreprocess.preprocess_type.display_name;
        }
        if (selectedAnnotationSet) {
            return canEdit ? 'Edit cluster annotations' : 'View cluster annotations';
        }
        if (analysis) {
            return `${analysis.display_name} analysis`;
        }
        if (selectedNode?.data?.selectType) {
            return (
                selectedNode.data.selectedPlot?.analysis?.name ||
                `${capitalize(selectedNode?.data?.selectType) || 'Experiment'} data`
            );
        }
        return 'Select an analysis to begin';
    };

    const renderModalContent = () => {
        if (selectedPreprocess) {
            return <PreprocessFormModalView experiment={experiment as Experiment} openOnComments={openOnComments} />;
        }
        if (selectedPlot) {
            return (
                <EditPlotModalView
                    activeExperiment={experiment}
                    hideEditForm={hideEditForm}
                    isLinkedExperiment={!!selectedPlot.linked_experiment_id}
                    openOnComments={openOnComments}
                    openOnPlot={openOnPlot}
                    plot={selectedPlot}
                />
            );
        }
        if (selectedAnnotationSet) {
            return <AnnotationFormModalView experiment={experiment as Experiment} />;
        }
        if (selectedNode?.data?.selectType) {
            return <DataTableModalView experiment={experiment as Experiment} selectedDataNode={selectedNode.data} />;
        }
        return <AnalysisCategoryNavigation experiment={experiment as Experiment} />;
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="xl"
            PaperProps={{
                style: {
                    minHeight: 'calc(100% - 64px)',
                    maxHeight: 'calc(100% - 64px)',
                    height: '100%',
                    width: '100%',
                    maxWidth: '100%',
                },
            }}
        >
            <DialogCloseButton onClose={onClose} tooltext="Close" />
            <PlutoDialogTitle
                title={getTitle()}
                iconStart={
                    analysis && (
                        <AnalysisIcon
                            shortname={analysis.shortname}
                            width={24}
                            height={24}
                            className={cn({
                                'text-gray-500': !analysis.is_enabled,
                                [defaultTextLightClass]: analysis.is_enabled,
                            })}
                        />
                    )
                }
            />
            <DialogContent
                className={cn('flex flex-col !overflow-hidden', {
                    'relative border-t-2 border-t-gray-200 !p-0':
                        selectedPlot || selectedPreprocess || selectedAnnotationSet || selectedNode,
                })}
            >
                <PlutoErrorBoundary>{renderModalContent()}</PlutoErrorBoundary>
            </DialogContent>
        </Dialog>
    );
};
export default ExperimentModal;

import { useEffect, useState } from 'react';
import { Button, CircularProgress, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { CheckIcon, ExclamationIcon, PauseIcon, PlayIcon, RefreshIcon } from '@heroicons/react/outline';
import Experiment from '@models/Experiment';
import useResumableFileUpload from '@hooks/useResumableFileUpload';
import { FileDataType } from '@models/PlutoFile';
import { humanFileSize } from '@util/StringUtil';
import cn from 'classnames';
import { XIcon } from '@heroicons/react/solid';
import ConfirmModal from '@components/ConfirmModal';
import { ProgressEventInfo } from '@services/ApiService';
import Logger from '@util/Logger';
import UploadSession from '@models/UploadSession';
import { ApiError } from 'next/dist/server/api-utils';

const logger = Logger.make('ResumableUploadFileItem');

export type ResumableUploadError = Error | { message?: string } | ApiError | null;
type Props = {
    data_type: FileDataType;
    experiment: Experiment;
    file: File;
    onDelete?: () => void;
    onProgress?: (progress: ProgressEventInfo | null) => void;
    onUploadComplete?: (session: UploadSession) => void;
    onUploadError?: (file: File, error: ResumableUploadError) => void;
};
const ResumableUploadFileItem = ({
    data_type,
    experiment,
    file,
    onDelete,
    onProgress,
    onUploadComplete,
    onUploadError,
}: Props) => {
    const {
        sessionLoading,
        uploadFile,
        uploadError,
        uploading,
        resumeUpload,
        complete,
        progress,
        pauseUpload,
        cancelUpload,
        pausePending,
        resuming,
    } = useResumableFileUpload({
        experiment,
        file,
        data_type,
        onUploadComplete,
        onProgress,
    });

    const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);

    useEffect(() => {
        logger.info('[useEffect] uploadFile()');
        uploadFile();
        return () => {
            pauseUpload();
        };
    }, [file]);
    useEffect(() => {
        if (uploadError) {
            onUploadError?.(file, uploadError);
        }
    }, [uploadError]);

    const handleDelete = async () => {
        if (confirmRemoveOpen) {
            await cancelUpload();
            setConfirmRemoveOpen(false);
            onDelete?.();
        } else {
            setConfirmRemoveOpen(true);
            pauseUpload();
        }
    };

    const isValidating = (uploading || sessionLoading || resuming) && !progress;

    return (
        <div className="space-y-2 rounded-lg border border-indigo-100 bg-indigo-50 px-4 py-2">
            <div className="flex justify-between">
                <div className="flex flex-1 flex-col space-y-1 pr-4">
                    <p className="break-all font-semibold">{file.name}</p>
                    {!uploadError && (
                        <div className="flex items-center space-x-2">
                            {(!progress || complete) && (
                                <p className="text-sm text-gray-500">{humanFileSize(file.size)}</p>
                            )}
                            {complete && (
                                <span className="flex items-center space-x-0.5">
                                    <CheckIcon className="text-success" width={18} />
                                    <span className="text-xs">Upload complete</span>
                                </span>
                            )}
                            {isValidating && (
                                <span className="flex items-center space-x-1">
                                    <CircularProgress size={10} variant="indeterminate" />
                                    <span className="text-xs">
                                        {resuming ? 'Resuming previous upload' : 'Starting upload'}
                                    </span>
                                </span>
                            )}
                            {progress && !complete && progress.percentage === 100 ? (
                                <span className="flex items-center space-x-1">
                                    <CircularProgress size={10} variant="indeterminate" />
                                    <span className="text-xs">Finishing upload...</span>
                                </span>
                            ) : null}

                            {progress && !complete && progress.percentage < 100 && (
                                <>
                                    <span className="text-sm text-gray-500">
                                        <span>{humanFileSize(progress.loaded)}</span>/
                                        <span>{humanFileSize(file.size)}</span>{' '}
                                        <span>({(progress?.percentage ?? 0).toFixed(2)}%)</span>
                                    </span>
                                    {(uploading || pausePending) && (
                                        <div className="flex items-center">
                                            <Tooltip
                                                title="Pause the upload once the current chunk has finished uploading."
                                                arrow
                                                placement="right"
                                                enterDelay={500}
                                            >
                                                <IconButton
                                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                                    onClick={() => pauseUpload()}
                                                    size="small"
                                                    disabled={pausePending}
                                                >
                                                    <PauseIcon
                                                        width={20}
                                                        className={cn({
                                                            'text-indigo-500': !pausePending,
                                                            'text-gray-500': pausePending,
                                                        })}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            {pausePending && <span className="text-xs text-gray-500">Pausing...</span>}
                                        </div>
                                    )}
                                    {!uploading && !complete && !pausePending && (
                                        <Tooltip
                                            title="Resume the file upload"
                                            enterDelay={500}
                                            arrow
                                            placement="right"
                                        >
                                            <IconButton
                                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                                onClick={() => resumeUpload()}
                                                size="small"
                                                disabled={pausePending}
                                            >
                                                <PlayIcon width={20} className="text-indigo-500" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {uploadError && (
                        <div className="flex w-full flex-1 flex-wrap justify-between gap-1">
                            <div className="flex items-center space-x-1">
                                <div className="mr-2 rounded-full bg-error p-1">
                                    <ExclamationIcon width={16} className="text-error" />
                                </div>
                                <p>{uploadError.message ?? 'Unable to upload file'}</p>
                            </div>
                            <Button variant="text" size="small" onClick={() => uploadFile()}>
                                <span className="flex text-left leading-5 text-error">
                                    <RefreshIcon width={20} className="mr-1 text-error" /> Retry Upload
                                </span>
                            </Button>
                        </div>
                    )}
                </div>

                <div className="flex flex-col">
                    {!complete && (
                        <div className="flex items-start">
                            <Tooltip
                                enterDelay={500}
                                title="Cancel the file upload and remove the file from the queue."
                                arrow
                                placement="top"
                            >
                                <IconButton
                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                    onClick={() => handleDelete()}
                                    size="small"
                                >
                                    <XIcon width={18} className="text-error" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
            {!complete && progress && (
                <div>
                    <LinearProgress variant="determinate" value={progress.percentage} color="primary" />
                </div>
            )}
            <ConfirmModal
                onConfirm={() => {
                    handleDelete();
                }}
                onCancel={() => {
                    setConfirmRemoveOpen(false);
                    resumeUpload();
                }}
                open={confirmRemoveOpen}
                title="Remove file upload"
                confirmText="Remove file"
                cancelText="Continue uploading"
                message={
                    <div className="space-y-4">
                        <p>
                            Are you sure you want to cancel uploading the following file? This action can not be undone.
                        </p>
                        <p className="break-all rounded-md bg-gray-100 p-2 font-mono text-sm">{file.name}</p>
                        <p>
                            <span className="font-semibold">Note:</span> You can pause the file upload at any time and
                            come back to it later.
                        </p>
                    </div>
                }
            />
        </div>
    );
};

export default ResumableUploadFileItem;

import { usePlotContext } from '@contexts/PlotContext';
import useApi from '@hooks/useApi';
import Plot from '@models/Plot';
import Endpoints from '@services/Endpoints';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import { PlotDisplayOption } from '@models/PlotDisplayOption';
import { useSWRConfig } from 'swr';
import { getFormSetup } from '@components/experiments/analyses/AnalysisFormUtil';
import { getPlotDisplayFormSetup } from '@components/experiments/plotDisplay/PlotDisplayFormUtil';
import { useState } from 'react';
import Logger from '@util/Logger';
import { useOptionalExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { AnalysisShortname } from '@models/analysis/AnalysisType';
import useAnalysisParameters from '@hooks/useAnalysisParameters';
import { useOptionalExperimentCanvasContext } from '../contexts/ExperimentCanvasContext';
import { Node } from 'reactflow';
import { defaultPlotNodeStyle } from '../components/plutoflow/PlotNode';
import Experiment from '../models/Experiment';

const logger = Logger.make('useClonePlot');

export const disabledCloneAnalysisTypes: AnalysisShortname[] = ['image', 'prism', 'external'];

const useClonePlot = ({ publicKey, experiment }: { publicKey?: string | null; experiment?: Experiment | null }) => {
    const { plot, experiment: contextExperiment } = usePlotContext();

    const activeExperiment = experiment || contextExperiment;
    const { analysisParameters = null } = useAnalysisParameters({
        plot,
        experiment: activeExperiment,
        publicKey,
    });

    const { mutate } = useSWRConfig();
    const { updatePlotDisplayOrder, plotListItems, refreshPlotItems } = useOptionalExperimentDetailViewContext();
    const { postNewNode } = useOptionalExperimentCanvasContext();
    const api = useApi();
    const [cloning, setCloning] = useState(false);
    const experimentId = activeExperiment?.uuid;

    const cloneDisabled = !plot || disabledCloneAnalysisTypes.includes(plot?.analysis_type);

    const cloneAnalysis = async <T extends ExperimentAnalysis>(
        analysis?: T | null,
        display?: PlotDisplayOption,
    ): Promise<T | null> => {
        try {
            if (!analysis || !plot) {
                return null;
            }

            const displaySetup = display
                ? getPlotDisplayFormSetup({
                      display: display,
                      analysis,
                      displayType: display?.display_type,
                  })
                : undefined;

            const display_analysis_values = displaySetup?.initialValues?.analysis_values;
            const legend_setup = displaySetup?.initialValues?.legend;

            const setup = getFormSetup({ plot, experiment: activeExperiment, analysisParameters });
            if (!setup) {
                return null;
            }

            const { initialValues: values } = setup;

            if (display_analysis_values) {
                Object.entries(display_analysis_values).forEach(([key, value]) => {
                    (values as any)[key] = value;
                });
                logger.debug('setting display form values on analysis', display_analysis_values);
            }

            if (legend_setup?.group_display_order) {
                values.group_display_order = legend_setup.group_display_order;
            }
            if (plot?.source_experiment_id) {
                values.source_experiment_id = plot.source_experiment_id;
            }

            return await api.post<T>(Endpoints.lab.experiment.analyses(experimentId), values);
        } catch (error) {
            logger.error(error);
            return null;
        }
    };

    /**
     * Clone a display object into a given plotId
     */
    const cloneDisplayOption = async <T extends PlotDisplayOption>({
        display,
        plotId,
    }: {
        display: T | null | undefined;
        plotId: string;
    }): Promise<T | null> => {
        try {
            if (!display || !plot) {
                return null;
            }

            const setup = getPlotDisplayFormSetup({
                analysis: plot.analysis,
                display,
                displayType: display.display_type,
            });
            if (!setup) {
                return null;
            }

            const { initialValues: value } = setup;

            if (plot?.source_experiment_id) {
                value.source_experiment_id = plot.source_experiment_id;
            }

            return await api.post<T>(Endpoints.lab.experiment.plot.displays({ experimentId, plotId }), value);
        } catch (error) {
            logger.error(error);
            return null;
        }
    };

    const legacyClone = async () => {
        const { display, analysis, analysis_type } = plot;

        const payload: { analysis_type: string; display_type: string; source_experiment_id?: string } = {
            analysis_type,
            display_type: display.display_type,
        };
        if (plot?.source_experiment_id) {
            payload.source_experiment_id = plot.source_experiment_id;
        }
        const clone = await api.post<Plot>(
            Endpoints.lab.experiment.plotsV2({
                experimentId,
            }),
            payload,
        );

        const clonedPlotId = clone.uuid;
        const clonedAnalysis = await cloneAnalysis(analysis, display);
        const clonedDisplay = clonedAnalysis ? await cloneDisplayOption({ display, plotId: clonedPlotId }) : null;

        if (clonedAnalysis) {
            const updateParams: { display_id?: string; analysis_id?: string } = {
                display_id: clonedDisplay?.uuid,
                analysis_id: clonedAnalysis?.uuid,
            };
            await api.put(Endpoints.lab.experiment.plot.base({ experimentId, plotId: clonedPlotId }), updateParams);

            // link the new analysis and display to the new plot
            await api.post(
                Endpoints.lab.experiment.plot.linkAnalysis({
                    experimentId,
                    plotId: clonedPlotId,
                }),
                { analysis_id: clonedAnalysis.uuid, display_id: clonedDisplay?.uuid },
            );
        }

        return clonedPlotId;
    };

    const cloneThroughApi = async () => {
        const newPlot = await api.post<Plot>(
            Endpoints.lab.experiment.plot.clone({
                experimentId,
                plotId: plot.uuid,
            }),
        );
        return newPlot?.uuid;
    };
    const ANALYSIS_INPUT_TYPES = ['multiomics_overlap_genes', 'overlap'];
    const clonePlot = async ({
        cloneToCanvas = false,
        linkedExperimentId,
    }: {
        cloneToCanvas?: boolean;
        linkedExperimentId?: string;
    }) => {
        if (!plot || !experiment) {
            throw new Error('Can not clone plot. Missing required parameters');
        }
        setCloning(true);
        try {
            let clonedPlotId = '';
            if (ANALYSIS_INPUT_TYPES.includes(plot.analysis_type)) {
                clonedPlotId = await cloneThroughApi();
            } else {
                clonedPlotId = await legacyClone();
            }

            if (plotListItems && plotListItems.length > 0) {
                const sortedPlots = [...plotListItems].filter((p) => p.uuid !== clonedPlotId);
                const updatedOrder: string[] = sortedPlots.map((p) => p.uuid);
                const originalIndex = sortedPlots.findIndex((p) => p.uuid === plot.uuid);
                updatedOrder.splice(originalIndex + 1, 0, clonedPlotId);
                await updatePlotDisplayOrder?.(updatedOrder);
            }

            if (cloneToCanvas) {
                const nodeData: Node['data'] = {
                    plotId: clonedPlotId,
                    legendIsScrollable: true,
                    linkedExperimentId,
                };
                await postNewNode?.({ nodeData, nodeType: 'plot', nodeStyle: defaultPlotNodeStyle });
            }

            await Promise.all([
                mutate(
                    Endpoints.lab.experiment.plot.base({
                        experimentId: linkedExperimentId || experiment.uuid,
                        plotId: clonedPlotId,
                    }),
                ),
                mutate(
                    Endpoints.lab.experiment.plot.data({
                        experimentId: linkedExperimentId || experiment.uuid,
                        plotId: plot.uuid,
                    }),
                ),
                refreshPlotItems?.(),
            ]);
        } catch (error) {
            logger.error(error);
        } finally {
            setCloning(false);
        }
    };

    return { clonePlot, cloning, cloneDisabled };
};

export default useClonePlot;

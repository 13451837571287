import { blankToUndefined } from '@util/StringUtil';
import React, { ChangeEvent, ReactNode } from 'react';
import { useField } from 'formik';
import { AssayDataUnits } from '@models/Experiment';
import TextInputField from '@components/forms/TextInputField';
import cn from 'classnames';
import CustomLegendColorField, { CustomLegendColorItem } from './CustomLegendColorField';

type Props = Pick<AssayDataUnits, 'units' | 'units_display_name'> & {
    className?: string;
    colorEndName?: string;
    colorStartName?: string;
    endColor?: string;
    endHint?: ReactNode;
    endLabel?: ReactNode;
    endName: string;
    endPlaceholder?: string;
    startColor?: string;
    startHint?: ReactNode;
    startLabel?: ReactNode;
    startName: string;
    startPlaceholder?: string;
    step?: number;
};
const RangeFieldGroup = ({
    className,
    colorEndName,
    colorStartName,
    endColor,
    endHint,
    endLabel,
    endName,
    endPlaceholder,
    startColor,
    startHint,
    startLabel,
    startName,
    startPlaceholder,
    step = 0.1,
    units_display_name,
    units,
}: Props) => {
    const unitHint = units?.display_name ?? units_display_name ?? '';
    const [, , startHelpers] = useField<number | null>(startName);
    const [, , endHelpers] = useField<number | null>(endName);

    const startItems: CustomLegendColorItem[] = [
        {
            id: colorStartName ?? '',
            label: 'Minimum Color',
            labelName: '',
            themeColor: startColor ?? '',
        },
    ];
    const endItems: CustomLegendColorItem[] = [
        {
            id: colorEndName ?? '',
            label: 'Maximum Color',
            labelName: '',
            themeColor: endColor ?? '',
        },
    ];

    return (
        <div className={cn('grid grid-cols-2 gap-x-2', className)}>
            <TextInputField
                name={startName}
                label={startLabel ?? 'Start'}
                placeholder={startPlaceholder ?? ''}
                type="number"
                step={step}
                hint={startHint ?? unitHint}
                noMargin
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    startHelpers.setValue(blankToUndefined(e.target.value) ? Number(e.target.value) : null)
                }
                componentRight={
                    !!colorStartName && (
                        <div className="ml-3 mr-1">
                            <CustomLegendColorField
                                hideLabel
                                items={startItems}
                                bottomOffsetClassname="-top-96 mb-8"
                                leftOffsetClassName="-translate-x-32"
                                fieldValue={colorStartName}
                            />
                        </div>
                    )
                }
            />
            <TextInputField
                name={endName}
                label={endLabel ?? 'End'}
                placeholder={endPlaceholder ?? ''}
                type="number"
                step={step}
                hint={endHint ?? unitHint}
                noMargin
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    endHelpers.setValue(blankToUndefined(e.target.value) ? Number(e.target.value) : null)
                }
                componentRight={
                    !!colorEndName && (
                        <div className="ml-3 mr-1">
                            <CustomLegendColorField
                                hideLabel
                                items={endItems}
                                bottomOffsetClassname="-top-96 mb-8"
                                leftOffsetClassName="-translate-x-64"
                                fieldValue={colorEndName}
                            />
                        </div>
                    )
                }
            />
        </div>
    );
};

export default RangeFieldGroup;

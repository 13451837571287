import Experiment, {
    CopyExperimentFromWorkflowParams,
    DESCRIPTION_CHARACTER_LIMIT,
    LINK_NAME_CHARACTER_LIMIT,
} from '@models/Experiment';
import { Form, Formik, FormikHelpers } from 'formik';
import Button from '@components/Button';
import useApi from '@hooks/useApi';
import * as Yup from 'yup';
import React, { ChangeEvent, useMemo } from 'react';
import Logger from '@util/Logger';
import { ApiError } from '@services/ApiError';
import { blankToNull, isBlank, isNotBlank } from '@util/StringUtil';
import ProjectSelect from '@components/dashboard/ProjectSelect';
import { Project } from '@models/Project';
import TextInputField from '@components/forms/TextInputField';
import useLabPermissions from '@hooks/useLabPermissions';
import { Tooltip, Alert } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import EditExperimentReadOnlyFields from '@components/experiments/EditExperimentReadOnlyFields';
import { useRouter } from 'next/router';
import Workflow from '@models/Workflow';
import Endpoints from '@/src/services/Endpoints';
import SelectableItem from '../../forms/SelectableItem';
import HierarchyIcon from '../../icons/custom/HierarchyIcon';

const logger = Logger.make('CopyExperimentFromWorkflowForm');
type FormValues = CopyExperimentFromWorkflowParams;
const schema: Yup.SchemaOf<FormValues> = Yup.object({
    name: Yup.string().required('Please enter an experiment name'),
    project_id: Yup.string().required('Please choose a project'),
    external_url: Yup.string().url('Please enter a valid url. Be sure to include http(s)://').nullable(),
    external_url_display_name: Yup.string()
        .nullable()
        .when('external_url', {
            is: (url: string) => !isBlank(url),
            then: Yup.string()
                .required('Display text is required when adding a link')
                .nullable()
                .max(LINK_NAME_CHARACTER_LIMIT, 'Character limit reached'),
            otherwise: Yup.string().nullable(),
        }),
    description: Yup.string().nullable().max(DESCRIPTION_CHARACTER_LIMIT, 'Character limit reached'),
});

type Props = {
    experiment: Experiment;
    workflow: Workflow;
    onSuccess?: (copiedExperiment: Experiment) => void;
    navigateOnSuccess?: boolean;
};
const CopyExperimentFromWorkflowForm = ({ experiment, onSuccess, navigateOnSuccess = true, workflow }: Props) => {
    const api = useApi();
    const { canCreateProject } = useLabPermissions();
    const router = useRouter();
    const initialValues = useMemo<FormValues>(
        () => ({
            description: experiment.description ?? '',
            external_url_display_name: experiment.external_url_display_name,
            external_url: experiment.external_url,
            name: `Copy of ${experiment.name}`,
            project_id: experiment.project?.uuid,
        }),
        [experiment],
    );
    const handleSubmit = async (values: FormValues, formik: FormikHelpers<FormValues>) => {
        logger.debug('submitting form values', values);
        const processedValues: CopyExperimentFromWorkflowParams = {
            description: blankToNull(values.description),
            external_url_display_name: blankToNull(values.external_url_display_name),
            external_url: blankToNull(values.external_url),
            name: values.name,
            project_id: blankToNull(values.project_id),
        };
        formik.setStatus(undefined);
        try {
            const copiedExperiment = await api.post<Experiment>(
                Endpoints.lab.experiment.workflow.copyExperiment({
                    experimentId: experiment.uuid,
                    workflowId: workflow.uuid,
                }),
                processedValues,
            );

            if (navigateOnSuccess) {
                await router.push(`/experiments/${copiedExperiment?.pluto_id}`);
            } else {
                formik.setSubmitting(false);
            }
            onSuccess?.(copiedExperiment);
        } catch (error) {
            logger.error(error);
            formik.setStatus(ApiError.getMessage(error as Error));
            formik.setSubmitting(false);
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} key={experiment.uuid} validationSchema={schema}>
            {({ values, errors, touched, setFieldValue, isSubmitting, handleChange, status }) => (
                <Form>
                    {status && (
                        <Alert severity="error" className="mb-4">
                            {status}
                        </Alert>
                    )}
                    <div>
                        <p className="font-semibold tracking-tight text-default">Starting data source</p>
                        <div className="mb-4 mt-2 flex flex-row">
                            <SelectableItem selected={true} className="mb-2 mr-2 flex-1">
                                <p className="font-semibold">Partially processed data</p>
                                <p>
                                    Continue preprocessing - copy over samples and the selected, in-progress workflow (
                                    <span className="font-semibold">{workflow.name}</span>).
                                </p>
                            </SelectableItem>
                            <div className="flex-1" />
                        </div>
                        <ProjectSelect
                            name="project_id"
                            label="Project"
                            value={values.project_id ?? ''}
                            error={errors.project_id && touched.project_id && errors.project_id}
                            disablePermissionDenied={true}
                            hidePermissionDenied={true}
                            showAddProject={canCreateProject}
                            onChange={(projectId) => setFieldValue('project_id', projectId)}
                            onProjectCreated={(project: Project) => {
                                setFieldValue('project_id', project.uuid);
                            }}
                        />
                        <TextInputField name="name" label="Name" />
                        <TextInputField
                            name="external_url"
                            placeholder="https://dropbox.com/home/My_Experiment"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(e);
                                const updatedValue = e.target.value;
                                if (isBlank(updatedValue)) {
                                    setFieldValue('external_url_display_name', '');
                                }
                            }}
                            label={
                                <>
                                    Add link
                                    <Tooltip
                                        title="Add a link to external data source, published paper, etc."
                                        placement="top"
                                        arrow
                                    >
                                        <InfoOutlined
                                            fontSize="small"
                                            className="-mt-1 ml-1 cursor-pointer text-gray-400"
                                        />
                                    </Tooltip>
                                </>
                            }
                        />
                        {isNotBlank(values.external_url) && (
                            <TextInputField
                                name="external_url_display_name"
                                label="Link display text"
                                placeholder="View experiment on Dropbox"
                            />
                        )}
                        <EditExperimentReadOnlyFields experiment={experiment} className="mb-8" />
                    </div>
                    <div className="flex justify-between">
                        <div className="flex max-w-[400px] items-start">
                            <HierarchyIcon height={22} width={22} className="mr-2 text-primary opacity-100" />
                            <p>
                                <span className="font-semibold">Provenance</span> will be tracked on both the copied
                                experiment and the original.{' '}
                                <a
                                    href="https://plutobio.slack.com/archives/C01GNPAKS6B/p1701969376643409?thread_ts=1701967993.180749&cid=C01GNPAKS6B"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Learn more
                                </a>
                            </p>
                        </div>
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            color="primary"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Create
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CopyExperimentFromWorkflowForm;

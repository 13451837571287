import { UnitShortname } from '@models/ExperimentType';
import { createContext } from 'react';
import { ProgressEventInfo } from '@services/ApiService';
import Experiment from '@models/Experiment';
import { DataFileType } from '@components/experiments/wizard/ExperimentWizardUtils';
import { StateSetter } from '@contexts/ContextTypes';
import { BasicSetter } from '../util/ObjectUtil';

const voidFn = () => undefined;
type ContextType = {
    assayDataError: string | null;
    assayProgress: ProgressEventInfo | null;
    assayUnits: UnitShortname | null;
    assayUnitsDisplayName: string | null;
    dataFileType: DataFileType;
    experiment: Experiment | null;
    fastqFilesAdded: boolean;
    hasCSVFiles: boolean;
    hasFastqFiles: boolean;
    hasSeuratFiles: boolean;
    isFastqProcessing: boolean;
    isFastqUploading: boolean;
    isSeuratProcessing: boolean;
    isSeuratUploading: boolean;
    needsUnits: boolean;
    setAssayDataError: StateSetter<string | null>;
    setAssayProgress: StateSetter<ProgressEventInfo | null>;
    setAssayUnits: StateSetter<UnitShortname | null>;
    setAssayUnitsDisplayName: StateSetter<string | null>;
    setDataFileType: BasicSetter<DataFileType>;
    setIsFastqUploading: StateSetter<boolean>;
    setIsSeuratUploading: StateSetter<boolean>;
    setShowUploader: StateSetter<boolean>;
    setUnitsError: StateSetter<string | null>;
    seuratFilesAdded: boolean;
    showUploader: boolean;
    unitsError: string | null;
};

const defaultValue: ContextType = {
    assayDataError: null,
    assayProgress: null,
    assayUnits: null,
    assayUnitsDisplayName: null,
    dataFileType: 'csv',
    experiment: null,
    fastqFilesAdded: false,
    hasCSVFiles: false,
    hasFastqFiles: false,
    hasSeuratFiles: false,
    isFastqProcessing: false,
    isFastqUploading: false,
    isSeuratProcessing: false,
    isSeuratUploading: false,
    needsUnits: false,
    setAssayDataError: voidFn,
    setAssayProgress: voidFn,
    setAssayUnits: voidFn,
    setAssayUnitsDisplayName: voidFn,
    setDataFileType: voidFn,
    setIsFastqUploading: voidFn,
    setIsSeuratUploading: voidFn,
    setShowUploader: voidFn,
    setUnitsError: voidFn,
    seuratFilesAdded: false,
    showUploader: false,
    unitsError: null,
};

export const AssayUploadContext = createContext<ContextType>(defaultValue);
AssayUploadContext.displayName = 'AssayUploadContext';

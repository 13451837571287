import Experiment from '@models/Experiment';
import FieldError from '@components/forms/FieldError';
import SelectableItem from '@components/forms/SelectableItem';
import { VariableIcon } from '@components/experiments/ExperimentIcons';
import { formatTableHeader } from '@util/StringUtil';
import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import Plot from '@models/Plot';
import { SeuratDifferentialExpressionAnalysisParameters } from '@models/AnalysisParameters';
import { SeuratDifferentialExpressionAnalysisFormValues } from '@/src/models/analysis/SeuratDifferentialExpressionAnalysis';

type Props = { plot: Plot; analysisParameters: SeuratDifferentialExpressionAnalysisParameters; experiment: Experiment };
const SeuratDifferentialLatentVariablesField = ({ analysisParameters, plot }: Props) => {
    const { values, setFieldValue, errors, touched } =
        useFormikContext<SeuratDifferentialExpressionAnalysisFormValues>();

    const { allVariables } = useMemo(() => {
        return {
            allVariables: (analysisParameters.latent_variables ?? []).sort((o1, o2) => {
                return (o1.display_name ?? '').localeCompare(o2?.display_name ?? '');
            }),
        };
    }, [plot, analysisParameters]);

    if (!allVariables.length) return null;
    return (
        <div className="form-field no-margin">
            <span className="field-label">Latent Variables</span>
            {errors.latent_variable_id && touched.latent_variable_id && (
                <FieldError>{errors.latent_variable_id}</FieldError>
            )}
            <div className="flex flex-row flex-wrap" data-cy="variables-field">
                {allVariables.map((variable, index) => {
                    return (
                        <SelectableItem
                            key={`variable_item_${index}`}
                            row
                            selected={values.latent_variable_id === variable.uuid}
                            onSelect={() => {
                                if (values.latent_variable_id === variable.uuid) {
                                    setFieldValue('latent_variable_id', null);
                                } else {
                                    setFieldValue('latent_variable_id', variable.uuid);
                                }
                            }}
                            className="mb-2 mr-2 items-center justify-center gap-2"
                            cyId="variable-item"
                        >
                            {variable && <VariableIcon variable={variable} />}
                            <span className="capitalize">{formatTableHeader(variable.display_name)}</span>
                        </SelectableItem>
                    );
                })}
            </div>
        </div>
    );
};

export default SeuratDifferentialLatentVariablesField;

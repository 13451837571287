import { ThemeStyle } from '@models/PlotConfigs';
import React from 'react';
import {
    BadgeCheckIcon,
    BeakerIcon,
    ClockIcon,
    DocumentTextIcon,
    ExclamationIcon,
    HashtagIcon,
    HeartIcon,
    UserIcon,
    ViewGridIcon,
    TerminalIcon,
} from '@heroicons/react/solid';
import { LatentParameterOption, ParameterOption } from '@models/AnalysisParameters';
import { PlotDisplayShortname } from '@models/PlotDisplayType';
import { MouseIcon } from '@components/icons/custom/MouseIcon';
import { DropletIcon } from '@components/icons/custom/DropletIcon';
import { DropletMediumIcon } from '@components/icons/custom/DropletMediumIcon';
import { DropletFilledIcon } from '@components/icons/custom/DropletFilledIcon';
import { GenderIcon } from '@components/icons/custom/GenderIcon';
import { ConditionIcon } from '@components/icons/custom/ConditionIcon';
import { FlaskIcon } from '@components/icons/custom/FlaskIcon';
import { CellLineIcon } from '@components/icons/custom/CellLineIcon';
import { LinePlotIcon } from '@components/icons/custom/LinePlotIcon';
import { OtherIcon } from '@components/icons/custom/OtherIcon';
import { BMIIcon } from '@components/icons/custom/BMIIcon';
import { DrugIcon } from '@components/icons/custom/DrugIcon';
import { BoxPlotIcon } from '@components/icons/custom/BoxPlotIcon';
import { ScoreBarPlotIcon } from '@components/icons/custom/ScoreBarPlotIcon';
import { BarPlotIcon } from '@components/icons/custom/BarPlotIcon';
import { HeatmapChartIcon } from '@components/icons/custom/HeatmapChartIcon';
import { VolcanoChartFilledIcon } from '@components/icons/custom/VolcanoChartFilledIcon';
import { VialIcon } from '@components/icons/custom/VialIcon';
import { ImageAnalysisIcon } from '@components/icons/custom/ImageAnalysisIcon';
import { PrismIcon } from '@components/icons/custom/PrismIcon';
import { TornadoPlotIcon } from '@components/icons/custom/TornadoPlotIcon';
import { ProfilePlotIcon } from '@components/icons/custom/ProfilePlotIcon';
import { KaplanMeierPlotIcon } from '@components/icons/custom/KaplanMeierPlotIcon';
import { EnrichmentPlotIcon } from '@components/icons/custom/EnrichmentPlotIcon';
import { AntibodyIcon } from '@components/icons/custom/AntibodyIcon';
import { SampleScatterPlotIcon } from '@components/icons/custom/SampleScatterPlotIcon';
import { IGVPlotLogo } from '@components/icons/custom/IGVPlotLogo';
import TextDisplayIcon from '@components/icons/custom/TextDisplayIcon';
import { FrameType } from '@models/StoryboardFrame';
import { defaultTextClass } from '@components/icons/CustomIcons';
import { SummaryAnalysisIcon } from '@components/icons/custom/SummaryAnalysisIcon';
import TextAnalysisIcon from '@components/icons/custom/TextAnalysisIcon';
import { DotPlotIcon } from '../icons/custom/DotPlotIcon';
import { NetworkGraphIcon } from '../icons/custom/NetworkGraphIcon';
import { PieChartIcon } from '../icons/custom/PieChartIcon';
import { StackedBarPlotIcon } from '../icons/custom/StackedBarPlotIcon';
import { SpreadsheetAnalysisIcon } from '@components/icons/custom/SpreadsheetAnalysisIcon';
import VennDiagramIcon from '../icons/custom/VennDiagramIcon';
import { RidgePlotIcon } from '../icons/custom/RidgePlotIcon';
import { ViolinPlotIcon } from '../icons/custom/ViolinPlotIcon';

export type DisplayIconProps = { type: PlotDisplayShortname; width?: number; height?: number; className?: string };
export const DisplayTypeIcon = ({ type, width, height, className }: DisplayIconProps) => {
    switch (type) {
        case 'bar_plot':
            return <BarPlotIcon width={width} height={height} className={className} />;
        case 'box_plot':
            return <BoxPlotIcon width={width} height={height} className={className} />;
        case 'dot_plot':
            return <DotPlotIcon width={width} height={height} className={className} />;
        case 'volcano_plot':
        case 'volcano_plot_v2':
            return <VolcanoChartFilledIcon height={height ?? 40} width={width ?? 40} className={className} />;
        case 'heatmap':
        case 'image_heatmap':
            return <HeatmapChartIcon width={width} height={height} className={className} />;
        case 'sample_scatter_plot':
        case 'cell_scatter_plot':
            return <SampleScatterPlotIcon width={width} height={height} className={className} />;
        case 'enrichment_plot':
            return <EnrichmentPlotIcon width={width} height={height} className={className} />;
        case 'kaplan_meier_curve':
            return <KaplanMeierPlotIcon width={width} height={height} className={className} />;
        case 'igv_plot':
            return <IGVPlotLogo width={width} height={height} className={className} />;
        case 'image':
            return <ImageAnalysisIcon width={width} height={height} className={className} />;
        case 'spreadsheet':
            return <SpreadsheetAnalysisIcon width={width} height={height} className={className} />;
        case 'prism_graphset':
            return <PrismIcon width={width} height={height} className={className} />;
        case 'score_bar_plot':
            return <ScoreBarPlotIcon width={width} height={height} className={className} />;
        case 'line_plot':
            return <LinePlotIcon width={width} height={height} className={className} />;
        case 'text':
            return <TextDisplayIcon width={width} height={height} className={className} />;
        case 'tornado_plot':
            return <TornadoPlotIcon width={width} height={height} className={className} />;
        case 'profile_plot':
            return <ProfilePlotIcon width={width} height={height} className={className} />;
        case 'external':
            return <TerminalIcon width={width} height={height} className={className} />;
        case 'network_graph':
            return <NetworkGraphIcon width={width} height={height} className={className} />;
        case 'pie_chart':
            return <PieChartIcon width={width} height={height} className={className} />;
        case 'stacked_bar_plot':
            return <StackedBarPlotIcon width={width} height={height} className={className} />;
        case 'venn_diagram':
            return <VennDiagramIcon width={width} height={height} className={className} />;
        case 'ridge_plot':
            return <RidgePlotIcon width={width} height={height} className={className} />;
        case 'violin_plot':
            return <ViolinPlotIcon width={width} height={height} className={className} />;
        default:
            return <BarPlotIcon width={width} height={height} className={className} />;
    }
};

type FrameIconProps = { type: FrameType } & Omit<DisplayIconProps, 'type'>;
export const FrameTypeIcon = ({ type, ...props }: FrameIconProps) => {
    switch (type) {
        case 'plot':
            return <SummaryAnalysisIcon className={defaultTextClass} {...props} />;
        case 'text':
            return <TextAnalysisIcon className={defaultTextClass} {...props} />;
    }
    return null;
};

export const getExperimentIcon = (name?: string | null, size = 16) => {
    const height = size;
    const width = size;
    let icon: JSX.Element | null = null;
    switch (name?.trim().toLowerCase().replace(/\s+/g, '_')) {
        case 'sample_id':
            icon = <VialIcon height={height} width={width} />;
            break;
        case 'condition':
            icon = <ConditionIcon height={height} width={width} />;
            break;
        case 'dose':
            icon = <FlaskIcon height={height} width={width} />;
            break;
        case 'mouse_id':
            icon = <MouseIcon height={height} width={width} />;
            break;
        case 'sex':
            icon = <GenderIcon height={height} width={width} />;
            break;
        case 'cell_line':
        case 'cell_type':
            icon = <CellLineIcon height={height} width={width} />;
            break;
        case 'other':
            icon = <OtherIcon height={'14'} width={'14'} />;
            break;
        case 'bmi':
            icon = <BMIIcon height={height} width={width} />;
            break;
        case 'timepoint':
            icon = <ClockIcon height={height} width={width} />;
            break;
        case 'age':
        case 'experiment_id':
            icon = <HashtagIcon height={height} width={width} />;
            break;
        case 'tissue':
            icon = <HeartIcon height={height} width={width} />;
            break;
        case 'drug':
            icon = <DrugIcon height={height} width={width} />;
            break;
        case 'treatment':
            icon = <BeakerIcon height={height} width={width} />;
            break;
        case 'tissue_type':
            icon = <ViewGridIcon height={height} width={width} />;
            break;
        case 'donor':
            icon = <UserIcon height={height} width={width} />;
            break;
        case 'replicate':
            icon = <HashtagIcon height={height} width={width} />;
            break;
        case 'r2_fastq':
        case 'r1_fastq':
            icon = <DocumentTextIcon height={height} width={width} />;
            break;
        case 'antibody':
            icon = <AntibodyIcon height={height} width={width} />;
            break;
        case 'input_control':
        case 'chipseq_input_control':
            icon = <BadgeCheckIcon height={height} width={width} />;
            break;
        case 'patient_status':
        case 'event_status':
            icon = <ExclamationIcon height={height} width={width} />;
            break;
        case 'patient_time_to_event':
        case 'time_to_event':
            icon = <ClockIcon height={height} width={width} />;
            break;
    }

    if (!icon) return null;
    return <div className="text-indigo-500">{icon}</div>;
};

export const VariableIcon = ({
    variable,
    size = 16,
}: {
    variable: ParameterOption | LatentParameterOption | null | undefined;
    size?: number;
}) => {
    return getExperimentIcon(variable?.display_name, size);
};

export const ThemeStyleIcon = ({ style }: { style: ThemeStyle }) => {
    switch (style) {
        case ThemeStyle.outline:
            return <DropletIcon />;
        case ThemeStyle.medium:
            return <DropletMediumIcon />;
        case ThemeStyle.dark:
            return <DropletFilledIcon />;
        default:
            return null;
    }
};

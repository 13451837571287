import {
    createContext,
    Dispatch,
    MutableRefObject,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Experiment from '@models/Experiment';
import useCanvasNodes from '../hooks/useCanvasNodes';
import useInitializeCanvas from '../hooks/useInitializeCanvas';
import useCanvasEdges, { CustomEdge } from '../hooks/useCanvasEdges';
import { useRouter } from 'next/router';
import {
    DefaultEdgeOptions,
    Edge,
    Node,
    OnEdgesChange,
    OnNodesChange,
    useReactFlow,
    Viewport,
    XYPosition,
} from 'reactflow';
import { StateSetter } from './ContextTypes';
import Plot from '../models/Plot';
import useEmbeddedExperiments from '../hooks/useEmbeddedExperiments';
import { EmbeddedExperiment, SourceExperiment } from '../models/EmbeddedExperiment';

type FocusedInputRefObject = { id: string } | null;
export type FocusedInputRefType = MutableRefObject<FocusedInputRefObject>;

export type LinkedExperimentData = {
    linked_experiment_id: string;
    source_experiment_id: string;
};
export type ContextType = {
    canvasLoaded: boolean;
    canvasLoading: boolean;
    canvasNodes: Node[];
    canvasNodesError: string;
    clearPanelChildNodes: (node: Node) => void;
    defaultEdgeOptions: DefaultEdgeOptions;
    defaultViewport: Viewport | undefined;
    deleteEmbeddedExperimentPlotNodes: (experimentId: string) => void;
    deleteModalOpen: 'node' | 'edge' | '';
    edges: Edge<Edge<CustomEdge[]>[]>[];
    edgesError: string;
    embeddedExperiments: EmbeddedExperiment[] | null;
    embeddedExperimentsError: string;
    embeddedExperimentsLoading: boolean;
    fetchCanvas: (skipLoadingState?: boolean) => void;
    fetchEmbeddedExperiments: () => void;
    focusedInputRef: FocusedInputRefType;
    handleDeleteElement: (deleteType: 'node' | 'edge' | '') => void;
    handleSelectTextNodeToEdit: (id: string) => void;
    handleUpdateEdge: (edge: CustomEdge) => void;
    handleUpdateNode: (node: Node | null) => void;
    helperLineHorizontal: number | undefined;
    helperLineVertical: number | undefined;
    isCanvasInUrl: boolean;
    linkEmbeddedExperiments: (embeddedExperimentIds: string[]) => void;
    newNodeType: string | null;
    onDragOver: (_: React.DragEvent) => void;
    onDrop: (_: React.DragEvent) => void;
    onEdgesChange: OnEdgesChange;
    onNodeDrag: (_: React.MouseEvent, node: Node) => void;
    onNodeDragStop: (_: React.MouseEvent, node: Node) => void;
    onNodesChange: OnNodesChange;
    onSelectPISuggestedAnalysesNode: (id: string) => void;
    onSelectTargetAnalysisNode: (id: string) => void;
    postNewNode: (nodeData: Node['data']) => Promise<Node>;
    saveNodes: (nodes: Node[]) => void;
    selectedEdge: CustomEdge | null;
    selectedLinkedExperimentData: LinkedExperimentData | null;
    selectedNode: Node | null;
    selectedPISuggestedAnalysesNode: Node | null;
    selectedTargetAnalysisNode: Node | null;
    setActivePlot: StateSetter<Plot | null>;
    setCanvasNodes: StateSetter<Node[]>;
    setDeleteModalOpen: StateSetter<'node' | 'edge' | ''>;
    setEdges: Dispatch<SetStateAction<Edge<Edge<CustomEdge[]>[]>[]>>;
    setNewNodeLocation: StateSetter<XYPosition | null>;
    setNewNodeType: Dispatch<SetStateAction<string | null>>;
    setSelectedEdge: Dispatch<SetStateAction<CustomEdge | null>>;
    setSelectedLinkedExperimentData: Dispatch<SetStateAction<LinkedExperimentData | null>>;
    setSelectedNode: Dispatch<SetStateAction<Node | null>>;
    setSelectedPISuggestedAnalysesNode: StateSetter<Node | null>;
    setSelectedTargetAnalysisNode: StateSetter<Node | null>;
    setShowEmbedAnalysis: Dispatch<SetStateAction<boolean>>;
    setShowExistingAnalyses: Dispatch<SetStateAction<boolean>>;
    setTextNodeToEdit: StateSetter<Node | null>;
    showEmbedAnalysis: boolean;
    showExistingAnalyses: boolean;
    sourceExperiment: SourceExperiment | null;
    textNodeToEdit: Node | null;
    unlinkAllEmbeddedExperiments: () => void;
    unlinkEmbeddedExperiments: (experimentIdsToUnlink: string[]) => void;
    updateEmbeddedExperiment: (payload: any) => void;
    isFromExperimentCard: boolean;
    setIsFromExperimentCard: Dispatch<SetStateAction<boolean>>;
};
const ExperimentCanvasContext = createContext<ContextType | null>(null);

export const useExperimentCanvasContext = () => {
    const context = useContext(ExperimentCanvasContext);
    if (!context) {
        throw new Error(
            'ExperimentCanvasContext has not been defined. Ensure you have wrapped your component in a context provider',
        );
    }
    return context;
};

export const useOptionalExperimentCanvasContext = (): Partial<ContextType> => {
    const context = useContext(ExperimentCanvasContext);
    if (!context) {
        return {};
    }
    return context;
};

ExperimentCanvasContext.displayName = 'ExperimentCanvasContext';

export const ExperimentCanvasContextProvider = ({
    children,
    experiment,
}: {
    children?: ReactNode;
    experiment?: Experiment | null;
}) => {
    const router = useRouter();

    const isCanvasInUrl = useMemo(() => router.asPath?.includes('canvas'), [router.asPath]);
    const { deleteElements } = useReactFlow();
    const [deleteModalOpen, setDeleteModalOpen] = useState<'node' | 'edge' | ''>('');
    const focusedInputRef = useRef<FocusedInputRefObject>(null);
    const [showExistingAnalyses, setShowExistingAnalyses] = useState<boolean>(true);
    const [showEmbedAnalysis, setShowEmbedAnalysis] = useState<boolean>(false);
    const [isFromExperimentCard, setIsFromExperimentCard] = useState<boolean>(false);
    const [selectedLinkedExperimentData, setSelectedLinkedExperimentData] = useState<LinkedExperimentData | null>(null);

    const {
        canvasLoading,
        canvasLoaded,
        defaultViewport,
        edges,
        canvasNodes,
        onEdgesChange,
        setEdges,
        setCanvasNodes,
        fetchCanvas,
    } = useInitializeCanvas({ experiment_id: experiment?.uuid, isCanvasInUrl });
    const { defaultEdgeOptions, selectedEdge, setSelectedEdge, handleUpdateEdge, edgesError } = useCanvasEdges({
        experiment_id: experiment?.uuid,
        edges,
        setEdges,
        canvasLoaded,
        focusedInputRef,
    });
    const {
        canvasNodesError,
        handleSelectTextNodeToEdit,
        handleUpdateNode,
        helperLineHorizontal,
        helperLineVertical,
        newNodeType,
        onDragOver,
        onDrop,
        onNodeDrag,
        onNodeDragStop,
        onNodesChange,
        onSelectPISuggestedAnalysesNode,
        onSelectTargetAnalysisNode,
        postNewNode,
        saveNodes,
        selectedNode,
        selectedPISuggestedAnalysesNode,
        selectedTargetAnalysisNode,
        setActivePlot,
        setNewNodeLocation,
        setNewNodeType,
        setSelectedNode,
        setSelectedPISuggestedAnalysesNode,
        setSelectedTargetAnalysisNode,
        setTextNodeToEdit,
        textNodeToEdit,
        deleteEmbeddedExperimentPlotNodes,
    } = useCanvasNodes({ experiment_id: experiment?.uuid, canvasLoaded, canvasNodes, setCanvasNodes, focusedInputRef });

    const {
        embeddedExperiments,
        error: embeddedExperimentsError,
        fetchEmbeddedExperiments,
        linkEmbeddedExperiments,
        loading: embeddedExperimentsLoading,
        sourceExperiment,
        unlinkAllEmbeddedExperiments,
        unlinkEmbeddedExperiments,
        updateEmbeddedExperiment,
    } = useEmbeddedExperiments({ experimentId: experiment?.uuid, fetchCanvas });

    const clearPanelChildNodes = (node: Node) => {
        const updatedPanelNode = {
            ...node,
            data: { ...node.data, plotIds: [], spannedCells: [], lastAdjustedIndex: 0, arrangement: null },
        }; // reset to default
        const newNodes = canvasNodes
            .filter((n) => n?.parentNode !== node.id)
            .map((n) => (n.id === node.id ? updatedPanelNode : n));
        setSelectedNode(updatedPanelNode);
        setCanvasNodes(newNodes);
    };

    const deleteChildNodeFromParent = (parentNodeId: string, childNodeId: string) => {
        const parentNode = canvasNodes.find((n) => n.id === parentNodeId);
        if (parentNode) {
            const updatedPanelNode = {
                ...parentNode,
                data: {
                    ...parentNode.data,
                    ...(parentNode.data.plotIds
                        ? { plotIds: parentNode.data.plotIds.filter((id: string) => id !== childNodeId) }
                        : null),
                    ...(parentNode.data.spannedCells
                        ? { spannedCells: parentNode.data.spannedCells.filter((id: string) => id !== childNodeId) }
                        : null),
                },
            };
            setCanvasNodes((prev) => prev.map((n) => (n.id === parentNodeId ? updatedPanelNode : n)));
        }
    };

    const handleDeleteElement = (deleteType: 'node' | 'edge' | '') => {
        if (!deleteType) return;
        focusedInputRef.current = null;
        if (deleteType === 'node') {
            if (!selectedNode) return;
            deleteElements({ nodes: [{ id: selectedNode.id }] });
            if (selectedNode.parentNode) {
                // timeout allows deleteElements to run first
                setTimeout(() => {
                    deleteChildNodeFromParent(selectedNode.parentNode ?? '', selectedNode.id);
                    setEdges((prev) => prev.filter((e) => e.source !== selectedNode.parentNode));
                }, 1);
            }
            const embeddedPlotExperimentId = selectedNode.data?.experimentId;
            const numOfExperimentIdInstances = canvasNodes.reduce((acc, item) => {
                return acc + (item.data?.experimentId === embeddedPlotExperimentId ? 1 : 0);
            }, 0);
            const isLastEmbeddedFromExperiment = numOfExperimentIdInstances === 1;
            if (isLastEmbeddedFromExperiment && embeddedPlotExperimentId) {
                unlinkEmbeddedExperiments([embeddedPlotExperimentId]);
            }
            setDeleteModalOpen('');
            saveNodes(canvasNodes.filter((n) => n.id !== selectedNode.id && n.parentNode !== selectedNode.id));
            return setSelectedNode(null);
        }
        if (!selectedEdge) return;
        deleteElements({ edges: [{ id: selectedEdge.id }] });
        setDeleteModalOpen('');
        setEdges((prev) => prev.filter((e) => e.id !== selectedEdge.id));
        return setSelectedEdge(null);
    };

    useEffect(() => {
        setSelectedNode(null);
        setSelectedEdge(null);
    }, [experiment?.uuid]);

    return (
        <ExperimentCanvasContext.Provider
            value={{
                canvasLoaded,
                canvasLoading,
                canvasNodes,
                canvasNodesError,
                clearPanelChildNodes,
                defaultEdgeOptions,
                defaultViewport,
                deleteEmbeddedExperimentPlotNodes,
                deleteModalOpen,
                edges,
                edgesError,
                embeddedExperiments,
                embeddedExperimentsError,
                embeddedExperimentsLoading,
                fetchCanvas,
                fetchEmbeddedExperiments,
                focusedInputRef,
                handleDeleteElement,
                handleSelectTextNodeToEdit,
                handleUpdateEdge,
                handleUpdateNode,
                helperLineHorizontal,
                helperLineVertical,
                isCanvasInUrl,
                isFromExperimentCard,
                linkEmbeddedExperiments,
                newNodeType,
                onDragOver,
                onDrop,
                onEdgesChange,
                onNodeDrag,
                onNodeDragStop,
                onNodesChange,
                onSelectPISuggestedAnalysesNode,
                onSelectTargetAnalysisNode,
                postNewNode,
                saveNodes,
                selectedEdge,
                selectedLinkedExperimentData,
                selectedNode,
                selectedPISuggestedAnalysesNode,
                selectedTargetAnalysisNode,
                setActivePlot,
                setCanvasNodes,
                setDeleteModalOpen,
                setEdges,
                setIsFromExperimentCard,
                setNewNodeLocation,
                setNewNodeType,
                setSelectedEdge,
                setSelectedLinkedExperimentData,
                setSelectedNode,
                setSelectedPISuggestedAnalysesNode,
                setSelectedTargetAnalysisNode,
                setShowEmbedAnalysis,
                setShowExistingAnalyses,
                setTextNodeToEdit,
                showEmbedAnalysis,
                showExistingAnalyses,
                sourceExperiment,
                textNodeToEdit,
                unlinkAllEmbeddedExperiments,
                unlinkEmbeddedExperiments,
                updateEmbeddedExperiment,
            }}
        >
            <>{children}</>
        </ExperimentCanvasContext.Provider>
    );
};

import Experiment from '@models/Experiment';
import React, { ReactNode, useCallback } from 'react';
import { getExperimentBadgeDisplayName } from '@util/ExperimentUtil';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ConditionalWrapper from '@components/ConditionalWrapper';
import { EmbeddedExperiment, SourceExperiment } from '../models/EmbeddedExperiment';
import { ExperimentSuggestion } from './layout/QuickOmniSearch';

type ExperimentProp = Experiment | SourceExperiment | EmbeddedExperiment | ExperimentSuggestion;
type Props = {
    bgClass?: string;
    className?: string;
    customKey?: string;
    disableLink?: boolean;
    experiment: ExperimentProp;
};
const ExperimentTypeBadge = ({
    bgClass = 'bg-black/[.08]',
    className,
    customKey,
    disableLink = false,
    experiment,
}: Props) => {
    const typeBadgeLabel = customKey
        ? experiment[customKey as keyof ExperimentProp]
        : getExperimentBadgeDisplayName(experiment);
    const router = useRouter();
    const notOnExcludedPage = router.route !== '/experiments' && router.route !== '/explore';

    const renderWrapper = useCallback(
        (children: ReactNode) => (
            <Link
                href={`/experiments?type=${customKey ? experiment[customKey as keyof ExperimentProp] : experiment.type.shortname}`}
                passHref
                legacyBehavior
            >
                <div>{children}</div>
            </Link>
        ),
        [experiment],
    );

    return (
        <ConditionalWrapper condition={notOnExcludedPage && !disableLink} wrapper={renderWrapper}>
            <div
                data-cy="experiment-type-badge"
                className={cn(
                    'mr-1 flex items-center whitespace-nowrap rounded-lg px-2 py-1 text-xs text-dark',
                    className,
                    bgClass,
                    {
                        'cursor-pointer transition-shadow duration-150 hover:shadow-lg':
                            notOnExcludedPage && !disableLink,
                    },
                )}
            >
                {typeBadgeLabel as string}
            </div>
        </ConditionalWrapper>
    );
};

export default ExperimentTypeBadge;

import { useState, useEffect } from 'react';
import useApi from '@hooks/useApi';
import useAuth from '@hooks/useAuth';
import { Evidence, InternalEvidenceType, ExternalEvidenceType, NewEvidencePayload } from '@models/Evidence';
import { BiomarkerSetType } from '@models/Biomarker';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';

type Props = { biomarkerId?: string; targetType?: BiomarkerSetType };
type EvidenceTypes = InternalEvidenceType | ExternalEvidenceType;

const useEvidence = ({ biomarkerId, targetType = 'biomarker' }: Props) => {
    const { authReady } = useAuth();
    const [evidence, setEvidence] = useState<Evidence[]>([]);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [count, setCount] = useState<number>(0);
    const api = useApi();
    const logger = Logger.make('EvidenceAPI');

    useEffect(() => {
        if (!authReady || !biomarkerId) return;

        fetchEvidence();
    }, [authReady, biomarkerId]);

    const fetchEvidence = async () => {
        setError('');
        setLoading(true);
        try {
            const newEvidence = await api.get<Evidence[]>(Endpoints.lab.evidence(), {
                object_type: targetType,
                object_uuid: biomarkerId,
            });

            const processedEvidence = newEvidence.map((item) => ({
                ...item,
                evidenceName: item.content_object?.name ?? item.content_object?.title ?? 'Unnamed Evidence',
                type: item.content_object?.object_type ?? 'externalevidence',
                status: item.status || 'None',
            }));

            const sortedEvidence = processedEvidence.sort((a, b) => (a.score ?? 0) - (b.score ?? 0));
            setEvidence(sortedEvidence);
            setCount(newEvidence.length);
        } catch (error) {
            logger.error(error);
            setError('Failed to fetch evidence');
        } finally {
            setLoading(false);
        }
    };

    const postNewEvidence = async ({
        type,
        id,
        referenceObjectID,
        rationale,
        status,
        score,
        datasetID,
        setType,
        url,
        title,
    }: {
        type?: EvidenceTypes;
        id?: string;
        referenceObjectID?: string;
        rationale?: string;
        status?: string;
        score?: number | null;
        datasetID?: string;
        setType?: BiomarkerSetType;
        url?: string;
        title?: string;
    }) => {
        const object_uuid: string | null = referenceObjectID ?? biomarkerId ?? null;
        if (!object_uuid) return;
        setError('');
        try {
            const payload: NewEvidencePayload = {
                content_object: {
                    title: title,
                    url: url,
                },
                object_type: setType ?? targetType,
                object_uuid,
                evidence_object_type: type,
                evidence_object_uuid: type === 'externalevidence' ? null : id, // Set to null for external evidence
                rationale,
                status,
                score,
                url,
                title: title,
            };
            // Conditionally add the evidence_object_uuid key if it's not externalevidence
            if (type !== 'externalevidence' && id) {
                payload.evidence_object_uuid = id;
            }
            if (!!datasetID) payload.literature_dataset_uuid = datasetID;

            await api.post<Evidence>(Endpoints.lab.evidence(), payload);
            if (!biomarkerId) return;
            await fetchEvidence();
        } catch (error) {
            logger.error(error);
            setError('Failed to add new evidence');
        }
    };

    const updateEvidence = async ({
        type,
        id,
        referenceObjectID,
        rationale,
        status,
        score,
        evidenceID,
        url,
        title,
    }: {
        type?: InternalEvidenceType | ExternalEvidenceType;
        id?: string | null;
        referenceObjectID?: string;
        rationale?: string;
        status?: string;
        score?: number | null;
        evidenceID: string;
        url?: string; // Passed from the modal
        title?: string; // Passed from the modal
    }) => {
        const object_uuid: string | null = referenceObjectID ?? biomarkerId ?? null;

        if (!object_uuid || !type) {
            console.error('Missing required data in updateEvidence');
            return;
        }

        // Construct the payload, ensuring url and title are included for external evidence
        const payload = {
            object_type: targetType,
            object_uuid,
            evidence_object_type: type,
            evidence_object_uuid: type === 'externalevidence' ? null : id, // Set to null for external evidence
            rationale,
            status,
            score,
            title,
            url,
        };

        try {
            await api.put<Evidence>(Endpoints.lab.evidenceBase(evidenceID), payload);
            if (!biomarkerId) return;
            await fetchEvidence();
        } catch (error) {
            logger.error(error);
            setError('Failed to update evidence');
        }
    };

    const archiveEvidence = async (evidenceId: string) => {
        setError('');
        try {
            await api.post(Endpoints.lab.archiveEvidence(evidenceId), {
                object_type: targetType,
                object_uuid: biomarkerId,
            });
            await fetchEvidence();
        } catch (error) {
            logger.error(error);
            setError('Failed to archive evidence');
        }
    };

    return {
        error,
        count,
        evidence,
        loading,
        postNewEvidence,
        archiveEvidence,
        setEvidence,
        updateEvidence,
    };
};

export default useEvidence;

import { useState, useEffect } from 'react';
import { BiomarkerList, Biomarker, BiomarkerListResponse, BiomarkerResponse } from '@models/Biomarker';
import Endpoints from '@services/Endpoints';
import useApi from './useApi';
import Logger from '@util/Logger';
import useAuth from './useAuth';
import { getValidValue } from '../util/StringUtil';
import { CuratedList, CuratedListResponse, CuratedListResultsResponse } from '../models/CuratedList';

const defaultFilters: BiomarkerListFilters = { search: '', sort_by: 'name', limit: 100, offset: 0, status: undefined };

export type BiomarkerListFilters = {
    search?: string;
    sort_by?: string;
    limit?: number;
    offset?: number;
    status?: string;
};
type Props = { listSlug?: string; biomarkerId?: string; targetType?: string; isCuratedLists?: boolean };

const useBiomarkers = ({ listSlug, biomarkerId, targetType, isCuratedLists }: Props) => {
    const { authReady, user, isLoggedIn } = useAuth();
    const [allBiomarkers, setAllBiomarkers] = useState<Biomarker[]>([]);
    const [hasBiomarkers, setHasBiomarkers] = useState<boolean>(false);
    const [biomarkerCount, setBiomarkerCount] = useState<number>(0);
    const [biomarkerResponse, setBiomarkerResponse] = useState<BiomarkerResponse | null>(null);
    const [sortedBiomarkers, setSortedBiomarkers] = useState<Biomarker[]>([]);
    const [biomarkerLists, setBiomarkerLists] = useState<BiomarkerList[] | CuratedList[]>([]);
    const [biomarkerList, setBiomarkerList] = useState<BiomarkerList | null>(null);
    const [biomarker, setBiomarker] = useState<Biomarker | null>(null);
    const [biomarkerError, setBiomarkerError] = useState<string>('');
    const [fileError, setFileError] = useState<string>('');
    const [listLoading, setListLoading] = useState<boolean>(true);
    const [biomarkersLoading, setBiomarkersLoading] = useState<boolean>(true);
    const [biomarkerLoading, setBiomarkerLoading] = useState<boolean>(true);
    const [copyingCuratedList, setCopyingCuratedList] = useState<boolean>(false);
    const [curatedListCopiedSuccessfully, setCuratedListCopiedSuccessfully] = useState<string>('');
    const [copyCuratedListError, setCopyCuratedListError] = useState<string>('');
    const [isCopyingList, setIsCopyingList] = useState<string[]>([]);
    const [followedLists, setFollowedLists] = useState<CuratedList[]>([]);
    const [followedListsLoading, setFollowedListsLoading] = useState<boolean>(true);
    const [followedListsError, setFollowedListsError] = useState<string>('');
    const [followCuratedListSuccess, setFollowCuratedListSuccess] = useState('');

    const [filters, setFilters] = useState<BiomarkerListFilters>(defaultFilters);
    const api = useApi();
    const logger = Logger.make('BiomarkerAPI');
    const targetTypeNameSingular = targetType === 'biomarkers' ? 'biomarker' : 'target';

    useEffect(() => {
        const currentBiomarkers = [...(biomarkerResponse?.items ?? [])];
        setAllBiomarkers(currentBiomarkers);
        const sortedMarkers = currentBiomarkers;
        setSortedBiomarkers(sortedMarkers);
    }, [biomarkerResponse]);

    useEffect(() => {
        if (!authReady) return;

        fetchBiomarkerLists();
        fetchFollowedLists();
    }, [authReady, targetType, isCuratedLists]);

    useEffect(() => {
        if (!authReady || !listSlug || !biomarkerId) return;

        fetchBiomarker(listSlug, biomarkerId);
    }, [authReady]);

    useEffect(() => {
        if (!listSlug) return;
        if (!authReady || !isLoggedIn || !user) return;

        fetchBiomarkerList(listSlug);
    }, [listSlug, authReady]);

    useEffect(() => {
        if (!listSlug) {
            setBiomarkerResponse(null);
            return;
        }

        fetchBiomarkers(listSlug);
    }, [filters, listSlug]);

    const fetchBiomarkerList = async (id: string) => {
        setBiomarkerError('');
        setListLoading(true);
        try {
            const newList = await api.get<BiomarkerList>(Endpoints.lab.biomarkerSet.base(id));
            setBiomarkerList(newList);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to fetch biomarker list');
        } finally {
            setListLoading(false);
        }
    };

    const fetchBiomarkers = async (id: string) => {
        setBiomarkerError('');
        setBiomarkersLoading(true);
        try {
            const sanitizedFilters = Object.fromEntries(
                Object.entries(filters).filter(([, value]) => value !== undefined),
            );

            const newMarkers = await api.get<BiomarkerResponse>(Endpoints.lab.biomarkers(id), sanitizedFilters);
            if (!filters.search) setHasBiomarkers(!!newMarkers.count);
            setBiomarkerResponse(newMarkers);
            setBiomarkerCount(newMarkers.count);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to fetch biomarkers');
        } finally {
            setListLoading(false);
            setBiomarkersLoading(false);
        }
    };

    const fetchBiomarkerLists = async () => {
        setBiomarkerError('');
        setListLoading(true);

        try {
            const newLists = await api.get(
                isCuratedLists ? Endpoints.lab.curated.base() : Endpoints.lab.biomarkerSets.base(),
            );
            const sortedLists = (
                isCuratedLists ? (newLists as CuratedListResponse).results : (newLists as BiomarkerListResponse).items
            )
                .filter((list: BiomarkerList) => !list.is_archived)
                .filter((list: BiomarkerList) => list.set_type === targetTypeNameSingular)
                .sort(
                    (a: BiomarkerList, b: BiomarkerList) =>
                        Date.parse(b.created_at ?? '') - Date.parse(a.created_at ?? ''),
                );
            setBiomarkerLists(isCuratedLists ? (sortedLists as CuratedList[]) : (sortedLists as BiomarkerList[]));
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to fetch biomarker lists');
        } finally {
            setListLoading(false);
        }
    };
    const fetchFollowedLists = async () => {
        setFollowedListsError('');
        setFollowedListsLoading(true);

        try {
            const newLists = await api.get<CuratedListResponse>(Endpoints.lab.followObject.base(), {
                set_type: targetTypeNameSingular,
            });
            const sortedLists = newLists.results
                .sort((a, b) => Date.parse(b.created_at ?? '') - Date.parse(a.created_at ?? ''))
                .map((r) => ({
                    ...r?.object_content,
                    followObjectUuid: r.uuid,
                }));
            setFollowedLists(sortedLists);
        } catch (error) {
            logger.error(error);
            setFollowedListsError('Failed to fetch followed lists');
        } finally {
            setFollowedListsLoading(false);
        }
    };

    const fetchBiomarker = async (biomarkerSetId: string, biomarkerId: string, query = {}) => {
        setBiomarkerError('');
        setBiomarkerLoading(true);

        if (!biomarkerSetId || !biomarkerId) {
            const errorMessage = 'Missing biomarker set ID or biomarker ID';
            logger.error(errorMessage);
            setBiomarkerError(errorMessage);
            return;
        }

        try {
            const biormarkerData = await api.get<Biomarker>(
                Endpoints.lab.biomarker(biomarkerSetId, biomarkerId),
                query,
            );
            setBiomarker(biormarkerData);
        } catch (error) {
            logger.error(`Failed to fetch biomarker with ID ${biomarkerId} from set ${biomarkerSetId}`, error);
            setBiomarkerError(`Failed to fetch biomarker with ID ${biomarkerId} from set ${biomarkerSetId}`);
            setBiomarkerLoading(false);
        } finally {
            setBiomarkerLoading(false);
        }
    };

    const addBiomarkerList = async (list: BiomarkerList) => {
        setBiomarkerError('');
        setListLoading(true);
        try {
            await api.post(Endpoints.lab.biomarkerSets.base(), list);

            fetchBiomarkerLists();
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to create new biomarker list');
        } finally {
            setListLoading(false);
        }
    };

    const addBiomarker = async (newBiomarker: Biomarker) => {
        if (!listSlug) return;
        setBiomarkerError('');
        setListLoading(true);
        try {
            await api.post(Endpoints.lab.biomarkers(listSlug), newBiomarker);

            setBiomarkerCount(biomarkerCount + 1);
            await fetchBiomarkerList(listSlug);
            fetchBiomarkers(listSlug);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to create new biomarker');
        } finally {
            setListLoading(false);
        }
    };

    const updateBiomarker = async (marker: Biomarker) => {
        if (!marker.uuid || !listSlug) return;
        setBiomarkerError('');
        setBiomarkersLoading(true);
        const payload: Biomarker = { ...marker, priority: getValidValue(marker.priority, null) };
        try {
            await api.put<Biomarker>(Endpoints.lab.biomarker(listSlug, marker.uuid), payload);
            await fetchBiomarkerList(listSlug);
            fetchBiomarkers(listSlug);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to update biomarker');
        } finally {
            setBiomarkersLoading(false);
        }
    };

    const deleteBiomarker = async (marker: Biomarker) => {
        if (!marker.uuid || !listSlug) return;
        setBiomarkerError('');
        setBiomarkersLoading(true);
        try {
            await api.doDelete(Endpoints.lab.biomarkerDelete(listSlug, marker.uuid));
            await fetchBiomarkerList(listSlug);
            fetchBiomarkers(listSlug);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to delete biomarker');
        } finally {
            setBiomarkersLoading(false);
        }
    };

    const copyCuratedListToOrg = async (list: BiomarkerList) => {
        if (!list.uuid) return;
        setCopyCuratedListError('');
        setCopyingCuratedList(true);
        setCuratedListCopiedSuccessfully('');
        try {
            await api.post(Endpoints.lab.biomarkerSet.copy(list.uuid));
            setCuratedListCopiedSuccessfully(list.name || 'biomarker list');
            setTimeout(() => {
                setCuratedListCopiedSuccessfully('');
            }, 5_000);
        } catch (error) {
            logger.error(error);
            setCopyCuratedListError('Failed to copy curated list');
            setCuratedListCopiedSuccessfully('');
        } finally {
            setCopyingCuratedList(false);
        }
    };

    const followCuratedList = async (list: BiomarkerList) => {
        if (!list.uuid) return;
        try {
            const payload = {
                is_user_specific: false,
            };
            const newFollowObject = await api.post<CuratedListResultsResponse>(
                Endpoints.lab.followObject.object({ object_type: 'moleculeset', object_uuid: list.uuid }),
                payload,
            );
            setFollowedLists((prev) => [
                ...prev,
                {
                    ...newFollowObject.object_content,
                    followObjectUuid: newFollowObject.uuid,
                },
            ]);
            setFollowCuratedListSuccess(newFollowObject.object_content?.uuid || '');
            setTimeout(() => setFollowCuratedListSuccess(''), 1000);
        } catch (error) {
            logger.error(error);
        }
    };

    const unfollowCuratedList = async (list: BiomarkerList) => {
        if (!list.uuid) return;
        try {
            const followedObject = followedLists.find((l) => l.uuid === list.uuid);
            if (followedObject?.followObjectUuid) {
                await api.doDelete<BiomarkerList>(
                    Endpoints.lab.followObject.object({
                        object_type: 'moleculeset',
                        object_uuid: followedObject.followObjectUuid,
                    }),
                );
                setFollowedLists((prev) => prev.filter((p) => p.followObjectUuid !== followedObject.followObjectUuid));
            }
        } catch (error) {
            logger.error(error);
        }
    };

    const updateBiomarkerList = async (list: BiomarkerList) => {
        if (!list.uuid) return;
        setBiomarkerError('');
        try {
            const updatedList = await api.put<BiomarkerList>(Endpoints.lab.biomarkerSet.base(list.uuid), list);

            const newCurentLists = biomarkerLists?.map((biomarkerList) =>
                updatedList.uuid === biomarkerList.uuid ? updatedList : biomarkerList,
            );
            setBiomarkerLists(newCurentLists);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to update biomarker list');
        }
    };

    const archiveBiomarkerList = async (list: BiomarkerList) => {
        if (!list.uuid) return;
        setBiomarkerError('');
        try {
            await api.post<BiomarkerList>(Endpoints.lab.biomarkerSetArchive(list.uuid));
            const newCurrentLists = (biomarkerLists as BiomarkerList[])?.filter(
                (biomarker) => biomarker.uuid !== list.uuid,
            );
            setBiomarkerLists(newCurrentLists);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to archive biomarker list');
        }
    };

    const copyBiomarkerList = async (list: BiomarkerList) => {
        if (!list.uuid) return;
        setBiomarkerError('');
        try {
            setIsCopyingList((prev) => [...prev, list.uuid as string]);
            const copiedBiomarker = await api.post<BiomarkerList>(Endpoints.lab.biomarkerSet.copy(list.uuid));
            setBiomarkerLists((prev: BiomarkerList[]) => [copiedBiomarker, ...prev]);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to copy biomarker list');
        } finally {
            setIsCopyingList((prev) => (prev ? prev.filter((uuid) => uuid !== list.uuid) : []));
        }
    };

    const handleCSVUpload = async (file: File | undefined): Promise<any> => {
        if (!file || !listSlug || !user?.organization?.uuid) return;
        setFileError('');
        setListLoading(true);
        const response: any = await api.apiService.uploadBiomarkers({
            file,
            slug: listSlug,
            orgId: user?.organization?.uuid,
        });
        if (response && 'error' in response) {
            logger.error(response.error);
            const errorMessage =
                (response.error?.response?.data?.details?.[0].message || response.error?.response?.data?.file[0]) ??
                'CSV upload invalid, please check file and try again';
            setFileError(errorMessage);
            setListLoading(false);
            return null;
        }
        await new Promise((resolve) => setTimeout(resolve, 2000)); // give the backend a moment to process
        setHasBiomarkers(true);
        await fetchBiomarkers(listSlug);
    };

    const templateURL = 'https://cdn.bfldr.com/2Q1IPX6I/at/kcxvjk77f8rjr7f7cb59/template_biomarkers_targets';
    const downloadCSVTemplate = () => {
        const a = document.createElement('a');

        a.setAttribute('download', 'upload_template.csv');
        a.setAttribute('href', templateURL);
        a.setAttribute('target', '_blank');
        a.click();
    };

    const clearFileError = () => setFileError('');
    const resetFilters = () => setFilters(defaultFilters);

    return {
        addBiomarker,
        addBiomarkerList,
        allBiomarkers,
        archiveBiomarkerList,
        biomarker,
        biomarkerCount,
        biomarkerError,
        biomarkerList,
        biomarkerLists,
        biomarkerLoading,
        biomarkerResponse,
        biomarkersLoading,
        clearFileError,
        copyBiomarkerList,
        copyCuratedListError,
        copyCuratedListToOrg,
        copyingCuratedList,
        curatedListCopiedSuccessfully,
        deleteBiomarker,
        downloadCSVTemplate,
        fetchBiomarker,
        fetchBiomarkerList,
        fileError,
        filters,
        followCuratedList,
        followCuratedListSuccess,
        followedLists,
        followedListsError,
        followedListsLoading,
        handleCSVUpload,
        hasBiomarkers,
        isCopyingList,
        listLoading,
        resetFilters,
        setCuratedListCopiedSuccessfully,
        setFilters,
        sortedBiomarkers,
        unfollowCuratedList,
        updateBiomarker,
        updateBiomarkerList,
    };
};

export default useBiomarkers;

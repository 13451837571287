import { ListSubheader, ListSubheaderProps } from '@mui/material';

function SelectListSubheader(props: ListSubheaderProps) {
    return (
        <ListSubheader
            {...props}
            sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: 'primary.main',
                lineHeight: 1.5,
                paddingTop: 1,
                paddingBottom: 1,
            }}
        />
    );
}

SelectListSubheader.muiSkipListHighlight = true;
export default SelectListSubheader;

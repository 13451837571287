import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';
import React, { useMemo } from 'react';
import SummaryHeatmapPlotBuilder from '@components/analysisCategories/summary/plots/builders/SummaryHeatmapPlotBuilder';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import { SummaryAnalysisPlotData } from '@models/ExperimentData';
import { processSummaryPlotData } from '@components/plots/PlotUtil';
import { BaseAssaySummaryExperimentAnalysis } from '@/src/models/analysis/ExperimentAnalysis';

type Props = { plot: Plot; experiment: Experiment };
const HeatmapView = ({ plot, experiment }: Props) => {
    const analysis = plot.analysis as BaseAssaySummaryExperimentAnalysis | null;

    const { groups } = useExperimentPlotGroups({
        experiment,
        plot,
        variables: plot.analysis?.variables,
    });

    const drawChart = useMemo<DrawChartFn>(
        () =>
            ({ size, svgSelection: _svg, context, tooltipId }) => {
                _svg.selectAll('g').remove();

                const { publicationMode, plotData, analysisParameters } = context;

                const data = plotData as SummaryAnalysisPlotData | null | undefined;
                const processedData = processSummaryPlotData(data);
                if (!processedData) {
                    return;
                }

                const { getTargetById } = getAnalysisParametersHelpers(analysisParameters);
                const getGroupById = (group_id: number) => {
                    return groups.find((g) => g.id === group_id) ?? null;
                };

                const svg = _svg.append<SVGSVGElement>('g');
                svg.selectAll('g').remove();
                const builder = SummaryHeatmapPlotBuilder.make({
                    svg,
                    plot,
                    data: processedData,
                    experiment,
                    getTargetById,
                    getGroupById,
                    width: size.width,
                    height: size.height,
                    publicationMode,
                    tooltipId,
                });
                builder.draw();
            },
        [groups, analysis?.group_display_order],
    );

    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default HeatmapView;

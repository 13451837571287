import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { BackgroundProps, BackgroundVariant, FitViewOptions, Viewport, useStore } from 'reactflow';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';
import Logger from '@util/Logger';
import useAuth from './useAuth';
import { FocusedInputRefType } from '../contexts/ExperimentCanvasContext';

export const DEFAULT_CANVAS_BG_COLOR = '#f9f9f9';
export const DEFAULT_CANVAS_GRID_COLOR = '#eee';
export const DEFAULT_ZOOM_LEVEL = 0.85; // 85%

export enum ExtendedBackgroundVariant {
    Cross = BackgroundVariant.Cross,
    Lines = BackgroundVariant.Lines,
    Dots = BackgroundVariant.Dots,
    Solid = 'solid',
}
export type CanvasBackgroundProps = BackgroundProps & {
    backgroundColor?: string;
};
export type CanvasBackgroundWithVariantProps = Omit<CanvasBackgroundProps, 'variant'> & {
    variant: ExtendedBackgroundVariant;
};

const fitViewOptions: FitViewOptions = {
    padding: 0.2,
    includeHiddenNodes: false,
    minZoom: 0.1,
    maxZoom: 4,
    duration: 1000,
};

const defaultOptions: CanvasBackgroundProps = {
    backgroundColor: DEFAULT_CANVAS_BG_COLOR,
    color: DEFAULT_CANVAS_GRID_COLOR,
    gap: 12,
    lineWidth: 1,
    size: 1,
    variant: BackgroundVariant.Lines,
};

const logger = Logger.make('useCanvasSettings');

const useCanvasSettings = ({
    experiment_id,
    canvasLoaded,
    focusedInputRef,
}: {
    experiment_id?: string;
    canvasLoaded?: boolean;
    focusedInputRef: FocusedInputRefType;
}) => {
    const { authReady, isLoggedIn } = useAuth();
    const [backgroundOptions, setBackgroundOptions] = useState<CanvasBackgroundProps>(defaultOptions);
    const [showMiniMap, setShowMiniMap] = useState<boolean>(true);
    const [exportMode, setExportMode] = useState<boolean>(false);
    const [viewport, setViewport] = useState<Viewport>();
    const [viewportError, setViewportError] = useState<string>('');
    const [panOnScroll, setPanOnScroll] = useState<boolean>(true);
    const api = useApi();

    const restoreLocalStorageSettings = () => {
        const canvasSettings = localStorage.getItem('pluto.canvas.settings');
        const parsedSettings = canvasSettings ? JSON.parse(canvasSettings ?? '') : {};

        if ('backgroundOptions' in parsedSettings) {
            setBackgroundOptions(parsedSettings.backgroundOptions);
        }
        if ('showMiniMap' in parsedSettings) {
            setShowMiniMap(parsedSettings.showMiniMap);
        }
        if ('exportMode' in parsedSettings) {
            setExportMode(parsedSettings.exportMode);
        }
        if ('viewport' in parsedSettings) {
            setViewport(parsedSettings.viewport);
        }
        if ('panOnScroll' in parsedSettings) {
            setPanOnScroll(parsedSettings.panOnScroll);
        }
    };

    const updateLocalStorageSettings = (newSettings: Record<string, any>) => {
        const currentSettings = localStorage.getItem('pluto.canvas.settings');
        const parsedSettings = currentSettings ? JSON.parse(currentSettings ?? '') : {};
        const tmpSettings = { ...parsedSettings, ...newSettings };
        localStorage.setItem('pluto.canvas.settings', JSON.stringify(tmpSettings));
    };

    useEffect(() => {
        if (!experiment_id || !authReady || !isLoggedIn || !canvasLoaded) return;

        restoreLocalStorageSettings();
    }, [experiment_id, canvasLoaded]);

    useDebounce(
        () => {
            if (!canvasLoaded || !experiment_id) return;
            saveViewport();
        },
        500,
        [viewport],
    );

    const zoomLevel = useStore((store) => store.transform[2]);

    const updateBackgroundOptions = (newOptions: BackgroundProps) => {
        setBackgroundOptions(newOptions);
        updateLocalStorageSettings({ backgroundOptions: newOptions });
    };

    const handleUpdateViewport = (newPort: Viewport) => {
        setViewport(newPort);
        updateLocalStorageSettings({ viewport: newPort });
    };

    const handlePanOnScroll = (shouldPanOnScroll: boolean) => {
        setPanOnScroll(shouldPanOnScroll);
        updateLocalStorageSettings({ panOnScroll: shouldPanOnScroll });
    };

    const toggleMiniMap = () => {
        setShowMiniMap(!showMiniMap);
        updateLocalStorageSettings({ showMiniMap: !showMiniMap });
    };

    const toggleExportMode = () => {
        setExportMode(!exportMode);
        updateLocalStorageSettings({ exportMode: !exportMode });
    };

    const saveViewport = () => {
        if (!experiment_id || !!focusedInputRef.current?.id) return;
        try {
            api.put(Endpoints.lab.experiment.canvasFlow(experiment_id), { viewport });
        } catch {
            logger.error(new Error('Failed to save new canvas viewport'));
            setViewportError('Failed to save canvas viewport!');
        }
    };

    return {
        backgroundOptions,
        exportMode,
        fitViewOptions,
        handlePanOnScroll,
        handleUpdateViewport,
        panOnScroll,
        setExportMode,
        showMiniMap,
        toggleExportMode,
        toggleMiniMap,
        updateBackgroundOptions,
        viewport,
        viewportError,
        zoomLevel,
    };
};

export default useCanvasSettings;

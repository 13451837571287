import { IGVData, IGVTrackItem } from '@models/ExperimentData';
import IGVBrowserConfig from '@models/igv/IGVBrowserConfig';
import { useMemo } from 'react';
import useIGV from '@hooks/useIGV';
import IGVTrack from '@models/igv/IGVTrack';
import { OrganismID } from '@models/Organism';
import { IGVGenomeId } from '@models/igv/GenomeConfig';
import { DEFAULT_THEME_COLOR } from '@models/PlotConfigs';
import { getPlotPalette } from '@components/ColorPaletteUtil';
import { isDefined } from '@util/TypeGuards';
import PaletteColors from '@components/PaletteColors';
import IGVPlotDisplayOption from '@models/plotDisplayOption/IGVPlotDisplayOption';
import { usePlotContext } from '@contexts/PlotContext';
import cn from 'classnames';
import WigTrack from '@models/igv/tracks/WigTrack';

const getGenomeRef = (organismType: OrganismID | string | null | undefined): IGVGenomeId => {
    switch (organismType) {
        case 'mouse':
            return 'mm10';
        default:
            return 'hg38';
    }
};

// type Props = { data: IGVData; plot: Plot; experiment: Experiment };
const IGVPlotView = () => {
    const { plot, plotData, experiment, publicationMode } = usePlotContext();
    const data = plotData as IGVData;

    const organismType = experiment.organism?.shortname;
    const defaultGenome = experiment?.organism?.genomes?.find((g) => g.is_default);
    const config = useMemo<IGVBrowserConfig>(() => {
        const display = plot.display as IGVPlotDisplayOption;
        const customColors = display.custom_color_json ?? {};
        const themeColor = display.theme_color ?? DEFAULT_THEME_COLOR;
        const palette = getPlotPalette(themeColor);

        const defaultGroupOrder: number[] = [];
        data.items.forEach((item) => {
            if (!defaultGroupOrder.includes(item.group_id)) {
                defaultGroupOrder.push(item.group_id);
            }
        });
        const groupDisplayOrder = (plot.display as IGVPlotDisplayOption).group_display_order ?? defaultGroupOrder ?? [];

        const getGroupIndex = (item: IGVTrackItem) => {
            let foundIndex = groupDisplayOrder.findIndex((id) => item.group_id === id);
            if (foundIndex < 0) {
                foundIndex = defaultGroupOrder.indexOf(item.group_id);
            }
            return Math.max(0, foundIndex);
        };

        const getTrackColor = (item: IGVTrackItem) => {
            return (
                customColors[item.group_id] ?? palette.colors[getGroupIndex(item) % (palette.colors.length - 1)].color
            );
        };

        const sampleTracks: WigTrack[] = data.items
            .filter((item) => item.type === 'sample' || !isDefined(item.type))
            .sort((t1, t2) => {
                return getGroupIndex(t1) - getGroupIndex(t2);
            })
            .map((item) => ({
                name: item.name,
                color: getTrackColor(item),
                url: item.url,
                autoHeight: true,
                maxHeight: 150,
                autoscale: true,
                type: 'wig',
                autoscaleGroup: display.autoscale_tracks_by_group ? `group_${item.group_id}` : 'sample',
                removable: false,
                description: `Group name: ${item.group_name}\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
            }));

        const calledPeakTracks: IGVTrack[] = data.items
            .filter((item) => item.type === 'called_peaks')
            .map((item) => ({
                name: item.name ?? 'Called peaks',
                color: PaletteColors.gray700.color,
                url: item.url,
                autoHeight: true,
                maxHeight: 100,
                autoscale: false,
                type: 'annotation',
                format: 'bed',
                searchable: true,
            }));

        const config: IGVBrowserConfig = {
            genome: experiment?.pipeline_run?.genome?.igv_id ?? defaultGenome?.igv_id ?? getGenomeRef(organismType),
            showNavigation: !publicationMode,
            locus: display.config_json?.locus ?? '1',
            trackDefaults: {
                annotation: { color: PaletteColors.gray700.color },
            },
            tracks: [
                {
                    type: 'sequence',
                    name: 'Sequence',
                    order: -Number.MIN_SAFE_INTEGER,
                },
                ...sampleTracks,
                ...calledPeakTracks,
            ],
        };
        return config;
    }, [data, plot, publicationMode]);

    const { igvRef } = useIGV({ config, plot });

    return (
        <div
            className={cn('pluto-igv mt-4 h-full w-full overflow-auto', {
                'igv-publication-mode': publicationMode,
            })}
        >
            <div ref={igvRef} className="rounded-lg bg-white w-full h-full" key="igv-container">
                Container
            </div>
        </div>
    );
};

export default IGVPlotView;

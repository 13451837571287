import { usePlotContext } from '@contexts/PlotContext';
import LoadingMessage from '@components/LoadingMessage';
import dynamic from 'next/dynamic';
import { PlotParams } from 'react-plotly.js';
import VolcanoPlotDisplayOption from '@models/plotDisplayOption/VolcanoPlotDisplayOption';
import { ArrowPlotData, DEGSample } from '@models/ExperimentData';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useOnScreen from '@hooks/useOnScreen';
import { useResizableContainerContext } from '@contexts/ResizableContainerContext';
import Mono from '@components/elements/Mono';
import { useFeatureToggleContext } from '@contexts/FeatureToggleContext';
import Logger from '@util/Logger';
import {
    buildPlotlyLayout,
    CustomPlotStylingOptions,
    DragMode,
    getHoverTemplate,
    getTargetData,
    getTextLabel,
    getVolcanoPlotLineSettings,
    IndexedDataPoint,
    PlotRange,
    POINT_OPACITY,
    POINT_SIZE,
    prepareData,
} from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import Button from '@components/Button';
import { ArrowData, TimeoutValue } from '@util/ObjectUtil';
import { VolcanoChartIcon } from '@components/icons/custom/VolcanoChartIcon';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import { PlotDatum } from 'plotly.js';
import useBiomarkers from '@/src/hooks/useBiomarkers';
import { StateSetter } from '@/src/contexts/ContextTypes';
import { debounce } from 'debounce';

const logger = Logger.make('PlotlyVolcanoPlot');
const Plotly = dynamic(() => import('react-plotly.js'), { ssr: false });

type Props = {
    customPlotStylingOptions?: CustomPlotStylingOptions | null;
    dragMode?: DragMode;
    isPlotFullyRendered?: boolean;
    loadingPlotThumbnailGeneration?: boolean;
    setIsPlotFullyRendered?: StateSetter<boolean>;
};
const PlotlyVolcanoPlot = ({
    customPlotStylingOptions = null,
    dragMode = undefined,
    isPlotFullyRendered,
    loadingPlotThumbnailGeneration,
    setIsPlotFullyRendered,
}: Props) => {
    const api = useApi();
    const { size, containerRef: resizeRef } = useResizableContainerContext();
    const forceShowTimeoutRef = useRef<TimeoutValue | null>(null);
    const afterPlotTimeoutRef = useRef<TimeoutValue | null>(null);
    const showPlotTimeoutRef = useRef<TimeoutValue | null>(null);
    const featureToggles = useFeatureToggleContext();
    const { onScreen, forceShow } = useOnScreen({ ref: resizeRef, initialOnScreen: true });
    const [showPlotlyPlot, setShowPlotlyPlot] = useState(false);

    const {
        plotData: pd,
        plotDataLoading,
        plot,
        isPlotDataValidating,
        publicationMode,
        isDragging,
        experiment,
        mutatePlot,
        isEditing,
    } = usePlotContext();
    const biomarkerListId = (plot?.display as VolcanoPlotDisplayOption)?.targets_biomarker_list || undefined;
    const { sortedBiomarkers: biomarkerTargets } = useBiomarkers({
        listSlug: biomarkerListId,
    });
    const plotData = pd as ArrowPlotData<DEGSample> | null | undefined;

    const plotIsNotReady =
        !showPlotlyPlot || plotDataLoading || !plotData || !plot || !plot.display || isPlotDataValidating;

    const debouncedHandleAfterPlot = useMemo(
        () =>
            debounce(() => {
                if (plotIsNotReady || isPlotFullyRendered) return;
                // Extra delay to ensure WebGL rendering is complete
                afterPlotTimeoutRef.current = setTimeout(() => {
                    setIsPlotFullyRendered?.(true);
                }, 1000);
            }, 1500),
        [plotIsNotReady, isPlotFullyRendered],
    );

    useEffect(() => {
        showPlotTimeoutRef.current = setTimeout(() => {
            setShowPlotlyPlot(true);
        }, 50);

        return () => {
            if (showPlotTimeoutRef.current) {
                clearTimeout(showPlotTimeoutRef.current);
            }
            if (afterPlotTimeoutRef.current) {
                clearTimeout(afterPlotTimeoutRef.current);
            }
        };
    }, []);

    // this is a bit of a hack to ensure the plot stays visible after dragging has finished.
    useEffect(() => {
        forceShowTimeoutRef.current = setTimeout(() => {
            forceShow();
        }, 10);

        return () => {
            if (forceShowTimeoutRef.current) {
                clearTimeout(forceShowTimeoutRef.current);
            }
        };
    }, [isDragging]);

    if (plotDataLoading) {
        return <LoadingMessage immediate />;
    }

    if (!plotData) {
        return (
            <div className="flex h-full items-center justify-center">
                <div>No plot data is available</div>
            </div>
        );
    }

    if (!plot || !plot.display) {
        return (
            <div className="flex h-full items-center justify-center">
                <div>Unable to load plot: no plot was found</div>
            </div>
        );
    }

    const display = plot.display as VolcanoPlotDisplayOption;

    const { data, stats } = useMemo<{
        data: PlotParams['data'] | null;
        stats?: PlotRange | null;
    }>(() => {
        try {
            const customOptions = display?.custom_options_json ?? {};
            const { volcanoThemeColors } = getVolcanoPlotLineSettings(display);
            const items = plotData.items as ArrowData<DEGSample>;

            if (Array.isArray(items)) {
                logger.debug('got wrong format for plot data');
                return { data: null };
            }

            const getText = (d: IndexedDataPoint): string | null => {
                return getTextLabel({ d, display, biomarkerTargetNames });
            };

            const biomarkerTargetNames = biomarkerTargets.map((b) => b.name).filter((b) => b !== null) as string[];

            const {
                increasedLabeled,
                decreasedLabeled,
                significantUp,
                significantDown,
                nonSignificantUp,
                nonSignificantDown,
                stats,
            } = prepareData({ items, display, biomarkerTargetNames });

            const significantIncreasedPointColor =
                customOptions['significantIncreased']?.fill_color ?? volcanoThemeColors.significantPositive.color;
            const significantDecreasedPointColor =
                customOptions['significantDecreased']?.fill_color ?? volcanoThemeColors.significantNegative.color;

            // Define traces - order matters. Traces listed near the bottom have a higher "z-index"
            const data: PlotParams['data'] = [
                {
                    type: 'scattergl',
                    name: `significantIncreased`,
                    x: significantUp.map((d) => d.x),
                    y: significantUp.map((d) => d.y),
                    textposition: 'middle left',
                    text: significantUp.map((d) => getText(d) ?? ''),
                    hovertemplate: significantUp.map((d) => getHoverTemplate(d)),
                    hoverlabel: { bgcolor: 'white', align: 'left' },
                    customdata: significantUp.map((d) => getTargetData(d)),
                    marker: {
                        symbol: 'circle',
                        color: significantIncreasedPointColor,
                        line: {
                            width: 0.5,
                            color:
                                customOptions['significantIncreased']?.line_color ??
                                volcanoThemeColors.significantPositive.color,
                        },
                        size: customOptions['significantIncreased']?.point_size ?? POINT_SIZE,
                        opacity: customOptions['significantIncreased']?.opacity
                            ? customOptions['significantIncreased']?.opacity / 100
                            : POINT_OPACITY,
                    },
                    mode: 'text+markers',
                },
                {
                    type: 'scattergl',
                    name: `significantDecreased`,
                    x: significantDown.map((d) => d.x),
                    y: significantDown.map((d) => d.y),
                    textposition: 'middle right',
                    text: significantDown.map((d) => getText(d) ?? ''),
                    hovertemplate: significantDown.map((d) => getHoverTemplate(d)),
                    hoverlabel: { bgcolor: 'white', align: 'left' },
                    customdata: significantDown.map((d) => getTargetData(d)),
                    marker: {
                        symbol: 'circle',
                        color: significantDecreasedPointColor,
                        line: {
                            width: 0.5,
                            color:
                                customOptions['significantDecreased']?.line_color ??
                                volcanoThemeColors.significantNegative.color,
                        },
                        size: customOptions['significantDecreased']?.point_size ?? POINT_SIZE,
                        opacity: customOptions['significantDecreased']?.opacity
                            ? customOptions['significantDecreased']?.opacity / 100
                            : POINT_OPACITY,
                    },
                    mode: 'text+markers',
                },
                {
                    type: 'scattergl',
                    name: `insignificantDecreased`,
                    x: nonSignificantDown.map((d) => d.x),
                    y: nonSignificantDown.map((d) => d.y),
                    textposition: 'middle right',
                    text: nonSignificantDown.map((d) => getText(d) ?? ''),
                    hovertemplate: nonSignificantDown.map((d) => getHoverTemplate(d)),
                    hoverlabel: { bgcolor: 'white', align: 'left' },
                    customdata: nonSignificantDown.map((d) => getTargetData(d)),
                    marker: {
                        symbol: 'circle',
                        color:
                            customOptions['insignificantDecreased']?.fill_color ??
                            volcanoThemeColors.insignificantNegative.color,
                        line: {
                            width: 0.5,
                            color:
                                customOptions['insignificantDecreased']?.line_color ??
                                volcanoThemeColors.significantNegative.color,
                        },
                        size: customOptions['insignificantDecreased']?.point_size ?? POINT_SIZE,
                        opacity: customOptions['insignificantDecreased']?.opacity
                            ? customOptions['insignificantDecreased']?.opacity / 100
                            : 100,
                    },
                    mode: 'text+markers',
                },
                {
                    type: 'scattergl',
                    name: `insignificantIncreased`,
                    x: nonSignificantUp.map((d) => d.x),
                    y: nonSignificantUp.map((d) => d.y),
                    textposition: 'middle right',
                    text: nonSignificantUp.map((d) => getText(d) ?? ''),
                    hovertemplate: nonSignificantUp.map((d) => getHoverTemplate(d)),
                    hoverlabel: { bgcolor: 'white', align: 'left' },
                    customdata: nonSignificantUp.map((d) => getTargetData(d)),
                    marker: {
                        symbol: 'circle',
                        color:
                            customOptions['insignificantIncreased']?.fill_color ??
                            volcanoThemeColors.insignificantPositive.color,
                        line: {
                            width: 0.5,
                            color:
                                customOptions['insignificantIncreased']?.line_color ??
                                volcanoThemeColors.significantPositive.color,
                        },
                        size: customOptions['insignificantIncreased']?.point_size ?? POINT_SIZE,
                        opacity: customOptions['insignificantIncreased']?.opacity
                            ? customOptions['insignificantIncreased']?.opacity / 100
                            : 100,
                    },
                    mode: 'text+markers',
                },
                {
                    type: 'scattergl',
                    name: `decreasedTarget`,
                    x: decreasedLabeled.map((d) => d.x),
                    y: decreasedLabeled.map((d) => d.y),
                    textposition: 'middle left',
                    text: decreasedLabeled.map((d) => getText(d) ?? ''),
                    hovertemplate: decreasedLabeled.map((d) => getHoverTemplate(d)),
                    hoverlabel: { bgcolor: 'white', align: 'left' },
                    customdata: decreasedLabeled.map((d) => getTargetData(d)),
                    textfont: {
                        color:
                            customPlotStylingOptions?.labeled_points?.fontColor ||
                            (publicationMode ? 'black' : undefined),
                        size: customPlotStylingOptions?.labeled_points?.fontSize || 10,
                        family: customPlotStylingOptions?.labeled_points?.fontFamily || 'Arial',
                    },
                    marker: {
                        symbol: 'circle',
                        color: customOptions['decreasedTarget']?.fill_color ?? volcanoThemeColors.negativeTarget.color,
                        line: {
                            width: 0.5,
                            color:
                                customOptions['decreasedTarget']?.line_color ??
                                customOptions['decreasedTarget']?.fill_color ??
                                volcanoThemeColors.negativeTarget.color,
                        },
                        size: customOptions['decreasedTarget']?.point_size ?? POINT_SIZE,
                        opacity: customOptions['decreasedTarget']?.opacity
                            ? customOptions['decreasedTarget']?.opacity / 100
                            : 1,
                    },
                    mode: display.custom_options_json?.show_target_labels === false ? 'markers' : 'text+markers',
                },
                {
                    type: 'scattergl',
                    name: `increasedTarget`,
                    x: increasedLabeled.map((d) => d.x),
                    y: increasedLabeled.map((d) => d.y),
                    textposition: 'middle right',
                    text: increasedLabeled.map((d) => getText(d) ?? ''),
                    hovertemplate: increasedLabeled.map((d) => getHoverTemplate(d)),
                    hoverlabel: { bgcolor: 'white', align: 'left' },
                    customdata: increasedLabeled.map((d) => getTargetData(d)),
                    textfont: {
                        color:
                            customPlotStylingOptions?.labeled_points?.fontColor ||
                            (publicationMode ? 'black' : undefined),
                        size: customPlotStylingOptions?.labeled_points?.fontSize || 10,
                        family: customPlotStylingOptions?.labeled_points?.fontFamily || 'Arial',
                    },
                    marker: {
                        symbol: 'circle',
                        color: customOptions['increasedTarget']?.fill_color ?? volcanoThemeColors.positiveTarget.color,
                        line: {
                            width: 0.5,
                            color:
                                customOptions['increasedTarget']?.line_color ??
                                customOptions['increasedTarget']?.fill_color ??
                                volcanoThemeColors.positiveTarget.color,
                        },
                        size: customOptions['increasedTarget']?.point_size ?? POINT_SIZE,
                        opacity: customOptions['increasedTarget']?.opacity
                            ? customOptions['increasedTarget']?.opacity / 100
                            : 1,
                    },
                    mode: display.custom_options_json?.show_target_labels === false ? 'markers' : 'text+markers',
                },
            ];

            return { data, stats };
        } catch (error) {
            logger.error(error);
            return { data: null };
        }
    }, [
        plotData?.data_hash,
        Array.isArray(plotData?.items),
        plot.display,
        publicationMode,
        customPlotStylingOptions,
        biomarkerTargets,
    ]);

    if (!data) {
        logger.warn('no processed data found', data, plotData);
        return null;
    }

    const toggleAnnotations = async (points: PlotDatum[], onlyAdd = false) => {
        try {
            const editedDisplay = { ...plot.display } as Partial<VolcanoPlotDisplayOption>;
            delete editedDisplay.uuid;
            delete editedDisplay.experiment_id;
            let updatedTargets = [...(editedDisplay.selected_targets ?? [])];
            points.forEach((point) => {
                const index = point.pointIndex;
                const target = point.customdata as string | null;
                if (!target) {
                    logger.warn('no target found for datapoint', point);
                    return;
                }
                const hasTarget = updatedTargets?.includes(target);
                if (hasTarget && !onlyAdd) {
                    logger.debug('[toggleAnnotations] removing target', target, `index=${index}`);
                    updatedTargets = updatedTargets?.filter((t) => t !== target);
                } else if (!hasTarget) {
                    logger.debug('[toggleAnnotations] adding target', target, `index=${index}`);
                    updatedTargets.push(target);
                }
            });

            editedDisplay.selected_targets = updatedTargets;
            await mutatePlot(async (currentPlot) => {
                try {
                    const updatedDisplay = await api.put<VolcanoPlotDisplayOption>(
                        Endpoints.lab.experiment.plot.display({
                            experimentId: experiment.uuid,
                            plotId: plot.uuid,
                            displayId: plot.display.uuid,
                        }),
                        editedDisplay,
                    );
                    if (currentPlot) {
                        return { ...currentPlot, display: updatedDisplay };
                    }
                } catch (plotError) {
                    logger.error(plotError);
                }
                return currentPlot ?? null;
            });
        } catch (error) {
            logger.error(error);
        }
    };

    const layoutCfg = buildPlotlyLayout({
        display,
        size,
        publicationMode,
        stats,
        dragMode: isEditing ? 'lasso' : dragMode,
        customOptions: display.custom_options_json,
        stylingOptions: customPlotStylingOptions ?? undefined,
    });
    const layout = { ...layoutCfg };

    if (!showPlotlyPlot) {
        return <LoadingMessage immediate message="Loading plot..." />;
    }
    return (
        <div className="relative flex h-full w-full items-start">
            {onScreen || isDragging ? (
                <Plotly
                    data={data}
                    layout={layout}
                    useResizeHandler={false}
                    onAfterPlot={debouncedHandleAfterPlot}
                    onSelected={(e) => {
                        if (!isEditing) {
                            return;
                        }
                        if (!e || !e.points || e.points.length === 0) {
                            logger.debug('[onSelected]', 'no event or points in the event handler');
                            return;
                        }
                        logger.debug('[onSelected]', e);
                        const { points } = e;
                        toggleAnnotations(points, true);
                    }}
                    onClick={(e) => {
                        if (!isEditing) {
                            return;
                        }
                        if (!e || !e.points || e.points.length === 0) {
                            logger.debug('[click]', 'no event or points in the event handler');
                            return;
                        }
                        logger.debug('[click]', e);
                        const { points } = e;

                        toggleAnnotations(points);
                    }}
                    // onRedraw={() => {
                    //     countRef.current++;
                    //     logger.debug(`redrawing. new count=${countRef.current}`);
                    // }}
                    // onRelayout={(e) => {
                    //     countRef.current++;
                    //     logger.debug(`relayout. new count=${countRef.current}`, e);
                    // }}
                    // onRestyle={(e) => {
                    //     countRef.current++;
                    //     logger.debug(`restyle. new count=${countRef.current}`, e);
                    // }}
                    // onInitialized={(fig) => {
                    //     logger.debug('[onInitialized]', fig);
                    // }}
                    // onUpdate={(fig) => {
                    //     logger.debug('[onUpdate]', fig);
                    // }}
                    config={{
                        displayModeBar: false, // TODO: use export mode to conditionally render this
                        // modeBarButtonsToAdd: [{name: 'toImage2', icon: 'camera', click: () => }],
                        // toImageButtonOptions: { filename: generatePlotFileName({ plot, experiment }), format: 'svg' },
                        autosizable: false,
                        staticPlot: loadingPlotThumbnailGeneration,
                    }}
                />
            ) : (
                <div className="h-full w-full p-4">
                    <div className="flex h-full w-full flex-col items-center justify-center space-y-4 rounded-lg border border-indigo-100">
                        <div className="flex items-center">
                            <div className="rounded-full bg-indigo-100 p-3 text-indigo-600">
                                <VolcanoChartIcon width={32} height={32} />
                            </div>
                        </div>
                        <div>
                            <Button onClick={() => forceShow()} variant="outlined" size="small" color="primary">
                                Reload plot
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {featureToggles.isEnabled('plot_size_debug') && (
                <div className="pointer-events-none absolute z-50 flex h-full w-full flex-col items-start justify-start border-2 border-dashed border-emerald-300">
                    <div className="z-50 bg-emerald-300/50 p-2 text-xs">
                        <p>
                            <Mono>PlotlyVolcanoPlot.tsx</Mono>
                        </p>
                        <p>height: {size?.height}px</p>
                        <p>width: {size?.width}px</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PlotlyVolcanoPlot;

import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import React, { useMemo } from 'react';
import SelectedCollectionsField from '@components/experiments/plotDisplay/SelectedCollectionsField';
import PValueField from '@components/experiments/plotDisplay/fields/PValueField';
import TopScoresField from '@components/experiments/plotDisplay/fields/TopScoresField';
import NormalizedEnrichmentScoreField from '@components/experiments/plotDisplay/fields/NormalizedEnrichmentScoreField';
import { getDefaultNESPlotTitle, getVolcanoPlotThemeColors } from '@components/plots/PlotUtil';
import { useFormikContext } from 'formik';
import { ScoresBarPlotPlotDisplayOptionFormValues } from '@models/PlotDisplayOption';
import CustomColorsFormDialogButton from '@components/profile/CustomColorsFormDialogButton';
import CustomLegendColorField, {
    CustomLegendColorItem,
} from '@components/experiments/plotDisplay/groups/CustomLegendColorField';
import { ExperimentAnalysis, PlotMetadata } from '@/src/models/analysis/ExperimentAnalysis';
import TFTargetCollection from '@/src/models/analysis/TranscriptionFactorEnrichmentAnalysis';

type Props = { plot: Plot; experiment: Experiment; analysisParameters?: AnalysisParameters | null };
const ScoreBarPlotDisplayFields = ({ plot, experiment, analysisParameters }: Props) => {
    const { values } = useFormikContext<ScoresBarPlotPlotDisplayOptionFormValues>();
    const plotMetadata = (
        plot.analysis ? plot.analysis['plot_metadata' as keyof ExperimentAnalysis] : {}
    ) as PlotMetadata;

    const items = useMemo<CustomLegendColorItem[]>(() => {
        const paletteColors = getVolcanoPlotThemeColors(values.theme_color);
        let legendItems: CustomLegendColorItem[] = [
            {
                id: 'positively',
                label: `Positively enriched`,
                themeColor: paletteColors.positive.color,
                labelName: '',
            },
            {
                id: 'negatively',
                label: `Negatively enriched`,
                themeColor: paletteColors.negative.color,
                labelName: '',
            },
        ];
        if (plot.analysis_type === 'transcription_factor_enrichment') {
            legendItems = [
                {
                    id: 'positively',
                    label: `Enriched`,
                    themeColor: paletteColors.positive.color,
                    labelName: '',
                },
            ];
        }
        return legendItems;
    }, [values.theme_color, plot.analysis_type]);

    const hasPValue =
        (plot.analysis &&
            plot.analysis['tf_target_collection' as keyof ExperimentAnalysis] &&
            (
                plot.analysis['tf_target_collection' as keyof ExperimentAnalysis] as Pick<
                    TFTargetCollection,
                    'has_p_value'
                >
            )?.['has_p_value']) ||
        plot.analysis_type === 'gene_set_enrichment';

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={getDefaultNESPlotTitle(values.nes_filter, plot.analysis_type)} />

            <div className="mb-6">
                {plotMetadata.has_gene_set ? (
                    <SelectedCollectionsField
                        plot={plot}
                        experiment={experiment}
                        analysisParameters={analysisParameters}
                        showTableButton
                    />
                ) : null}
                {plotMetadata.has_score_directions ? <NormalizedEnrichmentScoreField name="nes_filter" /> : null}
            </div>
            <div>
                <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Thresholds</h4>
                {hasPValue ? (
                    <PValueField
                        hideSectionLabel
                        name="adj_p_value_filter"
                        tooltipTitle="Set a threshold to determine which results appear on the plot"
                        showHint={false}
                        defaultValue={0.001}
                    />
                ) : null}
                {plotMetadata.show_top_scores ? (
                    <TopScoresField
                        hideSectionLabel
                        name="top_scores_filter"
                        tooltipTitle="Show the top scores that appear on the plot"
                        showHint={false}
                        defaultValue={10}
                    />
                ) : null}
            </div>
            <ThemeFieldGroup hideStyle />

            <section>
                <h4 className="mb-0 text-lg font-semibold tracking-tight text-dark">Legend</h4>
                <CustomColorsFormDialogButton color="primary" variant="text" size="small" marginLeft="-6px">
                    Manage favorite colors
                </CustomColorsFormDialogButton>
                <CustomLegendColorField items={items} />
            </section>
        </>
    );
};

export default ScoreBarPlotDisplayFields;

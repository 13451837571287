import { useEffect, useState } from 'react';
import Experiment from '@models/Experiment';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import { PlotListItem, PlotListItemsResponse } from '../models/Plot';
import Logger from '../util/Logger';

const logger = Logger.make('useFetchExperiment');
const defaultLimit = 20;

const useFetchExperiment = ({
    experimentId,
    limit = defaultLimit,
}: {
    experimentId?: string | null;
    limit?: number;
}) => {
    const api = useApi();
    const [experiment, setExperiment] = useState<Experiment | null>(null);
    const [experimentError, setExperimentError] = useState<string>('');
    const [experimentLoading, setExperimentLoading] = useState<boolean>(false);
    const [plotListItems, setPlotListItems] = useState<PlotListItem[]>([]);
    const [plotListLoading, setPlotListLoading] = useState<boolean>(false);

    const resetState = () => {
        setExperiment(null);
        setPlotListItems([]);
        setPlotListLoading(false);
        setExperimentLoading(false);
        setExperimentError('');
    };
    useEffect(() => {
        if (!experimentId) return resetState();

        const fetchExperiment = async () => {
            setExperimentError('');
            setExperimentLoading(true);
            try {
                const fetchedExperiment = await api.get<Experiment>(Endpoints.lab.experiment.base(experimentId));
                setExperiment(fetchedExperiment);
            } catch {
                setExperimentError('Failed to fetch experiment information! Please contact support.');
            } finally {
                setExperimentLoading(false);
            }
        };

        fetchExperiment();
        fetchPlotList();
    }, [experimentId]);

    const fetchPlotList = async () => {
        if (!experimentId) return;
        setPlotListLoading(true);
        try {
            const newResponse = await api.get<PlotListItemsResponse>(
                Endpoints.lab.experiment.plotsV2({ experimentId }, { offset: 0, limit }),
            );
            setPlotListItems(newResponse.items ?? []);
        } catch (error) {
            logger.log(error);
        } finally {
            setPlotListLoading(false);
        }
    };

    return {
        experiment,
        experimentError,
        experimentLoading,
        plotListItems,
        plotListLoading,
    };
};

export default useFetchExperiment;

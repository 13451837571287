import { ClusterSeuratObject } from '@models/preprocesses/ClusterSeuratObject';
import { CreateSeuratObject } from '@models/preprocesses/CreateSeuratObject';
import { FilterSeuratObject } from '@models/preprocesses/FilterSeuratObject';
import { IntegrateSeuratObject } from '@models/preprocesses/IntegrateSeuratObject';
import { NormalizeSeuratObject } from '@models/preprocesses/NormalizeSeuratObject';
import { RefineSeuratObject } from '@models/preprocesses/RefineSeuratObject';
import PreprocessStep from '@models/PreprocessStep';

type ExcludedFormValues = keyof PreprocessStep;
type MakeForm<T extends PreprocessStep, K extends keyof T = ExcludedFormValues> = Omit<T, ExcludedFormValues | K> & {
    preprocess_type: string;
};

export const regressNameMap = {
    regress_cc: 'cell cycle',
    regress_cc_diff: 'the difference between G2M and S cell cycle phase scores',
    regress_mito: 'mitochondrial gene expression',
    regress_umi: 'UMI counts',
};

export type ClusterSeuratObjectFormValues = MakeForm<ClusterSeuratObject>;
export type CreateSeuratObjectFormValues = MakeForm<CreateSeuratObject>;
export type FilterSeuratObjectFormValues = MakeForm<FilterSeuratObject>;
export type IntegrateSeuratObjectFormValues = MakeForm<IntegrateSeuratObject>;
export type NormalizeSeuratObjectFormValues = MakeForm<NormalizeSeuratObject>;
export type RefineSeuratObjectFormValues = MakeForm<RefineSeuratObject>;

type BasePreprocessFormValues = {
    uuid?: string;
};
export type PreprocessFormValues = BasePreprocessFormValues &
    (
        | ClusterSeuratObjectFormValues
        | CreateSeuratObjectFormValues
        | FilterSeuratObjectFormValues
        | IntegrateSeuratObjectFormValues
        | NormalizeSeuratObjectFormValues
        | RefineSeuratObjectFormValues
    );

import { AnalysisShortname } from '@models/analysis/AnalysisType';
import React from 'react';
import { LongitudinalAnalysisIcon } from '@components/icons/custom/LongitudinalAnalysisIcon';
import { SummaryAnalysisIcon } from '@components/icons/custom/SummaryAnalysisIcon';
import { DifferentialExpressionIcon } from '@components/icons/custom/DifferentialExpressionIcon';
import { ImageAnalysisIcon } from '@components/icons/custom/ImageAnalysisIcon';
import { PrismIcon } from '@components/icons/custom/PrismIcon';
import { SpreadsheetAnalysisIcon } from '@components/icons/custom/SpreadsheetAnalysisIcon';
import { TSNEAnalysisIcon } from '@components/icons/custom/TSNEAnalysisIcon';
import { UMAPAnalysisIcon } from '@components/icons/custom/UMAPAnalysisIcon';
import { CoverageAnalysisIcon } from '@components/icons/custom/CoverageAnalysisIcon';
import { PrincipalComponentsAnalysisIcon } from '@components/icons/custom/PrincipalComponentsAnalysisIcon';
import { GeneSetEnrichmentAnalysisIcon } from '@components/icons/custom/GeneSetEnrichmentAnalysisIcon';
import { PeakAnalysisIcon } from '@components/icons/custom/PeakAnalysisIcon';
import { SurvivalAnalysisIcon } from '@components/icons/custom/SurvivalAnalysisIcon';
import { TranscriptionFactorEnrichmentAnalysisIcon } from '@components/icons/custom/TranscriptionFactorEnrichmentAnalysisIcon';
import TextAnalysisIcon from '@components/icons/custom/TextAnalysisIcon';
import ClusteringAnalysisIcon from '@components/icons/custom/ClusteringAnalysisIcon';
import { TerminalIcon } from '@heroicons/react/outline';
import { FunctionalAnnotationAnalysisIcon } from '@components/icons/custom/FunctionalAnnotationAnalysisIcon';
import ProteinProteinInteractionAnalysisIcon from '@components/icons/custom/ProteinProteinInteractionAnalysisIcon';
import SampleCorrelationAnalysisIcon from '@components/icons/custom/SampleCorrelationAnalysisIcon';
import OverlapAnalysisIcon from '@components/icons/custom/OverlapAnalysisIcon';
import SeuratMarkerExpressionAnalysisIcon from '../../icons/custom/SeuratMarkerExpressionAnalysisIcon';

type Props = {
    shortname: AnalysisShortname;
    className?: string | undefined;
    width?: number | string;
    height?: number | string;
};
const AnalysisIcon = ({ shortname, ...props }: Props) => {
    switch (shortname) {
        case 'assay_summary':
        case 'assay_summary_cpm_normalized':
            return <SummaryAnalysisIcon {...props} />;
        case 'external':
            return <TerminalIcon width={props.width} height={props.height} className={props.className} />;
        case 'seurat_differential_expression':
        case 'differential_expression':
        case 'differential_binding':
        case 'differential_expression_continuous':
            return <DifferentialExpressionIcon {...props} />;
        case 'clustering':
            return <ClusteringAnalysisIcon {...props} />;
        case 'image':
            return <ImageAnalysisIcon {...props} />;
        case 'spreadsheet':
            return <SpreadsheetAnalysisIcon {...props} />;
        case 'prism':
            return <PrismIcon {...props} />;
        case 'principal_components':
            return <PrincipalComponentsAnalysisIcon {...props} />;
        case 'umap':
            return <UMAPAnalysisIcon {...props} />;
        case 'tsne':
            return <TSNEAnalysisIcon {...props} />;
        case 'gene_set_enrichment':
        case 'seurat_over_representation':
            return <GeneSetEnrichmentAnalysisIcon {...props} />;
        case 'peak':
            return <PeakAnalysisIcon {...props} />;
        case 'survival':
            return <SurvivalAnalysisIcon {...props} />;
        case 'longitudinal':
            return <LongitudinalAnalysisIcon {...props} />;
        case 'text':
            return <TextAnalysisIcon {...props} />;
        case 'coverage':
            return <CoverageAnalysisIcon {...props} />;
        case 'transcription_factor_enrichment':
            return <TranscriptionFactorEnrichmentAnalysisIcon {...props} />;
        case 'protein_protein_interaction':
            return <ProteinProteinInteractionAnalysisIcon {...props} />;
        case 'functional_annotation':
            return <FunctionalAnnotationAnalysisIcon {...props} />;
        case 'sample_correlation':
            return <SampleCorrelationAnalysisIcon {...props} />;
        case 'overlap':
        case 'multiomics_overlap_genes':
            return <OverlapAnalysisIcon {...props} />;
        case 'seurat_marker_expression':
        case 'seurat_module_score':
            return <SeuratMarkerExpressionAnalysisIcon {...props} />;
        default:
            return null;
    }
};

export default AnalysisIcon;

import React from 'react';
import FieldError from '@components/forms/FieldError';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useSWR from 'swr';
import { Team } from '@models/Team';
import Endpoints from '@services/Endpoints';
import { PermissionCheckOptions } from '@models/Permission';
import KeyboardHideIcon from '@mui/icons-material/KeyboardHide';
import TeamDialogFormButton from '@components/team/TeamDialogFormButton';
import { hasPermission } from '@util/PermissionUtil';
import { PaginationResponse } from '@services/EndpointUtil';
import { UserGroupIcon, OfficeBuildingIcon } from '@heroicons/react/solid';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { FormControl, ListItemIcon, MenuItem, Select } from '@mui/material';

type Props = {
    onChange?: SelectInputProps['onChange'];
    value?: string | null;
    label?: string;
    name: string;
    error?: string | null | boolean;
    showAddTeam?: boolean;
    permissions?: PermissionCheckOptions;
};

const TeamSelect: React.FunctionComponent<Props> = ({
    onChange,
    value,
    label,
    name,
    error,
    showAddTeam,
    permissions,
}: Props) => {
    const { data: teamPage } = useSWR<PaginationResponse<Team>>(Endpoints.teams());

    const loading = !teamPage && !error;
    const teams = teamPage?.items ?? [];

    const isDisabled = (team: Team) => {
        if (!permissions) {
            return false;
        }
        return !hasPermission(team, permissions);
    };

    return (
        <label className="form-field">
            <div className="flex flex-row items-baseline justify-between">
                <span className="field-label inline">{label ?? 'Select a project'}</span>
                {showAddTeam && (
                    <TeamDialogFormButton
                        dialogTitle={'Add team'}
                        variant="text"
                        color="primary"
                        startIcon={<AddCircleIcon fontSize="small" />}
                        className="shrink-0"
                        // onSave={(team) => onChange()}
                    >
                        Add team
                    </TeamDialogFormButton>
                )}
            </div>
            <FormControl variant="outlined" fullWidth error={!!error}>
                <Select
                    IconComponent={KeyboardHideIcon}
                    margin="dense"
                    name={name}
                    value={loading ? 'loading' : value}
                    onChange={onChange}
                    data-cy={`${name}-select`}
                >
                    {loading && (
                        <MenuItem disabled value="loading">
                            Loading...
                        </MenuItem>
                    )}
                    {!loading &&
                        teams.map((team, i) => (
                            <MenuItem
                                key={`team_${i}`}
                                value={team.uuid}
                                disabled={isDisabled(team)}
                                data-value={team.uuid}
                            >
                                <ListItemIcon className="min-w-[36px]">
                                    {team.is_org ? (
                                        <OfficeBuildingIcon className="float-left mr-1 h-4 w-4 flex-shrink-0" />
                                    ) : (
                                        <UserGroupIcon className="float-left mr-1 h-4 w-4 flex-shrink-0" />
                                    )}
                                </ListItemIcon>
                                {team.name}
                            </MenuItem>
                        ))}
                    {!loading && teams.length === 0 && <MenuItem disabled>No Teams Found</MenuItem>}
                </Select>
                {error && <FieldError>{error}</FieldError>}
            </FormControl>
        </label>
    );
};

export default TeamSelect;

import { ExternalLinkIcon, TrashIcon } from '@heroicons/react/outline';
import { Link, Tooltip } from '@mui/material';
import ExperimentTypeBadge from '../ExperimentTypeBadge';
import cn from 'classnames';
import Button from '../Button';
import { getOrganismIcon } from '../OrganismIcon';
import { Blobs } from '../effects/MouseMoveHoverEffect';
import { JoinedExperimentTypes } from '@/src/models/Experiment';

type Props = {
    experiment?: JoinedExperimentTypes | null;
    hideExternalLink?: boolean;
    onClick?: (experiment: JoinedExperimentTypes) => void;
    onRemove?: (experiment: JoinedExperimentTypes) => void;
};

const LinkedExperimentCard = ({ experiment, hideExternalLink = false, onClick, onRemove }: Props) => {
    if (!experiment) return null;
    return (
        <div
            className={cn(
                `card group relative overflow-hidden rounded-lg bg-indigo-300/20 p-0.5 text-left transition-all duration-300 ease-in-out cursor-pointer`,
            )}
            onClick={() => onClick?.(experiment)}
        >
            <div className="flex flex-col flex-1 inner relative z-10 h-full w-full rounded-md bg-white px-3 py-2 pl-6 backdrop-blur-3xl transition-all duration-300 group-hover:bg-white/80 group-hover:backdrop-blur-3xl">
                <p className="text-black text-xs font-normal">{experiment.pluto_id}</p>
                <p
                    className="text-black text-sm font-semibold line-clamp-3"
                    title={experiment.name || 'No experiment name'}
                >
                    {experiment.name || 'No experiment name'}
                </p>

                <div className="mt-2 justify-between flex flex-1 items-end">
                    <div className="inline-flex items-center">
                        <ExperimentTypeBadge experiment={experiment} bgClass="bg-black/[.08]" disableLink />
                        <span className="inline-flex h-5 w-5 text-gray-300">
                            {getOrganismIcon(
                                typeof experiment.organism === 'string'
                                    ? experiment.organism
                                    : experiment.organism?.shortname,
                            )}{' '}
                        </span>
                    </div>
                    <div className="inline-flex items-center gap-2">
                        {hideExternalLink ? null : (
                            <Link
                                className="flex cursor-pointer items-center font-semibold text-primary tracking-tight hover:opacity-70"
                                href={`/experiments/${experiment?.pluto_id}`}
                                target="_blank"
                                underline="none"
                            >
                                <Tooltip title="Open in new tab" placement="right" arrow enterDelay={700}>
                                    <ExternalLinkIcon className={cn('h-4 w-4 ml-1')} />
                                </Tooltip>
                            </Link>
                        )}
                        {onRemove ? (
                            <Button
                                size="small"
                                className="!p-0 !m-0 !min-w-0 flex cursor-pointer items-center font-semibold text-primary tracking-tight hover:opacity-70"
                                onClick={() => onRemove?.(experiment)}
                            >
                                <Tooltip title="Unlink experiment" placement="right" arrow enterDelay={700}>
                                    <TrashIcon className={cn('h-4 w-4 ml-1 text-error')} />
                                </Tooltip>
                            </Button>
                        ) : null}
                    </div>
                </div>
            </div>
            <Blobs />
        </div>
    );
};

export default LinkedExperimentCard;

import useFetchExperiment from '@/src/hooks/useFetchExperiment';
import Experiment from '@/src/models/Experiment';
import React, { ReactNode } from 'react';

type Props = {
    children: (experiment: Experiment | null | undefined) => ReactNode;
    experimentId?: string;
};

const FetchExperimentWrapper = ({ children, experimentId }: Props) => {
    const { experiment } = useFetchExperiment({
        experimentId,
    });

    if (!experimentId) {
        return <>{children(undefined)}</>;
    }

    return <>{children(experiment)}</>;
};

export default FetchExperimentWrapper;

import ButtonGroup, { ButtonGroupItem } from '@components/ButtonGroup';
import React, { useEffect, useMemo, useState } from 'react';
import NoSsr from '@components/NoSsr';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import Experiment from '@models/Experiment';
import NoCodeModulesView from './NoCodeModulesView';
import LowCodeRecipesView from './LowCodeRecipesView';

export type TabViewName = 'no_code_modules' | 'low_code_recipes';
type Props = {
    initialTab?: TabViewName;
    experiment: Experiment;
};

const AnalysisCategoryNavigation = ({ initialTab, experiment }: Props) => {
    const [currentTab, setCurrentTab] = useState<TabViewName | null>(null);

    const dataTableViewItems = useMemo<ButtonGroupItem<TabViewName>[]>(() => {
        const tabs: ButtonGroupItem<TabViewName>[] = [
            { value: 'no_code_modules', label: 'No-code modules' },
            { value: 'low_code_recipes', label: 'Low-code recipes' },
        ];

        return tabs;
    }, []);

    useEffect(() => {
        const items = dataTableViewItems;
        const firstTab =
            items.findLast((item) => item.disabled === false) ?? items.find((t) => t.value === initialTab) ?? items[0];
        setCurrentTab(firstTab.value);
    }, []);

    const getTabView = () => {
        switch (currentTab) {
            case 'no_code_modules':
                return <NoCodeModulesView experiment={experiment} />;
            case 'low_code_recipes':
                return <LowCodeRecipesView />;
            default:
                return <p className="text-center">Select a tab above to get started</p>;
        }
    };

    return (
        <div className="align-center flex flex-1 flex-col items-center" data-cy="analysis-category-navigation">
            <div className="md:relative md:z-10 md:mx-auto md:flex md:w-min md:items-center md:justify-center">
                <div className="flex overflow-x-auto">
                    <ButtonGroup
                        items={dataTableViewItems}
                        onChange={(screen) => setCurrentTab(screen || dataTableViewItems[0].value)}
                        toggle={false}
                        value={currentTab}
                    />
                </div>
            </div>
            <div className="relative mt-4 h-full min-h-[400px] w-full">
                <PlutoErrorBoundary>
                    <NoSsr>{getTabView()}</NoSsr>
                </PlutoErrorBoundary>
            </div>
        </div>
    );
};

export default AnalysisCategoryNavigation;

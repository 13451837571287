import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import Link from 'next/link';
import cn from 'classnames';
import Experiment from '@models/Experiment';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { TruncatedText } from '../experiments/history/items/TruncatedText';
import { Project } from '@models/Project';
import { FolderIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { ExperimentTypeShortname } from '@/src/models/ExperimentType';

type NavItemProps = { className?: string; experiment: Experiment; handleClose: () => void; currentSlug: string };
const NavItem = ({ experiment, className, handleClose, currentSlug }: NavItemProps) => {
    const experimentPath = `/experiments/${experiment?.pluto_id?.toUpperCase()}/${currentSlug}`;
    return (
        <Link href={experimentPath} passHref legacyBehavior>
            <div
                data-cy="nav-item"
                className={cn(
                    className,
                    'relative flex h-full cursor-pointer flex-col px-4 py-3 text-white/70 last:pb-5 hover:text-white',
                )}
                onClick={handleClose}
            >
                <span className="absolute top-5 text-seperator" style={{ left: '-1.78rem', fontSize: '30px' }}>
                    &middot;
                </span>
                <div className="text-[11px] font-normal">{experiment.short_display_name}</div>
                <TruncatedText
                    className="min-w-full text-[16px] font-normal tracking-tight"
                    text={experiment.name}
                    max={50}
                />
            </div>
        </Link>
    );
};

// Adjusts the current slug based on the experiment type and status
const adjustCurrentSlug = (experiment: Experiment, currentSlug?: string): string | undefined => {
    const isMultiomicsExperiment = experiment?.type?.shortname === ExperimentTypeShortname.multiomics;
    const isIncomplete = isMultiomicsExperiment
        ? !experiment?.status.includes('complete')
        : experiment?.status !== 'complete'; // multi-omics can be pending_complete or complete
    if (isMultiomicsExperiment && currentSlug?.includes('analysis')) {
        // Analysis view is not available for multiomics experiments
        // If is incomplete experiment, redirect to overview. If complete, redirect to canvas
        currentSlug = isIncomplete ? currentSlug.replace('analysis', '') : currentSlug.replace('analysis', 'canvas');
    }

    if (currentSlug?.includes('analysis') && isIncomplete) {
        // Analysis view is not available for incomplete experiments
        currentSlug = currentSlug.replace('analysis', '');
    }

    if (currentSlug?.includes('canvas') && isIncomplete) {
        // Canvas view is not available for incomplete experiments
        currentSlug = currentSlug.replace('canvas', '');
    }

    if (currentSlug?.includes('edit') && experiment?.status !== 'draft') {
        // Edit view is not intuitive for non-draft experiments
        currentSlug = currentSlug.replace('edit', '');
    }

    return currentSlug;
};

const ExperimentLinks = ({
    handleClose,
    projectExperiments,
}: {
    handleClose: () => void;
    projectExperiments: Experiment[];
}) => {
    const router = useRouter();
    const endString = router.route.split('/').pop();
    const currentSlug = endString === '[plutoId]' ? '' : endString;
    return (
        <div className="flex w-full">
            <div className="mx-6 mt-0 border-l border-seperator" />
            <div className="flex w-full flex-col">
                {projectExperiments.map((experiment) => {
                    const adjustedSlug = adjustCurrentSlug(experiment, currentSlug);
                    return (
                        <NavItem
                            key={experiment.pluto_id}
                            handleClose={handleClose}
                            experiment={experiment}
                            currentSlug={adjustedSlug ?? ''}
                        />
                    );
                })}
                {!projectExperiments.length && (
                    <p className="flex h-full px-4 py-2 text-white/70">
                        There are no other experiments in this project
                    </p>
                )}
            </div>
        </div>
    );
};

type Props = {
    experiment: Experiment;
    projectExperiments: Experiment[];
    project: Project;
};
const ProjectExperimentsDropdownMenu = ({ experiment, projectExperiments, project }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const projectPath = `/projects/${project?.pluto_id?.toUpperCase()}?tab=experiments`;
    const open = Boolean(anchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div
                className="flex h-full max-w-[300px] flex-grow items-center justify-center pl-2 text-center hover:cursor-pointer"
                onClick={handleOpenMenu}
            >
                <div className="truncate font-semibold text-white">{experiment.name ?? 'Loading...'}</div>
                <ArrowDropDown
                    className={cn(
                        ' w-6 h-6 text-white/70 transition-transform ease-in-out group-hover:translate-y-0.5',
                        {
                            'rotate-180': open,
                        },
                    )}
                />
            </div>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                MenuListProps={{
                    style: {
                        padding: 0,
                        width: '550px',
                    },
                    className: 'bg-pluto-layout-purple',
                }}
                PaperProps={{
                    style: {
                        borderRadius: 8,
                        boxShadow: '0 0 24px 0 rgba(0,0,0,.13)',
                        maxHeight: 300,
                        marginTop: 5,
                    },
                }}
            >
                <div>
                    <div className="flex h-full px-4 py-2 text-white/70">
                        <FolderIcon width={20} className="mr-2" />
                        <a className="text-[16px] font-normal text-white/70 hover:text-white" href={projectPath}>
                            {project?.name}
                        </a>
                    </div>
                    <ExperimentLinks projectExperiments={projectExperiments} handleClose={handleClose} />
                    <div className="-mt-[10px] flex h-full items-center pb-6 pl-[12.3px] pr-4 pt-2">
                        <PlusCircleIcon width={24} className="mr-2 text-seperator" />
                        <Link href={`/create-experiment?project=${project?.uuid}`} passHref legacyBehavior>
                            <p className="cursor-pointer pl-[20px] text-[16px] font-normal text-white/70 hover:text-white hover:opacity-70">
                                Create another experiment...
                            </p>
                        </Link>
                    </div>
                </div>
            </Menu>
        </>
    );
};
export default ProjectExperimentsDropdownMenu;

import * as Yup from 'yup';
import { AnnotationFormValues } from '@components/experiments/annotations/AnnotationFormTypes';
import { updateAnnotationSetSchema } from '@components/experiments/annotations/AnnotationFormSchemas';

import AnnotationSet from '@models/Annotation';
import { FormikValues } from 'formik';

/**
 * Values needed to initialize the Annotation form
 */
export type FormSetupParams = {
    annotationSet: AnnotationSet | null;
};
export type InitialValues = AnnotationFormValues & FormikValues;

/**
 * Values needed to create a new AnnotationSet Form
 */
export type FormSetup = { initialValues: InitialValues; schema: Yup.AnySchema | null };

/**
 * Get the initial conditions for the annotation form.
 * @param {AnnotationSet} annotationSet
 * @return {FormSetup}
 */
export const getFormSetup = ({ annotationSet }: FormSetupParams): FormSetup => {
    const initialValues: AnnotationFormValues = {
        annotations: annotationSet?.clusters
            ? annotationSet?.clusters.map((cluster) => ({
                  uuid: cluster.uuid,
                  number: cluster.number,
                  display_name: cluster.display_name,
                  color: cluster.color,
                  description: cluster.description,
              }))
            : [],
    };
    return {
        schema: updateAnnotationSetSchema,
        initialValues,
    };
};

import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters, GeneSetEnrichmentAnalysisParameters } from '@models/AnalysisParameters';
import { DefaultGeneSetSize, GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import GeneSetCollection from '@models/GeneSetCollection';
import { isDefined } from '@util/TypeGuards';
import { pluralize, proseListJoiner } from '@util/ObjectUtil';
import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { useRankMethodInfo } from '@hooks/useRankMethod';
import Bold from '@components/elements/Bold';
import Mono from '@components/elements/Mono';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null };
const GeneSetEnrichmentAnalysisMethodsSection = ({ plot, experiment, analysisParameters }: Props) => {
    const analysis = plot.analysis as GeneSetEnrichmentAnalysis;
    const { rankMethodInfo } = useRankMethodInfo(analysis.rank_method);
    const allCollections =
        (analysisParameters as GeneSetEnrichmentAnalysisParameters | null)?.gene_set_collections ?? [];

    const getCollectionById = (id: number): GeneSetCollection | null => {
        return allCollections.find((c) => c.id === id) ?? null;
    };

    const selectedCollections = analysis.gene_set_collections.map(getCollectionById).filter(isDefined);
    const organismShortname = experiment.organism.shortname;

    return (
        <section>
            <MethodsSectionTitle>Gene set enrichment analysis (GSEA)</MethodsSectionTitle>
            <div className="flex flex-col gap-3">
                <p>
                    Gene set enrichment analysis (GSEA) was using the <Mono>fgsea</Mono> R package and the{' '}
                    <Mono>fgseaMultilevel()</Mono> function <sup>1</sup>. The{' '}
                    <Bold>{rankMethodInfo.formattedDisplayName}</Bold> from the <Bold>{analysis.name}</Bold>{' '}
                    differential expression comparison was used to rank genes.{' '}
                    <Bold>{proseListJoiner(selectedCollections.map((c) => c.display_name))}</Bold> gene set{' '}
                    {pluralize(selectedCollections.length, 'collection', 'collections')} from the Molecular Signatures
                    Database (MSigDB)<sup>2,3</sup> {pluralize(selectedCollections.length, 'was', 'were')} curated using
                    the <Mono>msigdbr</Mono> R package<sup>4</sup>. Prior to running GSEA, the list of gene sets was
                    filtered to include only gene sets with between{' '}
                    {analysis.min_gene_set_size ?? DefaultGeneSetSize.min} and{' '}
                    {analysis.max_gene_set_size ?? DefaultGeneSetSize.max} genes.
                    {organismShortname === 'african_clawed_frog' ? (
                        <>
                            {' '}
                            Gene sets specific to the African clawed frog were generated by replacing human genes in
                            MSigDB gene sets with <i>Xenopus laevis</i> orthologs according to Xenbas<sup>5</sup>{' '}
                            (accessed on 04/23/2024).
                        </>
                    ) : null}
                    {organismShortname === 'eastern_spiny_mouse' ? (
                        <>
                            {' '}
                            <i>Acomys dimidiatus</i> Ensembl gene identifiers were appended to <i>Mus musculus</i> gene
                            names of the MSigDB, which were used for GSEA.
                        </>
                    ) : null}
                </p>
            </div>
        </section>
    );
};

export default GeneSetEnrichmentAnalysisMethodsSection;

import { useEffect, useState } from 'react';
import useAuth from '@hooks/useAuth';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import Plot from '../models/Plot';
import { ExperimentAnalysisInput } from '../models/analysis/ExperimentAnalysisInput';
import { getErrorDetails } from '../api/ApiTypes';
import {
    OverlapAnalysisInputInitialFormValues,
    OverlapAnalysisInputFormValues,
} from '../components/experiments/analyses/inputs/AnalysisInputFormTypes';

const logger = Logger.make('useAnalysisInputs');
type Props = {
    analysisId: string;
    experimentId: string;
    onCreateList?: (list: ExperimentAnalysisInput) => void;
    plot?: Plot;
    updateAnalysisForm: (lists: ExperimentAnalysisInput[]) => void;
};
const useAnalysisInputs = ({ analysisId, experimentId, onCreateList, plot, updateAnalysisForm }: Props) => {
    const { authReady, isLoggedIn } = useAuth();
    const api = useApi();
    const [removeListError, setRemoveListError] = useState<string>('');
    const [removingList, setRemovingList] = useState<boolean>(false);
    const [fetchingListError, setFetchingListError] = useState<string>('');
    const [fetchingList, setFetchingList] = useState<boolean>(false);
    const [inputLists, setInputLists] = useState<ExperimentAnalysisInput[]>([]);
    const [createListError, setCreateListError] = useState<string>('');
    const [creatingList, setCreatingList] = useState<boolean>(false);
    const [updatingList, setUpdatingList] = useState<boolean>(false);
    const [updateListError, setUpdateListError] = useState<string>('');

    const fetchInputs = async () => {
        if (!experimentId || !analysisId) return;
        setFetchingList(true);
        setFetchingListError('');
        try {
            const lists: ExperimentAnalysisInput[] = await api.get(
                Endpoints.lab.experiment.analysis.inputs({
                    experimentId,
                    analysisId,
                }),
            );
            setInputLists(lists.filter((list) => Object.keys(list).length > 0));
        } catch (err) {
            setFetchingListError(getErrorDetails(err).message);
            logger.error('Failed to load analysis inputs: ', err);
        } finally {
            setFetchingList(false);
        }
    };

    const removeList = async (analysisInputId: string) => {
        if (!experimentId || !analysisId) return;
        setRemoveListError('');
        setRemovingList(true);
        const updatedLists = inputLists.filter((list) => list.uuid !== analysisInputId);
        try {
            // Delete analysis input
            await api.doDelete(
                Endpoints.lab.experiment.analysis.input({
                    experimentId,
                    analysisId,
                    analysisInputId,
                }),
            );

            setInputLists(updatedLists);
            updateAnalysisForm(updatedLists);
        } catch (err) {
            setRemoveListError(getErrorDetails(err).message);
            logger.error('Failed to remove analysis input list: ', err);
        } finally {
            setRemovingList(false);
        }
    };

    const createList = async () => {
        if (!experimentId || !analysisId) return;
        setCreateListError('');
        setCreatingList(true);

        try {
            const payload = {
                input_type: 'target_list',
            };

            // Create new analysis input shell
            const newList: ExperimentAnalysisInput = await api.post(
                Endpoints.lab.experiment.analysis.inputs({
                    experimentId,
                    analysisId,
                }),
                payload,
            );

            const newLists = [...inputLists, newList];

            setInputLists(newLists);
            updateAnalysisForm(newLists);
            onCreateList?.(newList);
        } catch (err) {
            setCreateListError(getErrorDetails(err).message);
            logger.error('Failed to create analysis input list: ', err);
            throw err;
        } finally {
            setCreatingList(false);
        }
    };

    const updateList = async (payload: OverlapAnalysisInputFormValues, analysisInputId: string) => {
        if (!experimentId || !analysisId) return;
        setUpdateListError('');
        setUpdatingList(true);

        try {
            const updatedList = await api.apiService.putOverlapAnalysisInput({
                values: payload,
                experimentId,
                plot,
                analysisInputId,
            });
            if (updatedList) {
                setInputLists((prevLists) => {
                    const index = prevLists.findIndex((list) => list.uuid === updatedList.uuid);
                    if (index === -1) return prevLists;
                    const newLists = [...prevLists];
                    newLists[index] = updatedList;
                    return newLists;
                });
            }
            return { data: updatedList };
        } catch (err) {
            setUpdateListError(getErrorDetails(err).message);
            logger.error('Failed to update analysis input list: ', err);
            return { error: err };
        } finally {
            setUpdatingList(false);
        }
    };

    const resetListData = async (analysisInputId: string) => {
        if (!experimentId || !analysisId) return;
        try {
            const updatedList = await api.apiService.putOverlapAnalysisInput({
                values: OverlapAnalysisInputInitialFormValues,
                experimentId,
                plot,
                analysisInputId,
            });
            if (updatedList) {
                setInputLists((prevLists) => {
                    const index = prevLists.findIndex((list) => list.uuid === updatedList.uuid);
                    if (index === -1) return prevLists;
                    const newLists = [...prevLists];
                    newLists[index] = updatedList;
                    return newLists;
                });
            }
            return { data: updatedList };
        } catch (err) {
            return { error: err };
        }
    };

    useEffect(() => {
        if (!authReady || !isLoggedIn) return;

        fetchInputs();
    }, [analysisId, authReady, isLoggedIn]);

    return {
        createList,
        createListError,
        creatingList,
        fetchingList,
        fetchingListError,
        inputLists,
        removeList,
        removeListError,
        removingList,
        resetListData,
        setInputLists,
        updateList,
        updateListError,
        updatingList,
    };
};

export default useAnalysisInputs;

import SelectableItem from '@components/forms/SelectableItem';
import { Tooltip, CircularProgress } from '@mui/material';
import cn from 'classnames';
import { getOrganismIcon } from '@components/OrganismIcon';
import React from 'react';
import useOrganizationSettings from '@hooks/useOrganizationSettings';
import { useField } from 'formik';
import { OrganismID } from '@models/Organism';

type Props = {
    disableFormik?: boolean;
    name: string;
    noLabel?: boolean;
    onBypassSelect?: (value: OrganismID | null) => void;
    onSelect?: (value: OrganismID | null) => void;
    value?: OrganismID | null;
    disableDeselect?: boolean;
    /**
     * An optional list of supported organisms.
     * Only organisms whose shortname is included in this array will be shown.
     */
    supportedOrganisms?: OrganismID[];
    /**
     * An optional list of excluded organisms.
     * Only organisms whose shortname is not included in this array will be shown.
     */
    excludedOrganisms?: OrganismID[];
};

const OrganismPickerField = ({
    disableDeselect,
    disableFormik = false,
    excludedOrganisms,
    name,
    noLabel,
    onBypassSelect,
    onSelect,
    supportedOrganisms,
    value: valueProp,
}: Props) => {
    const { organisms, loadingOrganismSettings } = useOrganizationSettings();
    const [inputProps, , helpers] = !disableFormik ? useField<OrganismID | null>(name) : [null, null, null];
    const value = valueProp ?? inputProps?.value;

    // Filter organisms: only those that are filterable and (if provided) are among the supported ones.
    const filteredOrganisms = organisms.filter((o) => {
        const filterable = o.is_filterable;
        const supported = supportedOrganisms
            ? supportedOrganisms.includes(o.shortname as OrganismID)
            : excludedOrganisms
              ? !excludedOrganisms.includes(o.shortname as OrganismID)
              : true;
        return filterable && supported;
    });

    return (
        <label className="block">
            {!noLabel && <span className="block text-base font-semibold tracking-tight">Organism Type</span>}
            <div className="flex flex-row flex-wrap">
                {loadingOrganismSettings ? (
                    <div className="flex items-center justify-center w-full p-2 mt-2">
                        <CircularProgress size={20} />
                        &nbsp; Loading organisms
                    </div>
                ) : (
                    filteredOrganisms.map((organism, i) => (
                        <SelectableItem
                            key={`org_${i}`}
                            onSelect={() => {
                                const newVal =
                                    value === organism.shortname && !disableDeselect
                                        ? null
                                        : (organism.shortname as OrganismID);
                                if (onBypassSelect) return onBypassSelect(newVal);
                                helpers?.setValue(newVal);
                                onSelect?.(newVal);
                            }}
                            selected={organism.shortname === value}
                            paddingClass="p-2"
                            disabled={!organism.is_enabled}
                            className="mr-2 mt-2"
                        >
                            <Tooltip
                                placement="top"
                                arrow
                                title={
                                    <>
                                        <div className="text-center">{organism.display_name}</div>
                                        <div className="text-center">{organism.description}</div>
                                    </>
                                }
                            >
                                <span
                                    className={cn('flex h-8 w-8 items-center justify-center', {
                                        'text-indigo-400': organism.is_enabled,
                                        'text-gray-300': !organism.is_enabled,
                                    })}
                                >
                                    {getOrganismIcon(organism.shortname)}
                                </span>
                            </Tooltip>
                        </SelectableItem>
                    ))
                )}
            </div>
        </label>
    );
};

export default OrganismPickerField;

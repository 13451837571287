import { ApiError } from '@services/ApiError';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { CircularProgress } from '@mui/material';
import { deepEqual, getChangedValues } from '@util/ObjectUtil';
import { Formik, FormikHelpers } from 'formik';
import { getFormSetup } from '@components/experiments/preprocesses/PreprocessFormUtil';
import { isBlank } from '@util/StringUtil';
import { NumberIcon } from '../../icons/custom/NumberIcon';
import { PreprocessFormValues } from '@components/experiments/preprocesses/PreprocessFormTypes';
import { ScrollableSidebarContent, ScrollableSidebarFooter } from '@components/experiments/ScrollableSidebarContent';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import AggregateFormErrorAlert from '@components/experiments/AggregateFormErrorAlert';
import AnalysisFormSubmitButton from '@components/experiments/analyses/AnalysisFormSubmitButton';
import Button from '@components/Button';
import FormikListener from '@components/forms/FormikListener';
import LoadingMessage from '../../LoadingMessage';
import Logger from '@util/Logger';
import PreprocessFormFields from '@components/experiments/preprocesses/PreprocessFormFields';
import React, { useCallback } from 'react';
import StatisticalSymbolLabel from '@components/StatisticalSymbolLabel';

const logger = Logger.make('ExperimentPreprocessForm');

/**
 * Render the form to create/edit an Experiment Analysis for a given preprocess.
 *
 * Note: the `key` on the Formik form ensures that when a different preprocess is selected that a new form is rendered,
 * rather than re-using the existing, mounted component. This solves an issue where different plots have different
 * fields present/absent, which could cause null pointer errors on an initial render.
 * Passing in a key ensures we have a fresh component when the preprocess changes.
 * @return {JSX.Element}
 * @constructor
 */
const PreprocessForm = () => {
    const { experiment, setCurrentChanges, currentChanges } = useExperimentDetailViewContext();
    const {
        acceptPreprocessLoading,
        updatePreprocessLoading,
        preprocessFormData,
        selectedPreprocess: preprocess,
        selectedWorkflow,
        setConfirmAcceptPreprocessModalOpen,
        updatePreprocess,
    } = useExperimentWorkflowContext();

    /**
     * Get initial values for the preprocess form
     * @type {{initialValues: PreprocessFormValues, schema: Yup.AnySchema} | null}
     */
    const setup = getFormSetup({ preprocess, formData: preprocessFormData });
    if (!setup) {
        logger.warn(`Can not generate a setup object for preprocess_type "${preprocess?.preprocess_type.shortname}"`, {
            preprocess,
        });
        return <ScrollableSidebarContent>This type of preprocess is not yet supported.</ScrollableSidebarContent>;
    }

    /**
     * Save the form values to the experiment preprocess.
     * @param {PreprocessFormValues} values
     * @param {FormikHelpers<FormValues>} helpers
     * @return {Promise<void>}
     */
    const handleSubmit = async (
        values: PreprocessFormValues,
        helpers: FormikHelpers<PreprocessFormValues>,
    ): Promise<void> => {
        // ensure the uuid was removed if it was present
        delete values['uuid'];
        logger.debug('Submitting preprocess values', values, preprocess?.preprocess_type.shortname);
        helpers.setStatus(null);
        try {
            const payload: PreprocessFormValues = values;
            updatePreprocess(payload);
        } catch (error) {
            logger.error(error);
            logger.error('ApiErrorMessage', ApiError.getMessage(error as Error));
            helpers.setStatus({ error: ApiError.getMessage(error as Error) });
        } finally {
            helpers.setSubmitting(false);
            setCurrentChanges(null);
        }
    };

    const handleOnChange = useCallback(
        (values: PreprocessFormValues) => {
            const isEqual = deepEqual(values, setup.initialValues);
            if (!isEqual) {
                const changedValues = getChangedValues<PreprocessFormValues>(
                    values,
                    setup.initialValues as PreprocessFormValues,
                );
                if (changedValues && !deepEqual(changedValues, currentChanges)) {
                    setCurrentChanges(changedValues);
                }
            } else if (currentChanges) {
                setTimeout(() => {
                    setCurrentChanges(null);
                }, 300);
            }
        },
        [currentChanges, setup.initialValues],
    );

    if (!preprocess) {
        return (
            <div>
                <LoadingMessage message="Loading..." />
            </div>
        );
    }

    const pipelineRan = preprocess.pipeline_ran;
    const isAutomatedPreprocess = preprocess.preprocess_type.is_automated;
    const preprocessComplete = preprocess.pipeline_status === 'completed' && preprocess.step_status === 'accepted';
    const readyToBeAccepted = preprocess.pipeline_status === 'completed' && preprocess.step_status !== 'accepted';
    const indexOfPreprocess =
        selectedWorkflow?.preprocesses
            .map((p) => p.preprocess_type.shortname)
            .indexOf(preprocess.preprocess_type.shortname, 0) || 0;
    const submitText = pipelineRan ? 'Apply new updates' : 'Run preprocessing step';
    const submittingText = pipelineRan ? 'Applying updates...' : 'Running...';
    const submitBtnVariant = pipelineRan ? 'outlined' : 'contained';
    const submitDisabled =
        preprocess.pipeline_status === 'in_progress' || acceptPreprocessLoading || updatePreprocessLoading;
    const acceptedWorkflow = Boolean(experiment?.accepted_workflow);

    return (
        <Formik
            initialValues={setup.initialValues as PreprocessFormValues}
            validationSchema={setup.schema}
            onSubmit={handleSubmit}
            enableReinitialize
            key={preprocess.uuid}
        >
            {({ status, errors, touched, values }) => {
                const errorMessages = Object.keys(errors)
                    .filter(
                        (name) =>
                            (touched as Record<string, boolean>)[name] &&
                            !isBlank((errors as Record<string, string>)[name]),
                    )
                    .map((name) => (errors as Record<string, string>)[name]);
                return (
                    <>
                        <ScrollableSidebarContent data-cy="preprocess-form" className="px-8">
                            <div className="my-8">
                                <span className="field-label">Preprocess type</span>
                                <div className="flex flex-row items-center">
                                    <NumberIcon number={indexOfPreprocess} className="mr-2" />
                                    <StatisticalSymbolLabel name={preprocess.preprocess_type.display_name} />
                                </div>
                            </div>
                            <PreprocessFormFields preprocess={preprocess} />
                        </ScrollableSidebarContent>

                        {!preprocessComplete && !isAutomatedPreprocess && (
                            <ScrollableSidebarFooter>
                                {!acceptedWorkflow && (
                                    <>
                                        {status && status.error && errorMessages.length === 0 && (
                                            <p className="mb-4 break-words rounded-lg bg-error px-2 py-2 text-error">
                                                {status.error}
                                            </p>
                                        )}
                                        <AggregateFormErrorAlert />
                                        <AnalysisFormSubmitButton
                                            text={submitText}
                                            submittingText={submittingText}
                                            variant={submitBtnVariant}
                                            disabled={submitDisabled}
                                        />
                                    </>
                                )}
                                {!acceptedWorkflow && pipelineRan && (
                                    <>
                                        <p className="py-2 text-center text-gray-500">or</p>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => setConfirmAcceptPreprocessModalOpen(true)}
                                            fullWidth
                                            disabled={submitDisabled || !readyToBeAccepted || Boolean(currentChanges)}
                                            data-cy="accept-preprocess-btn"
                                            className="!text-white"
                                        >
                                            {acceptPreprocessLoading ? (
                                                <>
                                                    Accepting...{' '}
                                                    <CircularProgress color="inherit" className="ml-2" size={16} />
                                                </>
                                            ) : (
                                                <div className="align-center flex justify-center">
                                                    <CheckCircleIcon className="mr-1" width={18} />
                                                    Accept results & proceed
                                                </div>
                                            )}
                                        </Button>
                                    </>
                                )}
                            </ScrollableSidebarFooter>
                        )}
                        <FormikListener values={values} callback={handleOnChange} />
                    </>
                );
            }}
        </Formik>
    );
};

export default PreprocessForm;

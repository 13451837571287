import DataTable from '@components/dataTable/DataTable';
import LoadingMessage from '@components/LoadingMessage';
import React, { useEffect, useMemo, useState } from 'react';
import { Alert, MenuItem, Select } from '@mui/material';
import Experiment from '@models/Experiment';
import { generateResultDataFileName } from '@util/ExperimentUtil';
import { geneLinkCellRenderer } from '@components/dataTable/cellRenderers';
import { ResultData } from '@models/PreprocessStep';
import MoreMenuIconButton, { MoreMenuItem } from '../../MoreMenuIconButton';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import DownloadDataMoreMenuItem from '../../DownloadDataMoreMenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import cn from 'classnames';
import { useExperimentAnnotationContext } from '@/src/contexts/ExperimentAnnotationContext';

const StatusMessage = () => {
    return (
        <Alert severity="error" className="mx-auto max-w-lg">
            <div className="">Failed to fetch results</div>
        </Alert>
    );
};

export type Props = {
    canEdit?: boolean;
    data: ResultData | null;
    error: Error | null;
    experiment: Experiment;
    loading: boolean;
};
const AnnotationsDataTable = ({ canEdit, data, error, experiment, loading }: Props) => {
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortedData, setSortedData] = useState<ResultData | null>(null);
    const [sorting, setSorting] = useState<boolean>(false);
    const [sortDesc, setSortDesc] = useState(false);
    const permissions = useExperimentPermissions(experiment);

    const { annotations, setSelectedAnnotation, selectedAnnotation } = useExperimentAnnotationContext();

    const handleResultSort = async (colId: string, desc: boolean) => {
        if (!sortedData || !sortedData.items) return;

        setSorting(true);
        const sortedItems = await [...sortedData.items].sort((a, b) => {
            const aVal = a[colId];
            const bVal = b[colId];
            if (typeof aVal === 'string' || typeof bVal === 'string')
                return desc
                    ? aVal.toString().localeCompare(bVal.toString())
                    : bVal.toString().localeCompare(aVal.toString());
            return desc ? Number(bVal) - Number(aVal) : Number(aVal) - Number(bVal);
        });
        const newData = { ...sortedData, items: sortedItems };

        setSorting(false);
        setSortedData(newData);
    };

    useEffect(() => {
        if (!data || !data.headers) {
            setSortedData(null);
            return;
        }
        const formattedData = { ...data } as ResultData;
        formattedData.headers = formattedData?.headers.filter((h) => h !== 'Cluster');

        setSortedData(formattedData);
    }, [data]);

    useEffect(() => {
        handleResultSort(sortBy || '', sortDesc);
    }, [sortBy, sortDesc]);

    const downloadFilename = data ? generateResultDataFileName({ experiment, data }) : '';
    const downloadEnabled = Boolean(data?.download_url);

    const { menuItems } = useMemo(() => {
        const menuItems: MoreMenuItem[] = [];
        if (downloadEnabled && data) {
            menuItems.push(
                <DownloadDataMoreMenuItem url={data.download_url} baseFilename={downloadFilename} extension=".csv" />,
            );
        }

        return { menuItems };
    }, [experiment, permissions]);
    const renderTable = () => {
        if (canEdit && !sortedData)
            return <p className="mb-2 mt-2 text-default">Select a cluster to view its top markers</p>;

        return (
            <>
                {!canEdit && (
                    <>
                        <p className="text-default">Select a cluster to view its top markers</p>
                        <Select
                            displayEmpty
                            IconComponent={KeyboardArrowDownRoundedIcon}
                            margin="dense"
                            name="cluster"
                            value={selectedAnnotation?.uuid || ''}
                            onChange={({ target }) =>
                                setSelectedAnnotation(annotations?.find((a) => a.uuid === target?.value) || null)
                            }
                            className="max-w-md mt-2"
                            defaultValue=""
                        >
                            <MenuItem value="">All clusters</MenuItem>
                            {annotations?.map((anno) => {
                                return (
                                    <MenuItem key={anno.display_name} value={anno.uuid}>
                                        <div className="flex flex-row items-center justify-start">
                                            {anno.display_name}
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </>
                )}
                {!!loading && (
                    <div className="mb-4 mt-2 space-y-4">
                        <LoadingMessage message="Loading results..." size={24} />
                    </div>
                )}
                {error && <StatusMessage />}
                {!!(!loading && !error && sortedData) && (
                    <div
                        className={cn('mt-10 w-full', {
                            'bg-white opacity-50': !!sorting,
                        })}
                    >
                        <DataTable
                            isSorting={sorting}
                            data={sortedData}
                            tableColor="bg-cyan-100 text-cyan-900"
                            key={sortedData.uuid ?? 'no_data'}
                            cellRenderer={geneLinkCellRenderer({ experiment })}
                            onSortedChange={(header, desc) => {
                                setSortBy(header);
                                setSortDesc(desc ?? false);
                            }}
                            sortBy={sortBy ?? undefined}
                            sortable
                            filterable
                            manualSortBy
                            pageSize={250}
                            sortDesc={sortDesc}
                            moreMenuButton={<MoreMenuIconButton outlined items={menuItems} />}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="mb-4">
            <div className="flex items-center justify-between">
                <p className="z-10 text-lg font-semibold text-default">Marker genes</p>
            </div>
            {renderTable()}
        </div>
    );
};
export default AnnotationsDataTable;

import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import Experiment from '@models/Experiment';

type ContextType = {
    activeExperiment?: Experiment | null;
    resetToDefaultExperiment: () => void;
    setActiveExperiment: (experiment: Experiment | null) => void;
};

const ActiveExperimentContext = createContext<ContextType | null>(null);

export const useOptionalActiveExperimentContext = (): Partial<ContextType> => {
    const context = useContext(ActiveExperimentContext);
    if (!context) {
        return {};
    }
    return context;
};

export const useActiveExperimentContext = () => {
    const context = useContext(ActiveExperimentContext);
    if (!context) {
        throw new Error('ActiveExperimentContext must be used within an ActiveExperimentProvider');
    }
    return context;
};

export const ActiveExperimentProvider = ({
    children,
    defaultExperiment,
}: {
    children: ReactNode;
    defaultExperiment?: Experiment | null;
}) => {
    const [activeExperiment, setActiveExperiment] = useState<Experiment | null | undefined>(defaultExperiment);
    const defaultExperimentRef = useRef(defaultExperiment);

    const resetToDefaultExperiment = () => {
        setActiveExperiment(defaultExperimentRef.current);
    };

    useEffect(() => {
        if (!defaultExperiment) {
            setActiveExperiment(null);
            return;
        }
        setActiveExperiment(defaultExperiment);
        defaultExperimentRef.current = defaultExperiment;
    }, [defaultExperiment?.uuid, defaultExperiment?.accepted_workflow]);

    return (
        <ActiveExperimentContext.Provider value={{ activeExperiment, setActiveExperiment, resetToDefaultExperiment }}>
            {children}
        </ActiveExperimentContext.Provider>
    );
};

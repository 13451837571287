import useLinkedExperiments from '@/src/hooks/useLinkedExperiments';
import Experiment, { JoinedExperimentTypes } from '@/src/models/Experiment';
import React, { ReactNode } from 'react';
import LinkedExperimentCard from '../../experimentCard/LinkedExperimentCard';
import { useMouseMoveHoverEffect } from '../../effects/MouseMoveHoverEffect';
import LoadingMessage from '../../LoadingMessage';
import { useExperimentCanvasContext } from '@/src/contexts/ExperimentCanvasContext';

interface Props {
    cols?: number;
    description: ReactNode;
    experiment: Experiment;
    onSelect: (experiment: JoinedExperimentTypes) => void;
}

const SelectExperimentModalView: React.FC<Props> = ({ cols, description, experiment, onSelect }) => {
    const { linkedExperiments, sourceExperiment, loading } = useLinkedExperiments({ experimentId: experiment?.uuid });
    const { setSelectedLinkedExperimentData } = useExperimentCanvasContext();

    useMouseMoveHoverEffect();

    const handleSelectExperiment = (selectedExperiment: JoinedExperimentTypes) => {
        setSelectedLinkedExperimentData(null);
        onSelect(selectedExperiment);
    };
    const handleSelectLinkedExperiment = (selectedLinkedExperiment: JoinedExperimentTypes) => {
        setSelectedLinkedExperimentData({
            linked_experiment_id: selectedLinkedExperiment.uuid,
            source_experiment_id: experiment?.uuid || '',
        });
        onSelect(selectedLinkedExperiment);
    };

    const getColumnClass = () => {
        switch (cols) {
            case 1:
                return 'grid gap-4 grid-cols-1';
            case 2:
                return 'grid gap-4 grid-cols-1 md:grid-cols-2';
            case 3:
                return 'grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
            case 4:
                return 'grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4';
            default:
                return 'grid gap-4 grid-cols-1 md:grid-cols-2';
        }
    };

    return (
        <div>
            <p className="text-md mb-4 text-center">{description}</p>
            <div className="gap-10 flex flex-col flex-1">
                <div className="flex flex-col gap-2">
                    <p className="font-semibold text-md">Current experiment</p>
                    <div className={getColumnClass()}>
                        {loading ? (
                            <LoadingMessage message="Loading current experiment..." immediate />
                        ) : (
                            <LinkedExperimentCard
                                experiment={sourceExperiment}
                                hideExternalLink
                                onClick={handleSelectExperiment}
                            />
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="font-semibold text-md">Linked experiments</p>
                    <div className={getColumnClass()}>
                        {loading ? (
                            <LoadingMessage message="Loading linked experiments..." immediate />
                        ) : linkedExperiments?.length ? (
                            linkedExperiments.map((exp) => (
                                <LinkedExperimentCard
                                    key={exp.uuid}
                                    experiment={exp}
                                    hideExternalLink
                                    onClick={handleSelectLinkedExperiment}
                                />
                            ))
                        ) : (
                            <p>No linked experiments found.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectExperimentModalView;

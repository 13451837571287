import * as d3 from 'd3';
import BasePlotBuilder, {
    PlotMargin,
    ConstructorParams as BaseParams,
} from '@/src/components/plots/builders/BasePlotBuilder';
import { ArrowPlotData, GenericCellData, PlotMapping, VennDiagramItem } from '@models/ExperimentData';
import * as venn from 'venn.js';
import { getPlotPalette } from '@/src/components/ColorPaletteUtil';
import { numberWithCommas } from '@/src/util/StringUtil';
import VennDiagramDisplayOption from '@/src/models/plotDisplayOption/VennDiagramDisplayOption';

export type ConstructorParams = BaseParams<ArrowPlotData<GenericCellData>>;
export default class VennDiagramBuilder extends BasePlotBuilder<ArrowPlotData<GenericCellData>> {
    constructor(params: ConstructorParams) {
        super(params);
    }

    calculateMargins(): PlotMargin {
        return { top: 0, left: 0, right: 0, bottom: 0 };
    }

    draw = () => {
        const items = this.data.items as VennDiagramItem[];
        const display = this.plot.display as VennDiagramDisplayOption;
        const dataMap = this.data.plot_mapping as PlotMapping<VennDiagramItem>;
        const customOptions = display.custom_options_json ?? {};

        const getCircleColor = (d: VennDiagramItem, i: number) => {
            const theme = display.theme_color;
            const { colors } = getPlotPalette(theme);
            return customOptions?.[dataMap[d.hiddenLabel]]?.circle_color ?? colors[i % colors.length]?.color;
        };

        const getLabelColor = (d: VennDiagramItem) => {
            return customOptions?.[dataMap[d.hiddenLabel]]?.label_color ?? '#000000';
        };
        const getSubLabelColor = (d: VennDiagramItem) => {
            return customOptions?.[dataMap[d.hiddenLabel]]?.sublabel_color ?? '#000000';
        };
        const getCircleOpacity = (i: VennDiagramItem) => {
            const customOpacity = customOptions?.[dataMap[i.hiddenLabel]]?.circle_opacity;
            return customOpacity ? customOpacity / 100 : 0.25;
        };
        const getLabel = (i: VennDiagramItem) => {
            if (display.use_names_as_label) {
                const split = i.list_names.split('-');
                const numberOfListNames = split.length;
                const shortenedNames: string[] = [];
                split.forEach((name) => {
                    const maxLength = 19;
                    if (name.length > maxLength) {
                        shortenedNames.push(name.slice(0, maxLength) + '...');
                    } else {
                        shortenedNames.push(name);
                    }
                });
                return numberOfListNames > 1 ? shortenedNames.join(' & ') : i.list_names;
            }
            return i.label;
        };

        const getTooltipContent = (d: VennDiagramItem): string => {
            const pluralize = d.list_names.split('-').length > 1 ? 's' : '';
            return `
    <span class="block font-semibold text-dark">Set${pluralize}: ${d.hiddenLabel}</span>
    <span class="block text-sm text-gray-600">Name${pluralize}: ${d.list_names}</span>
    `;
        };

        const getSetsData = (d: VennDiagramItem) => {
            return d?.set ?? d?.sets ?? '';
        };

        // Number of circles (2-5)
        const maxSetSize =
            items.length > 0 ? Math.max(...items.map((item) => getSetsData(item)?.split(',').length)) : 2;

        // Standard size mapping to create shape based on number of circles
        const sizeMapping: { [key: number]: number } = {
            1: { 1: 12, 2: 4 },
            2: { 1: 12, 2: 4, 3: 2 },
            3: { 1: 12, 2: 4, 3: 2, 4: 1 },
            4: { 1: 16, 2: 12, 3: 4, 4: 2, 5: 1 },
        }[maxSetSize - 1] || { 1: 16, 2: 12, 3: 4, 4: 2, 5: 1 };

        const sets = items
            ? items.map((item) => {
                  const formattedSet = getSetsData(item)?.split(',') ?? [];
                  return {
                      hiddenLabel: item.label,
                      label: display.show_labels ? getLabel(item) : ' ',
                      list_names: item.list_names,
                      sets: formattedSet,
                      size: sizeMapping[formattedSet.length],
                      value: numberWithCommas(item.count ?? 0),
                  };
              })
            : [];

        // Definitions
        const svg = this.svg;
        const chart = venn.VennDiagram().width(this.width).height(this.height);
        chart.orientationOrder(function (a: { setid: string }, b: { setid: string }): number {
            return a.setid.localeCompare(b.setid);
        });
        const div = svg.datum(sets);
        const layout = chart(div),
            textCentres = layout.textCentres,
            circles = layout.circles;

        // Label color
        layout.update.selectAll('.label').style('fill', (d: VennDiagramItem) => getLabelColor(d));

        // Function to calculate the height of wrapped text
        function getTextHeight(label: string) {
            const width = circles[1].radius || 50;

            const tempText = svg
                .append('text')
                .attr('class', 'label')
                .attr('x', -9999) // position off-screen to avoid any visual overlap
                .text(null);

            const words = label.split(/\s+/).reverse(),
                maxLines = 3,
                minChars = (label.length + words.length) / maxLines,
                lineHeight = 1.1;
            let word = words.pop() as string,
                line = [word],
                joined,
                lineNumber = 0,
                tspan = tempText.append('tspan').text(word);

            while (true) {
                word = words.pop() as string;
                if (!word) break;
                line.push(word);
                joined = line.join(' ');
                tspan.text(joined);
                if (joined.length > minChars && (tspan.node()?.getComputedTextLength() ?? 0) > width) {
                    line.pop();
                    tspan.text(line.join(' '));
                    line = [word];
                    tspan = tempText.append('tspan').text(word);
                    lineNumber++;
                }
            }

            const initial = 0.35 - (lineNumber * lineHeight) / 2,
                x = tempText.attr('x'),
                y = tempText.attr('y');

            tempText
                .selectAll('tspan')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', function (d, i) {
                    return initial + i * lineHeight + 'em';
                });
            const height = tempText.node()?.getBBox().height;
            tempText.remove();

            return height;
        }

        // Show or hide sublabels
        if (display.show_sublabels) {
            layout.enter
                .append('text')
                .attr('class', 'sublabel')
                .text(function (d: VennDiagramItem) {
                    return d?.value + ' genes';
                })
                .style('fill', (d: VennDiagramItem) => getSubLabelColor(d))
                .style('font-size', '0px')
                .attr('text-anchor', 'middle')
                .attr('dy', '0')
                .attr('x', chart.width() / 2)
                .attr('y', chart.height() / 2);
            layout.update
                .selectAll('.sublabel')
                .filter(function (d: VennDiagramItem) {
                    return getSetsData(d) in textCentres;
                })
                .text(function (d: VennDiagramItem) {
                    return d?.value + ' genes';
                })
                .style('font-size', '10px')
                .attr('dy', function (d: VennDiagramItem) {
                    const labelHeight = getTextHeight(d?.label); // adjust the font size and width as needed
                    return labelHeight; // add extra space below the label height
                })
                .attr('x', function (d: VennDiagramItem) {
                    return Math.floor(textCentres[getSetsData(d)].x);
                })
                .attr('y', function (d: VennDiagramItem) {
                    return Math.floor(textCentres[getSetsData(d)].y);
                });
        } else {
            div.call(chart);
        }

        // Add tooltip
        const tooltipContainer = this.tooltip;

        // Highlight outline of intersection before mouseover
        svg.selectAll('path').style('stroke-opacity', 0).style('stroke', '#fff').style('stroke-width', 3);

        // Apply colors and opacity to the circles
        svg.selectAll('g').each(function (datum, i: number) {
            const d = datum as VennDiagramItem;
            if (getSetsData(d).length === 1) {
                const path = (this as HTMLElement).querySelector('path');
                if (path) {
                    path.style.fill = getCircleColor(d, i);
                    path.style.fillOpacity = `${getCircleOpacity(d)}`;
                }
            }
        });

        // Tooltip mouseover logic
        svg.selectAll('g')
            .on('mouseover', function (e, i: unknown) {
                const item = i as VennDiagramItem;
                venn.sortAreas(svg, item);
                venn.sortAreas(svg, i);

                // Display a tooltips
                tooltipContainer.transition().duration(50).style('opacity', 1);
                tooltipContainer
                    .html(getTooltipContent(item))
                    .style('left', `${e.pageX + 20}px`)
                    .style('top', `${e.pageY - 28}px`);

                // highlight the current path
                const selection = d3.select(this).transition('tooltip').duration(400);
                selection
                    .style('fill-opacity', getSetsData(item).length === 1 ? getCircleOpacity(item) + 0.2 : 0.1)
                    .style('fill-opacity', getSetsData(item).length === 1 ? getCircleOpacity(item) + 0.2 : 0.1)
                    .style('stroke-opacity', 1);
            })
            .on('mousemove', function (d) {
                tooltipContainer.style('left', d.pageX + 20 + 'px').style('top', d.pageY - 28 + 'px');
            })
            .on('mouseout', function (_, i) {
                const item = i as VennDiagramItem;
                tooltipContainer.transition().style('opacity', 0);
                const selection = d3.select(this).transition('tooltip').duration(400);
                selection
                    .select('path')
                    .style('fill-opacity', getSetsData(item).length === 1 ? getCircleOpacity(item) : 0.0)
                    .style('stroke-opacity', 0);
            });
    };
}

import { Creatable, Filterable } from '@models/SelectableTypes';
import Genome from '@models/Genome';

export enum OrganismID {
    african_clawed_frog = 'african_clawed_frog',
    cholerae = 'cholerae',
    dog = 'dog',
    eastern_spiny_mouse = 'eastern_spiny_mouse',
    fish = 'fish',
    flower = 'flower',
    fly = 'fly',
    fruitfly = 'fruitfly',
    human = 'human',
    mouse = 'mouse',
    any = 'any',
    other = 'other',
    rat = 'rat',
    worm = 'worm',
    zebrafish = 'zebrafish',
}

export interface Organism {
    shortname: OrganismID | string;
    display_name: string;
    short_display_name: string;
    description: string | null; //Homo Sapiens
    tooltip: string | null;
    genomes?: Genome[] | null;
}

export type SelectableOrganism = Organism & Creatable & Filterable;

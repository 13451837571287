import React from 'react';
import useAuth from './useAuth';
import useApi from './useApi';
import { SharingMember } from '../models/User';
import Endpoints from '../services/Endpoints';
import { useEffect, useState } from 'react';
import { PaginationResponse } from '../services/EndpointUtil';
import DefaultAvatar from 'boring-avatars';
import { getColorsForUserId } from '../components/AvatarCircle';
import ReactDOMServer from 'react-dom/server';
import { Comment } from '../models/Comment';
import { MentionsInputStyle } from 'react-mentions';

type FormattedOrgUsers = { id: string; display: string; avatar_url: string | null }[];

const MAX_INPUT_HEIGHT = 195;
export const defaultMentionStyle = {
    backgroundColor: '#A5B3F540',
    borderRadius: '0.25rem',
};
export const defaultMentionInputStyle: MentionsInputStyle = {
    control: {
        fontSize: 15,
        fontWeight: 'normal',
    },
    '&multiLine': {
        control: {
            minHeight: 63,
        },
    },
    '&singleLine': {
        display: 'inline-block',
    },
    suggestions: {
        list: {
            backgroundColor: '#FFF',
            fontSize: 14,
            boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
            borderRadius: '0.25rem',
        },
        item: {
            padding: '5px 15px',
            backgroundColor: '#FFF',
            transition: 'all 0.1s ease-in',
            '&focused': {
                backgroundColor: '#A5B3F540',
                transition: 'all 0.1s ease-in',
            },
        },
    },
    input: {
        padding: 9,
        overflow: 'auto',
        maxHeight: MAX_INPUT_HEIGHT,
        borderRadius: '0.25rem',
        borderColor: 'rgb(203 213 225)',
        lineHeight: 1.6,
    },
    highlighter: {
        padding: 9,
        boxSizing: 'border-box' as const,
        overflow: 'hidden',
        maxHeight: MAX_INPUT_HEIGHT,
        borderRadius: '0.25rem',
        lineHeight: 1.6,
    },
};

const useCommentMentions = () => {
    const api = useApi();
    const { authReady, user } = useAuth();
    const organization = user?.organization;
    const [error, setError] = useState<string | null>(null);
    const [orgUsersLoading, setOrgUsersLoading] = useState<boolean>(false);
    const [orgUsers, setOrgUsers] = useState<FormattedOrgUsers | null>(null);

    const fetchOrgUsers = async () => {
        if (!organization) return;

        setOrgUsersLoading(true);
        try {
            const fullUserData = await api.get<PaginationResponse<SharingMember>>(
                Endpoints.organization.members({ organizationId: organization.uuid }),
            );
            const users = fullUserData?.items?.map((user) => ({
                id: user.uuid,
                display: `${user.first_name} ${user.last_name}`,
                avatar_url: user.avatar_url,
            }));
            setOrgUsers(users);
            setError('');
        } catch {
            setError('Failed to fetch organization users');
        } finally {
            setOrgUsersLoading(false);
        }
    };

    const getParsedContent = (comment: Comment) => {
        if (comment?.tagged_users && !Object.keys(comment.tagged_users).length) return comment.content;

        return comment.content
            .split(/(@\{[a-zA-Z0-9\-]+\})/)
            .map((part) => {
                const match = part.match(/@\{([a-zA-Z0-9\-]+)\}/);

                if (match) {
                    const uuid = match[1]; // Extract the UUID
                    const matchedUser = comment.tagged_users?.[uuid];
                    const taggedUser = matchedUser
                        ? `${matchedUser.first_name} ${matchedUser.last_name}`
                        : 'Unknown User';
                    const img = orgUsers?.find((user) => user.id === uuid);

                    const avatarElement = img?.avatar_url ? (
                        <img
                            src={img.avatar_url}
                            alt={`${taggedUser}'s avatar`}
                            style={{
                                display: 'block',
                                margin: 0,
                                width: 20,
                                height: 20,
                                borderRadius: '50%',
                                marginRight: 5,
                                verticalAlign: 'middle',
                            }}
                        />
                    ) : (
                        <span style={{ marginRight: 5, verticalAlign: 'middle' }}>
                            <DefaultAvatar
                                size={20}
                                name={uuid ?? taggedUser ?? 'Profile Image'}
                                variant="bauhaus"
                                colors={getColorsForUserId(uuid ?? taggedUser ?? 'Profile Image')}
                                square={false}
                            />
                        </span>
                    );

                    const avatarHtml = ReactDOMServer.renderToStaticMarkup(avatarElement);

                    return `<span style="display: inline-flex; align-items: center; vertical-align: bottom;">
                    ${avatarHtml}
                    <span style="font-weight: bold;">${taggedUser}</span>
                </span>`;
                }

                return part;
            })
            .join('');
    };

    useEffect(() => {
        if (!authReady || !organization) return;

        fetchOrgUsers();
    }, [organization]);

    return { orgUsers, orgUsersLoading, error, getParsedContent };
};

export default useCommentMentions;

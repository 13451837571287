import useFormStyles from '@hooks/useFormStyles';
import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import { Chip, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { formatTableHeader } from '@util/StringUtil';
import { MenuProps } from '@/src/theme';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import FieldError from '@components/forms/FieldError';
import { AssaySummaryAnalysisParameters } from '@models/AnalysisParameters';
import { AssaySummaryAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import { isDefined } from '@util/TypeGuards';

const UNSELECTED_VALUE = -1;
type Props = { name: string; analysisParameters: Pick<AssaySummaryAnalysisParameters, 'targets'>; maxItems?: number };
type FormValues = Pick<AssaySummaryAnalysisFormValues, 'targets'>;
const SimpleTargetsField = ({ name, analysisParameters, maxItems }: Props) => {
    const classes = useFormStyles;

    const { allTargets } = useMemo(() => {
        return {
            allTargets: analysisParameters.targets ?? [],
        };
    }, [analysisParameters]);

    const { values, setFieldValue, errors, touched } = useFormikContext<FormValues>();

    return (
        <div
            className={cn('form-field', {
                'has-error': Boolean(errors.targets && touched.targets),
            })}
        >
            <span className="field-label">Target(s)</span>
            <FormControl fullWidth variant="outlined" error={Boolean(errors.targets && touched.targets)}>
                <Select
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    multiple
                    margin="dense"
                    value={values.targets.length === 0 ? [UNSELECTED_VALUE] : values.targets}
                    name={name}
                    onChange={(e: SelectChangeEvent<(string | number)[]>) => {
                        const value = e.target.value as number[];
                        setFieldValue(
                            'targets',
                            [...value].filter((v) => v !== UNSELECTED_VALUE),
                        );
                    }}
                    defaultValue={['none']}
                    placeholder="Select target..."
                    error={Boolean(errors.targets && touched.targets)}
                    renderValue={(selected: (string | number)[]) => {
                        if (selected[0] === UNSELECTED_VALUE && selected.length === 1) {
                            return <span className="opacity-50">Select a target...</span>;
                        }
                        return (
                            <div className={'flex flex-wrap'}>
                                {selected.map((chipValue) => (
                                    <Chip
                                        key={chipValue}
                                        className="m-2 bg-[#CED4FB]"
                                        label={formatTableHeader(
                                            allTargets.find((t) => t.id === Number(chipValue))?.display_name ??
                                                `${chipValue} not found`,
                                        )}
                                        onMouseDown={(event) => {
                                            event.stopPropagation();
                                        }}
                                        onDelete={() => {
                                            setFieldValue(
                                                'targets',
                                                values.targets?.filter((id) => id !== chipValue),
                                            );
                                        }}
                                    />
                                ))}
                            </div>
                        );
                    }}
                    MenuProps={{
                        ...MenuProps,
                        classes: {
                            paper: 'rounded-lg',
                        },
                    }}
                >
                    {allTargets.map(({ display_name, id }, i) => (
                        <MenuItem
                            sx={{
                                root: classes.rootItem,
                                selected: classes.selectedTarget,
                            }}
                            key={`target_${i}`}
                            value={id}
                            disabled={
                                isDefined(maxItems) && values.targets.length >= maxItems && !values.targets.includes(id)
                            }
                        >
                            <CheckBoxRoundedIcon sx={classes.checked} color="secondary" fontSize="small" />
                            <CheckBoxOutlineBlankRoundedIcon sx={classes.checkbox} color="secondary" fontSize="small" />
                            {formatTableHeader(display_name)}
                        </MenuItem>
                    ))}
                </Select>
                {errors.targets && touched.targets && <FieldError>{errors.targets}</FieldError>}
            </FormControl>
        </div>
    );
};

export default SimpleTargetsField;

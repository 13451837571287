import { FormikFieldError } from '@components/forms/FieldError';
import SelectableItem from '@components/forms/SelectableItem';
import { toggleValue } from '@util/ObjectUtil';
import { VariableIcon } from '@components/experiments/ExperimentIcons';
import { formatTableHeader } from '@util/StringUtil';
import React, { ReactNode, useMemo } from 'react';
import { ParameterOption } from '@models/AnalysisParameters';
import { useField, useFormikContext } from 'formik';
import { AnalysisVariables } from '@models/analysis/ExperimentAnalysis';

type FieldValue = number[];
type Props = {
    disabled?: boolean;
    /** Set to true if a groups field on the form does not need to be reset when variables change. Default=FALSE */
    disableGroups?: boolean;
    groupsName?: string;
    label?: ReactNode;
    maxItems?: number;
    variables: ParameterOption[];
    variablesName?: string;
    /**  Set to true if should save variable name instead of variable id **/
    useVariableName?: boolean;
};
const AnalysisVariablesMultiSelectField = ({
    disabled,
    disableGroups = false,
    groupsName = 'group_ids',
    label = 'Variable(s) to group by',
    maxItems,
    variables,
    variablesName = 'variables',
    useVariableName,
}: Props) => {
    const { setFieldValue } = useFormikContext<AnalysisVariables>();

    const [, field, { setValue }] = useField<FieldValue>(variablesName);

    const { sortedVariables } = useMemo(() => {
        return {
            sortedVariables: (variables ?? []).sort((o1, o2) => {
                return (o1.display_name ?? '').localeCompare(o2?.display_name ?? '');
            }),
        };
    }, [variables]);
    return (
        <div className="form-field">
            <span className="field-label">{label}</span>
            <FormikFieldError name={variablesName} className="mb-2" />
            <div className="flex flex-row flex-wrap">
                {sortedVariables.map((variable, index) => {
                    const value = useVariableName ? variable.display_name : variable.id;
                    return (
                        <SelectableItem
                            key={`variable_item_${index}`}
                            row
                            selected={field.value.includes(value as number)}
                            onSelect={() => {
                                if (maxItems && maxItems <= field.value.length) {
                                    if (maxItems === 1) {
                                        setFieldValue(variablesName, [value]);
                                    }
                                    return null;
                                }
                                if (!disableGroups) {
                                    setFieldValue(groupsName, []);
                                }
                                setValue(toggleValue(field.value, value as number));
                            }}
                            cyId="analysis-variable-item"
                            className="mb-2 mr-2 items-center justify-center gap-2"
                            disabled={disabled}
                        >
                            {variable && <VariableIcon variable={variable} />}
                            <span>{formatTableHeader(variable.display_name)}</span>
                        </SelectableItem>
                    );
                })}
            </div>
        </div>
    );
};

export default AnalysisVariablesMultiSelectField;

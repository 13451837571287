import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import React, { ReactNode, useMemo } from 'react';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import { Item } from '@components/MoreMenuIconButton';
import { LoadingIndicator } from '@components/LoadingButton';
import { DownloadIcon } from '@heroicons/react/solid';
import { ImageAnalysis } from '@models/analysis/ImageAnalysis';
import DownloadDataMoreMenuItem from '@components/DownloadDataMoreMenuItem';
import Endpoints from '@services/Endpoints';
import { generateReportFileName } from '@util/ExperimentUtil';
import {
    ArrowsExpandIcon,
    CodeIcon,
    DocumentTextIcon,
    ExternalLinkIcon,
    MinusCircleIcon,
    BeakerIcon,
    RefreshIcon,
} from '@heroicons/react/outline';
import useClonePlot from '@hooks/useClonePlot';
import useBreakpoints from '@hooks/useBreakpoints';
import { appendQueryParams, QueryParam } from '@services/QueryParams';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import { EditIcon } from '@components/icons/custom/EditIcon';
import { isServerImageDisplayType } from '@models/PlotDisplayType';
import { AnyExperimentData, PlotPipelinePlotData } from '@models/ExperimentData';
import { updateHashWithPlotUUID } from '../util/hashUtil';
import { useActiveExperimentContext } from '../contexts/ActiveExperimentContext';

type Props = {
    disableDownloadLinks?: boolean;
    experiment: Experiment;
    hideEditButton?: boolean;
    hideEditForm?: boolean;
    plot: Plot;
    plotData?: AnyExperimentData | null;
    publicKey?: string | null;
    ready: boolean;
    refreshThumbnail?: () => void;
    setFullScreen?: (full: boolean) => void;
    setMethodsOpen: (open: boolean) => void;
    setResultsOpen: (open: boolean) => void;
    showExperimentLink?: boolean;
};
const useCanvasMenuItems = ({
    disableDownloadLinks,
    experiment,
    hideEditButton,
    hideEditForm,
    plot,
    plotData,
    publicKey,
    ready,
    refreshThumbnail,
    setFullScreen,
    setMethodsOpen,
    setResultsOpen,
    showExperimentLink,
}: Props) => {
    const plotId = plot?.uuid;
    const {
        archivePlot,
        getPlotOverrides,
        handleChangeSelectedPlot,
        setCurrentPlotPanel,
        setExperimentModalOpen,
        setHideEditForm,
        setOpenOnComments,
        setPlotToEmbed,
    } = useExperimentDetailViewContext();
    const { setActiveExperiment } = useActiveExperimentContext();
    const { clonePlot, cloning, cloneDisabled } = useClonePlot({ publicKey, experiment });
    const overrides = getPlotOverrides?.(plotId) ?? { hasOverrides: false };
    const { isSmAndDown } = useBreakpoints();
    const permissions = useExperimentPermissions(experiment, !!publicKey);
    const disableEdit = !permissions.canEdit || hideEditButton;

    const menuItems = useMemo<(Item | ReactNode)[]>(() => {
        const menuItems: (Item | ReactNode)[] = [];
        if (!disableEdit) {
            menuItems.push({
                label: plot?.analysis?.uuid ? 'Edit' : 'Configure',
                onClick: () => {
                    handleChangeSelectedPlot?.(plot);
                    setOpenOnComments?.(false);
                    setCurrentPlotPanel?.(plot?.analysis?.uuid ? 'plot' : 'analysis');
                    if (hideEditForm) {
                        setHideEditForm?.(true);
                    }
                    if (experiment) {
                        setActiveExperiment(experiment);
                    }
                    setExperimentModalOpen?.(true);
                    updateHashWithPlotUUID(plot.uuid);
                },
                icon: <EditIcon className="h-4 w-4" />,
                closeOnClick: true,
            });
        }

        if (!disableEdit && permissions.canEdit && !cloneDisabled) {
            menuItems.push({
                label: 'Clone',
                onClick: async () => {
                    await clonePlot({
                        cloneToCanvas: true,
                        linkedExperimentId: plot?.linked_experiment_id,
                    });
                },
                icon: cloning ? <LoadingIndicator size={16} /> : <CopyIcon width={16} height={16} />,
                closeOnClick: false,
            });
        }

        // Plot download links
        if (ready && !disableDownloadLinks && !isSmAndDown) {
            if (plot?.display?.display_type !== 'image' && !isServerImageDisplayType(plot?.display?.display_type)) {
                menuItems.push({
                    label: 'Export',
                    href: appendQueryParams(
                        `/experiments/${experiment.pluto_id}/plots/${plotId}/export`,
                        overrides?.hasOverrides
                            ? {
                                  [QueryParam.ANALYSIS_ID]: overrides.analysis_id,
                                  [QueryParam.DISPLAY_ID]: overrides.display_id,
                              }
                            : undefined,
                    ),
                    icon: <DownloadIcon className="h-4 w-4" />,
                    newTab: true,
                    closeOnClick: true,
                });
            } else if ((plot?.analysis as ImageAnalysis)?.image_url) {
                const analysisId = overrides.analysis_id ?? plot?.analysis?.uuid;
                menuItems.push(
                    <DownloadDataMoreMenuItem
                        key={'download_image'}
                        endpoint={
                            !analysisId
                                ? undefined
                                : () => {
                                      return Endpoints.lab.experiment.analysis.imageAnalysisSignedUrl(
                                          {
                                              experimentId: experiment.uuid,
                                              analysisId,
                                          },
                                          { download: true },
                                      );
                                  }
                        }
                        url={analysisId ? undefined : (plot?.analysis as ImageAnalysis).image_url}
                        baseFilename={generateReportFileName({ experiment, suffix: 'plot' })}
                        extension=""
                        label="Download"
                    />,
                );
            } else if (isServerImageDisplayType(plot.display.display_type)) {
                // const analysisId = overrides.analysis_id ?? plot?.analysis?.uuid;
                const pdfs = (plotData as PlotPipelinePlotData | null)?.plots?.images?.pdf;
                const pngs = (plotData as PlotPipelinePlotData | null)?.plots?.images?.png;
                const pdfUrl = plot.display?.is_full_width ? pdfs?.wide : (pdfs?.square ?? pdfs?.auto);
                const pngUrl = plot.display?.is_full_width ? pngs?.wide : (pngs?.square ?? pngs?.auto);

                if (setFullScreen) {
                    menuItems.push({
                        label: 'Full-screen',
                        onClick: async () => {
                            await setFullScreen(true);
                        },
                        icon: <ArrowsExpandIcon width={16} />,
                        closeOnClick: false,
                    });
                }

                menuItems.push(
                    <DownloadDataMoreMenuItem
                        key={'download_pdf'}
                        url={pdfUrl}
                        baseFilename={generateReportFileName({ experiment, suffix: 'plot' })}
                        extension=".pdf"
                        label="Download .pdf"
                        newTab
                    />,
                    <DownloadDataMoreMenuItem
                        key={'download_png'}
                        url={pngUrl}
                        baseFilename={generateReportFileName({ experiment, suffix: 'plot' })}
                        extension=".png"
                        label="Download .png"
                        newTab
                    />,
                );
            }
        }

        if (showExperimentLink) {
            menuItems.push({
                label: 'Open original experiment',
                href: `/experiments/${plot?.source_experiment_id || experiment.pluto_id}/analysis#plot-${plot.uuid}`,
                closeOnClick: false,
                newTab: true,
                icon: <ExternalLinkIcon className="h-4 w-4" />,
            });
        }

        if (!hideEditButton) {
            menuItems.push({
                label: 'Embed',
                onClick: () => {
                    setPlotToEmbed?.(plot);
                    if (experiment) {
                        setActiveExperiment(experiment);
                    }
                },
                icon: <CodeIcon className="h-4 w-4" />,
                closeOnClick: true,
            });
        }

        if (plot?.analysis) {
            menuItems.push({
                label: 'View methods',
                onClick: () => setMethodsOpen(true),
                icon: <DocumentTextIcon className="h-4 w-4" />,
                closeOnClick: true,
            });
        }

        if (plot?.analysis_type !== 'image' && plot?.analysis_type !== 'text') {
            menuItems.push({
                label: 'View results',
                onClick: () => setResultsOpen(true),
                icon: <BeakerIcon className="h-4 w-4" />,
                closeOnClick: true,
            });
        }

        if (plot?.thumbnail_uri?.rectangle) {
            menuItems.push({
                label: 'Refresh thumbnail',
                onClick: () => {
                    refreshThumbnail?.();
                },
                icon: <RefreshIcon className="h-4 w-4" />,
                closeOnClick: true,
            });
        }

        if (!hideEditButton && permissions.canEdit) {
            menuItems.push({
                label: 'Remove',
                onClick: () => {
                    archivePlot?.(plotId, true, plot?.linked_experiment_id);
                },
                icon: <MinusCircleIcon className="h-4 w-4" />,
                closeOnClick: true,
                color: 'danger',
            });
        }

        return menuItems;
    }, [ready, plot, permissions, overrides, hideEditButton, disableEdit, refreshThumbnail]);

    return { menuItems };
};

export default useCanvasMenuItems;

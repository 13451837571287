import {
    allProjectColors,
    CreateProjectParams,
    getRandomProjectColor,
    isUpdateProjectParams,
    Project,
    ProjectColor,
    ProjectType,
    UpdateProjectParams,
} from '@models/Project';
import * as Yup from 'yup';
import { isBlank } from '@util/StringUtil';
import { useState, useMemo, useContext } from 'react';
import useLabPermissions from '@hooks/useLabPermissions';
import { isDefined } from '@util/TypeGuards';
import Logger from '@util/Logger';
import { ApiError } from '@services/ApiError';
import useProjectApi from '@hooks/useProjectApi';
import { AuthContext } from '../contexts/AuthContext';
import { Organization } from '../models/Organization';

const logger = Logger.make('useProjectForm');

export const DESCRIPTION_CHARACTER_LIMIT = 25_000;
export const LINK_NAME_CHARACTER_LIMIT = 50;
export const FUNDING_SOURCE_NONE = 'none';

export const formSchema = Yup.object({
    addExperiment: Yup.boolean(),
    funding_source_id: Yup.string(),
    type: Yup.string(),
    teamId: Yup.string().when('type', {
        is: (t: ProjectType) => t === ProjectType.team,
        then: Yup.string().required('Please select a team'),
        otherwise: Yup.string().nullable(),
    }),
    color: Yup.mixed<ProjectColor>().oneOf(allProjectColors),
    projectName: Yup.string().required('Please enter a project name').min(4, 'Please enter a longer project name'),
    description: Yup.string().nullable(),
    external_url: Yup.string().url('Please enter a valid url. Be sure to include http(s)://').nullable(),
    external_url_display_name: Yup.string()
        .nullable()
        .when('external_url', {
            is: (url: string) => !isBlank(url),
            then: Yup.string()
                .required('Display text is required when adding a link')
                .max(LINK_NAME_CHARACTER_LIMIT, 'Character limit reached'),
            otherwise: Yup.string().nullable(),
        }),
});
export type FormValues = Yup.InferType<typeof formSchema>;

const getInitialValues = ({
    personalProjectsEnabled,
    project,
    teamProjectsEnabled,
    userOrg,
}: {
    personalProjectsEnabled?: boolean;
    project?: Project | null;
    teamProjectsEnabled?: boolean;
    userOrg?: Organization;
}): FormValues => {
    let initialType: ProjectType | undefined = undefined;
    let initialTeamId = '';

    if (project) {
        // If it's an existing project (edit/update)
        if (project.team?.uuid) {
            initialType = ProjectType.team;
            initialTeamId = project.team.uuid;
        } else if (personalProjectsEnabled) {
            initialType = ProjectType.shareable;
        }
    } else {
        // If it's a new project (creation)
        if (teamProjectsEnabled) {
            initialType = ProjectType.team;
            initialTeamId = userOrg?.teams?.find((t) => !!t.is_org)?.uuid || '';
        } else if (personalProjectsEnabled) {
            initialType = ProjectType.shareable;
        }
    }

    return {
        projectName: project?.name ?? '',
        funding_source_id: project?.funding_source_id ?? FUNDING_SOURCE_NONE,
        addExperiment: true,
        teamId: initialTeamId,
        type: initialType,

        color: project?.color ?? getRandomProjectColor(),
        description: project?.description ?? '',
        external_url: project?.external_url ?? null,
        external_url_display_name: project?.external_url_display_name ?? null,
    };
};

type Props = { project?: Project | null };
const useProjectForm = ({ project }: Props) => {
    const [isEdit] = useState(isDefined(project));
    const [saveError, setSaveError] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);
    const { canCreateTeamProjects, canCreateShareableProjects } = useLabPermissions();
    const projectApi = useProjectApi();

    const { user } = useContext(AuthContext);
    const userOrg = user?.organization;

    const initialValues = useMemo(() => {
        return getInitialValues({
            personalProjectsEnabled: canCreateShareableProjects,
            project,
            teamProjectsEnabled: canCreateTeamProjects,
            userOrg,
        });
    }, [project, canCreateTeamProjects, canCreateShareableProjects]);

    /**
     * Invalidates all cache keys for projects and this specific project
     * @return {Promise<void>}
     */
    const revalidateLabProjects = async () => {
        await projectApi.revalidateLabProjects({ project });
    };

    /**
     * Create a new project. Catches API errors and Will return null, otherwise returns the created project
     * @param {CreateProjectParams} values
     * @return {Promise<Project | null>} the created project or null if there was an error
     */
    const createProject = async (values: CreateProjectParams): Promise<Project | null> => {
        try {
            setSaving(true);
            const createdProject = await projectApi.createProject(values);
            setSaving(false);
            return createdProject;
        } catch (error) {
            logger.error(error);
            setSaveError(ApiError.getMessage(error as Error));
            setSaving(false);
        }
        return null;
    };

    /**
     * Update an existing project. All API errors are caught and will return null.
     * @param {UpdateProjectParams} values
     * @return {Promise<Project | null>} the updated project or null if there was an error
     */
    const updateProject = async (values: UpdateProjectParams): Promise<Project | null> => {
        const projectId = project?.uuid;
        if (!projectId) {
            logger.error(new Error('Can not update a project when no project ID was provided.'));
            return null;
        }
        try {
            setSaving(true);
            const updatedProject = await projectApi.updateProject(projectId, values);
            setSaving(false);
            return updatedProject;
        } catch (error) {
            logger.error(error);
            setSaveError(ApiError.getMessage(error as Error));
            setSaving(false);
        }
        return null;
    };

    const submitFormValues = async (values: CreateProjectParams | UpdateProjectParams) => {
        if (isUpdateProjectParams(values) && isEdit) {
            return updateProject(values);
        } else {
            return createProject(values);
        }
    };

    return {
        initialValues,
        canCreateShareableProjects,
        canCreateTeamProjects,
        isEdit,
        saving,
        saveError,
        createProject,
        updateProject,
        revalidateLabProjects,
        submitFormValues,
    };
};

export default useProjectForm;

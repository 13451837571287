import { ScorePlotData } from '@models/ExperimentData';
import React, { useMemo } from 'react';
import ScoreBarPlotBuilder from '@components/analysisCategories/pathway/plots/builders/ScoreBarPlotBuilder';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import Logger from '@util/Logger';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

const logger = Logger.make('ScoreBarPlotView');

type Props = { customPlotStylingOptions: CustomPlotStylingOptions | null; xAxisTruncated: boolean };
const ScoreBarPlotView = ({ customPlotStylingOptions, xAxisTruncated }: Props) => {
    const drawChart = useMemo<DrawChartFn>(
        () =>
            ({ svgSelection: _svg, size, context, tooltipId }) => {
                logger.debug('rendering score plot with size', size);
                const { publicationMode, plot, plotData, experiment, isExportMode } = context;
                _svg.selectAll('g').remove();
                const svg = _svg.append<SVGSVGElement>('g');

                const builder = new ScoreBarPlotBuilder({
                    plot,
                    data: plotData as ScorePlotData,
                    svg,
                    experiment,
                    width: size.width,
                    height: size.height,
                    publicationMode,
                    xAxisTruncated,
                    isExportMode,
                    tooltipId,
                    stylingOptions: customPlotStylingOptions,
                });

                builder.draw();
            },
        [customPlotStylingOptions, xAxisTruncated],
    );
    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default ScoreBarPlotView;

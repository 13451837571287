import React from 'react';
import Plot from '@models/Plot';
import { SeuratDifferentialExpressionAnalysis } from '@models/analysis/SeuratDifferentialExpressionAnalysis';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import CodeBlockInline from '@components/CodeBlockInline';
import { StatTestSelectOptions } from '@models/preprocesses/ClusterSeuratObject';
import VolcanoPlotDisplayOption from '@models/plotDisplayOption/VolcanoPlotDisplayOption';
import { PValFillThreshold, PValFillThresholdLower, PValFillThresholdUpper } from '../displays/VolcanoPlotMethods';

type Props = { plot: Plot };
const SeuratDifferentialExpressionMethodsSection = ({ plot }: Props) => {
    const analysis = plot.analysis as SeuratDifferentialExpressionAnalysis | null;
    const display = plot.display as VolcanoPlotDisplayOption | null;
    const itemName = analysis?.analysis_type === 'differential_binding' ? 'peak' : 'gene';
    if (!analysis) {
        return <div>No analysis was found.</div>;
    }

    const { group1, group2, stat_test, min_pct } = analysis;

    const prettyStatTestName = StatTestSelectOptions.find((option) => option.value === stat_test)?.label;
    const $targetGroup = <span className="font-semibold">{group1?.display_name ?? 'Target group'}</span>;
    const $referenceGroup = <span className="font-semibold">{group2?.display_name ?? 'Reference group'}</span>;

    return (
        <section>
            <MethodsSectionTitle>Differential expression analysis</MethodsSectionTitle>

            <div className="space-y-4">
                <p className="mb-4">
                    Differential expression analysis was performed with the <CodeBlockInline>Seurat</CodeBlockInline> R
                    package<sup>1</sup>, comparing the groups: {$targetGroup} vs {$referenceGroup}. Differentially
                    expressed genes between {$targetGroup} vs {$referenceGroup} were identified using the{' '}
                    {stat_test !== 'wilcox' ? (
                        <CodeBlockInline>FindMarkers()</CodeBlockInline>
                    ) : (
                        <>
                            <CodeBlockInline>RunPresto()</CodeBlockInline>{' '}
                            <CodeBlockInline>SeuratWrappers</CodeBlockInline>
                        </>
                    )}{' '}
                    function, with the <span className="font-semibold">{prettyStatTestName}</span>. Genes were only
                    tested if they were detected in a minimum fraction of{' '}
                    <span className="font-semibold">{min_pct}</span> cells in either of the two groups.
                </p>
                <p>
                    Log<sub>2</sub> fold change was calculated for the comparison: {$targetGroup} vs {$referenceGroup}.
                    Thus, genes with a positive log<sub>2</sub> fold change value had increased expression in{' '}
                    {$targetGroup} cells. Genes with a negative log<sub>2</sub> fold change value had increased
                    expression in {$referenceGroup} cells.
                </p>
                <section>
                    <MethodsSectionTitle>Volcano plot</MethodsSectionTitle>
                    <p className="mb-6">
                        Volcano plot showing the log<sub>2</sub> fold change of each {itemName} on the x-axis and
                        the&nbsp;
                        <span className="whitespace-nowrap">
                            -log
                            <sub>10</sub>(adjusted <i>p</i>-value)
                        </span>
                        {` on the y-axis. Points are filled if the ${itemName}'s adjusted `}
                        <span className="whitespace-nowrap">
                            <i>p</i>-value is ≤ <PValFillThreshold display={display} />
                        </span>{' '}
                        and its fold change is either less than <PValFillThresholdLower display={display} /> or greater
                        than <PValFillThresholdUpper display={display} />.
                    </p>
                </section>
                <section>
                    <p className="mb-1 font-semibold text-gray-900">Statistical significance</p>
                    <p className="mb-4">
                        <i>p</i>-value adjustment was performed using bonferroni correction based on the total number of
                        genes in the dataset. Adjusted <i>p</i>-values are shown on the y-axis of the volcano plot. An
                        adjusted <i>p</i>-value of <PValFillThreshold display={display} /> was used as the threshold for
                        statistical significance. Note that extremely small adjusted <i>p</i>-values are treated as 0,
                        and for plotting purposes adjusted <i>p</i>-values of 0 are displayed as a maximum -log
                        <sub>10</sub> value of 310 on the volcano plot.
                    </p>
                </section>
            </div>
        </section>
    );
};

export default SeuratDifferentialExpressionMethodsSection;

import Link from 'next/link';
import { PropsWithChildren, ReactNode, useEffect, useContext } from 'react';
import { AuthContext } from '@contexts/AuthContext';
import cn from 'classnames';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import Experiment from '@models/Experiment';
import { hasCommentsPermission, hasPermission } from '@util/PermissionUtil';
import { PermissionName } from '@models/Permission';
import SharingDropdownButtonV2 from '@components/SharingDropdownButtonV2';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import Endpoints from '@services/Endpoints';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import { PaginationResponse } from '@services/EndpointUtil';
import { SharingMember } from '@models/User';
import { Project } from '@models/Project';
import ProjectExperimentsDropdownMenu from '@components/projects/ProjectExperimentsDropdownMenu';
import ExperimentActionsDropdownMenu from '@components/experiments/ExperimentActionsDropdownMenu';
import { Tooltip } from '@mui/material';
import { MapIcon, PencilAltIcon, TerminalIcon } from '@heroicons/react/outline';
import { ChartBarIcon, DatabaseIcon, UserGroupIcon } from '@heroicons/react/outline';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import CommentsIcon from './icons/custom/CommentsIcon';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { ExperimentTypeShortname, isSingleCellExperimentType } from '../models/ExperimentType';
import { isSponsor } from '@models/User';

const ICON_SIZE_CLASSES = 'h-4 w-4';

type NavItemProps = PropsWithChildren<{
    className?: string;
    href: string;
    name: string;
    disabled?: boolean;
    isBeta?: boolean;
    icon: ReactNode;
}>;

const NavItem = ({ href, className, icon, name, disabled = false, isBeta = false }: NavItemProps) => {
    const router = useRouter();
    const currentPathStripped = router.asPath.split('?organization')[0];
    const isCurrentPath =
        currentPathStripped === href || (href.includes('analysis') && router.asPath.includes('analysis')) || false;

    return (
        <Link href={href} passHref legacyBehavior>
            <a
                data-cy={`nav-item-${name.toLowerCase()}`}
                data-active={isCurrentPath ? 'yes' : undefined}
                aria-disabled={disabled}
                className={cn(
                    className,
                    'relative flex h-full items-center justify-center px-3 font-semibold no-underline transition-opacity hover:text-white',
                    {
                        'text-white/70': !isCurrentPath,
                        'text-white': isCurrentPath,
                        '!pointer-events-none !text-white/20': disabled,
                    },
                )}
            >
                {isBeta && (
                    <div className="pluto-gradient2 absolute right-[5px] top-[9px] h-[9px] w-[9px] rounded-full" />
                )}
                <span className="hidden lg:block">{name}</span>
                <Tooltip arrow title={name} placement="bottom">
                    <span className="block lg:hidden">{icon}</span>
                </Tooltip>
            </a>
        </Link>
    );
};

type DividerProps = {
    height?: string;
    marginTop?: string;
    className?: string;
};

const Divider = ({ height = '17px', marginTop = '0px', className }: DividerProps) => (
    <div
        className={cn('border-r max-[375px]:mt-0 max-[375px]:h-full', className)}
        style={{
            borderColor: '#7E51A9',
            borderWidth: '1px',
            marginTop,
            height,
        }}
    />
);

const SubPageLinks = ({ experiment }: { experiment?: Experiment }) => {
    const { user } = useContext(AuthContext);
    const isSponsorUser = isSponsor(user);
    const experimentPath = `/experiments/${experiment?.pluto_id?.toUpperCase()}`;

    const isSingleCellExperiment = isSingleCellExperimentType(experiment?.type?.shortname);
    const isMultiomicsExperiment = experiment?.type?.shortname === ExperimentTypeShortname.multiomics;
    const pipelineRunCompleted =
        experiment?.qc_report_url ||
        (experiment?.pipeline_run && experiment.pipeline_run?.pipeline_status?.status === 'completed');
    const singleCellPreAnalysisCompleted = isSingleCellExperiment
        ? Boolean(experiment?.accepted_workflow) && pipelineRunCompleted
        : true;
    const nonSingleCellPreAnalysisCompleted = isSingleCellExperiment ? true : experiment?.status.includes('complete');

    const navItems = [
        {
            name: 'Overview',
            icon: <PencilAltIcon className={ICON_SIZE_CLASSES} />,
            href: experimentPath,
            disabled: !experiment,
        },
        !isMultiomicsExperiment && {
            name: 'Analysis',
            icon: <ChartBarIcon className={ICON_SIZE_CLASSES} />,
            href: `${experimentPath}/analysis`,
            disabled: !experiment,
        }, // Hide Analysis tab for multiomics experiments
        !isSponsorUser && {
            name: 'Canvas',
            icon: <MapIcon className={ICON_SIZE_CLASSES} />,
            href: `${experimentPath}/canvas`,
            disabled: !experiment || !nonSingleCellPreAnalysisCompleted || !singleCellPreAnalysisCompleted,
        },
        !isSponsorUser && {
            name: 'Downloads',
            icon: <DatabaseIcon className={ICON_SIZE_CLASSES} />,
            href: `${experimentPath}/data`,
            disabled: !experiment,
        },
        !isSponsorUser && {
            name: 'Collaboration',
            icon: <UserGroupIcon className={ICON_SIZE_CLASSES} />,
            href: `${experimentPath}/collaboration`,
            disabled: !experiment,
        },
        !isSponsorUser && {
            name: 'Workflow',
            icon: <TerminalIcon className={ICON_SIZE_CLASSES} />,
            href: `${experimentPath}/workflow`,
            disabled: !experiment,
        },
    ].filter(Boolean); // Filter out falsey values (e.g., if canvas is not enabled)

    return (
        <nav className="flex flex-row text-xs" aria-label="Experiment Navigation">
            <ul className="flex">
                {navItems?.map(
                    (item, index) =>
                        item && (
                            <li key={item.name} className="flex items-center">
                                <NavItem {...item} />
                                {index < navItems.length - 1 && <Divider />}
                            </li>
                        ),
                )}
            </ul>
        </nav>
    );
};

type Props = {
    className?: string;
    experiment: Experiment;
    project: Project;
    projectExperiments: Experiment[];
    collapsed: boolean;
};

const ExperimentNavigation = ({ experiment, project, projectExperiments, collapsed }: Props) => {
    const router = useRouter();
    const projectId = project?.uuid;
    const isOnProjectPage = router.pathname.includes('project') ?? false;
    const { updateProjectSharingModal, updateExperimentSharingModal } = useLabSpaceContext();
    const { setExperimentCommentsOpen, experimentCommentsOpen } = useExperimentDetailViewContext();
    const permissions = useExperimentPermissions(experiment);
    const { features } = useOrganizationPermissions();
    const { user } = useContext(AuthContext);
    const canInvite = hasPermission(experiment, {
        requires: [PermissionName.invite_experiment_users],
    });

    const commentsEnabled = hasCommentsPermission({ features, experiment, user });

    const { data: experimentMembersData, error: experimentMembersError } = useSWR<PaginationResponse<SharingMember>>(
        () =>
            !permissions.members.canView || !experiment
                ? null
                : Endpoints.lab.experiment.members({ experimentId: experiment.uuid }),
    );
    const experimentMembersLoading = !experimentMembersData && !experimentMembersError;
    const experimentMembers = experimentMembersData?.items ?? [];
    const totalExperimentMembers = experimentMembersData?.count ?? 0;

    const { data: projectMembersData, error: projectMembersError } = useSWR<PaginationResponse<SharingMember>>(
        projectId && Endpoints.lab.project.members({ projectId }),
    );
    const projectMembersLoading = !projectMembersData && !projectMembersError;
    const projectMembers = projectMembersData?.items ?? [];
    const totalProjectMembers = projectMembersData?.count ?? 0;

    const membersLoading = isOnProjectPage ? projectMembersLoading : experimentMembersLoading;
    const members = isOnProjectPage ? projectMembers : experimentMembers;
    const totalMembers = isOnProjectPage ? totalProjectMembers : totalExperimentMembers;
    const membersError = isOnProjectPage ? projectMembersError : experimentMembersError;

    const handleInviteUser = () => {
        if (isOnProjectPage) {
            updateProjectSharingModal({ item: project, open: true, error: null });
        } else {
            updateExperimentSharingModal({ item: experiment, open: true, error: null });
        }
    };

    useEffect(() => {
        if (window.location.hash === '#comments') {
            setExperimentCommentsOpen(true);
        }
    }, []);

    return (
        <div
            id="experiment-nav"
            className="fixed z-[997] h-12 bg-pluto-layout-blue-hover flex items-center justify-between transition-all duration-300"
            style={{
                top: '54px',
                width: `calc(100% - ${collapsed ? '4rem' : '13rem'})`, // Adjust width based on sidebar
                left: collapsed ? '4rem' : '13rem', // Align with sidebar's width
            }}
        >
            {experiment ? (
                <div className="flex w-full flex-grow flex-wrap justify-between pl-2" data-cy="experiment-nav">
                    <div className="order-1 flex h-12 flex-grow">
                        <SubPageLinks experiment={experiment} />
                    </div>
                    <div
                        data-name="center-nav"
                        className="hidden flex-grow justify-center border-t border-highlight md:order-2 md:flex md:border-none lg:ml-2"
                    >
                        <ProjectExperimentsDropdownMenu
                            projectExperiments={projectExperiments}
                            experiment={experiment}
                            project={project}
                        />
                        <ExperimentActionsDropdownMenu experiment={experiment} />
                    </div>
                    <div className="order-2 flex flex-grow flex-row items-center justify-end border-highlight pr-2 max-[375px]:justify-start max-[375px]:border-t">
                        {commentsEnabled && (
                            <div
                                className="ml-2 mr-4 flex cursor-pointer items-center text-white/70 hover:text-white"
                                onClick={() => setExperimentCommentsOpen(!experimentCommentsOpen)}
                            >
                                <CommentsIcon height={18} width={18} className="mr-2 text-inherit" />
                                <p className="hidden text-xs md:block">Comments</p>
                            </div>
                        )}
                        {canInvite && (
                            <div className="mr-3">
                                <SharingDropdownButtonV2
                                    error={membersError?.message}
                                    loading={membersLoading}
                                    onManage={handleInviteUser}
                                    totalMembers={totalMembers}
                                    users={members}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div>Loading experiment navigation</div>
            )}
        </div>
    );
};

export default ExperimentNavigation;

import React, { useMemo, useRef } from 'react';
import { VariableSizeGrid as Grid, GridChildComponentProps, GridOnScrollProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { isDefined } from '@util/TypeGuards';
import { programmaticColumns } from '@components/dataTable/DataTable';
import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { TableInstance } from 'react-table';

type VirtualizedTableProps = {
    tableInstance: TableInstance;
    /** Minimum column width if the content estimation is smaller */
    columnWidth?: number;
    rowHeight?: number;
    headerHeight?: number;
    tableColor?: string;
    /** Maximum column width */
    maxColumnWidth?: number;
};

const VirtualizedTable = ({
    tableInstance,
    columnWidth = 170,
    rowHeight = 40,
    headerHeight = 55,
    tableColor = 'bg-secondary text-white',
    maxColumnWidth = 500,
}: VirtualizedTableProps) => {
    const { headerGroups, page, rows, prepareRow } = tableInstance;
    const currentRows = page ?? rows;

    // Prepare data rows
    const preparedRows = useMemo(() => {
        return currentRows.map((row) => {
            prepareRow(row);
            return row;
        });
    }, [currentRows, prepareRow]);

    // Assume headerGroups[0] contains the header row
    const headerGroup = headerGroups?.[0];
    const columnCount = headerGroup?.headers?.length ?? 0;

    // Compute column widths based on header and cell content.
    // For each column, we calculate the maximum text length from header and all cells,
    // then use a heuristic: (max text length * 8px) + 32px padding.
    // Finally, we limit the width with maxColumnWidth.
    const columnWidths = useMemo(() => {
        return (
            headerGroup?.headers?.map((column, colIndex) => {
                // Determine header text.
                const renderedHeader = column.render('Header');
                let headerText = '';
                if (typeof renderedHeader === 'string') {
                    headerText = renderedHeader;
                } else if (React.isValidElement(renderedHeader)) {
                    headerText = column.id;
                } else {
                    headerText = column.id;
                }

                let maxTextLength = headerText.length;

                // Check each row's cell content.
                preparedRows.forEach((row) => {
                    const cell = row.cells[colIndex];
                    if (isDefined(cell.value)) {
                        let cellText = '';
                        if (typeof cell.value === 'string') {
                            cellText = cell.value;
                        } else {
                            cellText = String(cell.value);
                        }
                        maxTextLength = Math.max(maxTextLength, cellText.length);
                    }
                });

                const estimatedWidth = maxTextLength * 8 + 32;
                const calculatedWidth = Math.max(estimatedWidth, columnWidth);
                return Math.min(calculatedWidth, maxColumnWidth);
            }) ?? []
        );
    }, [headerGroup?.headers, preparedRows, columnWidth, maxColumnWidth]);

    // Refs to synchronize horizontal scrolling
    const headerGridRef = useRef<Grid>(null);
    const bodyGridRef = useRef<Grid>(null);

    // Synchronize header scrollLeft when body scrolls
    const onBodyScroll = ({ scrollLeft }: GridOnScrollProps) => {
        if (headerGridRef.current) {
            headerGridRef.current.scrollTo({ scrollLeft });
        }
    };

    return (
        <div
            style={{ minHeight: '600px', height: '100%' }}
            className="w-full border-r border-l border-b border-gray-200 "
        >
            <AutoSizer className="w-full">
                {({ height, width }) => {
                    const bodyHeight = height - headerHeight;

                    // Compute total width of all columns as calculated before.
                    const totalCalculatedWidth = columnWidths.reduce((acc, w) => acc + w, 0);

                    // If the table’s calculated width is less than the container,
                    // scale each column proportionally to fill the container.
                    // (This simple approach will scale up the widths while still capping
                    // each column at maxColumnWidth.)
                    const finalColumnWidths =
                        totalCalculatedWidth < width
                            ? columnWidths.map((w) => Math.min(w * (width / totalCalculatedWidth), maxColumnWidth))
                            : columnWidths;

                    return (
                        <>
                            {/* Header Grid */}
                            <Grid
                                ref={headerGridRef}
                                className="header-grid"
                                columnCount={columnCount}
                                columnWidth={(index) => finalColumnWidths[index]}
                                height={headerHeight}
                                rowCount={1}
                                rowHeight={() => headerHeight}
                                width={width}
                            >
                                {({ columnIndex, style }: GridChildComponentProps) => {
                                    const column = headerGroup?.headers[columnIndex];
                                    const headerProps = column.getHeaderProps(column.getSortByToggleProps?.());
                                    const { key, ...restHeaderProps } = headerProps;

                                    return (
                                        <div
                                            key={key}
                                            {...restHeaderProps}
                                            style={{ ...style }}
                                            className={cn(
                                                'sticky top-0 z-10 px-4 py-4 text-left font-semibold last:-mr-1 last:rounded-tr-xl',
                                                tableColor,
                                            )}
                                        >
                                            <span className="flex items-center space-x-1">
                                                {column.render('Header')}
                                                {column.isSorted ? (
                                                    <span className="relative top-[3px]">
                                                        {column.isSortedDesc ? (
                                                            <SortDescendingIcon className="ml-2 h-5 w-5" />
                                                        ) : (
                                                            <SortAscendingIcon className="ml-2 h-5 w-5" />
                                                        )}
                                                    </span>
                                                ) : (
                                                    column.canSort && <span className="inline-block w-5" />
                                                )}
                                            </span>
                                        </div>
                                    );
                                }}
                            </Grid>

                            {/* Body Grid */}
                            <Grid
                                ref={bodyGridRef}
                                onScroll={onBodyScroll}
                                columnCount={columnCount}
                                columnWidth={(index) => finalColumnWidths[index]}
                                height={bodyHeight}
                                rowCount={preparedRows.length}
                                rowHeight={() => rowHeight}
                                width={width}
                            >
                                {({ columnIndex, rowIndex, style }: GridChildComponentProps) => {
                                    const row = preparedRows[rowIndex];
                                    const cell = row.cells[columnIndex];

                                    const cellContent =
                                        !isDefined(cell.value) && !programmaticColumns.includes(cell.column.id) ? (
                                            <i className="text-sm text-gray-300">N/A</i>
                                        ) : (
                                            cell.render('Cell')
                                        );
                                    return (
                                        <div
                                            style={{
                                                ...style,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            className="flex items-center justify-start border-b border-r border-gray-200 px-4 py-2 box-border bg-white"
                                        >
                                            {cellContent}
                                        </div>
                                    );
                                }}
                            </Grid>
                        </>
                    );
                }}
            </AutoSizer>
        </div>
    );
};

export default VirtualizedTable;

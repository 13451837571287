import { useSettingsHelpers, LabSettings } from '@hooks/useSettingsHelpers';
import useSWR from 'swr';
import { LabSettingsResponse } from '@api/lab/LabApiTypes';
import Endpoints from '@services/Endpoints';
import Experiment from '@models/Experiment';
import { SelectableExperimentType } from '@models/ExperimentType';
import AnalysisType, { AnalysisCategoryShortname, AnalysisShortname } from '@models/analysis/AnalysisType';
import { isDefined } from '@util/TypeGuards';
import { AnalysisCategoryInfo } from '@models/analysis/AnalysisCategory';
import { useMemo } from 'react';
import useAuth from '@hooks/useAuth';

export type ExperimentSettings = LabSettings & {
    hasAvailableAnalysisTypes: boolean;
    experimentType?: SelectableExperimentType | null | undefined;
};

/**
 * Get settings for an experiment. If no experiment is provided, the user's org's settings will be used.
 * @param {Experiment|null} experiment
 * @param options
 * @return {LabSettings}
 */
const useExperimentSettings = (
    experiment?: Experiment | null | undefined,
    options?: { pollingEnabled?: boolean; skip?: boolean },
): ExperimentSettings => {
    const { isLoggedIn } = useAuth();
    const { pollingEnabled = false, skip = false } = options ?? {};
    const { data, error } = useSWR<LabSettingsResponse>(
        () => {
            if (!isLoggedIn || skip) {
                return null;
            }
            return experiment
                ? Endpoints.lab.experiment.settings({ experimentId: experiment.uuid })
                : Endpoints.organization.settings();
        },
        { revalidateOnMount: true, refreshInterval: pollingEnabled ? 5_000 : undefined },
    );

    const settings = useSettingsHelpers({ data, error });
    const experimentTypeShortname = experiment?.type?.shortname;

    const { experimentType, hasAvailableAnalysisTypes, sortedAnalysisCategoryInfo, analysisTypesByCategory } =
        useMemo(() => {
            const experimentType = settings.experimentTypes.find((t) => t.shortname === experimentTypeShortname);

            const analysisTypesByCategory: Partial<Record<AnalysisCategoryShortname, AnalysisType[]>> =
                experimentTypeShortname ? (settings.categoryInfoByExperimentType[experimentTypeShortname] ?? {}) : {};

            const hasAvailableAnalysisTypes = (experimentType?.analysis_types?.length ?? 0) > 0;

            const sortedCategories = Object.keys(analysisTypesByCategory).sort((c1, c2) =>
                c1.toLowerCase().localeCompare(c2.toLowerCase()),
            ) as AnalysisCategoryShortname[];

            const sortedAnalysisCategoryInfo: AnalysisCategoryInfo[] = sortedCategories
                .map((categoryShortname) => {
                    const category = settings.analysisCategoriesByShortname[categoryShortname];
                    const analysisTypes = analysisTypesByCategory[categoryShortname] ?? [];
                    if (!category) {
                        return undefined;
                    }
                    return { category, analysisTypes };
                })
                .filter(isDefined);
            return { experimentType, hasAvailableAnalysisTypes, sortedAnalysisCategoryInfo, analysisTypesByCategory };
        }, [experimentTypeShortname, settings]);

    const getAnalysisInfo = (analysisShortname: AnalysisShortname) => {
        return settings.getAnalysisInfo(analysisShortname, experiment?.type?.shortname);
    };

    return {
        ...settings,
        getAnalysisInfo,
        experimentType,
        hasAvailableAnalysisTypes,
        analysisTypesByCategory,
        sortedAnalysisCategoryInfo,
    };
};

export default useExperimentSettings;

import { PlotMapping, RidgePlotItem } from '@models/ExperimentData';
import { PlotParams } from 'react-plotly.js';
import PaletteColors from '@components/PaletteColors';
import { ObservedSize } from '@hooks/useDebouncedResizeObserver';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

export type DataPoint = { x: string; y: string } & RidgePlotItem;
export type DragMode = PlotParams['layout']['dragmode'];
export type IndexedDataPoint = DataPoint & { index: number };

export type PlotRange = {
    yMin: number;
    yMax: number;
    xMin: number;
    xMax: number;
};

// Memoize prettifyLabel function results to avoid recomputing for the same input
const prettifyLabel = (() => {
    const cache: { [key: string]: string } = {};
    return (label: string) => {
        if (cache[label]) return cache[label];
        const pretty = label.replace(/_/g, ' ').toUpperCase();
        cache[label] = pretty;
        return pretty;
    };
})();

export const getIndexedPoint = ({
    dataMap,
    index,
    item,
}: {
    dataMap: PlotMapping<RidgePlotItem>;
    index: number;
    item: RidgePlotItem;
}): IndexedDataPoint => {
    const point: Partial<IndexedDataPoint> = {
        ...item,
        index,
    };
    point.x = item[dataMap.x_axis] as string;
    point.y = item[dataMap.y_axis] as string;

    return point as IndexedDataPoint;
};

export const buildPlotlyLayout = ({
    publicationMode,
    size,
    stylingOptions,
    dataMap,
}: {
    publicationMode: boolean;
    size: ObservedSize | undefined;
    stylingOptions?: CustomPlotStylingOptions;
    dataMap: PlotMapping<RidgePlotItem>;
}) => {
    const yAxisLabel = prettifyLabel(dataMap.y_axis);
    const xAxisLabel = prettifyLabel(dataMap.x_axis);

    const fontColor = publicationMode ? 'black' : PaletteColors.gray500.color;

    // Cache layout configuration as it rarely changes.
    const layout: PlotParams['layout'] = {
        autosize: false,
        width: size?.width,
        height: size?.height,
        showlegend: false,
        margin: {
            t: 0,
            r: 0,
            b: 48,
        },
        yaxis: {
            showgrid: false,
            zeroline: false,
            linewidth: 1,
            ticks: 'outside',
            type: 'linear',
            title: yAxisLabel,
            titlefont: {
                color: stylingOptions?.yaxis?.fontColor || fontColor,
                size: stylingOptions?.yaxis?.fontSize || 18,
                family: stylingOptions?.yaxis?.fontFamily || 'Arial',
            },
            color: fontColor,
        },
        xaxis: {
            showgrid: false,
            zeroline: false,
            linewidth: 1,
            ticks: 'outside',
            type: 'linear',
            title: xAxisLabel,
            titlefont: {
                color: stylingOptions?.xaxis?.fontColor || fontColor,
                size: stylingOptions?.xaxis?.fontSize || 18,
                family: stylingOptions?.xaxis?.fontFamily || 'Arial',
            },
            color: fontColor,
        },
        shapes: [],
    };

    return layout;
};

export const prepareData = ({ items, dataMap }: { items: RidgePlotItem[]; dataMap: PlotMapping<RidgePlotItem> }) => {
    const preparedItems: IndexedDataPoint[] = [];

    // Cache dataMap properties outside the loop to reduce object property access cost
    const { x_axis, y_axis } = dataMap;

    items.forEach((d: RidgePlotItem, i: number) => {
        const indexedPoint: IndexedDataPoint = {
            ...d,
            index: i,
            x: d[x_axis],
            y: d[y_axis],
        };

        preparedItems.push(indexedPoint);
    });

    return { preparedItems };
};

import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import {
    NormalizeSeuratObjectFormValues,
    PreprocessFormValues,
    regressNameMap,
} from '../../preprocesses/PreprocessFormTypes';
import CodeBlockInline from '@/src/components/CodeBlockInline';
import ProseList from '@/src/components/ProseList';
import { formatStringToNumberWithSeparator } from '@util/StringUtil';

type Props = { preprocessFormData: PreprocessFormValues; displayName: string };
const NormalizeSeuratMethods = ({ preprocessFormData, displayName }: Props) => {
    const preprocess = preprocessFormData as NormalizeSeuratObjectFormValues;
    const anyRegressTrue =
        preprocess.regress_cc || preprocess.regress_cc_diff || preprocess.regress_mito || preprocess.regress_umi;
    const getRegressTrueList = () => {
        const regressTrueList: string[] = [];
        if (preprocess.regress_cc) {
            regressTrueList.push('regress_cc');
        }
        if (preprocess.regress_cc_diff) {
            regressTrueList.push('regress_cc_diff');
        }
        if (preprocess.regress_mito) {
            regressTrueList.push('regress_mito');
        }
        if (preprocess.regress_umi) {
            regressTrueList.push('regress_umi');
        }
        return regressTrueList.map((val) => regressNameMap[val as keyof typeof regressNameMap]);
    };
    return (
        <div className="space-y-6">
            <section>
                <MethodsSectionTitle>{displayName}</MethodsSectionTitle>

                <div className="space-y-4">
                    <p>
                        RNA counts were log-normalized using Seurat&apos;s{' '}
                        <CodeBlockInline>NormalizeData()</CodeBlockInline> function using a scale factor of 10,000 to
                        ensure comparable expression values across cells. Variable features were identified with{' '}
                        <CodeBlockInline>FindVariableFeatures()</CodeBlockInline>, using the variance-stabilizing
                        transformation (VST) method and {formatStringToNumberWithSeparator(preprocess.n_features)}{' '}
                        variable features. The cell cycle was scored using the{' '}
                        <CodeBlockInline>CellCycleScoring()</CodeBlockInline> function.
                    </p>
                    {preprocess.norm_method === 'lognormalize' && (
                        <p>
                            The top {formatStringToNumberWithSeparator(preprocess.n_features)} variable features were
                            scaled using the <CodeBlockInline>ScaleData()</CodeBlockInline> function.{' '}
                            {anyRegressTrue && (
                                <span>
                                    Variation due to <ProseList items={getRegressTrueList()} /> was regressed out of the
                                    top {formatStringToNumberWithSeparator(preprocess.n_features)} variable genes to
                                    guide dimensionality reduction and cell clustering.
                                </span>
                            )}
                        </p>
                    )}
                    {preprocess.norm_method === 'sctransform' && (
                        <p>
                            <CodeBlockInline>SCTransform()</CodeBlockInline> was applied to each sample in the dataset
                            individually, using VST (flavor v2) and{' '}
                            {formatStringToNumberWithSeparator(preprocess.n_features)} variable features.{' '}
                            {anyRegressTrue && (
                                <span>
                                    Variation due to <ProseList items={getRegressTrueList()} /> was regressed out of the
                                    top {formatStringToNumberWithSeparator(preprocess.n_features)} variable genes to
                                    guide dimensionality reduction and cell clustering.{' '}
                                </span>
                            )}
                            Shared variable features were redefined across samples using{' '}
                            <CodeBlockInline>SelectIntegrationFeatures()</CodeBlockInline>, and variable features were
                            re-scaled to only consider those shared variable features.
                        </p>
                    )}

                    <div>
                        <MethodsSectionTitle>Dimensionality reduction</MethodsSectionTitle>
                        <p>
                            Dimensionality reduction was performed using the top{' '}
                            {formatStringToNumberWithSeparator(preprocess.n_features)} variable features on the
                            normalized Seurat object. Principal component analysis (PCA) (
                            <CodeBlockInline>RunPCA()</CodeBlockInline>) was applied to capture major sources of
                            variability. Uniform manifold approximation and projection (UMAP) (
                            <CodeBlockInline>RunUMAP()</CodeBlockInline>) was utilized for non-linear dimensionality
                            reduction, using {formatStringToNumberWithSeparator(preprocess.n_neighbors)} neighboring
                            points and the first {formatStringToNumberWithSeparator(preprocess.n_dims)} principal
                            components. t-distributed stochastic neighbor embedding (t-SNE) (
                            <CodeBlockInline>RunTSNE()</CodeBlockInline>) was used for further visualization, using the
                            first {formatStringToNumberWithSeparator(preprocess.n_dims)} principal components.
                        </p>
                    </div>

                    <div>
                        <MethodsSectionTitle>Software versions</MethodsSectionTitle>
                        <p>
                            R v4.3.1
                            <br />
                            Seurat v4.3.0
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NormalizeSeuratMethods;

import { getPlotPalette } from '../components/ColorPaletteUtil';
import { ExperimentType, ExperimentTypeSummary } from './ExperimentType';
import { Organism } from './Organism';
import { Project } from './Project';

export type EmbeddedExperiment = {
    name: string;
    organism: Organism;
    organization: string;
    plot_count: number;
    pluto_id: string;
    styles_json: {
        color: string;
        [key: string]: any;
    } | null;
    type: ExperimentType;
    uuid: string;
    project: Project;
    object_type: string;
};

export type SourceExperiment = {
    name: string;
    organism: Organism;
    organization: string;
    plot_count: number;
    pluto_id: string;
    type: ExperimentTypeSummary;
    uuid: string;
};

export type EmbeddedExperimentResponse = {
    source_experiment: SourceExperiment;
    embedded_experiments: EmbeddedExperiment[];
};

const plotPalette = getPlotPalette(0);
export const defaultEmbeddedExperimentColors = plotPalette.colors;

import React, { useEffect, useMemo } from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import SortableLegendField from '@components/experiments/plotDisplay/groups/SortableLegendField';
import { useField, useFormikContext } from 'formik';
import useExperimentPlotGroupsV3 from '@/src/hooks/useExperimentPlotGroupsV3';
import { SeuratDifferentialExpressionAnalysisFormValues } from '@/src/models/analysis/SeuratDifferentialExpressionAnalysis';
import { afterCharacter } from '@/src/util/StringUtil';
import { useSwitchStyles } from '@/src/components/SwitchStyles';
import { Switch } from '@mui/material';
import { GroupField } from '@/src/models/analysis/SeuratMarkerExpressionAnalysis';
import { deepEqual } from '@/src/util/ObjectUtil';

type Props = {
    description?: string;
    experiment: Experiment;
    groupOrderName?: string;
    groupsFieldName?: string;
    ignoreEmpty?: boolean;
    isSortable?: boolean;
    plot: Plot;
    setDisplaySaveDisabled: (value: boolean) => void;
    setUpdateGroupsDisabled: (value: boolean) => void;
};
/**
 * Fields to sort the legend
 */
const AssaySummaryLegendFieldGroupV3 = ({
    description,
    experiment,
    groupOrderName = 'group_display_order',
    groupsFieldName = 'groups',
    ignoreEmpty = false,
    isSortable = false,
    plot,
    setDisplaySaveDisabled,
    setUpdateGroupsDisabled,
}: Props) => {
    const { values } = useFormikContext<SeuratDifferentialExpressionAnalysisFormValues>();
    const [inputProps, , helpers] = useField<GroupField>(groupsFieldName);
    const [{ value: displayOrderValue = [] }, , groupOrderHelpers] = useField<string[] | undefined>(groupOrderName);
    const switchStyles = useSwitchStyles();

    const { loading: groupsLoading, groups } = useExperimentPlotGroupsV3({
        annotation_set_id: values.annotation_set_id ?? '',
        experiment,
        latent_variable_id: values.latent_variable_id ?? '',
        plot,
        variable_ids: values.variable_ids ?? [],
    });

    const filteredGroups = useMemo(() => {
        return (groups ?? [])
            .filter((g) => g.cell_count > 0)
            .sort((g1, g2) => {
                const g1ClusterNumber = g1.display_name.match(/Cluster (\d+)/)?.[1];
                const g2ClusterNumber = g2.display_name.match(/Cluster (\d+)/)?.[1];
                const g1VariableName = afterCharacter(g1.display_name, '-');
                const g2VariableName = afterCharacter(g2.display_name, '-');
                const g1startsWithNumber = g1.display_name.match(/^\d+/)?.[0];
                const g2startsWithNumber = g2.display_name.match(/^\d+/)?.[0];

                const sortByClusterNumber = Number(g1ClusterNumber) - Number(g2ClusterNumber);
                const sortByNumber = Number(g1startsWithNumber) - Number(g2startsWithNumber);
                const sortByDisplayName = g1.display_name.toLowerCase().localeCompare(g2.display_name.toLowerCase());
                const sortByVariableName = g1VariableName.toLowerCase().localeCompare(g2VariableName.toLowerCase());

                // Else, sort by cluster number
                if (sortByClusterNumber) {
                    return sortByClusterNumber;
                } else if (sortByNumber) {
                    // Or, sort by number if it begins with a number
                    return sortByNumber;
                } else if (sortByDisplayName) {
                    // Or, sort by display name
                    return sortByDisplayName;
                }

                // Then sort by variable name
                return sortByVariableName;
            })
            .map((g) => ({
                id: g.uuid,
                display_name: g?.display_name,
                sample_count: g?.sample_count,
                cell_count: g?.cell_count,
            }));
    }, [groups]);

    useEffect(() => {
        // Disable updates while groups are loading.
        if (groupsLoading) {
            setUpdateGroupsDisabled(true);
            setDisplaySaveDisabled(true);
            return;
        }
        setUpdateGroupsDisabled(false);

        // If there are no groups after loading, disable saving.
        if (filteredGroups.length === 0) {
            setDisplaySaveDisabled(true);
            return;
        }
        setDisplaySaveDisabled(false);

        // Compute new groups state based on the filtered groups.
        const groupsValue = inputProps.value ?? {};
        const groupIds = filteredGroups.map((g) => g.id);
        const newGroupsValue: GroupField = {};
        for (const id of groupIds) {
            newGroupsValue[id] = groupsValue[id] ?? true;
        }

        // Only update if the current groups value is different.
        if (!deepEqual(groupsValue, newGroupsValue)) {
            helpers.setValue(newGroupsValue);
        }

        // Only update the group order if it has changed.
        if (!deepEqual(displayOrderValue, groupIds)) {
            groupOrderHelpers.setValue(groupIds);
        }
    }, [
        groupsLoading,
        filteredGroups,
        inputProps.value,
        displayOrderValue,
        helpers,
        groupOrderHelpers,
        setDisplaySaveDisabled,
        setUpdateGroupsDisabled,
    ]);

    return (
        <SortableLegendField
            items={filteredGroups}
            name={groupOrderName}
            title={'Legend'}
            description={description}
            ignoreEmpty={ignoreEmpty}
            loading={groupsLoading}
            isSortable={isSortable}
            centerComponent={(group) => {
                return (
                    <Switch
                        sx={switchStyles}
                        checked={inputProps.value?.[group.id] ?? true}
                        onChange={(e) => {
                            helpers.setValue({
                                ...inputProps.value,
                                [group.id]: e.target.checked,
                            });
                        }}
                    />
                );
            }}
            centerHeaderComponent={
                !groupsLoading && filteredGroups.length > 0 ? (
                    <p className="flex flex-1 justify-end text-xs tracking-tight mr-12 -mb-2">Show cells?</p>
                ) : undefined
            }
        />
    );
};

export default AssaySummaryLegendFieldGroupV3;

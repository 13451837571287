import axios, { AxiosRequestConfig, AxiosProgressEvent } from 'axios';
import FileMeta from './FileMeta';
import FileProcessor, { FileChunk } from './FileProcessor';
import {
    DifferentChunkError,
    FileAlreadyUploadedError,
    UrlNotFoundError,
    UploadFailedError,
    UnknownResponseError,
    MissingOptionsError,
    UploadIncompleteError,
    InvalidChunkSizeError,
    UploadAlreadyFinishedError,
} from './errors';
import * as errors from './errors';
import Logger from '@util/Logger';
import { ApiError } from '@services/ApiError';
import { getErrorDetails } from '@/src/api/ApiTypes';

const logger = Logger.make('ChunkedFileUpload', 'file_upload');

const MIN_CHUNK_SIZE = 262144;

export type ChunkInfo = {
    totalBytes: number;
    uploadedBytes: number;
    chunkIndex: number;
    chunkLength: number;
};
export type ChunkUploadHandler = (info: ChunkInfo) => void;
export type UploadOptions = {
    id: string;
    url: string;
    file: File;
    chunkSize: number;
    onChunkUpload: ChunkUploadHandler;
    onChunkProgress: (progressEvent: AxiosProgressEvent) => void;
    onResumeStarted?: () => void;
    onResumeFinished?: () => void;
    onFailed?: (error?: ApiError | Error | { message?: string }) => void;
    contentType: string;
    storage: Storage;
};

export type ConstructorArgs = Pick<UploadOptions, 'id' | 'url' | 'file'> & Partial<UploadOptions>;

interface UploadValidationError extends Error {
    chunkIndex?: number;
    originalChecksum?: string;
    newChecksum?: string;
}
export default class ChunkedFileUpload {
    errors = errors;

    opts: UploadOptions;
    meta: FileMeta;
    processor: FileProcessor;
    lastResult: Response | null = null;
    finished = false;
    canceled = false;
    errored = false;
    abortController: AbortController;

    constructor(args: ConstructorArgs, allowSmallChunks = false) {
        const opts: UploadOptions = {
            chunkSize: MIN_CHUNK_SIZE,
            storage: window.localStorage,
            contentType: 'text/plain',
            onChunkUpload: () => undefined,
            onChunkProgress: () => undefined,
            ...args,
        };

        if ((opts.chunkSize % MIN_CHUNK_SIZE !== 0 || opts.chunkSize === 0) && !allowSmallChunks) {
            throw new InvalidChunkSizeError(opts.chunkSize);
        }

        if (!opts.id || !opts.url || !opts.file) {
            throw new MissingOptionsError();
        }

        this.opts = opts;
        this.meta = new FileMeta(opts.id, opts.file.size, opts.chunkSize, opts.storage);
        this.processor = new FileProcessor(opts.file, opts.chunkSize);
        this.lastResult = null;
        this.abortController = new AbortController();
    }

    async start() {
        const { meta, processor, opts, finished } = this;
        const resumeUpload = async () => {
            this.opts?.onResumeStarted?.();
            const localResumeIndex = meta.getResumeIndex();
            const remoteResumeIndex = await getRemoteResumeIndex();
            const initialChecksums = meta.checksums;
            const resumeIndex = Math.min(localResumeIndex, remoteResumeIndex);

            try {
                await processor.run(validateChunk, 0, resumeIndex);

                logger.debug('resuming upload at chunk', resumeIndex, this.opts.file.name);
                // notify uploader that the chunk was successful
                const total = opts.file.size;
                const end = resumeIndex * opts.chunkSize + opts.chunkSize - 1;
                opts.onChunkUpload({
                    totalBytes: total,
                    uploadedBytes: end + 1,
                    chunkIndex: resumeIndex,
                    chunkLength: opts.chunkSize,
                });
            } catch (e) {
                logger.error(e);
                const failedChunkError = e as UploadValidationError;
                logger.warn(`Validation failed on chunk ${failedChunkError.chunkIndex}, starting from scratch`, {
                    failedChunkIndex: failedChunkError.chunkIndex,
                    oldChecksum: failedChunkError.originalChecksum,
                    newChecksum: failedChunkError.newChecksum,
                    initialChecksums,
                    meta: meta.getMetaInfo(),
                });

                await processor.run(uploadChunk);
                return;
            } finally {
                this.opts.onResumeFinished?.();
            }

            await processor.run(uploadChunk, resumeIndex);
        };

        const uploadChunk = async (checksum: string, index: number, chunk: FileChunk): Promise<boolean> => {
            const total = opts.file.size;
            const start = index * opts.chunkSize;
            const end = index * opts.chunkSize + chunk.byteLength - 1;

            const headers = {
                'Content-Type': opts.contentType,
                'Content-Range': `bytes ${start}-${end}/${total}`,
            };

            const abortController = new AbortController();
            this.abortController = abortController;
            try {
                const res = await safePut(opts.url, chunk, {
                    headers,
                    onUploadProgress: opts.onChunkProgress,
                    signal: abortController.signal,
                });
                this.lastResult = res as Response;
                checkResponseStatus(res as Response, opts, [200, 201, 308]);

                meta.addChecksum(index, checksum);

                opts.onChunkUpload({
                    totalBytes: total,
                    uploadedBytes: end + 1,
                    chunkIndex: index,
                    chunkLength: chunk.byteLength,
                });
                return true;
            } catch (error) {
                if (getErrorDetails(error).message === 'canceled') {
                    this.canceled = true;
                    logger.info(`canceled uploading chunk. index = ${index} | checksum = ${checksum}`);
                    this.pause();
                    return false;
                }
                this.errored = true;
                this.pause();
                this.opts.onFailed?.(error as Error);
                return false;
            }
        };

        const validateChunk = async (newChecksum: string, index: number): Promise<boolean> => {
            const originalChecksum = meta.getChecksum(index);
            const isChunkValid = originalChecksum === newChecksum;
            if (!isChunkValid) {
                meta.reset();
                throw new DifferentChunkError(index, originalChecksum || '', newChecksum);
            }
            return true;
        };

        const getRemoteResumeIndex = async () => {
            const headers = {
                'Content-Range': `bytes */${opts.file.size}`,
            };
            logger.debug('Retrieving upload status from GCS');
            try {
                const res = (await safePut(opts.url, null, { headers })) as Response;
                checkResponseStatus(res, opts, [308]);
                const header = res.headers.get('range');
                logger.debug(`Received upload status from GCS: ${header}`);
                const range = header?.match(/(\d+?)-(\d+?)$/);
                const bytesReceived = parseInt(range?.[2] || '') + 1;
                return Math.floor(bytesReceived / opts.chunkSize);
            } catch {
                return -1;
            }
        };

        if (finished) {
            throw new UploadAlreadyFinishedError();
        }

        if (meta.isResumable() && meta.getFileSize() === opts.file.size) {
            logger.debug('Upload might be resumable', this.opts.file.name);
            await resumeUpload();
        } else {
            logger.debug('Upload not resumable, starting from scratch', this.opts.file.name);
            await processor.run(uploadChunk);
        }
        logger.debug('Upload complete, resetting meta', this.opts?.file?.name);
        meta.reset();
        this.finished = true;
        return this.lastResult;
    }

    pause() {
        this.processor.pause();
        logger.debug('Upload paused', this.opts.file.name);
    }

    unpause() {
        this.errored = false;
        this.processor.unpause();
        logger.debug('Upload un-paused', this.opts.file.name);
    }

    cancel() {
        logger.debug('Upload cancelled', this.opts.file.name);
        this.canceled = true;
        this.processor.cancel();
        this.abortController.abort();
        this.meta.reset();
    }
}

function checkResponseStatus(res: Response, opts: UploadOptions, allowed: number[] = []) {
    const { status } = res;
    if (allowed.indexOf(status) > -1) {
        return true;
    }

    switch (status) {
        case 308:
            throw new UploadIncompleteError();

        case 201:
        case 200:
            throw new FileAlreadyUploadedError(opts.id, opts.url);

        case 404:
            throw new UrlNotFoundError(opts.url);

        case 500:
        case 502:
        case 503:
        case 504:
            throw new UploadFailedError(status);

        default:
            throw new UnknownResponseError(res);
    }
}

/**
 * Get valid status codes for the API call to google cloud storage. Any 200 is valid, as is 308 (Incomplete Upload).
 * @param {number} status
 * @return {boolean}
 */
const validateStatus = (status: number): boolean => {
    if (status > 199 && status < 300) return true;
    if (status === 308) return true;
    return false;
};

async function safePut<D>(url: string, data?: D, config?: AxiosRequestConfig<D>) {
    try {
        return await axios.put(url, data, { ...config, validateStatus: validateStatus });
    } catch (e) {
        logger.info('safe put threw', e);
        if (e instanceof Error) {
            throw e;
        } else {
            return e;
        }
    }
}

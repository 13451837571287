import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { isNotBlank } from '@util/StringUtil';
import Plot from '@models/Plot';
import { getPlotDisplayTitle, getPlotSubTitle } from '@components/plots/PlotUtil';
import StatisticalSymbolLabel from '@components/StatisticalSymbolLabel';
import { CustomPlotStylingOptions } from '../analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

type Props = PropsWithChildren<{
    className?: string;
    customPlotStylingOptions?: CustomPlotStylingOptions | null;
    linkedExperimentName?: string | null;
    plot?: Plot | null;
    publicationMode?: boolean;
    subTitle?: string | null;
    title?: string | null;
}>;
const PlotTitle = ({
    plot,
    children,
    className,
    publicationMode,
    customPlotStylingOptions = null,
    linkedExperimentName,
    ...props
}: Props) => {
    let title = props.title ?? getPlotDisplayTitle(plot);
    const subTitle = props.subTitle ?? getPlotSubTitle(plot);

    if (!!title && title.length > 80) title = title.slice(0, 80) + '...';

    return (
        <div data-cy="plot-title">
            {linkedExperimentName ? (
                <p
                    className={cn('break-words text-center text-lg tracking-tight', {
                        'text-[16px] text-black': publicationMode,
                        'text-default text-xs': !publicationMode,
                    })}
                    style={
                        !!customPlotStylingOptions
                            ? { ...customPlotStylingOptions.title, color: customPlotStylingOptions.title.fontColor }
                            : {}
                    }
                >
                    {linkedExperimentName}
                </p>
            ) : null}
            <p
                className={cn('break-words text-center text-lg font-semibold tracking-tight', className, {
                    'text-[18px] text-black': publicationMode,
                })}
                style={
                    !!customPlotStylingOptions
                        ? { ...customPlotStylingOptions.title, color: customPlotStylingOptions.title.fontColor }
                        : {}
                }
            >
                {title ? <StatisticalSymbolLabel name={title} /> : children}
            </p>
            {isNotBlank(subTitle) && (
                <p
                    className={cn('break-words text-center text-lg tracking-tight', {
                        'text-[16px] text-black': publicationMode,
                        'text-default': !publicationMode,
                    })}
                    style={
                        !!customPlotStylingOptions
                            ? { ...customPlotStylingOptions.title, color: customPlotStylingOptions.title.fontColor }
                            : {}
                    }
                >
                    {subTitle}
                </p>
            )}
        </div>
    );
};

export default PlotTitle;

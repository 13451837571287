import { ParameterOption, StringParameterOption } from '@models/AnalysisParameters';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { LegendSVG } from '@components/plots/PlotLegendView';
import cn from 'classnames';
import { getPlotPalette } from '@components/ColorPaletteUtil';
import { BasePlotDisplayOptionFormValues, PlotLegendDisplayFormValues } from '@models/PlotDisplayOption';
import { ThemeStyle } from '@models/PlotConfigs';
import { isDefined } from '@util/TypeGuards';
import GroupColorPickerPopover from '@components/plots/colorPicker/GroupColorPickerPopover';
import EditLegendPopover from '@components/plots/legendEditor/EditLegendPopover';
import { blankToUndefined, formatStringToNumberWithSeparator, isNotBlank, isWhite } from '@util/StringUtil';
import CustomColorsFormDialogButton from '@components/profile/CustomColorsFormDialogButton';
import useAuth from '@hooks/useAuth';
import LoadingMessage from '@components/LoadingMessage';
import { OtherIcon } from '@components/icons/custom/OtherIcon';
import GridLayout, { Layout } from 'react-grid-layout';
import { BarsTwo } from '@components/icons/custom/BarsTwo';
import { EditIcon } from '@components/icons/custom/EditIcon';
import { CustomLegendColorItem } from './ControlledCustomLegendColorField';
import { equalsCheck } from '@/src/util/ObjectUtil';

type Props = {
    centerComponent?: (
        selectedItem: StringParameterOption | ParameterOption | (CustomLegendColorItem & StringParameterOption),
    ) => ReactNode;
    customOptionField?: string;
    description?: ReactNode;
    editComponent?: (
        selectedItem: StringParameterOption | ParameterOption | (CustomLegendColorItem & StringParameterOption),
    ) => ReactNode;
    ignoreDisplayOrder?: boolean;
    ignoreEmpty?: boolean;
    isEditable?: boolean;
    isSortable?: boolean;
    items: StringParameterOption[] | ParameterOption[] | (CustomLegendColorItem & StringParameterOption[]);
    legendPopoverTitle?: string;
    loading?: boolean;
    name: string;
    title?: ReactNode;
    centerHeaderComponent?: ReactNode;
};
const SortableLegendField = ({
    centerComponent,
    centerHeaderComponent,
    customOptionField,
    description,
    editComponent,
    ignoreDisplayOrder = false,
    ignoreEmpty = false,
    isEditable = true,
    isSortable = false,
    items,
    legendPopoverTitle,
    loading = false,
    name,
    title = 'Legend',
}: Props) => {
    const { user } = useAuth();
    const userColors = user?.custom_plot_colors ?? [];
    const organizationColors = user?.organization?.custom_plot_colors ?? [];

    const { values, setFieldValue } =
        useFormikContext<Partial<PlotLegendDisplayFormValues & BasePlotDisplayOptionFormValues>>();
    const [{ value: displayOrderValue = [] }] = useField<number[] | undefined>(name);

    const customColors = customOptionField ? (values.custom_options_json ?? {}) : (values.custom_color_json ?? {});
    const customNames = values.custom_legend_json ?? {};

    const setCustomColor = ({ groupId, color }: { groupId: number | string; color: string | undefined | null }) => {
        const field = customOptionField ? 'custom_options_json' : 'custom_color_json';
        const colorField = customOptionField ? `${field}.${groupId}.${customOptionField}` : `${field}.${groupId}`;
        setFieldValue(colorField, blankToUndefined(color));
    };

    const setCustomName = ({
        groupId,
        customName,
    }: {
        groupId: number | string;
        customName: string | undefined | null;
    }) => {
        setFieldValue(`custom_legend_json.${groupId}`, blankToUndefined(customName));
    };

    const legendItems = useMemo(() => {
        const filteredDisplayOption = displayOrderValue.filter(isDefined);

        const useFilteredDisplayOption = !ignoreDisplayOrder && (filteredDisplayOption.length > 0 || !ignoreEmpty);
        const orderedItemIds =
            (useFilteredDisplayOption ? filteredDisplayOption : null) ?? items?.map((g) => g.id) ?? [];

        return orderedItemIds.flatMap((groupId) => items?.find((g) => g.id === groupId) ?? []);
    }, [displayOrderValue, items, ignoreEmpty]);

    const plotPalette = getPlotPalette(values.theme_color);
    const colors = plotPalette.colors;

    // Update function without displayOrderValue dependency
    const updateDisplayOrder = useCallback(
        (newDisplayOrder: number[]) => {
            if (!equalsCheck(newDisplayOrder, displayOrderValue)) {
                setFieldValue(name, newDisplayOrder);
            }
        },
        [name, setFieldValue, displayOrderValue],
    );

    // When the layout changes, update our controlled layout and then update Formik state.
    const handleLayoutChange = useCallback(
        (newLayout: Layout[]) => {
            // Derive new display order from layout.
            const newDisplayOrder: number[] = newLayout
                .slice()
                .sort((a: Layout, b: Layout) => a.y - b.y)
                .map((layoutItem: Layout) => (isNaN(+layoutItem.i) ? +layoutItem.i : parseInt(layoutItem.i)));
            updateDisplayOrder(newDisplayOrder);
        },
        [updateDisplayOrder],
    );

    const renderLegendField = (g: StringParameterOption | ParameterOption, i: number, jsonName: string | null) => {
        const customColor = customOptionField ? customColors[`${g.id}`]?.[customOptionField] : customColors[`${g.id}`];
        const defaultThemeColor = colors[i % colors.length]?.color;
        const strokeColor = customColor ?? defaultThemeColor;

        return (
            <div key={g.id} className="w-full cursor-pointer">
                <Flipped flipId={g.id}>
                    <li className="-mx-2 flex items-center justify-between space-x-1 rounded-lg px-2 py-1 hover:bg-gray-100 hover:text-dark">
                        <div className="flex w-full items-center space-x-1">
                            <div className="noDrag">
                                <GroupColorPickerPopover
                                    title={g.display_name}
                                    color={strokeColor}
                                    onChange={(newColor) => setCustomColor({ groupId: g.id, color: newColor })}
                                    showRemove={isDefined(customColor)}
                                    onRemove={() => setCustomColor({ groupId: g.id, color: null })}
                                    customColors={userColors}
                                    customColorsLabel="My favorite colors"
                                    secondaryCustomColors={organizationColors}
                                    secondaryCustomColorsLabel="Organization colors"
                                    hideThemes
                                    themeColor={values.theme_color}
                                >
                                    <div className="group relative mt-0.5 flex cursor-pointer items-center justify-center">
                                        <LegendSVG
                                            radius={4}
                                            width={26}
                                            key={`${g.id}_color`}
                                            style={{
                                                fill: strokeColor,
                                                fillOpacity: values.theme_style === ThemeStyle.medium ? 0.75 : 1,
                                                stroke: isWhite(strokeColor) ? 'rgb(209, 213, 219)' : strokeColor,
                                                strokeOpacity: 1,
                                            }}
                                            className={cn('shrink-0 transition-all duration-500')}
                                        />
                                        {isNotBlank(customColor) && (
                                            <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center group-hover:hidden">
                                                {/*<ColorSwatchIcon className="h-4 w-4 text-white" />*/}
                                                <OtherIcon height={'14'} width={'14'} className="text-white" />
                                            </div>
                                        )}
                                    </div>
                                </GroupColorPickerPopover>
                            </div>

                            <div className="flex w-full items-center justify-between gap-4">
                                <span
                                    className="flex-1 line-clamp-2"
                                    title={`${jsonName || g.display_name}${g.cell_count ? ` (${formatStringToNumberWithSeparator(g.cell_count)} cells)` : null}`}
                                >
                                    <span>{jsonName || g.display_name}</span>
                                    {g.cell_count
                                        ? ` (${formatStringToNumberWithSeparator(g.cell_count)} cells)`
                                        : null}
                                </span>
                                {centerComponent?.(g)}
                                <div className="flex items-center">
                                    {isEditable ? (
                                        <div className="noDrag">
                                            {editComponent?.(g) ?? (
                                                <EditLegendPopover
                                                    id={g.id}
                                                    currentName={jsonName || g.display_name}
                                                    defaultName={g.display_name}
                                                    onSave={setCustomName}
                                                    title={legendPopoverTitle}
                                                >
                                                    <EditIcon height={14} width={14} className="mr-2 text-slate-500" />
                                                </EditLegendPopover>
                                            )}
                                        </div>
                                    ) : null}

                                    {isSortable ? <BarsTwo height={22} width={22} className="text-slate-500" /> : null}
                                </div>
                            </div>
                        </div>
                    </li>
                </Flipped>
            </div>
        );
    };

    return (
        <div>
            <div className="flex flex-col">
                <div className="mb-2">
                    <h4 className=" flex flex-row justify-between text-lg font-semibold tracking-tight text-dark">
                        <span>{title}</span>
                    </h4>
                    {description && <p className="text-gray-400">{description}</p>}
                    <CustomColorsFormDialogButton color="primary" variant="text" size="small" marginLeft="-14px">
                        Manage favorite colors
                    </CustomColorsFormDialogButton>
                </div>

                {loading ? (
                    <div>
                        <LoadingMessage inline immediate message="Loading..." />
                    </div>
                ) : (
                    <>
                        {centerHeaderComponent}
                        <Flipper flipKey={legendItems.map((g) => g.id).join('')} spring={'stiff'}>
                            {isSortable ? (
                                <GridLayout
                                    className="layout"
                                    cols={1}
                                    rowHeight={30}
                                    width={1}
                                    isResizable={false}
                                    onDragStop={handleLayoutChange}
                                    draggableCancel=".noDrag"
                                >
                                    {legendItems.map((g, i) => {
                                        const jsonName = isDefined(customNames[`${g.id}`])
                                            ? customNames[`${g.id}`]
                                            : null;
                                        return renderLegendField(g, i, jsonName);
                                    })}
                                </GridLayout>
                            ) : (
                                <>
                                    {legendItems.map((g, i) => {
                                        const jsonName = isDefined(customNames[`${g.id}`])
                                            ? customNames[`${g.id}`]
                                            : null;
                                        return renderLegendField(g, i, jsonName);
                                    })}
                                </>
                            )}
                        </Flipper>
                    </>
                )}
            </div>
        </div>
    );
};

export default SortableLegendField;

import { useState, useEffect } from 'react';
import useApi from '@hooks/useApi';
import { Comment, CommentObjectType } from '@models/Comment';
import useAuth from '@hooks/useAuth';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import { useWebSocketEvent } from './useWebSocketEvent';

export type CommentFilterValue = '' | 'experiment' | 'plot' | 'literature';
export type CommentValue = 'comment,note' | 'comment' | 'note';
export type NewCommentValue = 'comment' | 'note';
type Props = {
    object_type: CommentObjectType | undefined;
    object_uuid?: string;
    showOnlyPrivate?: boolean;
};
const useComments = ({ object_type, object_uuid, showOnlyPrivate = false }: Props) => {
    const { authReady, user } = useAuth();
    const [comments, setComments] = useState<Comment[]>([]);
    const [commentCount, setCommentCount] = useState<number>(0);
    const [commentError, setCommentError] = useState<string>('');
    const [commentType, setCommentType] = useState<CommentValue>('comment,note');
    const [commentsLoading, setCommentsLoading] = useState<boolean>(false);
    const api = useApi();
    const logger = Logger.make('useComments');

    // Handle WebSocket events for real-time updates
    const webSocketLogger = Logger.make('WebSocketContext');
    useWebSocketEvent('send_notification', (data) => {
        webSocketLogger.debug('Received comment event:', data);
        // Refetch the comments list when a new comment is received
        if (data.object_type === 'comment') {
            fetchComments(); // Triggers a re-fetch from the API
        }
    });

    const fetchComments = async () => {
        if (!object_uuid) return;

        setCommentsLoading(true);
        try {
            const queryParams = {
                object_type,
                object_uuid,
                comment_type: commentType,
                is_private: showOnlyPrivate ? true : undefined,
            };
            const newComments = await api.get<Comment[]>(Endpoints.lab.comments(), queryParams);

            const sortedComments = newComments.sort(
                (a: Comment, b: Comment) => Date.parse(b.created_at) - Date.parse(a.created_at),
            );
            setComments(sortedComments);
            setCommentCount(newComments.length);
            setCommentError('');
        } catch (error) {
            logger.error(error);
            setCommentError('Failed to fetch comments');
        } finally {
            setCommentsLoading(false);
        }
    };

    useEffect(() => {
        if (!authReady || !object_uuid) return;

        fetchComments();
    }, [object_uuid, commentType]);

    const postNewComment = async ({
        content,
        comment_type,
        is_private,
    }: {
        content: string;
        comment_type: NewCommentValue;
        is_private: boolean;
    }) => {
        if (!user || !user.uuid) return;

        setCommentsLoading(true);
        try {
            const newComment = await api.post<Comment>(Endpoints.lab.comments(), {
                content,
                object_type,
                object_uuid,
                comment_type,
                is_private,
            });
            setComments([newComment, ...comments]);
            setCommentCount(commentCount + 1);
            setCommentError('');
        } catch (error) {
            logger.error(error);
            setCommentError('Failed to create new comment');
        } finally {
            setCommentsLoading(false);
        }
    };

    const replyToComment = async ({ parent_comment, content }: { parent_comment: Comment; content: string }) => {
        setCommentsLoading(true);
        try {
            await api.post<Comment>(Endpoints.lab.comments(), {
                content,
                object_type: parent_comment.object_type,
                object_uuid: parent_comment.object_uuid,
                parent_comment_uuid: parent_comment.uuid,
                comment_type: 'comment',
            });
            fetchComments();
        } catch (error) {
            logger.error(error);
            setCommentError('Failed to reply to comment');
        } finally {
            setCommentsLoading(false);
        }
    };

    const updateComment = async ({
        content,
        comment_uuid,
        comment_type,
    }: {
        content: string;
        comment_uuid: string;
        comment_type: NewCommentValue;
    }) => {
        setCommentsLoading(true);
        try {
            await api.put<Comment>(Endpoints.lab.comments(), {
                content,
                comment_uuid,
                object_type,
                object_uuid,
                comment_type,
            });
            fetchComments();
            setCommentError('');
        } catch (error) {
            logger.error(error);
            setCommentError('Failed to update comment');
        } finally {
            setCommentsLoading(false);
        }
    };

    const deleteComment = async ({ comment_uuid }: { comment_uuid: string }) => {
        setCommentsLoading(true);
        try {
            await api.doDelete<Comment>(Endpoints.lab.comments({ comment_uuid, object_type, object_uuid }));
            fetchComments();
            setCommentError('');
        } catch (error) {
            logger.error(error);
            setCommentError('Failed to delete comment');
        } finally {
            setCommentsLoading(false);
        }
    };

    const updateCommentType = (newType: CommentValue) => setCommentType(newType);
    return {
        comments,
        commentCount,
        fetchComments,
        postNewComment,
        commentsLoading,
        updateComment,
        replyToComment,
        commentType,
        updateCommentType,
        deleteComment,
        commentError,
    };
};

export default useComments;

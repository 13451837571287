import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { isDefined } from '@util/TypeGuards';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import { ExternalPlotData } from '@models/ExperimentData';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import ExternalDisplayOption from '@models/plotDisplayOption/ExternalPlotDisplayOption';

type Props = { plot: Plot<ExperimentAnalysis, ExternalDisplayOption>; experiment: Experiment };
const ExternalPlotView = ({ plot, experiment }: Props) => {
    const { data: plotData } = useSWR<ExternalPlotData>(() =>
        plot?.analysis && isDefined(plot.uuid)
            ? Endpoints.lab.experiment.plot.data({
                  plotId: plot.uuid,
                  experimentId: experiment.uuid,
              })
            : null,
    );

    const imageFileTypes = ['.png', '.svg', '.jpg', '.jpeg', 'png', 'svg', 'jpg', 'jpeg', 'PNG', 'SVG', 'JPG', 'JPEG'];
    const plotUrl = plotData?.url;
    const fileIsImage = imageFileTypes.some((ext) => plotUrl?.toLowerCase().includes(ext));

    if (!plotUrl)
        return (
            <div className="flex h-full flex-col justify-center">
                <p className="text-lg font-semibold text-center">
                    The display file will be available here once it is uploaded
                </p>
            </div>
        );

    return (
        <div className="mt-4 flex h-full w-full flex-grow flex-col justify-center">
            <div className="relative h-full w-full">
                {fileIsImage ? (
                    <img className="w-full h-full object-contain object-center" src={plotUrl} />
                ) : (
                    <iframe width="100%" height="100%" src={plotUrl} className="p-6" />
                )}
            </div>
        </div>
    );
};

export default ExternalPlotView;

import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const SummaryAnalysisIcon = ({
    bgColor,
    className = defaultTextLightClass,
    height = 32,
    strokeWidth = 2,
    width = 32,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            className={className || defaultTextLightClass}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {bgColor && <rect x="0" y="0" width="24" height="24" fill={bgColor} />}
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth={strokeWidth} />
            <rect x="14" y="5" width="5" height="14" rx="1" stroke="currentColor" strokeWidth={strokeWidth} />
            <rect x="5" y="11" width="5" height="8" rx="1" stroke="currentColor" strokeWidth={strokeWidth} />
        </svg>
    );
};

import React, { useState, useContext } from 'react';
import LoadingMessage from '@components/LoadingMessage';
import cn from 'classnames';
import Experiment from '@models/Experiment';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import { ErrorOutline } from '@mui/icons-material';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { ApiError } from '@services/ApiError';
import MoreMenuIconButton from '@components/MoreMenuIconButton';
import DownloadDataMoreMenuItem from '@components/DownloadDataMoreMenuItem';
import { generateReportFileName } from '@util/ExperimentUtil';
import { AuthContext } from '@contexts/AuthContext';
import { isSponsor } from '@models/User';

type ReportResponse = { url: string };
type Props = { experiment: Experiment };
const InsightsReportView = ({ experiment }: Props) => {
    const { user } = useContext(AuthContext);
    const isSponsorUser = isSponsor(user);
    const [iframeLoading, setIframeLoading] = useState(false);

    const { data, error } = useSWR<ReportResponse>(
        Endpoints.lab.experiment.reportUrl({ experimentId: experiment.uuid }),
        { revalidateOnMount: true, revalidateOnFocus: true },
    );

    const loading = !data && !error;
    const iframeUrl = data?.url;

    if (!experiment.report_url) {
        return <div className="text-center text-lg text-error">No report url was provided</div>;
    }

    return (
        <div className="flex h-[75vh] flex-col">
            {!isSponsorUser && (
                <div className="mb-4 flex items-start justify-end space-x-4 md:-mt-20 md:pt-1">
                    <div className="rounded-full bg-white">
                        <MoreMenuIconButton
                            outlined
                            downloadIcon
                            items={[
                                <DownloadDataMoreMenuItem
                                    key={'download_html'}
                                    endpoint={() =>
                                        Endpoints.lab.experiment.reportDownloadUrl(
                                            { experimentId: experiment.uuid },
                                            {
                                                filename: `${generateReportFileName({ experiment })}.html`,
                                            },
                                        )
                                    }
                                    baseFilename={generateReportFileName({ experiment })}
                                    extension=".html"
                                    newTab={false}
                                />,
                            ]}
                        />
                    </div>
                </div>
            )}
            {(iframeLoading || loading) && <LoadingMessage message="Loading Pluto Report..." size={20} />}
            {error && (
                <div className="flex flex-col justify-center px-8 py-32 text-center">
                    <div className="mx-auto mb-4 rounded-full bg-error p-4 text-error">
                        <ErrorOutline height={24} width={24} />
                    </div>
                    <h2 className="text-xl font-semibold tracking-tight">Unable to load Pluto Report</h2>
                    <p className="text-base">{ApiError.getMessage(error)}</p>
                </div>
            )}
            {iframeUrl && (
                <>
                    <iframe
                        src={iframeUrl}
                        width="100%"
                        height="100%"
                        onLoad={() => setIframeLoading(false)}
                        className={cn('rounded-xl', { hidden: iframeLoading })}
                    />
                    <div className="flex justify-end">
                        <a
                            className="inline-flex items-center pt-5 text-right"
                            href={iframeUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ExternalLinkIcon width={18} className="mr-1" />
                            Open in new tab
                        </a>
                    </div>
                </>
            )}
        </div>
    );
};
export default InsightsReportView;

import Plot from '@models/Plot';
import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import ProseList from '@/src/components/ProseList';
import Bold from '@/src/components/elements/Bold';
import { OverlapAnalysisInput, OverlapAnalysisResponse, OverlapLists } from '@/src/models/analysis/OverlapAnalysis';
import {
    AnalysisParameters,
    DifferentialsAnalysisParameter,
    MultiomicsOverlapGenesAnalysisParameters,
} from '@/src/models/AnalysisParameters';

const createAnalysisIdToPlutoIdMap = (differentials: DifferentialsAnalysisParameter): Record<string, string> => {
    const map: Record<string, string> = {};
    for (const differential of differentials) {
        if (differential.results) {
            for (const result of differential.results) {
                map[result.analysis_id] = differential.pluto_id;
            }
        }
    }
    return map;
};

const findPlutoIdByAnalysisId = (analysisToPlutoMap: Record<string, string>, analysisId: string): string | null => {
    return analysisToPlutoMap[analysisId] || null;
};

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const MultiomicsOverlapGenesAnalysisMethodsSection = ({ plot, analysisParameters }: Props) => {
    const analysis = plot.analysis as OverlapAnalysisResponse;
    const parameters = analysisParameters as MultiomicsOverlapGenesAnalysisParameters;
    const analysisIdToPlutoIdMap = parameters?.differentials
        ? createAnalysisIdToPlutoIdMap(parameters.differentials)
        : {};

    const methodsAvailable =
        analysis[OverlapLists[0]]?.target_genes_format && analysis[OverlapLists[1]]?.target_genes_format;

    if (!methodsAvailable) {
        return <div>No analysis was found.</div>;
    }

    const getListNames = (): string[] => {
        return OverlapLists.reduce<string[]>((names, list) => {
            const listItem = analysis[list];
            if (listItem && listItem.target_genes_format) {
                const index = list.split('_')[1];
                const listName = `${listItem?.name ? listItem?.name : `List ${index}`}`;
                names.push(listName);
            }
            return names;
        }, []);
    };

    const getFoldChangeString = (listItem: OverlapAnalysisInput) => {
        if (listItem.fc_direction === 'up') {
            return `greater than ${listItem.fc_upper_threshold}`;
        } else if (listItem.fc_direction === 'down') {
            return `less than ${listItem.fc_lower_threshold}`;
        }
        return `greater than ${listItem.fc_upper_threshold} or less than ${listItem.fc_lower_threshold}`;
    };

    return (
        <section>
            <section>
                <MethodsSectionTitle>Overlap genes (cross-experiment) analysis</MethodsSectionTitle>
                <p className="mb-2">
                    Overlap genes (cross-experiment) analysis was performed using the following gene sets:{' '}
                    <ProseList items={getListNames()} />.
                </p>
                <div className="mb-6">
                    {OverlapLists.map((listId) => {
                        const listItem = analysis[listId];
                        if (listItem && listItem.target_genes_format === 'differential') {
                            return (
                                <span key={listId}>
                                    For gene set <Bold>{listItem.name}</Bold> (from{' '}
                                    <Bold>
                                        {findPlutoIdByAnalysisId(
                                            analysisIdToPlutoIdMap,
                                            listItem.differential_analysis_id || '',
                                        )}
                                    </Bold>
                                    ), differential genes were defined as genes with an adjusted <i>p</i>-value of less
                                    than or equal to <Bold>{listItem.adj_pval_threshold}</Bold>, and a fold change{' '}
                                    <Bold>{getFoldChangeString(listItem)}</Bold>.{' '}
                                </span>
                            );
                        }
                        return null;
                    })}
                </div>
            </section>

            <section>
                <MethodsSectionTitle>Venn diagram</MethodsSectionTitle>
                <p className="mb-6">
                    Venn diagram illustrating the relationships and overlaps between input gene sets. Each circle in the
                    Venn diagram represents a different set of genes. The overlapping or intersecting areas between
                    circles represents genes that are shared between gene sets. The non-overlapping areas of each circle
                    represent genes that are unique to a particular gene set.
                </p>
            </section>
        </section>
    );
};

export default MultiomicsOverlapGenesAnalysisMethodsSection;

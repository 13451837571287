import Experiment from '@models/Experiment';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FormikHelpers, useFormikContext } from 'formik';
import { OverlapAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import { OverlapAnalysisParameters } from '@models/AnalysisParameters';
import { AddRounded } from '@mui/icons-material';
import Button from '@components/Button';
import useAnalysisInputs from '@/src/hooks/useAnalysisInputs';
import Plot from '@/src/models/Plot';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CircularProgress, Tooltip } from '@mui/material';
import { AnalysisInputFormValues } from '../inputs/AnalysisInputFormTypes';
import { ApiError } from '@/src/services/ApiError';
import Logger from '@util/Logger';
import ConditionalWrapper from '@/src/components/ConditionalWrapper';
import cn from 'classnames';
import { OverlapAnalysis, OverlapLists } from '@/src/models/analysis/OverlapAnalysis';
import GridLayout, { Layout } from 'react-grid-layout';
import OverlapAnalysisInput from '../inputs/OverlapAnalysisInput';
import AnalysisInputForm from '../inputs/AnalysisInputForm';
import { SelectorIcon } from '@heroicons/react/outline';
import { useExperimentDetailViewContext } from '@/src/contexts/ExperimentDetailViewContext';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { BarsTwo } from '@components/icons/custom/BarsTwo';
import { ExperimentAnalysisInput } from '@/src/models/analysis/ExperimentAnalysisInput';
import OrganismPickerField from '@/src/components/filters/fields/OrganismPickerField';
import { ExperimentTypeShortname } from '@/src/models/ExperimentType';
import { OrganismID } from '@/src/models/Organism';
import ConfirmModal from '@/src/components/ConfirmModal';
import useOrganizationSettings from '@/src/hooks/useOrganizationSettings';
import useLinkedExperiments from '@/src/hooks/useLinkedExperiments';

const logger = Logger.make('OverlapAnalysisFormFields');

const OrganismAwareAnalysisType = ['multiomics_overlap_genes'];

type Props = {
    analysisParameters: OverlapAnalysisParameters;
    experiment: Experiment;
    plot: Plot;
};
const OverlapAnalysisFormFields = ({ analysisParameters, experiment, plot }: Props) => {
    const { values, setFieldValue, submitForm } = useFormikContext<OverlapAnalysisFormValues>();
    const hasInitialized = useRef(false);
    const [tmpListOrder, setTmpListOrder] = useState<string[]>([]);
    const [isDraggable, setIsDraggable] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [organism, setOrganism] = useState<OrganismID | null>(
        ((plot?.analysis as OverlapAnalysis)?.organism?.shortname as OrganismID) || null,
    );
    const [showConfirmOrganismModal, setShowConfirmOrganismModal] = useState<OrganismID | null>(null);
    const {
        inputExpanded,
        inputSaveButtonRef,
        pingSave,
        scrollToInputSaveButton,
        setAnalysisFormSubmitDisabled,
        setInputExpanded,
    } = useExperimentDetailViewContext();
    const { organisms } = useOrganizationSettings();
    const { linkedExperiments } = useLinkedExperiments({
        experimentId: experiment?.uuid,
    });

    const isMultiOmics = experiment.type.shortname === ExperimentTypeShortname.multiomics;
    const isOrganismAware = OrganismAwareAnalysisType.includes(plot?.analysis?.analysis_type || '');

    const linkedExperimentOrganisms =
        Array.from(new Set(linkedExperiments?.map((e) => e.organism?.shortname).filter(Boolean))) || [];
    const availableOrganisms =
        organisms
            .map((p) => p?.shortname)
            .filter((o): o is OrganismID => o !== OrganismID.any && linkedExperimentOrganisms.includes(o)) || [];

    useEffect(() => {
        if ((plot?.analysis as OverlapAnalysis)?.organism?.shortname && !organism) {
            setOrganism(((plot?.analysis as OverlapAnalysis)?.organism?.shortname as OrganismID) || null);
        }
    }, []);

    const updateAnalysisForm = useCallback((lists: ExperimentAnalysisInput[]) => {
        const maxLists = OverlapLists.length;

        for (let i = 0; i < maxLists; i++) {
            const uuid = i < lists.length ? lists[i].uuid : null;
            setFieldValue(`list_${i + 1}_id`, uuid);
        }
    }, []);
    const onCreateList = useCallback((newList: ExperimentAnalysisInput) => {
        setInputExpanded(newList.uuid);
    }, []);

    const {
        createList,
        creatingList,
        fetchingList,
        inputLists,
        removeList,
        resetListData,
        setInputLists,
        updateList,
        updatingList,
    } = useAnalysisInputs({
        experimentId: experiment.uuid,
        analysisId: plot.analysis?.uuid ?? '',
        updateAnalysisForm,
        plot,
        onCreateList,
    });

    const maxListsReached = inputLists?.length === 3;
    const addListButtonDisabled = maxListsReached || creatingList || fetchingList || isDraggable;

    const onAddList = async () => {
        if (inputExpanded) {
            scrollToInputSaveButton();
        } else {
            await createList();
        }
    };

    /**
     * Save the form values to the experiment annotation.
     * @param {OverlapAnalysisFormValues} values
     * @param {FormikHelpers<FormValues>} helpers
     * @return {Promise<void>}
     */
    const handleSubmit = async (
        values: AnalysisInputFormValues,
        helpers: FormikHelpers<AnalysisInputFormValues>,
        analysisInputId: string,
    ): Promise<void> => {
        logger.debug('Submitting Overlap analysis input values', values, analysisInputId);
        helpers.setStatus(null);
        try {
            const result = await updateList(values, analysisInputId);
            if (result?.error) {
                throw result.error; // Propagate the error to the parent catch block
            }
            const indexOf = analysisInputId ? inputLists.findIndex((list) => list.uuid === analysisInputId) : -1;

            // Expand next list, if it exists and is not filled out
            if (indexOf < inputLists.length - 1 && !inputLists[indexOf + 1]?.target_genes_format) {
                setInputExpanded(inputLists[indexOf + 1].uuid);
            } else {
                setInputExpanded(null);
            }
        } catch (error) {
            logger.error('ApiErrorMessage', ApiError.getMessage(error as Error));
            helpers.setStatus({ error: ApiError.getMessage(error as Error) });
        } finally {
            helpers.setSubmitting(false);
        }
    };

    // Auto-expand first input list on initial load if it is not filled out
    useEffect(() => {
        if (!hasInitialized.current && inputLists?.[0]?.uuid && !inputLists?.[0]?.target_genes_format) {
            setInputExpanded(inputLists[0].uuid);
            hasInitialized.current = true;
        }
    }, [inputLists]);

    useEffect(() => {
        if (isDraggable) {
            setAnalysisFormSubmitDisabled(true);
        } else {
            setAnalysisFormSubmitDisabled(false);
        }
    }, [isDraggable]);

    const renderWrapper = useCallback(
        (children: ReactNode) => (
            <Tooltip title="Maximum of 3 lists allowed." placement="right" arrow>
                <div className="mt-3 inline-flex items-center justify-center">{children}</div>
            </Tooltip>
        ),
        [experiment],
    );
    const handleLayoutChange = (newLayout: Layout[]) => {
        const newListOrder: string[] = newLayout
            .sort((a: Layout, b: Layout) => {
                return a.y - b.y;
            })
            .map((layoutItem: Layout) => (isNaN(+layoutItem.i) ? layoutItem.i : `${parseInt(layoutItem.i)}`));
        setTmpListOrder(newListOrder);
    };
    const handleLayoutSave = () => {
        for (let i = 0; i < tmpListOrder.length; i++) {
            setFieldValue(`list_${i + 1}_id`, tmpListOrder[i]);
        }
        setInputLists((prevLists) => {
            const orderMap = tmpListOrder.reduce((map: { [key: string]: number }, uuid, index) => {
                map[uuid] = index;
                return map;
            }, {});

            // Sort prevLists based on the order in tmpListOrder
            const sortedLists = [...prevLists].sort((a, b) => {
                return orderMap[a.uuid ?? 0] - orderMap[b.uuid ?? 0];
            });

            return sortedLists;
        });
    };

    const onRemove = async (analysisInputId: string) => {
        setInputExpanded(null);
        await removeList(analysisInputId);
    };

    const renderLists = () =>
        inputLists?.map((list, index) => (
            <div key={list?.uuid ?? index} className="w-full">
                <Flipped flipId={list?.uuid ?? index}>
                    <div
                        className={cn('flex flex-1 flex-row items-center gap-2', {
                            'cursor-grab': isDraggable,
                        })}
                    >
                        {isDraggable ? (
                            <div className="flex shrink-0">
                                <BarsTwo height={22} width={22} className="text-slate-500" />
                            </div>
                        ) : null}

                        <CSSTransition timeout={500} delay={1000} classNames="item" key={list?.uuid ?? index}>
                            <div className="w-full flex flex-1">
                                <AnalysisInputForm
                                    index={index}
                                    plot={plot}
                                    input={list}
                                    handleSubmit={(values, helpers) => handleSubmit(values, helpers, list?.uuid ?? '')}
                                >
                                    {(formikProps) => (
                                        <OverlapAnalysisInput
                                            analysisParameters={analysisParameters}
                                            deleteDisabled={inputLists.length <= 2}
                                            disableExpand={isDraggable}
                                            expanded={inputExpanded}
                                            experiment={experiment}
                                            formik={formikProps}
                                            index={index}
                                            list={list}
                                            pingSave={inputExpanded === pingSave}
                                            removeList={onRemove}
                                            saveButtonRef={inputSaveButtonRef}
                                            scrollToInputSaveButton={scrollToInputSaveButton}
                                            setExpanded={setInputExpanded}
                                            updateList={updateList}
                                            updatingList={updatingList}
                                            organism={isOrganismAware ? organism : null}
                                        />
                                    )}
                                </AnalysisInputForm>
                            </div>
                        </CSSTransition>
                    </div>
                </Flipped>
            </div>
        ));

    return (
        <>
            <div className="space-y-8">
                {isMultiOmics ? (
                    <section className="flex flex-col gap-1 mb-2">
                        <p className="text-lg font-semibold text-dark">Organism type</p>
                        <OrganismPickerField
                            name="organism"
                            noLabel
                            onBypassSelect={(value) => {
                                if (organism && organism === value) return;
                                if (organism) {
                                    setShowConfirmOrganismModal(value);
                                } else {
                                    setOrganism(value);
                                }
                            }}
                            supportedOrganisms={availableOrganisms}
                            value={organism}
                            disableFormik
                            disableDeselect
                        />
                    </section>
                ) : null}
                <ConditionalWrapper
                    condition={isOrganismAware && !organism}
                    wrapper={(children) => (
                        <Tooltip title="Select an organism above to begin" placement="top" arrow>
                            <div>{children}</div>
                        </Tooltip>
                    )}
                >
                    <section>
                        <div className="mb-2 flex flex-row items-center justify-between">
                            <p className="text-lg font-semibold text-dark">List of Comparisons</p>
                            <Button
                                size="small"
                                variant={isDraggable ? 'outlined' : 'text'}
                                color="primary"
                                onClick={() => {
                                    if (inputExpanded) {
                                        // Require save before re-ordering
                                        scrollToInputSaveButton();
                                    } else if (isDraggable) {
                                        // Save the layout
                                        handleLayoutSave();
                                        submitForm();
                                        setIsDraggable(false);
                                    } else {
                                        // Enter drag state
                                        setInputExpanded(null);
                                        setIsDraggable(true);
                                    }
                                }}
                                startIcon={<SelectorIcon className="h-4 w-4" />}
                                className={cn({
                                    'opacity-50 pointer-events-none': isOrganismAware && !organism,
                                })}
                            >
                                {isDraggable ? 'Save Layout' : 'Re-order'}
                            </Button>
                        </div>
                        <div
                            className={cn('relative min-h-[152px] w-full', {
                                'opacity-50 pointer-events-none': isOrganismAware && !organism,
                            })}
                        >
                            <CSSTransition timeout={100} classNames="fade" in={fetchingList} unmountOnExit>
                                <div className="flex h-full w-full flex-col gap-3">
                                    <div className="flex h-[49px] w-full animate-pulse flex-col rounded-xl bg-gray-100 text-center text-gray-700" />
                                    <div className="flex h-[49px] w-full animate-pulse flex-col rounded-xl bg-gray-100 text-center text-gray-700" />
                                </div>
                            </CSSTransition>
                            <TransitionGroup component={null}>
                                <Flipper
                                    flipKey={inputLists?.map((g) => g.uuid).join('')}
                                    spring={'stiff'}
                                    className="flex flex-col gap-3"
                                >
                                    {isDraggable ? (
                                        <GridLayout
                                            className="layout"
                                            rowHeight={48}
                                            width={1}
                                            cols={1}
                                            isResizable={false}
                                            onLayoutChange={handleLayoutChange}
                                        >
                                            {renderLists()}
                                        </GridLayout>
                                    ) : (
                                        renderLists()
                                    )}
                                </Flipper>
                            </TransitionGroup>
                            <ConditionalWrapper condition={maxListsReached} wrapper={renderWrapper}>
                                <Button
                                    size="small"
                                    variant="text"
                                    color="primary"
                                    onClick={onAddList}
                                    startIcon={<AddRounded fontSize="small" />}
                                    disabled={addListButtonDisabled}
                                    className={cn({ 'mt-3': !maxListsReached })}
                                >
                                    {creatingList ? (
                                        <>
                                            Adding list...{' '}
                                            <CircularProgress
                                                color="inherit"
                                                className="ml-2 text-slate-500"
                                                size={14}
                                            />
                                        </>
                                    ) : (
                                        'Add another list'
                                    )}
                                </Button>
                            </ConditionalWrapper>
                        </div>
                    </section>
                </ConditionalWrapper>
            </div>
            <ConfirmModal
                open={!!showConfirmOrganismModal}
                onConfirm={async () => {
                    setConfirmLoading(true);
                    setOrganism(showConfirmOrganismModal);

                    Object.entries(values)
                        .filter(([key, value]) => key.startsWith('list_') && value !== null) // Filter for keys starting with "list_" and non-null values
                        .forEach(async ([_, value]) => {
                            if (value) {
                                await resetListData(value as string);
                            }
                        });

                    setShowConfirmOrganismModal(null);
                    setConfirmLoading(false);
                }}
                confirmLoading={confirmLoading}
                onCancel={() => setShowConfirmOrganismModal(null)}
                title="Change organism?"
                message="Are you sure? Once changed, all lists will be reset."
                confirmText="Yes, change"
                cancelText="Cancel"
            />
        </>
    );
};

export default OverlapAnalysisFormFields;

import ComparativeHeatmapBuilder from '@components/analysisCategories/comparative/builders/ComparativeHeatmapBuilder';
import Logger from '@util/Logger';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import { useMemo } from 'react';
import { ComparativeAnalysis } from '@models/analysis/ComparativeAnalysis';
import HeatmapDisplayOption from '@models/plotDisplayOption/HeatmapDisplayOption';
import Plot from '@models/Plot';
import { ComparativeAnalysisPlotData } from '@models/ExperimentData';

const logger = Logger.make('ComparativeHeatmapView');

const ComparativeHeatmapView = () => {
    const drawChart = useMemo<DrawChartFn>(() => {
        const fn: DrawChartFn = ({ svgSelection, size, context, tooltipId }) => {
            const { experiment, plotData } = context;

            const plot = context.plot as Plot<ComparativeAnalysis, HeatmapDisplayOption> | null;
            const data = plotData as ComparativeAnalysisPlotData | null;

            if (!plot || !data) {
                return null;
            }

            const { control_group: controlGroup, experimental_group: experimentalGroup } = (plot?.analysis ??
                {}) as ComparativeAnalysis;

            if (!controlGroup || !experimentalGroup) {
                logger.warn('No control or experiment group found for ', {
                    plot_uuid: plot.uuid,
                    analysis_uuid: plot.analysis?.uuid,
                    experiment_uuid: experiment?.uuid,
                });
                return null;
            }
            const { publicationMode } = context;
            svgSelection.selectAll('g').remove();
            const svg = svgSelection.append<SVGSVGElement>('g');
            svg.selectAll('g').remove();

            const builder = new ComparativeHeatmapBuilder({
                plot,
                data,
                experiment,
                height: size.height,
                svg,
                width: size.width,
                controlGroup,
                experimentalGroup,
                publicationMode,
                tooltipId,
            });
            builder.draw();
        };
        return fn;
    }, []);

    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default ComparativeHeatmapView;

import { GenericCellData } from '@models/ExperimentData';
import { PlotParams } from 'react-plotly.js';
import PaletteColors from '@components/PaletteColors';
import { ObservedSize } from '@hooks/useDebouncedResizeObserver';
import { getPlotPalette } from '@/src/components/ColorPaletteUtil';
import { ThemeColor } from '@/src/models/PlotConfigs';
import { capitalizeWordsAndReplaceUnderscores } from '@/src/util/StringUtil';
import { Datum } from 'plotly.js';
import { functionalAnnotationAnalysisHeaderMapping } from '@/src/models/analysis/PlotDataHeaderAnalysis';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

export const buildPlotlyLayout = ({
    xAxisTitle,
    size,
    publicationMode,
    isTransposed,
    barmode,
    stylingOptions,
}: {
    xAxisTitle: string;
    size: ObservedSize | undefined;
    publicationMode?: boolean;
    isTransposed?: boolean;
    barmode?: PlotParams['layout']['barmode'];
    stylingOptions?: CustomPlotStylingOptions;
}) => {
    const originalYAxis: PlotParams['layout']['yaxis'] = {
        automargin: true,
        autorange: true,
        title: '<b>Frequency (%)</b>',
        titlefont: {
            color: stylingOptions?.yaxis?.fontColor || (publicationMode ? 'black' : undefined),
            size: stylingOptions?.yaxis?.fontSize || 18,
            family: stylingOptions?.yaxis?.fontFamily || 'Arial',
        },
        color: publicationMode ? '#000' : PaletteColors.gray500.color,
        dtick: 20,
        showgrid: true,
        tickfont: { size: 12 },
        tickmode: 'linear',
        ticks: 'outside',
        zeroline: false,
    };
    const originalXAxis: PlotParams['layout']['xaxis'] = {
        automargin: true,
        autorange: true,
        title: xAxisTitle,
        titlefont: {
            color: stylingOptions?.xaxis?.fontColor || (publicationMode ? 'black' : undefined),
            size: stylingOptions?.xaxis?.fontSize || 18,
            family: stylingOptions?.xaxis?.fontFamily || 'Arial',
        },
        color: publicationMode ? '#000' : PaletteColors.gray500.color,
        dtick: 1,
        linewidth: 1,
        showgrid: false,
        tick0: 0,
        tickangle: isTransposed ? 0 : -35,
        tickfont: { size: 12 },
        tickmode: 'linear',
        ticks: 'outside',
        zeroline: false,
    };
    const yaxis = isTransposed ? originalXAxis : originalYAxis;
    const xaxis = isTransposed ? originalYAxis : originalXAxis;
    const width = size?.width;
    const height = size?.height;

    const layout: PlotParams['layout'] = {
        autosize: false,
        barmode,
        dragmode: undefined,
        height,
        shapes: [],
        showlegend: false,
        width,
        xaxis,
        yaxis,
    };

    return layout;
};

export const prepareData = ({
    analysisType,
    customColors,
    customLegend,
    isTransposed,
    items,
    legendDisplayOrder,
    names,
    themeColor,
    xAxisIdentifier,
    xAxisTitle,
}: {
    analysisType: string;
    customColors: Record<string, string> | null;
    customLegend: Record<string, string> | null;
    isTransposed?: boolean;
    items: GenericCellData[];
    legendDisplayOrder: string[];
    names: string[];
    themeColor?: ThemeColor;
    xAxisIdentifier: string;
    xAxisTitle: string;
}) => {
    const x = Object.values(items.map((i) => i[xAxisIdentifier])) as Datum[];
    const sortedNames = names.sort((n1, n2) => {
        return legendDisplayOrder.indexOf(n1) - legendDisplayOrder.indexOf(n2);
    });
    const plotPalette = getPlotPalette(themeColor);
    const paletteColors = plotPalette.colors;

    const preparedItems: PlotParams['data'] = sortedNames.map((name, i) => {
        const y = Object.values(items.map((item) => item[name])) as Datum[];
        const getColor = () => {
            return customColors?.[name] ?? paletteColors[i % paletteColors.length].color;
        };
        // prettier-ignore
        const nameDisplay = customLegend?.[name] !== undefined
            ? customLegend[name]
            : (analysisType === 'functional_annotation'
                ? functionalAnnotationAnalysisHeaderMapping[name as keyof typeof functionalAnnotationAnalysisHeaderMapping]
                : capitalizeWordsAndReplaceUnderscores(name));

        return {
            type: 'bar',
            x: isTransposed ? y : x,
            y: isTransposed ? x : y,
            orientation: isTransposed ? 'h' : undefined,
            name: nameDisplay,
            marker: {
                color: getColor(),
            },
            hovertemplate:
                `<b>${nameDisplay}</b><br>` +
                `<b>${xAxisTitle}</b>: %{${isTransposed ? 'y' : 'x'}}<br>` +
                `<b>Frequency</b>: %{${isTransposed ? 'x:.2f' : 'y:.2f'}}%<br></b>` +
                '<extra></extra>',
            hoverlabel: { align: 'left', bgcolor: '#ffffff' },
        };
    });

    return { preparedItems };
};

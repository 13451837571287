import Experiment, { JoinedExperimentTypes } from '@models/Experiment';
import { PageLayout } from '@layouts/Layout';
import { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@mui/material';
import TextInput from '@components/forms/TextInput';
import { Close, Search } from '@mui/icons-material';
import useExperimentSettings from '@hooks/useExperimentSettings';
import AnalysisCard from '@components/experiments/AnalysisCard';
import { useDebounce } from 'react-use';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import Plot, { PlotCreateParams, PlotStatus } from '@models/Plot';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import Endpoints from '@services/Endpoints';
import { ApiError } from '@services/ApiError';
import useApi from '@hooks/useApi';
import Logger from '@util/Logger';
import LoadingMessage from '@components/LoadingMessage';
import AnalysisType, { AnalysisCategoryShortname } from '@models/analysis/AnalysisType';
import { AnalysisCategory, AnalysisCategoryInfo } from '@models/analysis/AnalysisCategory';
import { ScrollableSidebarContainer } from '../ScrollableSidebarContent';
import cn from 'classnames';
import ControlledAnalysisCategoryPickerField from '../../filters/fields/ControlledAnalysisCategoryPickerField';
import ControlledPlotTypePickerField from '../../filters/fields/ControlledPlotTypePickerField';
import PlotDisplayType from '@/src/models/PlotDisplayType';
import Button from '../../Button';
import { ChevronDoubleRightIcon, ChevronLeftIcon, XIcon } from '@heroicons/react/outline';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ContentfulSidebarView from './contentful/ContentfulSidebarView';
import { useMouseMoveHoverEffect } from '../../effects/MouseMoveHoverEffect';
import { ExperimentTypeShortname } from '@/src/models/ExperimentType';
import SelectExperimentModalView from './SelectExperimentModalView';
import { useExperimentCanvasContext } from '@/src/contexts/ExperimentCanvasContext';
import useFetchExperiment from '@/src/hooks/useFetchExperiment';

const sortByDisplayName = (a: AnalysisType, b: AnalysisType): number => {
    const nameA = a.display_name.toUpperCase();
    const nameB = b.display_name.toUpperCase();

    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
};

const formatResults = (sortedAnalysisCategoryInfo: AnalysisCategoryInfo[]) => {
    const flatMapAnalysisTypes: AnalysisType[] = sortedAnalysisCategoryInfo.flatMap((item) => item.analysisTypes);
    const flatMapSingleArray: AnalysisType[] = flatMapAnalysisTypes.flatMap((item) => item);
    const sortedResults: AnalysisType[] = flatMapSingleArray.sort(sortByDisplayName);
    return sortedResults;
};

const logger = Logger.make('NoCodeModulesView');

type AnalysisCategoryReduce = AnalysisCategoryInfo[];
type PageProps = {
    experiment: Experiment;
    handleAnalysisSelect?: ({
        plotId,
        linked_experiment_id,
    }: {
        plotId: string;
        linked_experiment_id?: string;
    }) => void;
    selectedExperiment?: JoinedExperimentTypes | null;
    setSelectedExperiment?: (experiment: JoinedExperimentTypes | null) => void;
};
const NoCodeModulesView: PageLayout<PageProps> = ({
    experiment,
    handleAnalysisSelect,
    selectedExperiment,
    setSelectedExperiment,
}: PageProps) => {
    const [searchVal, setSearchVal] = useState<string>('');
    const [plotTypeFilter, setPlotTypeFilter] = useState<string | null>(null);
    const [analysisCategoryFilter, setAnalysisCategoryFilter] = useState<AnalysisCategoryShortname | null>(null);
    const {
        experiment: contextExperiment,
        refreshExperiment,
        refreshPlotItems,
        handleChangeSelectedPlot,
        updatePlotDisplayOrder,
    } = useExperimentDetailViewContext();
    const { selectedLinkedExperimentData, setSelectedLinkedExperimentData, isFromExperimentCard } =
        useExperimentCanvasContext();

    const { experiment: fetchedLinkedExperiment } = useFetchExperiment({
        experimentId: selectedLinkedExperimentData?.linked_experiment_id,
    });

    useEffect(() => {
        if (selectedLinkedExperimentData && !selectedLinkedExperimentData?.linked_experiment_id) {
            setSelectedExperiment?.(contextExperiment);
        }
    }, [selectedLinkedExperimentData]);
    useEffect(() => {
        if (fetchedLinkedExperiment) {
            setSelectedExperiment?.(fetchedLinkedExperiment);
        }
    }, [fetchedLinkedExperiment]);

    const activeExperiment = (
        selectedLinkedExperimentData?.linked_experiment_id
            ? fetchedLinkedExperiment
            : selectedExperiment?.uuid
              ? selectedExperiment
              : experiment || contextExperiment
    ) as Experiment;
    const showSelectExperimentView =
        contextExperiment?.type.shortname === ExperimentTypeShortname.multiomics &&
        !selectedExperiment &&
        !isFromExperimentCard;

    const showBackButton =
        contextExperiment?.type.shortname === ExperimentTypeShortname.multiomics &&
        selectedExperiment?.uuid &&
        !isFromExperimentCard;

    const { getAnalysisTypes, sortedAnalysisCategoryInfo } = useExperimentSettings(activeExperiment, {
        skip:
            showSelectExperimentView ||
            !!(selectedLinkedExperimentData?.linked_experiment_id && !fetchedLinkedExperiment),
    });
    const analysisTypes = getAnalysisTypes(activeExperiment?.type.shortname);
    const [filteredResults, setFilteredResults] = useState<AnalysisCategoryInfo[]>([]);
    const [finalResults, setFinalResults] = useState<AnalysisType[]>([]);
    const [hasSearched, setHasSearched] = useState<boolean>(false);
    const [contentfulExpanded, setContentfulExpanded] = useState(false);
    const [selectedAnalysisType, setSelectedAnalysisType] = useState<AnalysisType | null>(null);
    const toggleContentfulExpanded = () => setContentfulExpanded(!contentfulExpanded);
    const hasFilters = !!plotTypeFilter || !!analysisCategoryFilter;
    const showLoading = !finalResults.length && !hasSearched && !hasFilters;
    const showNoResults = !finalResults.length && (hasSearched || hasFilters);

    const api = useApi();
    const permissions = useExperimentPermissions(activeExperiment);

    useMouseMoveHoverEffect();

    //Unmount to ensure that analyses are fetched each time component is rendered
    useEffect(() => {
        return () => setFinalResults([]);
    }, []);

    useEffect(() => {
        if (sortedAnalysisCategoryInfo?.length && filteredResults.length === 0 && !hasFilters) {
            setFilteredResults(sortedAnalysisCategoryInfo);
        }
    }, [sortedAnalysisCategoryInfo]);

    useDebounce(
        () => {
            const results = filteredResults.reduce<AnalysisCategoryReduce>((acc, { category, analysisTypes }) => {
                const filteredAnalysisTypes = analysisTypes.filter((type) => {
                    const matchesDisplayName = type.display_name.toLowerCase().includes(searchVal.toLowerCase());

                    // Look for matches in the display_name of associated display_types
                    const matchesDisplayType = type.display_types.some((displayType) =>
                        displayType.display_name.toLowerCase().includes(searchVal.toLowerCase()),
                    );

                    const matchesCategory = category.display_name.toLowerCase().includes(searchVal.toLowerCase());

                    return matchesDisplayName || matchesDisplayType || matchesCategory;
                });
                const hasAnalysisTypeResults = filteredAnalysisTypes.length > 0;
                if (hasAnalysisTypeResults) {
                    acc.push({ category, analysisTypes: filteredAnalysisTypes });
                }
                return acc;
            }, []);
            const sortedResults = results.length > 0 ? formatResults(results) : [];
            setHasSearched(!!searchVal);
            setFinalResults(sortedResults);
        },
        300,
        [searchVal, filteredResults],
    );

    useEffect(() => {
        if (!analysisCategoryFilter && !plotTypeFilter) {
            return setFilteredResults(sortedAnalysisCategoryInfo);
        }

        const results = sortedAnalysisCategoryInfo.reduce<AnalysisCategoryReduce>(
            (acc, { category, analysisTypes }) => {
                const isSelectedCategory = analysisCategoryFilter && category.display_name !== analysisCategoryFilter;
                if (isSelectedCategory) {
                    return acc;
                }
                const filteredAnalysisTypes = plotTypeFilter
                    ? analysisTypes.filter((type) => {
                          // Look for matches in the display_name of associated display_types
                          const matchesDisplayType = type.display_types.some(
                              (displayType) =>
                                  displayType.display_name.toLowerCase() === (plotTypeFilter?.toLowerCase() ?? ''),
                          );

                          return matchesDisplayType;
                      })
                    : analysisTypes;
                const hasAnalysisTypeResults = filteredAnalysisTypes.length > 0;
                if (hasAnalysisTypeResults) {
                    acc.push({ category, analysisTypes: filteredAnalysisTypes });
                }
                return acc;
            },
            [],
        );
        setFilteredResults(results);
    }, [analysisCategoryFilter, plotTypeFilter]);

    const plotTypeFilterOptions = useMemo(() => {
        const analysisTypes = formatResults(sortedAnalysisCategoryInfo);
        const displayTypesDupes = analysisTypes.flatMap((analysis) =>
            analysis.display_types.map((displayType) => ({
                display_name: displayType.display_name,
                shortname: displayType.shortname,
            })),
        );
        const uniqueDisplayTypes = new Map();
        displayTypesDupes.forEach((obj) => uniqueDisplayTypes.set(obj.shortname, obj));
        const results = Array.from(uniqueDisplayTypes.values());

        const sortedResults: PlotDisplayType[] = results.sort(sortByDisplayName);
        return sortedResults ?? [];
    }, []);

    const analysisCategoryFilterOptions = useMemo(() => {
        const options: AnalysisCategory[] = sortedAnalysisCategoryInfo.map((info) => info.category);
        return options ?? [];
    }, []);

    const handleContentfulExpanded = (analysis: AnalysisType | null) => {
        if ((contentfulExpanded && analysis?.shortname === selectedAnalysisType?.shortname) || !analysis) {
            setSelectedAnalysisType(null);
            toggleContentfulExpanded();
            return;
        }
        if (contentfulExpanded && analysis?.shortname !== selectedAnalysisType?.shortname) {
            setSelectedAnalysisType(analysis);
            return;
        }
        setSelectedAnalysisType(analysis);
        toggleContentfulExpanded();
    };

    const resetFilters = () => {
        setAnalysisCategoryFilter(null);
        setPlotTypeFilter(null);
        setSearchVal('');
    };

    const onAnalysisSelect = async () => {
        const analysis = selectedAnalysisType as AnalysisType;
        const plotStatus: PlotStatus = permissions.canEdit ? 'published' : 'preview';
        const displayTypes = analysisTypes.find((a) => a.shortname === analysis.shortname)?.display_types;
        const payload: PlotCreateParams = {
            analysis_type: analysis.shortname,
            display_type: displayTypes?.[0]?.shortname ?? null,
            status: plotStatus,
            ...(selectedLinkedExperimentData?.source_experiment_id
                ? { source_experiment_id: selectedLinkedExperimentData.source_experiment_id }
                : null),
        };
        try {
            const plot = await api.post<Plot>(
                Endpoints.lab.experiment.plotsV2({
                    experimentId: (selectedExperiment || activeExperiment).uuid,
                }),
                payload,
            );
            const newDisplayOrder = [
                plot.uuid,
                ...(((selectedExperiment || activeExperiment) as Experiment).plot_display_order ?? []),
            ];
            updatePlotDisplayOrder(newDisplayOrder);
            handleChangeSelectedPlot(plot);
            if (handleAnalysisSelect)
                await handleAnalysisSelect({
                    plotId: plot.uuid,
                    linked_experiment_id: selectedExperiment?.uuid,
                });

            await Promise.all([refreshExperiment(), refreshPlotItems()]);
        } catch (error) {
            const message =
                ApiError.getMessage(error as Error) ?? 'Unable to create the plot: An unexpected error occurred.';
            logger.error(message);
        }
    };

    const renderView = () => {
        if (showSelectExperimentView) {
            return (
                <SelectExperimentModalView
                    experiment={contextExperiment}
                    onSelect={(exp) => setSelectedExperiment?.(exp)}
                    description={
                        <>
                            Choose the experiment from which you&apos;d like to{' '}
                            <span className="font-semibold text-dark">create a new analysis</span>.
                            <br />
                            You can select either your current experiment or one of your linked experiments.
                        </>
                    }
                    cols={3}
                />
            );
        }

        return (
            <>
                {showBackButton && (
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={() => {
                            setSelectedLinkedExperimentData(null);
                            setSelectedExperiment?.(null);
                        }}
                        className="absolute -top-[4.7rem] left-0 !px-0"
                        startIcon={<ChevronLeftIcon width="14" className="mr-1" />}
                    >
                        Back to experiment list
                    </Button>
                )}
                <div className="flex flex-[0_0_auto] flex-row justify-between border-b-2 border-b-gray-100 pb-4">
                    <div className="flex flex-1 items-center">
                        <div className="mr-6 w-full max-w-[225px]">
                            <ControlledAnalysisCategoryPickerField
                                noLabel
                                options={analysisCategoryFilterOptions}
                                placeholder="Filter by analysis category"
                                setValue={setAnalysisCategoryFilter}
                                showNone
                                value={analysisCategoryFilter}
                            />
                        </div>
                        <div className="mr-2 w-full max-w-[225px]">
                            <ControlledPlotTypePickerField
                                noLabel
                                options={plotTypeFilterOptions}
                                placeholder="Filter by plot type"
                                setValue={setPlotTypeFilter}
                                showNone
                                value={plotTypeFilter}
                            />
                        </div>
                        <Button
                            color="primary"
                            variant="text"
                            size="small"
                            onClick={() => resetFilters()}
                            disabled={!hasFilters && !searchVal}
                            startIcon={<XIcon className="h-4 w-4" />}
                        >
                            Clear filters
                        </Button>
                    </div>
                    <TextInput
                        className="w-1/3 pl-3"
                        noMargin
                        value={searchVal}
                        name="search"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchVal(e.target.value)}
                        placeholder="Search analyses..."
                        iconRight={
                            <div className="flex">
                                {searchVal && (
                                    <IconButton
                                        sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                        onClick={() => setSearchVal('')}
                                        size="small"
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                                <IconButton
                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                    type="submit"
                                    size="small"
                                    color={searchVal ? 'primary' : 'default'}
                                >
                                    <Search />
                                </IconButton>
                            </div>
                        }
                        disableFormik
                    />
                </div>
                <div className="relative flex h-full w-full flex-1">
                    <div className="relative flex h-full flex-1 overflow-auto scroll-smooth">
                        <div
                            className={cn('absolute top-0 transition-all duration-700', {
                                'pr-5 sm:w-[100%] md:w-[50%] lg:w-[65%] lg:pr-2': contentfulExpanded,
                                'w-full': !contentfulExpanded,
                            })}
                        >
                            <CSSTransition timeout={300} classNames="fade" in={showLoading} unmountOnExit>
                                <div className="flex h-full w-full flex-1 items-center justify-center">
                                    <LoadingMessage message="Loading analyses..." immediate />
                                </div>
                            </CSSTransition>
                            <CSSTransition timeout={300} classNames="fade" in={showNoResults} unmountOnExit>
                                <div className="flex flex-1 items-center justify-center pb-12 pt-16">
                                    <p>
                                        No analyses found. Need additional help or an analysis not available in Pluto?
                                        Contact our team.
                                    </p>
                                </div>
                            </CSSTransition>
                            <TransitionGroup component={null}>
                                <CSSTransition timeout={500} classNames="item" in={true}>
                                    <>
                                        <div
                                            className={cn(
                                                'grid flex-auto gap-8 rounded-lg pb-2 pt-4 transition-all duration-200',
                                                {
                                                    'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4':
                                                        !contentfulExpanded,
                                                    'md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3': contentfulExpanded,
                                                },
                                            )}
                                        >
                                            {finalResults.map((analysis) => {
                                                return (
                                                    <AnalysisCard
                                                        analysis={analysis}
                                                        key={analysis.shortname}
                                                        onSelect={(analysis) => handleContentfulExpanded(analysis)}
                                                        isSelected={
                                                            selectedAnalysisType?.shortname === analysis.shortname
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                        <p className="pb-14 pt-5 font-semibold tracking-tight">
                                            Don&apos;t see what you&apos;re looking for? We&apos;re constantly adding
                                            new functionality based on your feedback -
                                            <span key="pipeline-error">
                                                <a
                                                    href="https://help.pluto.bio"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="link mx-1"
                                                >
                                                    contact us
                                                </a>
                                            </span>
                                            to share your thoughts!
                                        </p>
                                    </>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                    </div>

                    <ScrollableSidebarContainer
                        className={cn(
                            'absolute right-0 top-0 z-10 h-full border-l-2 border-gray-100 bg-white py-5 pl-5 transition-all duration-700 sm:w-[100%] md:w-[50%] lg:w-[33%]',
                            {
                                'translate-x-[120%]': !contentfulExpanded,
                                'delay-0': contentfulExpanded,
                            },
                        )}
                        style={{ boxShadow: 'rgba(0, 0, 0, 0.1) -10px -5px 8px -6px' }}
                    >
                        <CSSTransition timeout={300} classNames="fade" in={contentfulExpanded} unmountOnExit>
                            <>
                                <ContentfulSidebarView
                                    analysis={selectedAnalysisType}
                                    category="nocode"
                                    onAnalysisSelect={onAnalysisSelect}
                                />
                                <div
                                    className={cn(
                                        'group absolute -left-[55px] top-[50%] z-20 cursor-pointer rounded-full border-2 border-gray-200 bg-white p-1 transition-all duration-700',
                                        {
                                            'translate-x-[120%]': contentfulExpanded,
                                            'delay-0': !contentfulExpanded,
                                        },
                                    )}
                                    onClick={() => handleContentfulExpanded(null)}
                                >
                                    <ChevronDoubleRightIcon
                                        height={20}
                                        width={20}
                                        className={cn(
                                            'text-indigo-500 transition-all duration-300 ease-in-out group-hover:translate-x-[1.5px]',
                                        )}
                                    />
                                </div>
                            </>
                        </CSSTransition>
                    </ScrollableSidebarContainer>
                </div>
            </>
        );
    };

    if (!activeExperiment) return;

    return (
        <div data-cy="no-code-modules-view" className="relative h-full w-full">
            {renderView()}
        </div>
    );
};

export default NoCodeModulesView;

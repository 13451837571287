import { SparklesIcon } from '@heroicons/react/outline';
import React from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import useExperimentSettings from '@hooks/useExperimentSettings';
import AnalysisIcon from '@components/experiments/analyses/AnalysisIcon';
import CanvasPlotShellActions from '@components/plots/CanvasPlotShellActions';
import LoadingMessage from '@components/LoadingMessage';

type Props = {
    experiment?: Experiment | null;
    hideButtons?: boolean;
    hideEditForm?: boolean;
    loading?: boolean;
    plot?: Plot | null;
};
const CanvasPlotShellView = ({ experiment, hideButtons = false, hideEditForm, loading, plot }: Props) => {
    const { getAnalysisInfo } = useExperimentSettings(experiment);

    if (loading || !plot) {
        return (
            <div className="flex h-full w-full flex-col items-center justify-center px-8 py-16 text-center">
                <div className="mx-auto mb-4 rounded-full bg-indigo-100 p-0 text-indigo-800">
                    <LoadingMessage size={24} immediate />
                </div>
                <h2 className="text-xl font-semibold tracking-tight">Loading plot data...</h2>
                <p className="text-base">Your plot will appear here shortly.</p>
            </div>
        );
    }

    return (
        <div className="flex h-full w-full flex-col items-center justify-center px-8 py-12 text-center">
            <div className="mx-auto mb-4 rounded-full bg-indigo-100 p-4 text-indigo-800">
                {plot?.analysis_type ? (
                    <AnalysisIcon shortname={plot.analysis_type} width={24} height={24} />
                ) : (
                    <SparklesIcon height={24} width={24} />
                )}
            </div>
            <h2 className="text-xl font-semibold tracking-tight">New insights in the making</h2>
            {plot && plot.analysis_type && (
                <div className="space-y-8">
                    <p className="text-base">
                        Your plot will appear here once you&apos;ve finished setting up your{' '}
                        <span className="lowercase">
                            {getAnalysisInfo(plot.analysis_type, experiment?.type.shortname)?.display_name}
                        </span>{' '}
                        analysis using the options to the left.
                    </p>
                    {!hideButtons && (
                        <CanvasPlotShellActions plot={plot} experiment={experiment} hideEditForm={hideEditForm} />
                    )}
                </div>
            )}
        </div>
    );
};

export default CanvasPlotShellView;

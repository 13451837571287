import { BaseExperimentAnalysis, ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import { PipelineStatus } from '@models/ExperimentData';
import { PlotDisplayOption } from '@models/PlotDisplayOption';
import { AnalysisShortname } from '@models/analysis/AnalysisType';
import { PlotDisplayShortname } from '@models/PlotDisplayType';
import { SimpleBasePlotDisplayOption } from '@models/plotDisplayOption/BasePlotDisplayOption';

export type PlotStatus = 'published' | 'preview';
export type PlotLayout = {
    x: number;
    y: number;
    w: number;
    h: number;
};

export default interface Plot<
    A extends BaseExperimentAnalysis = ExperimentAnalysis,
    D extends SimpleBasePlotDisplayOption = PlotDisplayOption,
> {
    analysis_form_type: string;
    analysis_type: AnalysisShortname;
    analysis: A | null;
    display: D;
    embedded_in_experiments?: string[];
    layout?: PlotLayout;
    linked_experiment_id?: string;
    share_level?: string | null;
    source_experiment_id?: string;
    status: PlotStatus;
    uuid: string;
    thumbnail_uri: {
        rectangle?: string;
        square?: string;
        tall?: string;
    };
}

export type PlotCreateParams = Pick<Plot, 'analysis_type' | 'status'> & {
    display_type: PlotDisplayShortname | null;
};

export type PlotThumbnailUri = {
    rectangle?: string;
    square?: string;
    tall?: string;
};
export type PlotListItem = {
    analysis_form_type: string;
    analysis_type: AnalysisShortname;
    created_by_id: string;
    display_type: PlotDisplayShortname;
    is_full_width: boolean;
    linked_experiment_id?: string;
    name: string | null;
    pipeline_status: PipelineStatus;
    plot_title: string | null;
    share_level?: string | null;
    source_experiment_id?: string;
    status: PlotStatus;
    updated_by_id: string;
    uuid: string;
    thumbnail_uri: PlotThumbnailUri;
    updated_at: string;
    created_at: string;
};

export type PlotListItemsResponse = {
    count: number;
    items: PlotListItem[];
};

export const supportedThumbnailDisplayTypes = ['volcano_plot_v2', 'cell_scatter_plot', 'scatter_plot'];

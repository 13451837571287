import Experiment from '@models/Experiment';
import DifferentialExpressionAnalysisPickerField from '@components/experiments/analyses/fields/DifferentialExpressionAnalysisPickerField';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { ProteinProteinInteractionAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import {
    BiomarkerListAnalysisParameter,
    DifferentialExpressionPickerFieldTypes,
    ProteinProteinInteractionAnalysisParameters,
} from '@models/AnalysisParameters';
import SimpleCollapse from '@components/experiments/SimpleCollapse';
import PValueField from '@components/experiments/plotDisplay/fields/PValueField';
import TextInputField from '@components/forms/TextInputField';
import SingleSelectField from '@components/filters/fields/SingleSelectField';
import AsyncTargetPicker from '@/src/components/AsyncTargetPicker';
import { Tooltip } from '@mui/material';
import ConditionalWrapper from '@/src/components/ConditionalWrapper';
import cn from 'classnames';
import { HelpCircleIcon } from '@/src/components/icons/custom/HelpCircleIcon';
import CustomDropzone from '@/src/components/fileUpload/CustomDropzone';
import SimpleSelectField from '../../plotDisplay/fields/SimpleSelectField';
import FileUploadDescription from '../../wizard/FileUploadDescription';
import useOrganizationPermissions from '@/src/hooks/useOrganizationPermissions';

type Props = {
    analysisParameters: ProteinProteinInteractionAnalysisParameters;
    experiment: Experiment;
};
const ProteinProteinInteractionAnalysisFormFields = ({ experiment, analysisParameters }: Props) => {
    const { values, setFieldValue, ...form } = useFormikContext<ProteinProteinInteractionAnalysisFormValues>();
    const { features } = useOrganizationPermissions();

    const biomarkerList: BiomarkerListAnalysisParameter[] = useMemo(
        () => analysisParameters?.biomarker_list ?? [],
        [analysisParameters],
    );

    const updateValues = (updateObject: { [field: string]: string | number | string[] }) => {
        form.setValues({
            ...values,
            adj_pval_threshold: undefined,
            differential_analysis_id: null,
            differential_analysis_shortname: null,
            fc_direction: undefined,
            fc_threshold: undefined,
            targets_biomarker_list: undefined,
            targets_csv_filename: undefined,
            targets_csv: undefined,
            targets: undefined,
            ...updateObject,
        });
        form.setErrors({});
    };

    const renderTooltipWrapper = useCallback(
        (children: ReactNode, tooltipText: string) => (
            <Tooltip title={tooltipText ?? ''} placement="top" arrow>
                <div>{children}</div>
            </Tooltip>
        ),
        [experiment],
    );

    const getBiomarkerTooltipText = () => {
        if (!features?.experiment_features.biomarkers_enabled) {
            return 'Biomarkers is not currently enabled for your lab space. Reach out to Pluto support to learn more.';
        }
        if (!biomarkerList?.length) {
            return "No biomarkers/targets available for this experiment's organism type";
        }
        return '';
    };

    return (
        <div className="space-y-8">
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Select targets</p>
                <div className="space-y-2">
                    <label
                        className={cn('block', {
                            'opacity-50': !analysisParameters?.target_list_is_enabled,
                        })}
                    >
                        <ConditionalWrapper
                            condition={!analysisParameters?.target_list_is_enabled}
                            wrapper={(children) =>
                                renderTooltipWrapper(
                                    children,
                                    'Protein-protein interaction analysis per target is only available for experiments with a pipeline run.',
                                )
                            }
                        >
                            <input
                                type="radio"
                                className={cn('cursor-pointer text-indigo-500', {
                                    'cursor-not-allowed': !analysisParameters?.target_list_is_enabled,
                                })}
                                name="target_genes_format"
                                checked={values.target_genes_format === 'list'}
                                onChange={() => {
                                    updateValues({
                                        target_genes_format: 'list',
                                    });
                                }}
                                disabled={!analysisParameters?.target_list_is_enabled}
                            />
                            <span className="ml-2">Enter target list</span>
                        </ConditionalWrapper>
                    </label>

                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="target_genes_format"
                            onChange={() => {
                                updateValues({
                                    target_genes_format: 'file',
                                });
                            }}
                            checked={values.target_genes_format === 'file'}
                        />
                        <span className="ml-2">Upload target csv</span>
                    </label>

                    <label
                        className={cn('block', {
                            'opacity-50': !analysisParameters?.differential_is_enabled,
                        })}
                    >
                        <ConditionalWrapper
                            condition={!analysisParameters?.differential_is_enabled}
                            wrapper={(children) =>
                                renderTooltipWrapper(
                                    children,
                                    'Protein-protein interaction analysis on differential targets requires a differential analysis to be run first.',
                                )
                            }
                        >
                            <input
                                type="radio"
                                className={cn('cursor-pointer text-indigo-500', {
                                    'cursor-not-allowed': !analysisParameters?.differential_is_enabled,
                                })}
                                name="target_genes_format"
                                onChange={() => {
                                    updateValues({
                                        target_genes_format: 'differential',
                                        fc_direction: 'up',
                                        fc_threshold: 1.2,
                                        adj_pval_threshold: 0.05,
                                    });
                                }}
                                checked={values.target_genes_format === 'differential'}
                                disabled={!analysisParameters?.differential_is_enabled}
                            />
                            <span className="ml-2">Use differential targets</span>
                        </ConditionalWrapper>
                    </label>
                    <label
                        className={cn('block', {
                            'opacity-50': !biomarkerList?.length,
                        })}
                    >
                        <ConditionalWrapper
                            condition={!features?.experiment_features.biomarkers_enabled || !biomarkerList?.length}
                            wrapper={(children) => renderTooltipWrapper(children, getBiomarkerTooltipText())}
                        >
                            <input
                                type="radio"
                                className={cn('cursor-pointer text-indigo-500', {
                                    'cursor-not-allowed': !biomarkerList?.length,
                                })}
                                name="target_genes_format"
                                checked={values.target_genes_format === 'biomarker'}
                                onChange={() => {
                                    updateValues({
                                        target_genes_format: 'biomarker',
                                    });
                                }}
                                disabled={!biomarkerList?.length}
                            />
                            <span className="ml-2">Select a biomarker/target list</span>
                        </ConditionalWrapper>
                    </label>

                    {values.target_genes_format === 'list' ? (
                        <div className="!mt-4 rounded-xl border border-indigo-500 p-4">
                            <AsyncTargetPicker
                                name="targets"
                                experiment={experiment}
                                label="Targets"
                                description="Search for targets in your assay data (if available), or paste in a comma-separated or new line-separated list (case sensitive)"
                                className="!mb-0"
                                columnNameProp="Gene_Symbol"
                            />
                        </div>
                    ) : null}

                    {values.target_genes_format === 'file' ? (
                        <div className="!mt-4 gap-4 rounded-xl border border-indigo-500 p-4 text-center">
                            <FileUploadDescription
                                uploadStep="targets_ppi"
                                analysisParameters={analysisParameters}
                                experiment={experiment}
                            />
                            <div className="mt-4">
                                <CustomDropzone
                                    uploadHeader="Upload CSV file"
                                    chooseFileText="Choose file"
                                    onFilesChanged={(files: File[] | null) => {
                                        const file = files?.[0];
                                        if (file) {
                                            setFieldValue('targets_csv', file);
                                            setFieldValue('targets_csv_filename', file?.name);
                                        }
                                    }}
                                    acceptFileTypes={{ 'text/csv': ['.csv'] }}
                                    maxFiles={1}
                                    existingFileNames={
                                        values?.targets_csv_filename ? [values.targets_csv_filename] : []
                                    }
                                />
                            </div>
                        </div>
                    ) : null}
                    {values.target_genes_format === 'differential' ? (
                        <div className="!mt-4 rounded-xl border border-indigo-500 p-4">
                            <DifferentialExpressionAnalysisPickerField
                                experimentId={experiment.uuid}
                                name="differential_analysis_id"
                                analysisTypeFieldName="differential_analysis_shortname"
                                filter={{ analysis_types: DifferentialExpressionPickerFieldTypes }}
                            />
                            <SimpleCollapse label={'Thresholds'} initialOpen={false}>
                                <div className="space-y-4">
                                    <PValueField
                                        name="adj_pval_threshold"
                                        hideSectionLabel
                                        noMargin
                                        max={0.25}
                                        tooltipTitle="Adjusted p-value threshold for passing genes to be included in the analysis"
                                        label={
                                            <span>
                                                Adjusted <i>p</i>-value
                                            </span>
                                        }
                                    />

                                    <TextInputField
                                        label="Fold change threshold"
                                        name="fc_threshold"
                                        noMargin
                                        step={0.1}
                                        min={0}
                                        type="number"
                                    />
                                    <SingleSelectField
                                        name="fc_direction"
                                        label="Fold change direction"
                                        options={[
                                            { value: 'up', label: 'Up' },
                                            { value: 'down', label: 'Down' },
                                        ]}
                                    />
                                </div>
                            </SimpleCollapse>
                        </div>
                    ) : null}
                    {values.target_genes_format === 'biomarker' ? (
                        <SimpleSelectField
                            name="targets_biomarker_list"
                            label=""
                            items={
                                biomarkerList?.map((l) => ({
                                    label: l.name,
                                    value: l.uuid,
                                })) ?? []
                            }
                            placeholder="Select biomarkers or targets"
                            value={values.targets_biomarker_list ?? ''}
                            onChange={(e) => {
                                const updatedValue = e.target.value;
                                setFieldValue('targets_biomarker_list', updatedValue ?? undefined);
                            }}
                        />
                    ) : null}
                </div>
            </section>
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Network type</p>
                <div className="space-y-2">
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="network_type"
                            checked={values.network_type === 'functional'}
                            onChange={() => {
                                setFieldValue('network_type', 'functional');
                            }}
                        />
                        <span className="inline-flex flex-row items-center">
                            <span className="ml-2 mr-1">Full STRING network</span>
                            <Tooltip
                                title="Edges indicate both functional and physical protein associations"
                                placement="top"
                                arrow
                            >
                                <span>
                                    <HelpCircleIcon height={16} width={16} />
                                </span>
                            </Tooltip>
                        </span>
                    </label>
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="network_type"
                            checked={values.network_type === 'physical'}
                            onChange={() => {
                                setFieldValue('network_type', 'physical');
                            }}
                        />
                        <span className="inline-flex flex-row items-center">
                            <span className="ml-2 mr-1">Physical subnetwork</span>
                            <Tooltip
                                title="Edges indicate that the proteins are part of a physical complex"
                                placement="top"
                                arrow
                            >
                                <span>
                                    <HelpCircleIcon height={16} width={16} />
                                </span>
                            </Tooltip>
                        </span>
                    </label>
                </div>
            </section>
        </div>
    );
};

export default ProteinProteinInteractionAnalysisFormFields;

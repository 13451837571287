import { useFormikContext } from 'formik';
import { isBlank } from '@util/StringUtil';
import { pluralize } from '@util/ObjectUtil';
import React from 'react';

interface FormValues {
    [key: string]: any;
}

const AggregateFormErrorAlert = () => {
    const { errors, touched } = useFormikContext<FormValues>();

    const errorMessages = Object.keys(errors)
        .filter((name) => (name.includes('|') ? true : touched[name] && !isBlank(errors[name])))
        .map((name) => errors[name]);
    return (
        <>
            {errorMessages.length > 0 && (
                <div className="mb-4 rounded-lg bg-error p-2 text-error" data-cy="detailed-errors">
                    <p className="font-semibold">
                        {errorMessages.length} {pluralize(errorMessages.length, 'error', 'errors')}
                        {' found:'}
                    </p>
                    <ul className="space-y-2">
                        {errorMessages.map((m) => (
                            <li key={`${m}`}>{m as string}</li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default AggregateFormErrorAlert;

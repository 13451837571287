import { AuthContext } from '@/src/contexts/AuthContext';
import { NewCommentValue } from '@/src/hooks/useComments';
import { isSponsor } from '@/src/models/User';
import { Tooltip } from '@mui/material';
import React, { useContext } from 'react';

interface PrivateCommentCheckboxProps {
    value: boolean;
    onChange: (value: boolean) => void;
    type: NewCommentValue;
}

const PrivateCommentCheckbox: React.FC<PrivateCommentCheckboxProps> = ({ value, onChange, type }) => {
    const { user } = useContext(AuthContext);
    const isSponsorUser = isSponsor(user);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    };

    return (
        <div className="flex flex-row gap-2 items-center">
            <input
                className="rounded text-indigo-500 hover:cursor-pointer"
                type="checkbox"
                checked={value || isSponsorUser} // Always true for sponsor users
                onChange={handleChange}
                disabled={isSponsorUser} // Disable for sponsor users
            />
            <Tooltip title="Private comments are only viewable by you." placement="top" arrow enterDelay={200}>
                <span>Private {type ?? 'comment'}</span>
            </Tooltip>
        </div>
    );
};

export default PrivateCommentCheckbox;

import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { ReactNode, useCallback, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import Logger from '@util/Logger';
import { useFormikContext } from 'formik';
import Button from '@components/Button';
import cn from 'classnames';
import { PhotographIcon } from '@heroicons/react/outline';
import TextAreaField from '@components/forms/TextAreaField';
import { FormikFieldError } from '@components/forms/FieldError';
import { BYTES_IN_MB } from '@util/StringUtil';
import { Alert, AlertTitle } from '@mui/material';
import SimpleTargetsField from '@components/experiments/analyses/assaySummary/SimpleTargetsField';
import { AnalysisParameters } from '@models/AnalysisParameters';
import AsyncTargetPicker from '@components/AsyncTargetPicker';
import { SpreadsheetAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import {
    isAssaySummaryAnalysisParameters,
    isDifferentialExpressionAnalysisParameters,
} from '@models/AnalysisParametersHelpers';
import { SpreadsheetAnalysis } from '@models/analysis/SpreadsheetAnalysis';
import { SpreadsheetAnalysisIcon } from '@/src/components/icons/custom/SpreadsheetAnalysisIcon';

const logger = Logger.make('SpreadsheetAnalysisFormFields');

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters };
const SpreadsheetAnalysisFormFields = ({ plot, experiment, analysisParameters }: Props) => {
    const form = useFormikContext<SpreadsheetAnalysisFormValues>();
    const [fileError, setFileError] = useState<ReactNode | null>(null);

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
        logger.info('dropzone onDrop triggered', acceptedFiles, fileRejections);

        if (fileRejections?.length > 0) {
            // handle rejections;
            const messages = new Set<string>();
            fileRejections
                .flatMap((rejection) => rejection.errors)
                .forEach((error) => {
                    switch (error.code) {
                        case ErrorCode.TooManyFiles:
                            messages.add('You may only select one file');
                            break;
                        case ErrorCode.FileInvalidType:
                            messages.add(
                                'File type not supported. Only .csv, .tsv, .xls, .xlsm, and .xlsx files are allowed.',
                            );
                            break;
                        default:
                            messages.add(error.message);
                            break;
                    }
                });
            setFileError(
                <>
                    <AlertTitle>Error adding spreadsheet</AlertTitle>
                    <ul className="list-disc">
                        {[...messages].map((message) => (
                            <li key={message}>{message}</li>
                        ))}
                    </ul>
                </>,
            );
            return [];
        }
        setFileError(null);

        const spreadsheetFile = acceptedFiles[0]; // Directly use the File object
        if (!spreadsheetFile) {
            return;
        }
        logger.info('Spreadsheet file selected', spreadsheetFile);
        form.setFieldValue('spreadsheet', spreadsheetFile); // Store the File object directly

        return [spreadsheetFile];
    }, []);

    const dropzone = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        disabled: false,
        accept: {
            'text/csv': ['.csv'],
            'text/tab-separated-values': ['.tsv'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
        },
        maxFiles: 1,
        maxSize: BYTES_IN_MB * 100,
        multiple: false,
    });

    const analysis = plot.analysis as SpreadsheetAnalysis | null;
    const spreadsheetFile = form.values.spreadsheet ?? analysis?.spreadsheet_url;

    let fileNameDisplay;
    if (spreadsheetFile instanceof File) {
        fileNameDisplay = `File loaded: ${spreadsheetFile.name}`;
    } else if (typeof spreadsheetFile === 'string') {
        fileNameDisplay = 'Spreadsheet file selected';
    }

    return (
        <div className="space-y-8">
            {fileError && (
                <Alert severity="error" onClose={() => setFileError(null)}>
                    {fileError}
                </Alert>
            )}
            <div
                className={cn('flex max-h-[250px] flex-col justify-center rounded-2xl', {
                    'items-center bg-indigo-50': dropzone.isDragActive,
                    'min-h-[250px] border border-dashed border-indigo-400': !spreadsheetFile || dropzone.isDragActive,
                })}
                {...dropzone.getRootProps()}
            >
                <input {...dropzone.getInputProps()} />
                {dropzone.isDragActive ? (
                    <div className="flex flex-col items-center justify-center text-center">
                        <PhotographIcon width={48} className="text-indigo-600" />
                        <p className="text-xl font-semibold">Drop spreadsheet here</p>
                    </div>
                ) : spreadsheetFile ? (
                    <div className="space-y-2 text-center">
                        <p className="text-lg font-medium">{fileNameDisplay}</p>
                        <Button
                            onClick={dropzone.open}
                            startIcon={<PhotographIcon width={24} />}
                            variant="outlined"
                            color="primary"
                            size="small"
                        >
                            Change spreadsheet
                        </Button>
                    </div>
                ) : (
                    <div className="space-y-2">
                        <p className="text-center">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={dropzone.open}
                                startIcon={<SpreadsheetAnalysisIcon className="text-white" width={24} />}
                            >
                                Choose a spreadsheet
                            </Button>
                        </p>
                        <p className="text-center">Select a spreadsheet for analysis</p>
                    </div>
                )}
                <FormikFieldError name="spreadsheet" />
            </div>
            <div>
                <TextAreaField name="methods" label="Methods" minRows={4} />
            </div>

            {experiment.type.shortname === 'simple' && isAssaySummaryAnalysisParameters(analysisParameters) && (
                <SimpleTargetsField name="targets" analysisParameters={analysisParameters} />
            )}
            {experiment.type.shortname !== 'simple' &&
                isDifferentialExpressionAnalysisParameters(analysisParameters) && (
                    <AsyncTargetPicker experiment={experiment} name="targets" />
                )}
        </div>
    );
};

export default SpreadsheetAnalysisFormFields;

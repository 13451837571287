import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { FormikFieldError } from '@components/forms/FieldError';
import React, { ReactChild, ReactNode, useState } from 'react';
import { useField } from 'formik';
import { isBlank, isNotBlank } from '@util/StringUtil';
import { PrismPreviewGraph } from '@models/analysis/PrismAnalysis';
import { isDefined } from '@util/TypeGuards';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { BaseAnalysisParameters } from '@/src/models/AnalysisParameters';

const NONE_VALUE = 'none';
const RenderValue = ({
    loading,
    graphs,
    id,
}: {
    graphs?: PrismPreviewGraph[];
    loading?: boolean;
    id: string | null;
}) => {
    const selectedValue = graphs?.find((s) => s.id === id)?.title;

    if (!isBlank(selectedValue) && selectedValue !== NONE_VALUE) {
        return <span className="text-dark">{selectedValue}</span>;
    }

    return <span className="opacity-70">{loading ? 'Loading...' : 'Select a graph...'}</span>;
};

type Props = {
    experiment: Experiment;
    plot: Plot;
    name: string;
    label?: ReactNode;
    tooltip?: { title: NonNullable<ReactNode>; href?: string | null; icon?: ReactChild | null } | null;
};

const PrismGraphPickerField = ({ experiment, plot, name, label = 'Graph to show', tooltip }: Props) => {
    const { data, error: graphsError } = useSWR<BaseAnalysisParameters>(() =>
        Endpoints.lab.experiment.plot.analysisParameters({ experimentId: experiment.uuid, plotId: plot.uuid }),
    );
    const graphs: PrismPreviewGraph[] = data?.preview_graphs ?? [];
    const loading = !graphs && !graphsError;
    const [helpers, { value, error, touched }] = useField<string>(name);
    const [formValue, setFormValue] = useState(isBlank(value) ? NONE_VALUE : value);

    let $tooltip: ReactNode | null = null;
    if (isDefined(tooltip)) {
        const $icon = tooltip.icon ?? <QuestionMarkCircleIcon className="h-4 w-4" />;
        const $child = isNotBlank(tooltip.href) ? (
            <a href={tooltip.href} target="_blank" rel="nofollow noreferrer">
                {$icon}
            </a>
        ) : (
            $icon
        );
        $tooltip = (
            <Tooltip title={tooltip.title} placement="right" arrow>
                <span>{$child}</span>
            </Tooltip>
        );
    }

    return (
        <label className="form-field">
            <span className="field-label flex items-center space-x-2">
                <span>{label}</span>
                {$tooltip}
            </span>

            <FormControl variant="outlined" fullWidth error={!!error && touched} disabled={loading}>
                <Select
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    margin="dense"
                    name={name}
                    value={loading ? 'loading' : formValue}
                    onChange={(e: any) => {
                        helpers.onChange(e);
                        setFormValue(e.target.value);
                    }}
                    placeholder="Select a comparison"
                    renderValue={(id: string) => <RenderValue loading={loading} graphs={graphs} id={id} />}
                >
                    <MenuItem value={NONE_VALUE} hidden className="!hidden">
                        Nothing selected
                    </MenuItem>
                    {loading && <MenuItem value="loading">Loading...</MenuItem>}
                    {!loading &&
                        graphs?.map((graph) => (
                            <MenuItem key={graph.id} value={graph.id}>
                                <div>
                                    <span className="block ">{graph.title}</span>
                                </div>
                            </MenuItem>
                        ))}
                </Select>
                <FormikFieldError name={name} />
            </FormControl>
        </label>
    );
};

export default PrismGraphPickerField;

import { ArrowPlotData, GenericCellData, isArrowPlotData, TabularData } from '@models/ExperimentData';
import {
    CellProps,
    Column,
    HeaderProps,
    PluginHook,
    Renderer,
    TableOptions,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { numberWithCommas } from '@util/StringUtil';
import { isDefined } from '@util/TypeGuards';
import { FilterIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import { IconButton } from '@mui/material';
import TextInput from '@components/forms/TextInput';
import Button from '@components/Button';
import DefaultTableCell from '@components/dataTable/DefaultTableCell';
import TableHeaderIcon from '@components/dataTable/TableHeaderIcon';
import TablePaginationFooter from '@components/dataTable/TablePaginationFooter';
import { useDebounce } from 'react-use';
import CombinedDataTable from './CombinedData';
import { ArrowData } from '@/src/util/ObjectUtil';

export const programmaticColumns = ['selection', 'action', 'r1_fastq', 'r2_fastq'];

export type DataTableProps<D extends GenericCellData = GenericCellData> = {
    data: TabularData<D> | ArrowPlotData<D>;
    data_hash?: string;
    tableColor?: string;
    sortable?: boolean;
    filterable?: boolean;
    moreMenuButton?: ReactNode;
    headerRenderer?: (header: string, index: number) => Renderer<HeaderProps<D>> | undefined;
    cellRenderer?: (header: string, index: number) => Renderer<CellProps<D>> | undefined;
    onRowSelected?: (row: D) => void;
    onSortedChange?: (header: string, desc?: boolean) => void;
    sortBy?: string;
    sortDesc?: boolean;
    /** Set to TRUE if the data is already sorted, such as via the API.
     * If FALSE, this component will attempt to sort the data on the client,
     * which can result in unexpected behavior with numeric values in particular */
    manualSortBy?: boolean;
    minHeight?: string;
    pageSize?: number;
    showSummaryStats?: boolean;
    viewDataOnly?: boolean;
    isSorting?: boolean;
    showColumnLimit?: boolean;
};

const getHeaderString = (h: string, item: GenericCellData) => {
    // Support legacy venn diagram headers
    if (h === 'set' && item?.sets) {
        return 'sets';
    }
    if (h === 'sets' && item?.set) {
        return 'set';
    }
    return h;
};
const filterHeaders = (h: string, item: GenericCellData) => {
    // Support legacy venn diagram headers
    if (h === 'gene_symbols' && !item?.gene_symbols) {
        return false;
    }
    return true;
};

const DataTable = ({
    data,
    data_hash,
    tableColor = 'bg-secondary text-white',
    sortable,
    filterable,
    moreMenuButton,
    headerRenderer,
    cellRenderer,
    onRowSelected,
    sortBy,
    sortDesc = false,
    onSortedChange,
    manualSortBy = false,
    pageSize,
    showSummaryStats,
    viewDataOnly = false,
    isSorting = false,
    showColumnLimit = false,
}: DataTableProps) => {
    const [query, setQuery] = useState('');
    const [filterValue, setFilterValue] = useState<string | null>(null);

    const columns: Column<GenericCellData>[] = useMemo(
        () =>
            data.headers
                .filter((h) => (Array.isArray(data.items) ? filterHeaders(h, data.items?.[0]) : true))
                .map((h, i) => {
                    const j = Array.isArray(data.items) ? getHeaderString(h, data.items[0]) : h;
                    return {
                        Header: headerRenderer?.(j, i) ?? (
                            <div style={{ cursor: sortable ? 'pointer' : 'default' }}>
                                <TableHeaderIcon text={j} sortable={sortable} />
                            </div>
                        ),
                        id: j,
                        accessor: (cell: GenericCellData) => cell[j],
                        Cell: cellRenderer?.(j, i) ?? DefaultTableCell,
                        enableMultiSort: false,
                    };
                }),
        [data.headers, sortable, headerRenderer, cellRenderer, data.items],
    );

    const [increasedCount, setIncreasedCount] = useState(0);
    const [decreasedCount, setDecreasedCount] = useState(0);

    const tableData = useMemo<GenericCellData[]>(() => {
        if (isArrowPlotData(data)) {
            const dataItems = data.items as ArrowData<GenericCellData>;
            const keys = Object.keys(dataItems);
            const items: GenericCellData[] = [];
            const firstKey = keys[0];
            const length = dataItems[firstKey].length;
            for (let i = 0; i < Math.min(length); i++) {
                items.push(
                    keys.reduce<GenericCellData>((item, key) => {
                        item[key] = dataItems[key]?.[i];
                        return item;
                    }, {}),
                );
            }
            return items;
        } else {
            return data.items;
        }
    }, [data_hash ?? isArrowPlotData(data), data.count, pageSize, showSummaryStats, isSorting]);

    useEffect(() => {
        let incCount = 0;
        let decCount = 0;
        tableData.forEach((row) => {
            const foldChange = row['Log2_Fold_Change'];
            if (typeof foldChange === 'number') {
                if (foldChange > 0) incCount++;
                if (foldChange < 0) decCount++;
            }
        });
        setIncreasedCount(incCount);
        setDecreasedCount(decCount);
    }, [tableData]);

    const queryFilter = useMemo(() => {
        return query;
    }, [query]);

    const initialStateProp: TableOptions<GenericCellData>['initialState'] = {};
    if (sortBy) {
        initialStateProp.sortBy = [{ id: sortBy, desc: sortDesc }];
    }
    if (isDefined(pageSize)) {
        initialStateProp.pageIndex = 0;
        initialStateProp.pageSize = pageSize;
    }

    const tableOptions: TableOptions<GenericCellData> = {
        columns,
        data: tableData,
        initialState: initialStateProp,
        manualSortBy: manualSortBy,
    };

    const plugins: PluginHook<GenericCellData>[] = [];
    if (filterable) {
        plugins.push(useGlobalFilter);
        tableOptions.globalFilter = queryFilter;
    }
    if (sortable) {
        plugins.push(useSortBy);
        tableOptions.disableSortRemove = true;
    }
    const hasPagination = isDefined(pageSize);
    if (hasPagination) {
        plugins.push(usePagination);
    }

    if (onRowSelected) {
        // plugins.push(useRowSelect);
        plugins.push((hooks) => {
            hooks.visibleColumns.push((columns) => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    Header: () => <div></div>,
                    Cell: ({ row }: CellProps<GenericCellData>) => (
                        <div>
                            <Button
                                onClick={() => {
                                    onRowSelected(row.original);
                                }}
                                color="primary"
                                className="z-0"
                            >
                                Select
                            </Button>
                        </div>
                    ),
                },
                ...columns,
            ]);
        });
    }

    const tableInstance = useTable<GenericCellData>(tableOptions, ...plugins);
    const { getTableProps, setGlobalFilter } = tableInstance;
    useDebounce(
        () => {
            setGlobalFilter?.(filterValue || undefined);
        },
        200,
        [filterValue, setGlobalFilter],
    );

    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => {
        if (!filterOpen) {
            setFilterOpen(true);
        } else {
            setFilterOpen(false);
            setQuery('');
            setFilterValue('');
        }
    };

    const tableState = tableInstance.state;
    const sortState = tableState?.sortBy;
    useEffect(() => {
        const firstSort = sortState?.[0];
        if (sortable && firstSort) {
            onSortedChange?.(firstSort.id, firstSort.desc ?? false);
        }
    }, [sortable, sortState, onSortedChange]);

    const renderTable = () => {
        return (
            <div className="w-full h-full">
                <div className="w-full h-full">
                    <div {...getTableProps()} className="rounded-corners relative w-full table-auto border-separate">
                        <CombinedDataTable tableInstance={tableInstance} tableColor={tableColor} />
                    </div>
                </div>
            </div>
        );
    };

    if (viewDataOnly) {
        return renderTable();
    }

    return (
        <div>
            {filterable && (
                <>
                    <div className="mb-4 flex items-start justify-end space-x-4 md:-mt-20 md:pt-1">
                        <div className="rounded-full border border-indigo-100 bg-white">
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                onClick={toggleFilter}
                                className={cn('text-indigo-500')}
                                size="large"
                            >
                                {filterOpen ? (
                                    <XIcon className="h-4 w-4 text-indigo-500" />
                                ) : (
                                    <FilterIcon className="h-4 w-4 text-indigo-500" />
                                )}
                            </IconButton>
                        </div>
                        <div className="rounded-full bg-white">{moreMenuButton}</div>
                    </div>
                    {filterOpen && (
                        <TextInput
                            value={query ?? ''}
                            name="filter"
                            className="mb-4 w-full"
                            onChange={(e) => {
                                setQuery(e.target.value);
                                setFilterValue(e.target.value);
                            }}
                            placeholder="Filter data..."
                            disableFormik
                            iconRight={
                                <div className="flex items-center">
                                    {query && (
                                        <IconButton
                                            sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                            onClick={() => {
                                                setQuery('');
                                                setFilterValue('');
                                            }}
                                            size="small"
                                        >
                                            <XCircleIcon className="h-4 w-4 text-indigo-500" />
                                        </IconButton>
                                    )}
                                </div>
                            }
                        />
                    )}
                </>
            )}
            {showSummaryStats && (
                <div className="my-4">
                    <p>
                        <span className="font-semibold">Overall: </span>
                        Increased Count: {increasedCount.toLocaleString()} <span>&bull;</span> Decreased Count:{' '}
                        {decreasedCount.toLocaleString()}
                    </p>
                </div>
            )}
            {!filterable && moreMenuButton}
            <div className="overflow-hidden rounded-xl">
                {renderTable()}
                {(isDefined(data.count) || data?.items?.length) && (
                    <div>
                        {hasPagination ? (
                            <TablePaginationFooter
                                className="mt-2 px-2"
                                page={tableInstance.state.pageIndex}
                                pageSize={tableInstance.state.pageSize}
                                totalPages={tableInstance.pageCount}
                                nextPage={() => tableInstance.nextPage()}
                                previousPage={() => tableInstance.previousPage()}
                                totalResults={tableInstance.rows.length}
                                setPageIndex={(page: number) => tableInstance.gotoPage(page)}
                                pageOptions={tableInstance.pageOptions}
                            />
                        ) : (
                            <div className="mb-4 ml-2 mt-2 text-default">
                                Showing {numberWithCommas((tableInstance.page ?? tableInstance.rows).length)} of{' '}
                                {numberWithCommas(data.count)} records{' '}
                                {showColumnLimit && <span> & up to 20 columns</span>}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataTable;

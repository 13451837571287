import useSWR from 'swr';
import { AnalysisParameters, AssaySummaryAnalysisParameters, ParameterOption } from '@models/AnalysisParameters';
import Endpoints from '@services/Endpoints';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { isDefined } from '@util/TypeGuards';
import { ApiError } from '@services/ApiError';

export const getAnalysisParametersHelpers = <T extends AnalysisParameters>(analysisParameters?: T | null) => {
    const getTargetById = (targetId: number | string): ParameterOption | null => {
        if (typeof targetId === 'string') {
            return null;
        }
        return (
            (analysisParameters as AssaySummaryAnalysisParameters | null)?.targets?.find((t) => t.id === targetId) ??
            null
        );
    };

    const getVariableById = (variableId: number): ParameterOption | null => {
        return (
            (analysisParameters as AssaySummaryAnalysisParameters | null)?.variables?.find(
                (v) => v.id === variableId,
            ) ?? null
        );
    };

    return {
        getTargetById,
        getVariableById,
    };
};

const useAnalysisParameters = <T extends AnalysisParameters>({
    plot,
    experiment,
    publicKey,
}: {
    plot?: Plot | null;
    experiment?: Experiment | null;
    publicKey?: string | null;
}): {
    loading: boolean;
    analysisParameters: AnalysisParameters | null | undefined;
    analysisParametersError: ApiError | undefined | null;
} & ReturnType<typeof getAnalysisParametersHelpers> => {
    const { data: analysisParameters, error: analysisParametersError } = useSWR<T, ApiError>(() =>
        plot?.uuid && experiment?.uuid
            ? Endpoints.lab.experiment.plot.analysisParameters(
                  {
                      experimentId: experiment.uuid,
                      plotId: plot.uuid,
                  },
                  { key: publicKey ?? undefined },
              )
            : null,
    );

    const utils = getAnalysisParametersHelpers(analysisParameters);

    return {
        analysisParameters: analysisParameters,
        analysisParametersError,
        loading: !isDefined(analysisParameters) && !isDefined(analysisParametersError),
        ...utils,
    };
};
export default useAnalysisParameters;

import { getD3Symbol, getTargetSymbol, LineConfig, LinePlotDatum } from '@components/plots/PlotTypes';
import BasePlotBuilder, { ConstructorParams, PlotMargin } from '@components/plots/builders/BasePlotBuilder';
import { ExperimentData } from '@models/ExperimentData';
import {
    DATAPOINT_SYMBOL_SIZE,
    getAssaySummaryYAxisTitle,
    rotateXAxisLabels as drawRotatedXAxisLabels,
    wrapTextNode,
    X_AXIS_PADDING_FACTOR,
    Y_AXIS_PADDING_FACTOR,
} from '@components/plots/PlotUtil';
import LinePlotDisplayOption from '@models/plotDisplayOption/LinePlotDisplayOption';
import { LongitudinalAnalysis } from '@models/analysis/LongitudinalAnalysis';
import {
    AXIS_LABEL_CLASSNAMES,
    AXIS_LABEL_PUBLICATION_CLASSNAMES,
    AXIS_TITLE_CLASSNAMES,
    AXIS_TITLE_PUBLICATION_CLASSNAMES,
    ErrorBarOption,
    ThemeStyle,
} from '@models/PlotConfigs';
import * as d3 from 'd3';
import {
    axisBottom,
    axisLeft,
    BaseType,
    max,
    mean,
    min,
    ScaleBand,
    scaleBand,
    scaleLinear,
    ScaleLinear,
    ScaleSymLog,
    Selection,
} from 'd3';
import { isDefined, isNumber, isString } from '@util/TypeGuards';
import { blankToNull, formatTableHeader, roundToDecimal } from '@util/StringUtil';
import Logger from '@util/Logger';
import { getPlotPalette } from '@components/ColorPaletteUtil';
import { pluralize } from '@util/ObjectUtil';
import cn from 'classnames';
import { AnalysisParameters, ParameterOption } from '@models/AnalysisParameters';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

const logger = Logger.make('LinePlotBuilder');

const NULL_GROUP_ID = -1;

type ErrorBarValue = { top: number; bottom: number; value: number };
type ErrorBarPoint = Record<ErrorBarOption, ErrorBarValue>;
const errorBarWhiskerWidth = 10;
const errorBarStrokeWidth = 2;
const errorBarSpacing = 1;
const errorBarMaxJitterPx = 4;
const errorBarJitterEnabled = false;
const HIDE_X_AXIS_DOMAIN_WHEN_NEGATIVE = false;
type LinePoint = {
    x: number;
    y: number;
    target_name: string;
    target_id: string | number;
    group_id: number | null;
    group_name: string | null;
    sample_count: number;
    error_bars?: ErrorBarPoint;
};
type LinePlotConstructorArgs = ConstructorParams<ExperimentData<LinePlotDatum>> & {
    analysisParameters: AnalysisParameters | null;
    stylingOptions: CustomPlotStylingOptions | null;
};

export default class LinePlotBuilder extends BasePlotBuilder<ExperimentData<LinePlotDatum>> {
    // data: ExperimentData<LinePlotDatum>;
    stylingOptions: CustomPlotStylingOptions | null;
    samplesByX: Record<string, LinePlotDatum[]> = {};
    sortedGroups: { group_id: number; group_name: string }[] = [];
    dataByTargetAndGroup: Record<string, Record<number, LinePlotDatum[]>> = {};
    lineData: LinePoint[][] = [];
    scales!: {
        yScale: ScaleLinear<number, number> | ScaleSymLog<number, number>;
        xScale: ScaleBand<string>;
        xScaleLinear: ScaleLinear<number, number>;
    };
    sortedTargets: (string | number)[] = [];
    analysisParameters: AnalysisParameters | null;
    xLinear = true;

    constructor(options: LinePlotConstructorArgs) {
        super(options);
        this.analysisParameters = options.analysisParameters;
        this.makeScales();
        this.stylingOptions = options.stylingOptions;
    }

    get clipPathId() {
        return `${this.idPrefix}${this.plot.uuid}-clip`;
    }

    getXVariable(): ParameterOption | null {
        const analysis = this.analysis;
        const analysisParameters = this.analysisParameters;
        const xVariable = isDefined(analysis?.x_axis_variable_id)
            ? analysisParameters?.variables?.find((v) => v.id === analysis?.x_axis_variable_id)
            : undefined;

        return xVariable ?? null;
    }

    getTargetIndex(d: Pick<LinePlotDatum, 'target_id'>) {
        return this.sortedTargets.indexOf(d.target_id);
    }

    getDotSymbol(d: Pick<LinePlotDatum, 'target_id'>) {
        return getTargetSymbol({ target_id: d.target_id, sortedTargets: this.sortedTargets });
    }

    getGroupIndex(d: Pick<LinePlotDatum, 'group_id'>) {
        if (d.group_id) {
            return this.sortedGroups.findIndex((g) => g.group_id === d.group_id);
        }
        return 0;
    }

    getGroupColor(d: Pick<LinePlotDatum, 'group_id'>) {
        const { theme_color } = this.displayOptions;
        const { colors: barColors } = getPlotPalette(theme_color);

        if (!isDefined(d.group_id)) {
            return this.displayOptions.custom_color_json?.['all'] ?? barColors[0].color;
        }
        const customColor = this.displayOptions.custom_color_json?.[d.group_id];
        if (isDefined(customColor)) {
            return customColor;
        }

        const groupIndex = this.getGroupIndex(d);

        const index = groupIndex >= 0 ? groupIndex : barColors.length - 1;
        return barColors[index % barColors.length]?.color;
    }

    calculateMargins(): PlotMargin {
        return { top: 10, bottom: 40, left: 70, right: 10 };
    }

    get displayOptions(): LinePlotDisplayOption {
        return this.plot.display as LinePlotDisplayOption;
    }

    get analysis(): LongitudinalAnalysis | null {
        return this.plot.analysis as LongitudinalAnalysis | null;
    }

    get maxSampleValue() {
        return max(this.data.items ?? [], (d) => d.y_value ?? 0) ?? 0;
    }

    get minSampleValue() {
        return min(this.data.items, (d) => d.y_value ?? 0) ?? 0;
    }

    // TODO: Calculate real error bar values
    getErrorBarDomain(): { bottom?: number; top?: number } {
        const showErrorBars = this.displayOptions?.show_error_bars ?? false;
        if (!showErrorBars) {
            return {};
        }
        const errorBarType = this.displayOptions?.error_bars_option;

        const allBars = this.lineData.flatMap((array) => array.map((d) => d.error_bars)).filter(isDefined);

        const maxTop = max(allBars, (bar) => {
            switch (errorBarType) {
                case 'sd':
                    return bar.sd.top;
                case 'sem':
                    return bar.sem.top;
                default:
                    return undefined;
            }
        });
        const minBottom = min(allBars, (bar) => {
            switch (errorBarType) {
                case 'sd':
                    return bar.sd.bottom;
                case 'sem':
                    return bar.sem.bottom;
                default:
                    return undefined;
            }
        });

        const displayOptions = this.displayOptions;

        const errorBarLocations = displayOptions?.error_bars_locations ?? [];

        return {
            top: errorBarLocations.includes('top') ? maxTop : undefined,
            bottom: errorBarLocations.includes('bottom') ? minBottom : undefined,
        };
    }

    get yDomain(): { yMin: number; yMax: number } {
        const { top: yErrorBarTop = 0, bottom: yErrorBarBottom = 0 } = this.getErrorBarDomain();

        const yMin =
            this.displayOptions.y_axis_start ??
            Math.min(this.minSampleValue, yErrorBarBottom, 0) * Y_AXIS_PADDING_FACTOR;

        const yMax =
            this.displayOptions.y_axis_end ?? Math.max(yErrorBarTop, this.maxSampleValue, 0) * Y_AXIS_PADDING_FACTOR;
        return { yMin, yMax };
    }

    get yAxisTitle() {
        return getAssaySummaryYAxisTitle({
            experiment: this.experiment,
            analysisType: this.plot.analysis?.analysis_type,
            options: this.displayOptions,
        });
    }

    get yAxisFormat() {
        const { yMax } = this.yDomain;
        return yMax > 1_000_000 ? '.1e' : ',f';
    }

    protected getYScale = () => {
        const margin = this.margin;
        const height = this.height;
        const scale = this.displayOptions.y_axis_scale ?? 'linear';
        const { yMin, yMax } = this.yDomain;
        switch (scale) {
            case 'symlog':
                return d3
                    .scaleSymlog()
                    .domain([yMin, yMax])
                    .rangeRound([height - margin.bottom, margin.top]);
            case 'linear':
            default:
                return d3
                    .scaleLinear()
                    .domain([yMin, yMax])
                    .rangeRound([height - margin.bottom, margin.top]);
        }
    };

    getXAxisValuesString(): string[] {
        const values = new Set(this.data.items?.map((item) => item.x_value) ?? []);
        const sortedValues = Array.from(values);
        sortedValues.sort((v1, v2) => v1 - v2);
        return sortedValues.map((v) => `${v}`);
    }

    getXAxisValuesNumeric(): number[] {
        const values = new Set(this.data.items?.map((item) => item.x_value) ?? []);
        const sortedValues = Array.from(values).map(Number);
        sortedValues.sort((v1, v2) => v1 - v2);
        return sortedValues;
    }

    getXValue = (d: string | number | Partial<Pick<LinePlotDatum & LinePoint, 'x_value' | 'x'>>) => {
        let value: number | string | undefined;
        if (isString(d) || isNumber(d)) {
            value = d;
        } else {
            value = d.x ?? d.x_value;
        }

        if (!isDefined(value)) {
            return undefined;
        }
        const stringValue = isString(value) ? value : `${value}`;

        if (this.xLinear) {
            return this.scales.xScaleLinear(Number(value));
        }
        return (this.scales.xScale(stringValue) ?? 0) + this.scales.xScale.bandwidth() / 2;
    };

    protected makeScales = () => {
        // const firstTargetGroups = this.firstTargetGroups;
        const margin = this.margin;
        const xValues = this.getXAxisValuesString();
        const width = this.width;
        const yScale = this.getYScale();
        const xScale = scaleBand()
            .domain(xValues)
            .range([margin.left, width - margin.right])
            .padding(0.2);

        const xValuesNumeric = this.getXAxisValuesNumeric();
        const [xMin] = xValuesNumeric;
        const xMax = xValuesNumeric[xValuesNumeric.length - 1] * X_AXIS_PADDING_FACTOR;

        const xScaleLinear = scaleLinear()
            .domain([this.displayOptions?.x_axis_start ?? xMin, this.displayOptions.x_axis_end ?? xMax])
            .range([margin.left, width - margin.right]);
        this.scales = { yScale, xScale, xScaleLinear };
        return this.scales;
    };

    get xAxisLabelRotation() {
        if (this.publicationMode) {
            return 45;
        }
        return 0;
    }

    makeLogYAxisTickValues = ({ yMax, yMin }: { yMax: number; yMin: number }) => {
        const magnitude_positive = Math.floor(Math.log10(yMax));
        const magnitude_negative = Math.ceil(Math.log10(Math.abs(yMin)));
        const logTickValues: number[] = [];
        const majorTicks: number[] = [0, 1];
        for (let j = 0; j < 10; j++) {
            logTickValues.push(Number((0.1 * j).toFixed(1)));
        }

        // Positive values
        for (let i = 0; i <= magnitude_positive; i++) {
            const currentMagnitude = Math.pow(10, i + 1);

            if (currentMagnitude > 1) {
                const stepVal = currentMagnitude / 10;
                for (let j = 1; j < 10; j++) {
                    const minorValue = stepVal * j;
                    if (minorValue < yMax) {
                        logTickValues.push(minorValue);
                    }
                }
            }
            if (currentMagnitude < yMax) {
                logTickValues.push(currentMagnitude);
                majorTicks.push(currentMagnitude);
            }
        }
        if (Math.ceil(yMax) > 1.1 * majorTicks[majorTicks.length - 1]) {
            majorTicks.push(Math.ceil(yMax));
        }

        logTickValues.push(Math.ceil(yMax));

        // Negative values
        if (yMin < 0) {
            for (let j = 0; j < 10; j++) {
                logTickValues.unshift(Number((-0.1 * j).toFixed(1)));
            }
            majorTicks.unshift(-1);

            for (let i = 0; i <= magnitude_negative; i++) {
                const currentMagnitude = Math.pow(10, i + 1);
                if (currentMagnitude > 1) {
                    const stepVal = currentMagnitude / 10;
                    for (let j = 1; j < 10; j++) {
                        const minorValue = stepVal * j * -1;
                        if (minorValue > yMin) {
                            logTickValues.unshift(minorValue);
                        }
                    }
                }
                if (currentMagnitude < Math.abs(yMin)) {
                    logTickValues.unshift(currentMagnitude * -1);
                    majorTicks.unshift(currentMagnitude * -1);
                }
            }

            if (Math.floor(yMin) < 1.1 * majorTicks[0]) {
                majorTicks.unshift(Math.floor(yMin));
            }

            logTickValues.unshift(Math.floor(yMin));
        }

        return { tickValues: logTickValues, majorTicks };
    };

    appendYAxis = () => {
        const styles = this.stylingOptions?.yaxis;
        const { yScale } = this.scales;
        const { yMax, yMin } = this.yDomain;
        const height = this.height;
        const margin = this.margin;
        const yAxisTitle = this.yAxisTitle;
        const yAxisFormat = this.yAxisFormat;

        const publicationMode = this.publicationMode;

        const { tickValues, majorTicks } =
            isDefined(this.displayOptions.y_axis_scale) && this.displayOptions.y_axis_scale !== 'linear'
                ? this.makeLogYAxisTickValues({
                      yMax,
                      yMin,
                  })
                : { tickValues: null, majorTicks: [] };

        const drawYAxis = (g: Selection<SVGGElement, unknown, d3.BaseType, undefined>) => {
            const yAxisConfig = axisLeft(yScale).ticks(null, yAxisFormat).tickSizeOuter(0);
            if (tickValues) {
                yAxisConfig.tickValues(tickValues).tickFormat((domainValue: d3.NumberValue) => {
                    const value = domainValue.valueOf();
                    if (majorTicks.includes(value)) {
                        return `${value}`;
                    }
                    return '';
                });
            }
            return g
                .call((g) => g.select('.domain').remove())
                .attr('transform', `translate(${margin.left},0)`)
                .attr('class', `${publicationMode ? AXIS_LABEL_PUBLICATION_CLASSNAMES : AXIS_LABEL_CLASSNAMES} y-axis`)
                .call(yAxisConfig)
                .call((g) => {
                    const yAxisWidth = g.node()?.getBoundingClientRect().width ?? 100;
                    const textRoot = g
                        .append('text')
                        .attr('x', -(height - margin.bottom) / 2)
                        // .attr('y', -yAxisWidth)
                        .attr('fill', styles ? styles.fontColor : 'currentColor')
                        .style('font-size', styles ? styles.fontSize : '18')
                        .style('font-family', styles ? styles.fontFamily : 'Arial')
                        .attr('text-anchor', 'middle')
                        .attr('transform', 'rotate(-90)')
                        .attr(
                            'class',
                            cn(
                                'y-axis-title',
                                this.publicationMode ? AXIS_TITLE_PUBLICATION_CLASSNAMES : AXIS_TITLE_CLASSNAMES,
                            ),
                        )
                        .text(`${yAxisTitle ?? ''}`)
                        .call((node) => wrapTextNode(node, height - margin.bottom - margin.top));

                    const titleWidth = textRoot?.node()?.getBoundingClientRect().width || 0;
                    const textYOffset = -(yAxisWidth + titleWidth - 12);
                    textRoot.attr('y', textYOffset).selectAll('tspan').attr('y', textYOffset);

                    return g;
                });
        };
        this.svg.selectAll('.y-axis').remove();
        this.svg.append('g').call(drawYAxis);
    };

    get xAxisTitle() {
        return blankToNull(this.displayOptions?.x_axis_title) ?? this.getXVariable()?.display_name ?? '';
    }

    appendXAxis = () => {
        const styles = this.stylingOptions?.xaxis;
        const { xScale, xScaleLinear } = this.scales;
        const height = this.height;
        const width = this.width;
        const margin = this.margin;
        const { yMin } = this.yDomain;
        const xAxisTitle = this.xAxisTitle;
        let labelRotation = this.xAxisLabelRotation;
        const publicationMode = this.publicationMode;
        const drawXAxis = (g: Selection<SVGGElement, unknown, BaseType, unknown>) => {
            g.attr('transform', `translate(0,${height - margin.bottom})`)
                .attr('class', `${publicationMode ? AXIS_LABEL_PUBLICATION_CLASSNAMES : AXIS_LABEL_CLASSNAMES} x-axis`)
                .call(
                    this.xLinear
                        ? axisBottom(xScaleLinear).ticks(null).tickSizeOuter(0)
                        : axisBottom(xScale)
                              .tickSize(12)
                              .tickSizeOuter(0)
                              .tickFormat((label) => formatTableHeader(label)),
                );
            const labels = g.selectAll<SVGTextElement, unknown>('.tick text');

            if (
                !this.xLinear &&
                (xScale.bandwidth() < 35 ||
                    labels.nodes().some((node) => (node.getBoundingClientRect()?.width ?? 0) >= xScale.bandwidth()))
            ) {
                labelRotation = 45;
            }

            if (labelRotation) {
                labels.call(wrapTextNode, 160);
                drawRotatedXAxisLabels(g, labelRotation);
            } else {
                labels.call(wrapTextNode, xScale.bandwidth());
            }

            if (yMin < 0 && HIDE_X_AXIS_DOMAIN_WHEN_NEGATIVE) {
                g.select('.domain').remove();
            }

            const xAxisHeight = g.node()?.getBoundingClientRect().height ?? 100;
            return g
                .append('text')
                .attr('x', (width + margin.left) / 2)
                .attr('y', xAxisHeight + 24)
                .attr('fill', styles ? styles.fontColor : 'currentColor')
                .style('font-size', styles ? styles.fontSize : '18')
                .style('font-family', styles ? styles.fontFamily : 'Arial')
                .attr('text-anchor', 'middle')
                .attr(
                    'class',
                    cn(
                        this.publicationMode ? AXIS_TITLE_PUBLICATION_CLASSNAMES : AXIS_TITLE_CLASSNAMES,
                        'x-axis-title',
                    ),
                )
                .text(`${xAxisTitle ?? ''}`);
        };
        this.svg.selectAll('.x-axis').remove();
        this.svg.selectAll('.x-axis-title').remove();
        this.svg.append('g').call(drawXAxis);
    };

    getTooltipContent = (d: LinePlotDatum): string => {
        return `
<span class="block font-semibold text-dark">${d.sample_id}</span>
<span class="block text-sm text-gray-600">x: ${roundToDecimal(d.x_value, { decimals: 4 })}</span>
<span class="block text-sm text-gray-600">y: ${roundToDecimal(d.y_value, { decimals: 4 })}</span>
<span class="block text-sm text-gray-600">group: ${d.group_name}</span>
<span class="block text-sm text-gray-600">target: ${d.target_name}</span>
`;
    };

    getLineTooltipContent = (d: LinePoint): string => {
        return `
<span class="block font-semibold text-dark">${d.group_name ?? 'All samples'}</span>
<span class="block text-sm text-gray-600">target: ${d.target_name}</span>
<span class="block text-sm text-gray-600">group mean (${d.sample_count.toLocaleString()} ${pluralize(
            d.sample_count,
            'sample',
            'samples',
        )})</span>

<span class="block text-sm text-gray-600">x: ${roundToDecimal(d.x, { decimals: 4 })}</span>
<span class="block text-sm text-gray-600">y: ${roundToDecimal(d.y, { decimals: 4 })}</span>
`;
    };

    drawDataPoints = () => {
        const svg = this.svg;
        const options = this.displayOptions;
        const themeStyle = options.theme_style;
        const allSamples = this.data.items ?? [];
        const { yMin, yMax } = this.yDomain;
        const { yScale } = this.scales;
        const xValue = this.getXValue;
        const tooltipContainer = this.tooltip;
        const getLineColor = (d: LinePlotDatum) => this.getLineColor(d);

        // Draw data points. First, remove any existing ones. Filter out points outside of max/min range
        svg.selectAll('.data-point').remove();
        if (options.show_data_points) {
            svg.append('g')
                .attr('clip-path', `url(#${this.clipPathId})`)
                .selectAll('g')

                .data(allSamples.filter((s) => s.y_value >= yMin && s.y_value <= yMax))
                .enter()
                .append('path')
                .attr('d', (d) => {
                    return d3
                        .symbol()
                        .type(getD3Symbol(this.getDotSymbol(d)))
                        .size(DATAPOINT_SYMBOL_SIZE)();
                })

                .style('fill-opacity', '.75')
                .attr('fill', (d) => (themeStyle !== ThemeStyle.outline ? 'white' : (this.getLineColor(d) ?? '')))
                .attr('stroke', (d) => this.getLineColor(d) ?? '')
                .attr('stroke-width', themeStyle !== ThemeStyle.outline ? 1 : 1)
                .attr('transform', (d) => {
                    const y = yScale(d.y_value);
                    const x = xValue(d) ?? 0;
                    return `translate(${x}, ${y})`;
                })
                .on('mouseover', (event, d) => {
                    const circle = d3.select(event.target);
                    circle.style('cursor', 'crosshair').attr('class', '').attr('stroke', 'red').attr('fill', 'red');
                    tooltipContainer.transition().duration(50).style('opacity', 1);
                    tooltipContainer
                        .html(this.getTooltipContent(d))
                        .style('left', `${event.pageX + 10}px`)
                        .style('top', `${event.pageY - 10}px`);
                    event.parentNode?.appendChild(circle);
                })
                .on('mouseout', function (event, d) {
                    const circle = d3.select(event.target);
                    circle
                        .style('fill-opacity', '.75')
                        .attr('stroke', getLineColor(d) ?? '')
                        .attr('fill', themeStyle !== ThemeStyle.outline ? 'white' : (getLineColor(d) ?? ''));
                    tooltipContainer.transition().style('opacity', 0);
                });
        }
    };

    getLineColor(d: Pick<LinePlotDatum, 'group_id'>) {
        return this.getGroupColor(d);
    }

    drawYZeroLine = () => {
        const { yScale, xScale } = this.scales;
        const [xMin, xMax] = xScale.range();
        this.svg
            .append('g')
            .append('line')
            .attr('stroke', '#333')
            .attr('stroke-width', 1)
            .attr('x1', xMin)
            .attr('x2', xMax)
            .attr('y1', yScale(0) + 0.5)
            .attr('y2', yScale(0) + 0.5);
    };

    drawLine({
        lineConfig,
        svg,
        items,
        color,
        strokeWidth = 1,
    }: {
        lineConfig: LineConfig<LinePoint>;
        items: LinePoint[];
        svg: Selection<SVGGElement, unknown, BaseType, unknown>;
        color: string | undefined;
        strokeWidth?: number;
    }) {
        svg.append('g')
            .selectAll('g')
            .data(items)
            .enter()
            .append('line')
            .attr('stroke', color ?? '')
            .attr('stroke-width', strokeWidth)
            .attr('pointer-events', 'none')
            .attr('x1', lineConfig.x1)
            .attr('x2', lineConfig.x2)
            .attr('y1', lineConfig.y1)
            .attr('y2', lineConfig.y2);
    }

    drawGroupLines() {
        const { yScale } = this.scales;
        const svg = this.svg
            .append('g')
            .attr('class', 'plot-lines-group')
            .attr('clip-path', `url(#${this.clipPathId})`);
        const tooltipContainer = this.tooltip;
        const totalGroups = this.sortedGroups.length;
        svg.selectAll('.plot-line').remove();
        svg.selectAll('.plot-line-dot').remove();

        const getX = (d: LinePoint) => this.getXValue(d);

        const drawLineSeries = (lineItems: LinePoint[]) => {
            const groupIndex = this.getGroupIndex(lineItems[0]) ?? 0;
            const line = d3
                .line<LinePoint>()
                .x((d) => getX(d) ?? 0)
                .y((d) => yScale(d.y))
                .curve(d3.curveLinear);
            if (lineItems.length === 0) {
                return;
            }
            const lineColor = this.getLineColor(lineItems[0]);
            // draw the line itself
            svg.append('path')
                .datum(lineItems)
                .attr('d', line)
                .attr('class', 'plot-line')
                .attr('pointer-events', 'none')
                .attr('fill', 'none')
                .attr('stroke', lineColor ?? '')
                .attr('stroke-width', 2);

            // draw error bars
            if (this.displayOptions.show_error_bars && this.displayOptions.error_bars_locations?.length) {
                const errorBarType = this.displayOptions.error_bars_option ?? 'sd';
                const errorLineConfigs: LineConfig<LinePoint>[] = [];
                const offsetDirection = groupIndex < (totalGroups - 1) / 2 ? -1 : 1;

                const spacingDenominator = totalGroups % 2 === 0 ? 2 : 1;

                let errorXOffset =
                    offsetDirection * (groupIndex * (errorBarSpacing + errorBarStrokeWidth)) -
                    (errorBarStrokeWidth + errorBarSpacing) / spacingDenominator;

                if (Math.abs(errorXOffset) > errorBarMaxJitterPx) {
                    errorXOffset = errorXOffset % errorBarMaxJitterPx;
                }
                if (!errorBarJitterEnabled) {
                    errorXOffset = 0;
                }

                if (this.displayOptions.error_bars_locations?.includes('top')) {
                    errorLineConfigs.push(
                        // topWhisker
                        {
                            x1: (d) => {
                                if ((d.error_bars?.[errorBarType].top ?? 0) === 0) {
                                    return getX(d) ?? 0;
                                }
                                return (getX(d) ?? 0) + errorBarWhiskerWidth / 2 + errorXOffset;
                            },
                            x2: (d) => {
                                if ((d.error_bars?.[errorBarType].top ?? 0) === 0) {
                                    return getX(d) ?? 0;
                                }
                                return (getX(d) ?? 0) - errorBarWhiskerWidth / 2 + errorXOffset;
                            },
                            y1: (d) => yScale(d.error_bars?.[errorBarType].top ?? 0),
                            y2: (d) => yScale(d.error_bars?.[errorBarType].top ?? 0),
                        },
                        // vertical line
                        {
                            x1: (d) => (getX(d) ?? 0) + errorXOffset,
                            x2: (d) => (getX(d) ?? 0) + errorXOffset,
                            y1: (d) => yScale(d.error_bars?.[errorBarType].top ?? 0),
                            y2: (d) => yScale(d.y ?? 0),
                        },
                    );
                }

                if (this.displayOptions.error_bars_locations?.includes('bottom')) {
                    // bottomWhisker
                    errorLineConfigs.push(
                        // bottom whisker
                        {
                            x1: (d) => {
                                if ((d.error_bars?.[errorBarType].bottom ?? 0) === 0) {
                                    return getX(d) ?? 0;
                                }
                                return (getX(d) ?? 0) + errorBarWhiskerWidth / 2 + errorXOffset;
                            },
                            x2: (d) => {
                                if ((d.error_bars?.[errorBarType].bottom ?? 0) === 0) {
                                    return getX(d) ?? 0;
                                }
                                return (getX(d) ?? 0) - errorBarWhiskerWidth / 2 + errorXOffset;
                            },
                            y1: (d) => yScale(d.error_bars?.[errorBarType].bottom ?? 0),
                            y2: (d) => yScale(d.error_bars?.[errorBarType].bottom ?? 0),
                        },
                        // vertical line
                        {
                            x1: (d) => (getX(d) ?? 0) + errorXOffset,
                            x2: (d) => (getX(d) ?? 0) + errorXOffset,
                            y1: (d) => yScale(d.error_bars?.[errorBarType].bottom ?? 0),
                            y2: (d) => yScale(d.y ?? 0),
                        },
                    );
                }

                errorLineConfigs.forEach((lineConfig) => {
                    this.drawLine({
                        lineConfig,
                        svg,
                        items: lineItems,
                        color: lineColor,
                        strokeWidth: errorBarStrokeWidth,
                    });
                });
            }

            // draw dots on the line
            svg.append('g')
                .attr('class', 'plot-line-dot')
                .selectAll('circle')
                .data(lineItems)
                .enter()
                .append('path')
                .attr('d', (d) => {
                    return d3
                        .symbol()
                        .type(getD3Symbol(this.getDotSymbol(d)))
                        .size(DATAPOINT_SYMBOL_SIZE)();
                })
                .attr('transform', (d) => {
                    const y = yScale(d.y);
                    const x = getX(d) ?? 0;
                    return `translate(${x}, ${y})`;
                })
                .attr('fill', lineColor ?? '')
                .attr('stroke', lineColor)
                .on('mouseover', (event, d) => {
                    const circle = d3
                        .select(event.target)
                        .style('cursor', 'crosshair')
                        .attr('stroke', 'red')
                        .attr('fill', 'red');
                    event.parentNode?.appendChild(circle);
                    tooltipContainer
                        .html(this.getLineTooltipContent(d))
                        .style('opacity', 1)
                        .style('left', `${event.pageX + 10}px`)
                        .style('top', `${event.pageY - 10}px`);
                })
                .on('mouseout', function (event) {
                    d3.select(event.target).attr('fill', lineColor).attr('stroke', lineColor);
                    tooltipContainer.style('opacity', 0);
                });
        };

        this.lineData.forEach(drawLineSeries);
    }

    getDataByTargetAndGroup() {
        const getGroupIndex = (d: LinePlotDatum) => this.getGroupIndex(d);
        return this.data.items.reduce<Record<string, Record<number, LinePlotDatum[]>>>((agg, datum) => {
            const groupId = datum.group_id ?? NULL_GROUP_ID;
            const targetName = datum.target_name;
            const targetData = agg[targetName] ?? {};
            const groupSeries = targetData[groupId] ?? [];
            if (getGroupIndex(datum) < 0 || !isDefined(datum.x_value) || !isDefined(datum.y_value)) {
                return agg;
            }
            groupSeries.push(datum);
            targetData[groupId] = groupSeries;
            agg[targetName] = targetData;
            return agg;
        }, {});
    }

    static calculateErrorBarsForSamples(samples: LinePlotDatum[]): ErrorBarPoint {
        const sampleCount = samples.length;
        const mean = d3.mean(samples, (s) => s.y_value) ?? 0;
        const standardDeviation = d3.deviation(samples, (s) => s.y_value) ?? 0;
        const standardErrorOfMean = standardDeviation / Math.sqrt(sampleCount);

        return {
            sem: { top: mean + standardErrorOfMean, bottom: mean - standardErrorOfMean, value: standardErrorOfMean },
            sd: { top: mean + standardDeviation, bottom: mean - standardDeviation, value: standardDeviation },
        };
    }

    makeLineData(data: Record<string, Record<number, LinePlotDatum[]>>): LinePoint[][] {
        const lineData: LinePoint[][] = [];
        Object.values(data).forEach((targetData) => {
            const groupsData = Object.values(targetData);
            groupsData.forEach((groupData) => {
                const groupDataByX = groupData.reduce<Record<string, LinePlotDatum[]>>((agg, datum) => {
                    const x = `${datum.x_value}`;
                    agg[x] = agg[x] ?? [];
                    agg[x].push(datum);
                    return agg;
                }, {});

                const xValues = Object.keys(groupDataByX);
                const groupLineData: LinePoint[] = [];

                xValues.forEach((xValue) => {
                    const groupXData = groupDataByX[xValue] ?? [];
                    const [datum] = groupXData;
                    if (!datum) {
                        logger.warn(`no datum found for groupXData[${xValue}]`, groupXData);
                        return;
                    }

                    const yValue = mean(groupXData, (d) => d.y_value) ?? 0;

                    const linePoint: LinePoint = {
                        x: Number(xValue),
                        y: yValue,
                        group_id: datum.group_id ?? null,
                        target_name: datum.target_name,
                        target_id: datum.target_id,
                        group_name: datum.group_name ?? null,
                        sample_count: groupXData.length,
                        error_bars: LinePlotBuilder.calculateErrorBarsForSamples(groupXData),
                    };
                    groupLineData.push(linePoint);
                });
                lineData.push(groupLineData);
            });
        });

        return lineData;
    }

    setupData() {
        const sortedGroupIds = this.analysis?.group_display_order ?? [];
        this.sortedGroups = (this.analysis?.groups ?? [])
            .map((g) => ({
                group_name: g.display_name,
                group_id: g.id,
            }))
            .sort((g1, g2) => {
                return sortedGroupIds.indexOf(g1.group_id) - sortedGroupIds.indexOf(g2.group_id);
            });

        this.sortedTargets = this.analysis?.targets ?? [];
        const getGroupIndex = (d: LinePlotDatum) => this.getGroupIndex(d);
        const samplesByX = this.data.items.reduce<Record<string, LinePlotDatum[]>>((agg, datum) => {
            const x = `${datum.x_value}`;
            const groupIndex = getGroupIndex(datum);
            if (groupIndex < 0 || !isDefined(datum.x_value) || !isDefined(datum.y_value)) {
                return agg;
            }
            agg[x] = agg[x] ?? [];
            agg[x].push(datum);
            return agg;
        }, {});

        const dataByTargetAndGroup = this.getDataByTargetAndGroup();
        const lineData = this.makeLineData(dataByTargetAndGroup);
        this.dataByTargetAndGroup = dataByTargetAndGroup;
        this.samplesByX = samplesByX;
        this.lineData = lineData;
        return { samplesByX, dataByTargetAndGroup, lineData };
    }

    getXAxisHeight() {
        return this.svg.select<SVGGElement>('.x-axis')?.node()?.getBoundingClientRect().height ?? 0;
    }

    getXAxisTitleHeight() {
        return this.svg.select<SVGGElement>('.x-axis-title')?.node()?.getBoundingClientRect().height ?? 0;
    }

    getYAxisHeight() {
        return this.svg.select<SVGGElement>('.y-axis')?.node()?.getBoundingClientRect().width ?? 0;
    }

    draw(): void {
        this.setupData();
        this.makeScales();
        this.appendYAxis();
        this.appendXAxis();

        const xAxisHeight = this.getXAxisHeight();
        const xAxisTitleHeight = this.getXAxisTitleHeight();

        this.margin.left = Math.ceil(this.getYAxisHeight());
        this.margin.bottom = xAxisHeight + xAxisTitleHeight;

        this.makeScales();

        // Note: calling append yaxis multiple times to get the text wrapping working properly. There's probably a better way
        this.appendYAxis();
        this.margin.left = Math.ceil(this.getYAxisHeight());
        this.makeScales();
        this.appendYAxis();
        this.appendXAxis();

        if (this.yDomain.yMin < 0) {
            this.drawYZeroLine();
        }

        if (this.displayOptions.show_data_points) {
            this.drawDataPoints();
        }

        this.drawGroupLines();

        const clipPathHeight = Math.max(this.height - this.margin.top - this.margin.bottom, 0);

        this.svg
            .append('defs')
            .append('clipPath')
            .attr('id', this.clipPathId)
            .append('rect')
            .attr('width', Math.max(this.width - this.margin.left - this.margin.right, 0))
            .attr('height', clipPathHeight)
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

        return;
    }
}

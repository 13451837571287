import React, { useContext } from 'react';
import { AuthContext } from '@contexts/AuthContext';
import DefaultLayout, { DefaultLayoutProps } from '@layouts/DefaultLayout';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import ExperimentNavigation from '@components/ExperimentNavigation';
import DashboardNavigation from '@components/layout/DashboardNavigation';
import LoginForm from '@components/LoginForm';
import Card from '@components/Card';
import ProjectArchiveConfirmModal from '@components/projects/ProjectArchiveConfirmModal';
import useAuth from '@hooks/useAuth';
import LoadingMessage from '@components/LoadingMessage';
import ProjectSharingDialog from '@components/sharing/ProjectSharingDialog';
import { LabSpaceContextProvider } from '@contexts/LabSpaceContext';
import LabspaceExperimentSharingDialog from '@components/sharing/LabspaceExperimentSharingDialog';
import TrialBanner from '@components/trial/TrialBanner';
import { useTrialContext } from '@contexts/TrialContext';
import { useRouter } from 'next/router';
import { QueryParam } from '@services/QueryParams';
import useSWR from 'swr';
import Experiment from '@models/Experiment';
import Endpoints from '@services/Endpoints';
import { Project } from '@models/Project';
import { PaginationResponse } from '@services/EndpointUtil';
import { ExperimentDetailViewContextProvider } from '@contexts/ExperimentDetailViewContext';
import { ExperimentWorkflowContextProvider } from '@contexts/ExperimentWorkflowContext';
import { ExperimentAnnotationContextProvider } from '@contexts/ExperimentAnnotationContext';
import { ExperimentCanvasContextProvider } from '../contexts/ExperimentCanvasContext';
import { ReactFlowProvider } from 'reactflow';
import { useSidebar } from '@contexts/SidebarContext';
import Banner from '@components/banner/Banner';
import cn from 'classnames';
import { ActiveExperimentProvider } from '../contexts/ActiveExperimentContext';

const DefaultAuthComponent = () => {
    return (
        <DefaultLayout maxWidth="lg">
            <Card>
                <h3 className="mb-4 text-center text-3xl font-semibold tracking-tight">
                    {"There's so much more to explore on Pluto!"}
                </h3>
                <p className="mb-8 text-center text-default">Log in to your account to view this page</p>
                <LoginForm />
            </Card>
        </DefaultLayout>
    );
};

export type ExperimentLayoutProps = PropsWithChildren<
    DefaultLayoutProps & { authRequired?: boolean; authComponent?: ReactNode; fixedViewport?: boolean }
>;
const ExperimentLayout = ({
    children,
    container = false,
    noMargin,
    authRequired = false,
    authComponent = <DefaultAuthComponent />,
    ...props
}: ExperimentLayoutProps) => {
    const { user } = useContext(AuthContext);
    const organization = user?.organization;
    const branding = organization?.branding;
    const navBackgroundColorTop = branding?.nav_background_top;
    const navBackgroundColorBottom = branding?.nav_background_bottom;
    const router = useRouter();
    const { plutoId } = router.query as {
        plutoId: string | undefined;
        [QueryParam.STEP]: string | undefined;
    };
    const { isLoggedIn, authReady } = useAuth();
    const { trialExpired } = useTrialContext();
    const { collapsed, toggleCollapsed } = useSidebar();

    const hasAccess = isLoggedIn || !authRequired;
    let $body: ReactNode = null;
    if (hasAccess) {
        $body = children;
    } else if (!authReady) {
        $body = <LoadingMessage />;
    } else if (authReady && !isLoggedIn) {
        $body = authComponent;
    }

    const { data: experiment } = useSWR<Experiment>(
        () => (plutoId && isLoggedIn ? Endpoints.lab.experiment.base(plutoId) : null),
        { revalidateOnFocus: true, refreshInterval: 10_000 },
    );
    const projectId = experiment?.project?.pluto_id ?? '';

    const { data: project } = useSWR<Project>(() => (projectId && user ? Endpoints.lab.project.base(projectId) : null));
    const { data: projectExperimentsResponse } = useSWR<PaginationResponse<Experiment>>(() =>
        projectId && project?.share_level === 'public'
            ? Endpoints.public.project.experiments({ projectId })
            : projectId && Endpoints.lab.project.experiments({ projectId }),
    );
    const projectExperiments =
        projectExperimentsResponse?.items.filter(
            (projExp) => projExp.pluto_id !== experiment?.pluto_id && !projExp.is_archived,
        ) ?? [];

    useEffect(() => {
        if (trialExpired && authReady && isLoggedIn) {
            router.push('https://pluto.bio/get-info');
        }
    }, [authReady, isLoggedIn, trialExpired]);

    if (trialExpired) {
        return null;
    }

    const sidebarStyle = {
        paddingTop: '54px',
        zIndex: 998,
        ...(navBackgroundColorTop &&
            navBackgroundColorBottom && {
                background: `linear-gradient(to bottom, ${navBackgroundColorTop} 54px, ${navBackgroundColorBottom})`,
            }),
    };

    return (
        <DefaultLayout className="max-w-screen min-h-screen" container={container} {...props} noMargin={noMargin}>
            <ActiveExperimentProvider defaultExperiment={experiment}>
                <ReactFlowProvider>
                    <ExperimentCanvasContextProvider experiment={experiment}>
                        <ExperimentWorkflowContextProvider>
                            <ExperimentAnnotationContextProvider>
                                <ExperimentDetailViewContextProvider experiment={experiment}>
                                    <LabSpaceContextProvider>
                                        <Banner />
                                        <TrialBanner innerClassName="" />
                                        <div>
                                            {/* Sidebar - Positioning to account for banner */}
                                            <div
                                                className="flex-shrink-0 from-pluto-layout-purple to-pluto-layout-darkpurple bg-gradient-to-b fixed h-screen"
                                                style={sidebarStyle}
                                            >
                                                <DashboardNavigation
                                                    collapsed={collapsed}
                                                    toggleCollapsed={toggleCollapsed}
                                                />
                                            </div>

                                            {/* Content Area - Positioning dependent on if nav is collapsed */}
                                            <div
                                                className={cn(
                                                    'flex-grow pt-10 overflow-hidden transition-all duration-300',
                                                    collapsed ? 'ml-16' : 'ml-52',
                                                )}
                                            >
                                                <div>
                                                    {experiment && (
                                                        <ExperimentNavigation
                                                            experiment={experiment as Experiment}
                                                            project={project as Project}
                                                            projectExperiments={projectExperiments}
                                                            collapsed={collapsed}
                                                        />
                                                    )}
                                                    <div className="pt-12">{$body}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <ProjectArchiveConfirmModal />
                                        <LabspaceExperimentSharingDialog />
                                        <ProjectSharingDialog />
                                    </LabSpaceContextProvider>
                                </ExperimentDetailViewContextProvider>
                            </ExperimentAnnotationContextProvider>
                        </ExperimentWorkflowContextProvider>
                    </ExperimentCanvasContextProvider>
                </ReactFlowProvider>
            </ActiveExperimentProvider>
        </DefaultLayout>
    );
};

export default ExperimentLayout;

import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import Plot from '@models/Plot';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import ResultsDataTableView from './ResultsDataTableView';
import Experiment from '@/src/models/Experiment';

export type Props = {
    experiment?: Experiment | null;
    onClose: () => void;
    open: boolean;
    plot: Plot;
};
const ResultsModal = ({ open = false, onClose, plot, experiment }: Props) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogCloseButton onClose={() => onClose()} />

            <PlutoDialogTitle title="Results" />

            <DialogContent>
                <ResultsDataTableView selectedPlot={plot} experimentId={experiment?.uuid} />
            </DialogContent>
        </Dialog>
    );
};
export default ResultsModal;

import Experiment from '@models/Experiment';
import { Autocomplete, Box } from '@mui/material';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react';
import { Checkbox, Chip, CircularProgress, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Endpoints from '@services/Endpoints';
import { blankToNull, isBlank, isNotBlank } from '@util/StringUtil';
import { ExperimentData } from '@models/ExperimentData';
import useSWR from 'swr';
import Button from '@components/Button';
import { useField } from 'formik';
import cn from 'classnames';
import { FormikFieldError } from '@components/forms/FieldError';
import { matchSorter } from 'match-sorter';
import { isDefined } from '@util/TypeGuards';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Logger from '@util/Logger';
import { onlyUnique, pluralize } from '@util/ObjectUtil';
import { ClipboardIcon, TrashIcon } from '@heroicons/react/outline';
import useSimpleClipboard from '@hooks/useSimpleClipboard';

const logger = Logger.make('AsyncTargetPicker');

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const PAGE_SIZE = 250;
export type ValueType = string[];

type Props = {
    className?: string;
    columnNameProp?: string;
    description?: ReactNode;
    disabled?: boolean;
    experiment: Experiment;
    label?: string;
    maxItems?: number;
    name: string;
    targetColumnIndex?: number;
    useTargetDataEndpoint?: boolean;
};
/**
 * A Formik form field for picking targets from an experiment.
 * This component handles fetching the data and executing the search.
 * @param {Props} props
 * @return {JSX.Element}
 * @constructor
 */
const AsyncTargetPicker = ({
    className,
    columnNameProp,
    description,
    disabled,
    experiment,
    label = 'Target(s)',
    maxItems,
    name,
    targetColumnIndex = 0,
    useTargetDataEndpoint = false,
}: Props) => {
    /** the formik field values */
    const [{ value: targets = [] }, { error: formError, touched }, { setValue }] = useField<ValueType>(name);

    /** is picker menu open */
    const [open, setOpen] = useState(false);

    /** the text the user has entered into the search input */
    const [searchTerm, setSearchTerm] = useState<string | null>(null);

    /** the total results returned - needed to support the different counts from searches  */
    const [totalResults, setTotalResults] = useState<number | null>(null);

    /** the number of items in the current list after search - used for no results but added values */
    const [sortedCount, setSortedCount] = useState<number | null>(null);

    /** the number of items in the current list - used for no results but added values */
    const [itemsCount, setItemsCount] = useState<number | null>(null);

    /** the number of items in the list initially before added values - used for no results but added values */
    const [initialItemsCount, setInitialItemsCount] = useState<number | null>(null);

    /** debounced value for SWR */
    const [queryValue, setQueryValue] = useState<string | null>(null);

    const { copyTextToClipboard, showSuccess: copySuccess } = useSimpleClipboard();

    /** Fetch the target data from the API using the queryValue */
    const queryPayload = {
        experimentId: experiment.uuid,
        search: blankToNull(queryValue) ?? undefined,
        limit: PAGE_SIZE,
        offset: 0,
    };
    const { data, error } = useSWR<ExperimentData>(() =>
        useTargetDataEndpoint
            ? Endpoints.lab.experiment.targetData(queryPayload)
            : Endpoints.lab.experiment.assayData(queryPayload),
    );

    const loading = !data && !error;

    const removeTarget = (target: string) => {
        const updatedTargets = targets.filter((t) => t !== target);
        logger.debug(`[removeTarget] removing "${target}"`, { original: targets, updatedTargets });
        setValue(updatedTargets);
    };

    const filterOptions =
        (loading: boolean) =>
        (options: string[], { inputValue }: FilterOptionsState<string>): string[] => {
            if (loading) {
                return options.slice(0, 1);
            }
            const sorted = matchSorter(options.slice(1), inputValue, { threshold: matchSorter.rankings.CONTAINS });
            if (isDefined(options[0])) {
                sorted.unshift(options[0]);
            }
            return sorted;
        };

    useEffect(() => {
        if (!loading) {
            // Compute sorted count similar to filterOptions logic
            const options = data?.items?.[targetColumnIndex]
                ? data.items.map((cell) => `${cell[columnNameProp ?? data.headers[targetColumnIndex]]}`)
                : [];
            const filtered = matchSorter(options.slice(1), searchTerm ?? '', {
                threshold: matchSorter.rankings.CONTAINS,
            });
            setSortedCount(filtered.length);
        }
    }, [searchTerm, data, loading]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setQueryValue(searchTerm);
        }, 250);
        const completedStatus = data?.status === 'complete';

        if (completedStatus && (data?.count ?? 0) > 0 && isBlank(searchTerm)) {
            setTotalResults(data?.count ?? 0);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [searchTerm, data?.count, queryValue]);

    const items = useMemo<string[]>(() => {
        const columnName = columnNameProp ?? data?.headers?.[targetColumnIndex];
        if (!columnName) {
            return [];
        }
        const lowerCaseColumnName = columnName.toLowerCase();
        const items: string[] =
            data?.items
                .map((cell) => {
                    if (cell[columnName]) return `${cell[columnName]}`;
                    return cell[lowerCaseColumnName] ? `${cell[lowerCaseColumnName]}` : '';
                })
                .filter((v, i, a) => {
                    // Filter out empty strings and duplicates
                    if (v === '') return false;
                    return onlyUnique(v, i, a);
                }) ?? [];
        const dataCount = items?.length ?? 0;
        const customDataCount = targets?.length ?? 0;

        targets.forEach((t) => {
            if (!items.includes(t)) {
                items.push(t);
            }
        });

        items.sort();
        const hasMoreMatches = dataCount > PAGE_SIZE;

        const currentCount = Math.min(PAGE_SIZE, dataCount).toLocaleString();

        if (loading) {
            items.unshift('Loading targets...');
        } else if (!isBlank(queryValue)) {
            const showCustomValueText = (sortedCount ?? 0) > 0 && dataCount === 0;
            const ofTotalText = hasMoreMatches ? ` of ${(dataCount ?? 0).toLocaleString()}` : '';
            const searchResultText = `Showing ${currentCount}${ofTotalText} targets matching\xa0"${queryValue}"`;
            const searchResultsTextCustomValues = `Showing ${sortedCount} targets matching\xa0"${queryValue}"`;
            const noResultsText = `No results found for\xa0"${queryValue}"`;

            items.unshift(
                showCustomValueText ? searchResultsTextCustomValues : dataCount > 0 ? searchResultText : noResultsText,
            );
        } else {
            const ofTotalText = hasMoreMatches ? ` of ${(dataCount ?? 0).toLocaleString()}` : '';
            const noDataText = `Showing ${customDataCount} targets`;
            const hasDataText = `Showing first ${currentCount}${ofTotalText}\xa0targets`;
            items.unshift(dataCount === 0 ? noDataText : hasDataText);
        }

        return items;
    }, [targets, data, queryValue, loading, searchTerm, sortedCount, totalResults, initialItemsCount]);

    useEffect(() => {
        const completedStatus = data?.status === 'complete';
        // account for the first item being a placeholder
        const count = items?.length > 0 ? items?.length - 1 : (items?.length ?? 0);
        if (completedStatus && !initialItemsCount && count < 1) {
            setInitialItemsCount(count);
        }
        if (completedStatus) {
            setItemsCount(count);
        }
    }, [items, data]);

    const additionalSelectionDisabled = isDefined(maxItems) && targets.length >= maxItems;

    const isFirstItem = (option: string) => {
        return option === items[0];
    };

    /**
     * Decide if the option is disabled. Disabled if the max number of items has been selected and the item is
     * not currently selected (allows for unselecting) or if the item is the first in the list,
     * which is not a real selection.
     * @param {string} option
     * @return {boolean}
     */
    const isOptionDisabled = (option: string) => {
        return isFirstItem(option) || (additionalSelectionDisabled && !targets.includes(option));
    };

    const getPlaceholderText = () => {
        const dataCount = totalResults ?? 0;
        const noDataSearchText =
            targets?.length === 0
                ? 'Add targets...'
                : `Search ${
                      itemsCount !== dataCount ? targets?.length.toLocaleString() : dataCount.toLocaleString()
                  } targets...`;
        const searchText =
            initialItemsCount === 0 || initialItemsCount === itemsCount || itemsCount === targets?.length
                ? `Search ${targets?.length.toLocaleString()} targets...`
                : `Search ${dataCount.toLocaleString()} targets...`;
        const defaultSearchText = totalResults === 0 || itemsCount === 0 ? noDataSearchText : searchText;
        if (loading) {
            return 'Loading targets...';
        }
        return defaultSearchText;
    };

    return (
        <div
            className={cn(
                'form-field input-inline-block mb-4',
                { 'has-error': Boolean(formError && touched) },
                className,
            )}
        >
            <span className="field-label block">{label}</span>
            {description && <p className="mb-2 text-xs text-gray-500/90">{description}</p>}
            <Autocomplete
                multiple
                style={{ width: '100%' }}
                open={open}
                disabled={disabled}
                disableCloseOnSelect
                clearOnBlur={false}
                selectOnFocus
                handleHomeEndKeys
                noOptionsText="No targets found"
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                value={targets}
                getOptionDisabled={isOptionDisabled}
                filterOptions={filterOptions(loading)}
                onChange={(_e, newValues, reason) => {
                    if (reason === 'clear') {
                        return;
                    }

                    setValue(newValues);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={items}
                loading={loading}
                inputValue={searchTerm ?? ''}
                onInputChange={(_event, newInputValue, reason) => {
                    if (reason === 'reset') {
                        return;
                    }
                    const regex = new RegExp(/\s*,\s*|\s+/g);

                    if (newInputValue && newInputValue.match(regex)) {
                        logger.debug('[onInputChange] new value ', newInputValue);
                        const bulkValues = newInputValue.split(regex);
                        const newValues = [...targets];
                        bulkValues.forEach((bv) => {
                            if (!newValues.includes(bv) && isNotBlank(bv)) {
                                newValues.push(bv);
                            }
                        });
                        logger.debug('[onInputChanged] setting new values to', newValues);
                        setValue(newValues);
                        return;
                    }

                    setSearchTerm(newInputValue);
                }}
                renderOption={(props: HTMLAttributes<HTMLLIElement> & { key?: string }, option, { selected }) => {
                    const { key, ...restProps } = props;
                    const matches = match(option, searchTerm ?? '', { insideWords: true });
                    const parts = parse(option, matches);
                    const isFirst = option === items[0];
                    return (
                        <Box
                            key={key}
                            component="li"
                            className="flex cursor-pointer space-x-2 p-2 hover:bg-slate-100"
                            {...restProps}
                        >
                            {!isFirstItem(option) && (
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8, padding: 0 }}
                                    checked={selected}
                                    size="small"
                                />
                            )}
                            {isFirst ? (
                                option
                            ) : (
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            className={cn({ 'font-semibold text-indigo-700': part.highlight })}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </Box>
                    );
                }}
                renderTags={() => null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        placeholder={getPlaceholderText()}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            className: 'flex-nowrap',
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <div className="flex h-full items-center justify-center pl-2">
                                            <CircularProgress color="inherit" size={16} />
                                        </div>
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />

            <div className="mt-2 flex max-h-[180px] flex-wrap overflow-y-auto">
                {targets.map((target) => (
                    <Chip key={target} onDelete={() => removeTarget(target)} label={target} className="mb-0.5 mr-0.5" />
                ))}
            </div>

            {targets.length > 0 && (
                <div className="mt-1 flex justify-between">
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => copyTextToClipboard(targets.join(', '))}
                        startIcon={<ClipboardIcon width={18} />}
                        size="small"
                    >
                        {copySuccess ? 'Copied!' : 'Copy'}
                    </Button>

                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => setValue([])}
                        startIcon={<TrashIcon width={18} className={'text-error'} />}
                        size="small"
                    >
                        <span className="leading-tight text-error">
                            Remove {targets.length} {pluralize(targets.length, 'target', 'targets')}
                        </span>
                    </Button>
                </div>
            )}

            <FormikFieldError name={name} />
        </div>
    );
};

export default AsyncTargetPicker;

import Plot from '@models/Plot';
import { ProteinProteinInteractionAnalysis } from '@models/analysis/ProteinProteinInteractionAnalysis';
import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Bold from '@/src/components/elements/Bold';
import NetworkGraphDisplayOption from '@/src/models/plotDisplayOption/NetworkGraphDisplayOption';

const NetworkTypePrettyNames: { [key: string]: string } = {
    functional: 'full STRING network',
    physical: 'physical subnetwork',
};

type Props = { plot: Plot };
const ProteinProteinInteractionAnalysisMethodsSection = ({ plot }: Props) => {
    const analysis = plot.analysis as ProteinProteinInteractionAnalysis;
    const display = plot.display;
    const fcComparisonString = analysis?.fc_direction === 'up' ? 'greater than' : 'less than';

    if (!analysis) {
        return <div>No analysis was found.</div>;
    }

    return (
        <section>
            <section>
                <MethodsSectionTitle>Protein-protein interaction analysis</MethodsSectionTitle>
                <p className="mb-2">
                    Protein-protein interaction analysis was performed using the Search Tool for the Retrieval of
                    Interacting Genes/Proteins (STRING) database (v12.0).<sup>1-13</sup> The STRING web interface was
                    queried using the provided targets to return a{' '}
                    <Bold>{NetworkTypePrettyNames[analysis?.network_type as keyof typeof NetworkTypePrettyNames]}</Bold>
                    .{' '}
                    {analysis?.target_genes_format === 'differential' ? (
                        <>
                            Differential targets were defined as targets with an adjusted <i>p</i>-value less than{' '}
                            <Bold>{analysis?.adj_pval_threshold}</Bold>, and a fold change {fcComparisonString}{' '}
                            <Bold>{analysis?.fc_threshold}</Bold>.
                        </>
                    ) : undefined}
                </p>
                <p className="mb-6 italic">
                    Note: This analysis is limited to 2,000 targets. If the provided targets exceed 2,000, only the
                    first 2,000 targets will be used.
                </p>
            </section>

            <section>
                <MethodsSectionTitle>Network graph</MethodsSectionTitle>
                <p className="mb-6">
                    Network graph showing protein-protein interactions. Line color indicates the type of interaction
                    evidence.{' '}
                    {(display as NetworkGraphDisplayOption)?.use_weighted_edges === true
                        ? 'Line thickness indicates the strength of data support.'
                        : undefined}
                </p>
            </section>
        </section>
    );
};

export default ProteinProteinInteractionAnalysisMethodsSection;

import { ThemeStyle } from '@models/PlotConfigs';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { initialMouseState, MousePosition } from '@components/plots/PlotTypes';
import NoSsr from '@components/NoSsr';
import PlotLegendView from '@components/plots/PlotLegendView';
import Experiment from '@models/Experiment';
import { CircularProgress, Tooltip } from '@mui/material';
import Plot, { supportedThumbnailDisplayTypes } from '@models/Plot';
import { PipelineStatusPlotData, PlotPipelinePlotData } from '@models/ExperimentData';
import MethodsModal from '@components/experiments/methods/MethodsModal';
import ResultsModal from '@components/experiments/ResultsModal';
import Button from '@components/Button';
import { DeleteOutlineRounded } from '@mui/icons-material';
import CanvasPlotShellView from '@components/plots/CanvasPlotShellView';
import PlotDataErrorView from '@components/plots/PlotDataErrorView';
import cn from 'classnames';
import { ApiError } from '@services/ApiError';
import LocalStorageService from '@util/LocalStorageService';
import usePlot from '@hooks/usePlot';
import usePlotLegendItems from '@hooks/usePlotLegendItems';
import { isDefined } from '@util/TypeGuards';
import {
    getPlotDisplayTitle,
    isDoubleClickEnabled,
    isDoubleWidePlot,
    isRightClickEnabled,
} from '@components/plots/PlotUtil';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import { PlotContextProvider } from '@contexts/PlotContext';
import { ExperimentAnalysisWithCustomLegend, isPipelineAnalysis } from '@models/analysis/ExperimentAnalysis';
import AnalysisCategoryPlotContentView from '@components/analysisCategories/AnalysisCategoryPlotContentView';
import { isBlank } from '@util/StringUtil';
import useDebouncedResizeObserver from '@hooks/useDebouncedResizeObserver';
import useAnalysisParameters from '@hooks/useAnalysisParameters';
import PlotCardMenuButton from '@components/plots/PlotCardMenuButton';
import { ResizableContainerContextProvider } from '@contexts/ResizableContainerContext';
import { PlotOverrideSummary, useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { DragMode } from '../analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import CustomPlotLegendView from './CustomPlotLegendView';
import { AnalysisShortname } from '@/src/models/analysis/AnalysisType';
import DotPlotDisplayOption from '@/src/models/plotDisplayOption/DotPlotDisplayOption';
import WhiteIconButton from '../WhiteIconButton';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import useOrganizationPermissions from '@/src/hooks/useOrganizationPermissions';
import useExperimentPermissions from '@/src/hooks/useExperimentPermissions';
import { validateAnalysisInputsPlotDataReady } from '../experiments/analyses/inputs/AnalysisInputFormUtil';
import PlotTitle from './PlotTitle';
import { updateHashWithPlotUUID } from '@util/hashUtil';
import { usePlotThumbnail } from '@/src/hooks/generateAndSaveThumbnail';
import PlotImageThumbnail from './PlotImageThumbnail';
import { useActiveExperimentContext } from '@/src/contexts/ActiveExperimentContext';
import ConditionalWrapper from '../ConditionalWrapper';
import PlotDisplayLoadingView from './PlotDisplayLoadingView';
import { hasCommentsPermission } from '@/src/util/PermissionUtil';
import { AuthContext } from '@/src/contexts/AuthContext';
import { useRouter } from 'next/router';

const MIN_LEGEND_COLUMN_WIDTH = 525;
export type LegendPosition = 'bottom' | 'right' | 'auto';
export type Props = {
    aspectRatio?: number | null;
    cursorClassName?: string;
    disableDownloadLinks?: boolean;
    disableFullWidth?: boolean;
    disableMaxHeight?: boolean;
    disablePlotImages?: boolean; // disable plot images logic for generating thumbnails
    disableRoundedCorners?: boolean;
    disableSelected?: boolean;
    dragMode?: DragMode;
    experiment?: Experiment | null;
    extraPadding?: boolean;
    hideBorders?: boolean;
    hideEditButton?: boolean;
    hideEditForm?: boolean;
    hideLegend?: boolean;
    hideMoreMenu?: boolean;
    hidePlotShellButtons?: boolean;
    hideTitle?: boolean;
    isCanvasNode?: boolean;
    isDragging?: boolean;
    isExportMode?: boolean;
    isLinkedExperiment?: boolean;
    isModalView?: boolean;
    legendIsScrollable?: boolean;
    legendPosition?: LegendPosition;
    minHeight?: number;
    noPadding?: boolean;
    overrides?: PlotOverrideSummary | null;
    plot: Plot;
    publicationMode?: boolean;
    publicKey?: string | null;
    resizeNoLeading?: boolean;
    resizeNoTrailing?: boolean;
    showExpand?: boolean;
    showExperimentLink?: boolean;
    showHeader?: boolean;
    useThumbnailImage?: boolean; // use thumbnail image instead of plot image if available
};

const PlotCardView = ({
    cursorClassName,
    disableDownloadLinks,
    disableFullWidth = false,
    disableMaxHeight = false,
    disablePlotImages = false,
    disableRoundedCorners = false,
    disableSelected = false,
    dragMode = undefined,
    experiment,
    hideBorders = false,
    hideEditButton,
    hideEditForm = false,
    hideLegend = false,
    hideMoreMenu = false,
    hidePlotShellButtons = false,
    hideTitle = false,
    isCanvasNode = false,
    isExportMode = false,
    isLinkedExperiment = false,
    isModalView = false,
    legendIsScrollable = true,
    legendPosition = 'auto',
    overrides: overridesParam,
    plot: plotParam,
    publicationMode,
    publicKey,
    showExpand = false,
    showExperimentLink,
    showHeader = true,
    useThumbnailImage = false,
}: Props) => {
    const plotId = plotParam.uuid;
    const [methodsOpen, setMethodsOpen] = React.useState(false);
    const [resultsOpen, setResultsOpen] = React.useState(false);
    const [isPlotFullyRendered, setIsPlotFullyRendered] = useState(false);
    const plotExportRef = useRef<HTMLDivElement | null>(null);
    const [mousePosition, setMousePosition] = useState<MousePosition>(initialMouseState());
    const [showLegendBottom, setShowLegendBottom] = useState(legendPosition === 'bottom');
    const permissions = useExperimentPermissions(experiment, !!publicKey);
    const { features } = useOrganizationPermissions();
    const [legendLoaded, setLegendLoaded] = useState(false);
    const [plotThumbnailError, setPlotThumbnailError] = useState(false);
    const [isRefreshingThumbnail, setIsRefreshingThumbnail] = useState(false);
    const { user } = useContext(AuthContext);
    const router = useRouter();

    const plotContainerRef = useRef<HTMLDivElement>(null);
    const plotThumbnailRef = useRef<HTMLDivElement | null>(null);
    const { ref: plotSizeRef, size } = useDebouncedResizeObserver({ wait: 200, useDebounce: true });
    const { size: plotContainerSize } = useDebouncedResizeObserver({ ref: plotContainerRef, useDebounce: true });
    const { plot, plotData, plotDataError, plotDataLoading, isPlotDataValidating, mutatePlot, plotLoading } = usePlot({
        experiment,
        plot: plotParam,
        plotId,
        overrides: overridesParam,
        publicKey,
        useThumbnailImage: useThumbnailImage && !isRefreshingThumbnail,
    });

    const displaySupportsThumbnails = supportedThumbnailDisplayTypes.includes(plot?.display?.display_type || '');
    const thumbnailUri = plot?.thumbnail_uri?.rectangle ?? null;
    const isUsingThumbnail =
        useThumbnailImage &&
        displaySupportsThumbnails &&
        !!thumbnailUri &&
        !plotThumbnailError &&
        !isRefreshingThumbnail;
    const { analysisParameters } = useAnalysisParameters({ plot, experiment, publicKey });
    const {
        style: legendStyle,
        legendItems,
        horizontalOnly,
    } = usePlotLegendItems({ plot, plotData, experiment, publicKey });

    const {
        clearPlotOverrides,
        getPlotOverrides,
        handleChangeSelectedPlot,
        plotDisplayLoadingStates,
        selectedPlot,
        setCurrentContextMenuPlotId,
        setCurrentPlotPanel,
        setExperimentModalOpen,
        setHideEditForm,
        setOpenOnComments,
        setOpenOnPlot,
        setZoomTransform,
    } = useExperimentDetailViewContext();
    const { setActiveExperiment } = useActiveExperimentContext();

    const overrides: PlotOverrideSummary = getPlotOverrides?.(plotId) ?? { hasOverrides: false };
    const isSelected = !disableSelected && plotId === selectedPlot?.uuid;
    const loading = isUsingThumbnail
        ? !!plot?.analysis && (plotLoading || plotDataLoading || plotData?.pipeline_status === 'in_progress')
        : !!plot?.analysis && (plotDataLoading || plotData?.pipeline_status === 'in_progress');
    const hasValidAnalysisInputs =
        plot?.analysis_form_type === 'analysis_input' ? validateAnalysisInputsPlotDataReady(plot.analysis) : true;

    const sourceExperimentReady = isDefined(plot?.analysis?.uuid);
    const linkedExperimentReady = isDefined(experiment?.uuid);
    const experimentReady =
        (isLinkedExperiment ? linkedExperimentReady : sourceExperimentReady) &&
        !plotDataLoading &&
        hasValidAnalysisInputs;
    const thumbnailReady = !plotLoading && isDefined(plot?.analysis?.uuid) && hasValidAnalysisInputs;
    const dataReady = isUsingThumbnail ? thumbnailReady : experimentReady;
    const display = plot?.display as DotPlotDisplayOption;

    const pipelineProcessing = (plotData as PipelineStatusPlotData)?.pipeline_status === 'in_progress';
    const hasPipelinePlotData =
        ((plotData as PipelineStatusPlotData)?.items ?? []).length > 0 ||
        (plotData?.count ?? 0) > 0 ||
        Boolean((plotData as PlotPipelinePlotData)?.plots?.images);

    const showPlotTitle =
        !hideTitle && !pipelineProcessing && (!isPipelineAnalysis(plot?.analysis) || hasPipelinePlotData);
    const plotDisplayName = getPlotDisplayTitle(plot);
    const showLegend = !hideLegend && !pipelineProcessing && plotData?.count !== 0;

    const { generateAndSaveThumbnail, loadingPlotThumbnailGeneration } = usePlotThumbnail({
        containerRef: plotThumbnailRef,
        experiment,
        plot,
        onComplete: () => {
            mutatePlot();
            if (isRefreshingThumbnail) {
                setIsRefreshingThumbnail(false);
            }
        },
    });

    useEffect(() => {
        // If on analysis page, create thumbnail through PlotView.tsx for better layout
        const isAnalysisPage = router.asPath.includes('analysis');
        const isModalViewOnCanvas = router.asPath.includes('canvas') && isModalView;
        if (isAnalysisPage || isModalViewOnCanvas) return;

        const noThumbnailExists = !thumbnailUri;
        const plotHasLoaded =
            isPlotFullyRendered &&
            !loading &&
            dataReady &&
            legendLoaded &&
            !pipelineProcessing &&
            !isPlotDataValidating;
        if (!plotHasLoaded || (!noThumbnailExists && !isRefreshingThumbnail)) {
            setIsPlotFullyRendered(false);
            return;
        }
        if (
            (noThumbnailExists || isRefreshingThumbnail) &&
            !disablePlotImages &&
            displaySupportsThumbnails &&
            plotHasLoaded
        ) {
            generateAndSaveThumbnail();
        }
    }, [
        isPlotFullyRendered,
        dataReady,
        loading,
        legendLoaded,
        pipelineProcessing,
        isPlotDataValidating,
        isRefreshingThumbnail,
    ]);

    useEffect(() => {
        if (legendPosition === 'bottom' || display?.display_type === 'enrichment_plot') {
            setShowLegendBottom(true);
        } else if (size.width && size.width < MIN_LEGEND_COLUMN_WIDTH && legendPosition !== 'right') {
            setShowLegendBottom(true);
        } else if (display.hasOwnProperty('is_transposed') && !display?.is_transposed && legendPosition !== 'right') {
            setShowLegendBottom(true);
        } else {
            setShowLegendBottom(false);
        }
    }, [legendPosition, size.width, plot?.display]);

    const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (LocalStorageService.isDevMode()) {
            return;
        }
        event.preventDefault();
        setMousePosition({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const isDoubleWide = isDoubleWidePlot(plot);

    const commentsEnabled = hasCommentsPermission({ features, experiment, user });

    const expandPlotFullScreen = () => {
        if (plot) {
            handleChangeSelectedPlot?.(plot);
            if (commentsEnabled && permissions.canEdit) {
                // Open comments if permissions allow
                setOpenOnComments?.(true);
            } else {
                // Else open plot fullscreen
                setOpenOnPlot?.(true);
            }
            if (hideEditForm) {
                setHideEditForm?.(true);
            }
            if (experiment) {
                setActiveExperiment(experiment);
            }
            setCurrentPlotPanel?.(plot.analysis?.uuid ? 'plot' : 'analysis');
            setExperimentModalOpen?.(true);
            updateHashWithPlotUUID(plot.uuid);
        }
    };

    const handleLegendLoad = () => {
        setTimeout(() => {
            setLegendLoaded(true);
        }, 200);
    };

    const renderTooltipWrapper = useCallback((children: JSX.Element | JSX.Element[]) => {
        return (
            <Tooltip
                title="Open to view interactive plot"
                placement="top"
                enterDelay={1_000}
                enterNextDelay={1_000}
                followCursor
            >
                <div className="w-full h-full">{children}</div>
            </Tooltip>
        );
    }, []);

    const refreshThumbnail = () => {
        if (!disablePlotImages && displaySupportsThumbnails) {
            setIsRefreshingThumbnail(true);
            return;
        }
        alert('Failed to refresh thumbnail. Please try again later or contact support if the issue persists.');
    };

    if (!!plotDataError)
        return (
            <div className="flex flex-col justify-center px-8 py-16 text-center">
                <PlotDataErrorView
                    error={ApiError.getMessage(plotDataError)}
                    code={plotDataError?.code}
                    plot={plot ?? plotParam}
                    experiment={experiment}
                />
            </div>
        );
    if (!dataReady)
        return (
            <div className="relative flex h-full w-full items-center rounded-2xl bg-white" data-cy="plot-card">
                <CanvasPlotShellView
                    plot={plot}
                    loading={loading}
                    experiment={experiment}
                    hideButtons={hidePlotShellButtons}
                    hideEditForm={hideEditForm}
                />
            </div>
        );

    if (!experiment) return;

    return (
        <NoSsr>
            <PlotContextProvider
                experiment={experiment}
                analysisParameters={analysisParameters}
                plot={plot ?? plotParam}
                plotId={plotId}
                hideEditButton={hideEditButton}
                plotData={plotData}
                plotDataError={plotDataError}
                plotDataLoading={plotDataLoading}
                isPlotDataValidating={isPlotDataValidating}
                overrides={overrides}
                publicationMode={publicationMode}
                isExportMode={isExportMode}
                disableMaxHeight={disableMaxHeight}
                mutatePlot={mutatePlot}
                isEditing={isSelected}
                setDragEnabled={() => false}
            >
                <ConditionalWrapper condition={isUsingThumbnail} wrapper={renderTooltipWrapper}>
                    <div
                        className={cn('group relative h-full w-full bg-white', cursorClassName, {
                            'md:col-span-2': isDoubleWide && !publicationMode,
                            'cursor-pointer': isBlank(cursorClassName) && isDoubleClickEnabled(plot),
                            'rounded-2xl': !disableRoundedCorners,
                            'overflow-hidden': plot?.analysis_type === 'image',
                        })}
                        data-cy="plot-card"
                        data-plot-id={plot?.uuid}
                        onContextMenu={isRightClickEnabled(plot) ? handleRightClick : undefined}
                    >
                        {isPlotDataValidating && !loading && (
                            <div className={'absolute left-4 top-4 flex items-center space-x-1'}>
                                <CircularProgress size={12} />
                                <span className="text-xs text-default"></span>
                            </div>
                        )}
                        <div ref={plotSizeRef} className="h-full">
                            <div className="relative flex h-full flex-grow flex-col-reverse">
                                <div
                                    className={cn('flex h-full flex-col space-y-2 ', {
                                        'justify-between':
                                            dataReady &&
                                            plot?.display?.display_type !== 'image' &&
                                            !pipelineProcessing &&
                                            showPlotTitle,
                                        'justify-center': !dataReady || pipelineProcessing || !showPlotTitle,
                                        'pb-4': overrides.hasOverrides,
                                    })}
                                >
                                    {dataReady ? (
                                        <div ref={plotExportRef} className="flex h-full max-h-full w-full flex-col">
                                            {plotDisplayLoadingStates?.[plotId] ? (
                                                <PlotDisplayLoadingView />
                                            ) : (
                                                <>
                                                    <div className="flex w-full flex-col">
                                                        {showHeader ? (
                                                            <div
                                                                className={cn(
                                                                    'flex w-full flex-row justify-between rounded-tl rounded-tr px-4 py-1',
                                                                    {
                                                                        'border-b border-slate-400': !hideBorders,
                                                                    },
                                                                )}
                                                            >
                                                                <p className="text-lg font-semibold tracking-tight">
                                                                    {plotDisplayName}
                                                                </p>

                                                                {showExpand ? (
                                                                    <span className="absolute right-2 top-9 z-50 hidden opacity-0 transition-all duration-200 ease-in-out group-hover:inline-block group-hover:opacity-100">
                                                                        <WhiteIconButton
                                                                            onClick={expandPlotFullScreen}
                                                                            color="primary"
                                                                        >
                                                                            <ArrowsExpandIcon width={24} />
                                                                        </WhiteIconButton>
                                                                    </span>
                                                                ) : null}

                                                                <div className="flex flex-row space-x-2">
                                                                    {!hideMoreMenu && experiment && (
                                                                        <PlotCardMenuButton
                                                                            disableDownloadLinks={disableDownloadLinks}
                                                                            experiment={experiment}
                                                                            hideEditButton={hideEditButton}
                                                                            hideEditForm={hideEditForm}
                                                                            mousePosition={mousePosition}
                                                                            plot={plot ?? plotParam}
                                                                            plotData={plotData}
                                                                            ready={dataReady}
                                                                            setMethodsOpen={setMethodsOpen}
                                                                            setMousePosition={setMousePosition}
                                                                            setResultsOpen={setResultsOpen}
                                                                            showExperimentLink={showExperimentLink}
                                                                            onMenuOpen={() => {
                                                                                setCurrentContextMenuPlotId?.(
                                                                                    plot?.uuid ?? null,
                                                                                );
                                                                            }}
                                                                            onMenuClose={() => {
                                                                                setCurrentContextMenuPlotId?.(null);
                                                                            }}
                                                                            publicKey={publicKey}
                                                                            refreshThumbnail={refreshThumbnail}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="flex w-full flex-row justify-center px-4 py-1">
                                                                <PlotTitle
                                                                    plot={plot}
                                                                    publicationMode={publicationMode}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {isUsingThumbnail ? (
                                                        <div className="relative flex h-full w-full overflow-hidden">
                                                            <PlotImageThumbnail
                                                                src={(thumbnailUri as string) || ''}
                                                                alt={`Thumbnail for plot ${plotId}`}
                                                                onError={() => setPlotThumbnailError(true)}
                                                                error={plotThumbnailError}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            ref={plotThumbnailRef}
                                                            className={cn(
                                                                'flex h-full w-full justify-center overflow-hidden',

                                                                {
                                                                    'flex-col': showLegendBottom || horizontalOnly,
                                                                    'py-2 px-4': showHeader,
                                                                },
                                                            )}
                                                        >
                                                            <div
                                                                className="relative flex h-full w-full justify-center overflow-hidden"
                                                                ref={plotContainerRef}
                                                            >
                                                                <ResizableContainerContextProvider
                                                                    containerRef={plotContainerRef}
                                                                    size={plotContainerSize}
                                                                >
                                                                    <PlutoErrorBoundary>
                                                                        <AnalysisCategoryPlotContentView
                                                                            dragMode={dragMode}
                                                                            setZoomTransform={setZoomTransform}
                                                                            setIsPlotFullyRendered={
                                                                                setIsPlotFullyRendered
                                                                            }
                                                                            loadingPlotThumbnailGeneration={
                                                                                loadingPlotThumbnailGeneration
                                                                            }
                                                                        />
                                                                    </PlutoErrorBoundary>
                                                                </ResizableContainerContextProvider>
                                                            </div>

                                                            {legendItems.length > 0 && showLegend && (
                                                                <div
                                                                    className={cn('mt-4', {
                                                                        'max-w-[30%] flex-shrink-0':
                                                                            !showLegendBottom && !horizontalOnly,
                                                                    })}
                                                                >
                                                                    <PlotLegendView
                                                                        items={legendItems}
                                                                        style={legendStyle ?? ThemeStyle.outline}
                                                                        horizontal={showLegendBottom || horizontalOnly}
                                                                        fullWidth={
                                                                            !disableFullWidth &&
                                                                            (plot?.display?.is_full_width ||
                                                                                publicationMode)
                                                                        }
                                                                        disableMaxHeight={isCanvasNode}
                                                                        disableMaxWidth={isCanvasNode}
                                                                        disableScroll={!legendIsScrollable}
                                                                        onLoad={handleLegendLoad}
                                                                    />
                                                                </div>
                                                            )}
                                                            {showLegend &&
                                                                plot &&
                                                                (plot?.analysis as ExperimentAnalysisWithCustomLegend)
                                                                    ?.pipeline_status === 'completed' && (
                                                                    <div
                                                                        className={cn('mt-4', {
                                                                            'max-w-[30%] flex-shrink-0':
                                                                                !showLegendBottom && !horizontalOnly,
                                                                        })}
                                                                    >
                                                                        <CustomPlotLegendView
                                                                            plot={plot}
                                                                            shortname={
                                                                                plot.analysis
                                                                                    ?.analysis_type as AnalysisShortname
                                                                            }
                                                                            plotData={
                                                                                plotData as PipelineStatusPlotData
                                                                            }
                                                                            showLegendBottom={showLegendBottom}
                                                                            publicationMode={publicationMode}
                                                                            isExportMode={isExportMode}
                                                                            onLoad={handleLegendLoad}
                                                                        />
                                                                    </div>
                                                                )}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="h-full w-full">
                                            <CanvasPlotShellView
                                                plot={plot}
                                                loading={loading}
                                                experiment={experiment}
                                                hideButtons={hidePlotShellButtons}
                                                hideEditForm={hideEditForm}
                                            />
                                        </div>
                                    )}
                                </div>
                                {dataReady && overrides.hasOverrides && (
                                    <div className="absolute bottom-0 left-0 flex-shrink-0 px-2 py-2 text-red-800">
                                        <Button
                                            color="inherit"
                                            startIcon={<DeleteOutlineRounded />}
                                            variant="text"
                                            onClick={() => clearPlotOverrides?.(plotId)}
                                            size="small"
                                        >
                                            Discard Changes
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </ConditionalWrapper>
                {plot?.analysis && experiment && (
                    <>
                        <MethodsModal
                            open={methodsOpen}
                            onClose={() => setMethodsOpen(false)}
                            plot={plot}
                            experiment={experiment}
                            overrideAnalysisId={overrides.analysis_id}
                            publicKey={publicKey}
                        />
                        <ResultsModal
                            open={resultsOpen}
                            onClose={() => setResultsOpen(false)}
                            plot={plot}
                            experiment={experiment}
                        />
                    </>
                )}
            </PlotContextProvider>
        </NoSsr>
    );
};

export default PlotCardView;

import { useEffect, useRef } from 'react';
import { TimeoutValue } from '../util/ObjectUtil';

export function usePollingEffect(
    asyncCallback: () => void,
    dependencies: any = [],

    { trigger = false, interval = 20_000, onCleanUp = () => {} } = {},
) {
    const timeoutIdRef = useRef<TimeoutValue | null>(null);
    useEffect(() => {
        let _stopped = false;
        if (trigger) {
            (async function pollingCallback() {
                try {
                    await asyncCallback();
                } finally {
                    timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, interval);
                }
            })();
        }
        return () => {
            _stopped = true;
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
            onCleanUp?.();
        };
    }, [...dependencies, interval, trigger]);
}

import React, { ReactNode } from 'react';
import { CheckCircle, ErrorOutline, InfoOutlined, WarningOutlined } from '@mui/icons-material';
import CanvasPlotShellActions from '@components/plots/CanvasPlotShellActions';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import { PlotDisplayOption } from '@models/PlotDisplayOption';
import Plot from '@models/Plot';
import cn from 'classnames';
import Experiment from '@/src/models/Experiment';

type Severity = 'error' | 'warn' | 'info' | 'success';
type Props = {
    error?: string | null;
    code?: string | null | undefined;
    severity?: Severity;
    plot?: Plot<ExperimentAnalysis, PlotDisplayOption>;
    experiment?: Experiment | null;
};
const PlotDataErrorView = ({ error, code, severity: severityProp, plot, experiment }: Props) => {
    let headline: ReactNode = 'Whoops! Something went wrong...';
    let severity = severityProp;
    switch (code) {
        case 'invalid_data_format':
            headline = 'There is an issue with your plot\xa0data';
            severity = 'warn';
            break;
        default:
            break;
    }

    return (
        <div className="flex h-full w-full flex-col justify-center px-8 py-24 text-center">
            <div
                className={cn('mx-auto mb-4 rounded-full  p-4', {
                    'bg-error text-error': severity === 'error',
                    'bg-amber-100 text-amber-500': severity === 'warn',
                    'bg-indigo-200 text-indigo-600': severity === 'info',
                    'bg-success text-success': severity === 'success',
                })}
            >
                {severity === 'error' && <ErrorOutline height={24} width={24} />}
                {severity === 'warn' && <WarningOutlined height={24} width={24} />}
                {severity === 'info' && <InfoOutlined height={24} width={24} />}
                {severity === 'success' && <CheckCircle height={24} width={24} />}
            </div>
            <h2 className="text-xl font-semibold tracking-tight">{headline}</h2>
            <p className="text-base">
                {error ?? 'An unexpected error occurred while processing your plot data. Please try again later.'}
            </p>
            {plot ? (
                <div className="mt-4">
                    <CanvasPlotShellActions plot={plot} experiment={experiment} />
                </div>
            ) : null}
        </div>
    );
};

export default PlotDataErrorView;

import Experiment from '@models/Experiment';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import Plot, { supportedThumbnailDisplayTypes } from '@models/Plot';
import { useCallback, useEffect, useState } from 'react';
import { PlotOverrideSummary, useOptionalExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { AnyExperimentData } from '@models/ExperimentData';
import { hasAnyPipelineStatus } from '@components/plots/PlotUtil';
import { isDefined, isEmptyObject } from '@util/TypeGuards';
import { blankToUndefined } from '@util/StringUtil';
import Logger from '@util/Logger';
import useMatchMutate from '@hooks/useMatchMutate';
import CellScatterPlotDisplayOption from '../models/plotDisplayOption/CellScatterPlotDisplayOption';

const logger = Logger.make('usePlot');

const displayTypeDataLimit = {
    volcano_plot_v2: 150_000,
    dot_plot: 2_000,
    heatmap: 3_000,
    ridge_plot: 1_000_000,
    violin_plot: 1_000_000,
    sample_scatter_plot: 5_000,
    bar_plot: 5_000,
    box_plot: 5_000,
};

type Props = {
    dataLimit?: number;
    disablePlotData?: boolean;
    disablePolling?: boolean;
    overrides?: PlotOverrideSummary | null;
    experiment?: Experiment | null;
    plot?: Plot | null | undefined;
    plotData?: AnyExperimentData;
    plotId: string | null | undefined;
    publicKey?: string | null;
    revalidateOnMount?: boolean;
    searchTerm?: string | undefined | null;
    useThumbnailImage?: boolean;
    sortDataBy?: string | null;
};
const usePlot = ({
    dataLimit,
    disablePlotData = false,
    disablePolling = false,
    experiment,
    overrides: overridesParam,
    plot: plotParam,
    plotData: plotDataParam,
    plotId,
    publicKey,
    revalidateOnMount,
    searchTerm,
    useThumbnailImage,
    sortDataBy,
}: Props) => {
    const { getPlotOverrides } = useOptionalExperimentDetailViewContext();
    const [refreshInterval, setRefreshInterval] = useState(0);
    const { pathEqualsIgnoreQueryMutate } = useMatchMutate();
    const overrides = overridesParam ?? (plotId ? getPlotOverrides?.(plotId) : null);
    const {
        data: plot,
        error: plotError,
        isValidating: isPlotValidating,
        mutate: mutatePlot,
    } = useSWR(
        isDefined(plotId) && experiment?.uuid
            ? Endpoints.lab.experiment.plot.base(
                  {
                      experimentId: experiment.uuid,
                      plotId,
                  },
                  {
                      display_id: overrides?.display_id ?? undefined,
                      analysis_id: overrides?.analysis_id ?? undefined,
                      key: publicKey ?? undefined,
                  },
              )
            : null,
        { fallbackData: plotParam, refreshInterval, revalidateOnMount },
    );
    const plotLoading = !plot && !plotError;

    const getPlotDataLimit = useCallback(() => {
        if (dataLimit) return dataLimit;

        const limit = displayTypeDataLimit[plot?.display?.display_type as keyof typeof displayTypeDataLimit];
        if (limit) return limit;

        if (plot?.display?.display_type === 'cell_scatter_plot') {
            const downsampling_num = (plot?.display as CellScatterPlotDisplayOption)?.downsampling_num;
            const useDownsampling = downsampling_num && downsampling_num <= 100_000;
            return useDownsampling ? downsampling_num : 100_000;
        }
        return undefined;
    }, [dataLimit, plot?.display?.display_type]);

    const displaySupportsThumbnails = supportedThumbnailDisplayTypes.includes(plot?.display?.display_type || '');
    const thumbnailExists = plot?.thumbnail_uri && !isEmptyObject(plot?.thumbnail_uri);
    const validThumbnail = useThumbnailImage && displaySupportsThumbnails ? !thumbnailExists : true;

    const experimentExists = experiment?.uuid;
    const analysisExists = plot?.analysis && isDefined(plotId);
    const noSkipFlagsActive = !disablePlotData;

    const shouldFetchPlotData = noSkipFlagsActive && validThumbnail && analysisExists && experimentExists;

    /** Fetch plot data */
    const key = shouldFetchPlotData
        ? Endpoints.lab.experiment.plot.data(
              {
                  plotId: plotId,
                  experimentId: experiment.uuid,
              },
              {
                  analysis_id: overrides?.analysis_id ?? undefined,
                  display_id: overrides?.display_id ?? undefined,
                  search: blankToUndefined(searchTerm) ?? undefined,
                  sort_by: blankToUndefined(sortDataBy),
                  limit: getPlotDataLimit(),
                  key: publicKey ?? undefined,
              },
          )
        : null;

    const {
        data: plotData,
        error: plotDataError,
        isValidating: isPlotDataValidating,
    } = useSWR<AnyExperimentData>(key, { refreshInterval, revalidateOnMount, fallbackData: plotDataParam });

    const plotDataLoading = key ? plotData === undefined && !plotDataError && isPlotDataValidating : false;

    useEffect(() => {
        if (!plot || disablePolling) {
            setRefreshInterval(0);
            return;
        }
        const hasAnyPending =
            hasAnyPipelineStatus(plot, 'in_progress') ||
            plotData?.pipeline_status === 'in_progress' ||
            plotData?.plot_status === 'in_progress';
        if (hasAnyPending) {
            setRefreshInterval(10_000);
        } else {
            setRefreshInterval(0);
        }
    }, [plot, disablePolling, plotData]);

    useEffect(() => {
        if (!shouldFetchPlotData) return;
        if (plotId && experiment?.uuid) {
            logger.debug('plot display type changed to', plot?.display?.display_type, 'revalidating plot data');
            pathEqualsIgnoreQueryMutate(
                Endpoints.lab.experiment.plot.data({
                    plotId: plotId,
                    experimentId: experiment.uuid,
                }),
            );
        }
    }, [plot?.display?.display_type, plotId, experiment?.uuid, shouldFetchPlotData]);

    return {
        plot,
        plotData,
        plotDataError,
        plotError,
        isPlotDataValidating,
        isPlotValidating,
        plotLoading,
        overrides,
        mutatePlot,
        plotDataLoading,
    };
};

export default usePlot;

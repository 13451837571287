import useSWRImmutable from 'swr/immutable';
import { LabSettingsResponse } from '@api/lab/LabApiTypes';
import Endpoints from '@services/Endpoints';
import { LabSettings, useSettingsHelpers } from '@hooks/useSettingsHelpers';
import useAuth from '@hooks/useAuth';

const useOrganizationSettings = (): LabSettings & { loadingOrganismSettings: boolean } => {
    const { user } = useAuth();
    const { data, error, isValidating } = useSWRImmutable<LabSettingsResponse>(() =>
        user ? Endpoints.organization.settings() : null,
    );
    return { ...useSettingsHelpers({ data, error }), loadingOrganismSettings: isValidating };
};

export default useOrganizationSettings;

import { getCategoryForAnalysis } from '@models/analysis/AnalysisType';
import ComparativePlotDisplayView from '@components/analysisCategories/comparative/ComparativePlotDisplayView';
import SummaryPlotDisplayView from '@components/analysisCategories/summary/SummaryPlotDisplayView';
import { usePlotContext } from '@contexts/PlotContext';
import DimensionalityReductionPlotDisplayView from '@components/analysisCategories/dimensionalityReduction/DimensionalityReductionPlotDisplayView';
import PlotNotSupportedView from '@components/analysisCategories/PlotNotSupportedView';
import PathwayPlotDisplayView from '@components/analysisCategories/pathway/PathwayPlotDisplayView';
import GenomePlotDisplayView from '@components/analysisCategories/genome/GenomePlotDisplayView';
import ClinicalOutcomesPlotDisplayView from '@components/analysisCategories/clinicalOutcomes/ClinicalOutcomesPlotDisplayView';
import ContentPlotDisplayView from '@components/analysisCategories/content/ContentPlotDisplayView';
import ExternalPlotDisplayView from '@components/analysisCategories/external/ExternalPlotDisplayView';
import {
    CustomPlotStylingOptions,
    DragMode,
} from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import { ZoomTransformObject } from '../plots/builders/BasePlotBuilder';
import { StateSetter } from '@/src/contexts/ContextTypes';

type Props = {
    customPlotStylingOptions?: CustomPlotStylingOptions | null;
    dragMode?: DragMode;
    isPlotFullyRendered?: boolean;
    loadingPlotThumbnailGeneration?: boolean;
    setIsPlotFullyRendered?: StateSetter<boolean>;
    setZoomTransform?: (transformObject: ZoomTransformObject) => void;
    xAxisTruncated?: boolean;
    zoomEnabled?: boolean;
};
const AnalysisCategoryPlotContentView = ({
    customPlotStylingOptions = null,
    dragMode = undefined,
    isPlotFullyRendered,
    loadingPlotThumbnailGeneration,
    setIsPlotFullyRendered,
    setZoomTransform,
    xAxisTruncated = false,
    zoomEnabled = undefined,
}: Props) => {
    const { plot } = usePlotContext();
    const analysisType = plot?.analysis?.analysis_type ?? plot?.analysis_type;
    const analysisCategory = plot?.analysis?.category?.shortname ?? getCategoryForAnalysis(analysisType);

    if (!plot) return;

    switch (analysisCategory) {
        case 'clinical_outcomes':
            return <ClinicalOutcomesPlotDisplayView customPlotStylingOptions={customPlotStylingOptions} />;
        case 'genome':
            return <GenomePlotDisplayView customPlotStylingOptions={customPlotStylingOptions} />;
        case 'comparative':
            return (
                <ComparativePlotDisplayView
                    customPlotStylingOptions={customPlotStylingOptions}
                    dragMode={dragMode}
                    isPlotFullyRendered={isPlotFullyRendered}
                    loadingPlotThumbnailGeneration={loadingPlotThumbnailGeneration}
                    setIsPlotFullyRendered={setIsPlotFullyRendered}
                />
            );
        case 'summary':
            return (
                <SummaryPlotDisplayView
                    customPlotStylingOptions={customPlotStylingOptions}
                    isPlotFullyRendered={isPlotFullyRendered}
                    setIsPlotFullyRendered={setIsPlotFullyRendered}
                />
            );
        case 'dimensionality_reduction':
            return <DimensionalityReductionPlotDisplayView customPlotStylingOptions={customPlotStylingOptions} />;
        case 'pathway':
            return (
                <PathwayPlotDisplayView
                    customPlotStylingOptions={customPlotStylingOptions}
                    zoomEnabled={zoomEnabled}
                    setZoomTransform={setZoomTransform}
                    xAxisTruncated={xAxisTruncated}
                />
            );
        case 'external':
            return <ExternalPlotDisplayView />;
        case 'image':
        case 'content':
            return <ContentPlotDisplayView />;
        default:
            return <PlotNotSupportedView />;
    }
};

export default AnalysisCategoryPlotContentView;

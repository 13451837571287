import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { ReactNode, useCallback, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import Logger from '@util/Logger';
import { useFormikContext } from 'formik';
import Button from '@components/Button';
import cn from 'classnames';
import { PhotographIcon } from '@heroicons/react/outline';
import TextAreaField from '@components/forms/TextAreaField';
import { FormikFieldError } from '@components/forms/FieldError';
import { BYTES_IN_MB } from '@util/StringUtil';
import { Alert, AlertTitle } from '@mui/material';
import SimpleTargetsField from '@components/experiments/analyses/assaySummary/SimpleTargetsField';
import { AnalysisParameters } from '@models/AnalysisParameters';
import AsyncTargetPicker from '@components/AsyncTargetPicker';
import { ImageAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import {
    isAssaySummaryAnalysisParameters,
    isDifferentialExpressionAnalysisParameters,
} from '@models/AnalysisParametersHelpers';
import { ImageAnalysis } from '@models/analysis/ImageAnalysis';
import { PreviewFile } from '@contexts/ExperimentDetailViewContext';

const logger = Logger.make('ImageAnalysisFormFields');

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters };
const ImageAnalysisFormFields = ({ plot, experiment, analysisParameters }: Props) => {
    const form = useFormikContext<ImageAnalysisFormValues>();
    const [fileError, setFileError] = useState<ReactNode | null>(null);
    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageLoadError = () => {
        setFileError('Unable to load the image');
        setImageLoadError(true);
    };

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
        logger.info('dropzone onDrop triggered', acceptedFiles, fileRejections);

        // handle rejections;
        if (fileRejections?.length > 0) {
            const messages = new Set<string>();
            fileRejections
                .flatMap((rejection) => rejection.errors)
                .forEach((error) => {
                    switch (error.code) {
                        case ErrorCode.TooManyFiles:
                            messages.add('You many only select one file');
                            break;
                        case ErrorCode.FileInvalidType:
                            messages.add(
                                'File must be an image. Accepted file extensions: .jpg, .jpeg, .png, .svg, .gif',
                            );
                            break;
                        default:
                            messages.add(error.message);
                            break;
                    }
                });
            setFileError(
                <>
                    <AlertTitle>Error adding image</AlertTitle>
                    <ul className="list-disc">
                        {[...messages].map((message) => (
                            <li key={message}>{message}</li>
                        ))}
                    </ul>
                </>,
            );
            return [];
        }
        setFileError(null);

        const imageFile = acceptedFiles.map((file) => {
            return Object.assign(file, {
                preview: URL.createObjectURL(file),
            });
        })[0];
        if (!imageFile) {
            return;
        }
        logger.info('preview image files', imageFile);
        form.setFieldValue('image', imageFile);
        return [imageFile];
    }, []);

    const dropzone = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        disabled: false,
        accept: {
            'image/*': ['image/gif', 'image/jpeg', 'image/png', 'image/tiff', 'image/svg+xml'],
        },
        maxFiles: 1,
        maxSize: BYTES_IN_MB * 100,
        multiple: false,
    });

    const analysis = plot.analysis as ImageAnalysis | null;
    const imageUrl = (form.values.image as PreviewFile)?.preview ?? analysis?.image_url;

    return (
        <div className="space-y-8">
            {fileError && (
                <Alert severity="error" onClose={() => setFileError(null)}>
                    {fileError}
                </Alert>
            )}
            <div
                className={cn('flex max-h-[250px] flex-col justify-center rounded-2xl', {
                    'items-center bg-indigo-50': dropzone.isDragActive,
                    'min-h-[250px] border border-dashed border-indigo-400': !imageUrl || dropzone.isDragActive,
                })}
                {...dropzone.getRootProps()}
            >
                <input {...dropzone.getInputProps()} />
                {dropzone.isDragActive && (
                    <div className="flex flex-col items-center justify-center text-center">
                        <PhotographIcon width={48} className="text-indigo-600" />
                        <p className="text-xl font-semibold">Drop image here</p>
                    </div>
                )}

                {!dropzone.isDragActive && !imageUrl && (
                    <div className="space-y-2">
                        <p className="text-center">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={dropzone.open}
                                startIcon={<PhotographIcon className="text-white" width={24} />}
                            >
                                Choose an image
                            </Button>
                        </p>
                        <p className="text-center">Select an image for analysis</p>
                    </div>
                )}

                {!dropzone.isDragActive && imageUrl && (
                    <div className="space-y-2">
                        <div className="w-full ">
                            {!imageLoadError && (
                                <img
                                    src={imageUrl}
                                    alt="Selected image for analysis"
                                    className="max-h-[250px] w-full object-contain object-left"
                                    onError={() => handleImageLoadError()}
                                />
                            )}
                        </div>
                        <div>
                            <Button
                                onClick={dropzone.open}
                                startIcon={<PhotographIcon width={24} />}
                                variant="outlined"
                                color="primary"
                                size="small"
                            >
                                Change image
                            </Button>
                        </div>
                    </div>
                )}
                <FormikFieldError name="image" />
            </div>
            <div>
                <TextAreaField name="methods" label="Methods" minRows={4} />
            </div>

            {experiment.type.shortname === 'simple' && isAssaySummaryAnalysisParameters(analysisParameters) && (
                <SimpleTargetsField name="targets" analysisParameters={analysisParameters} />
            )}
            {experiment.type.shortname !== 'simple' &&
                isDifferentialExpressionAnalysisParameters(analysisParameters) && (
                    <AsyncTargetPicker experiment={experiment} name="targets" />
                )}
        </div>
    );
};

export default ImageAnalysisFormFields;

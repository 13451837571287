import React, { ReactNode } from 'react';
import { AssayDataUnits } from '@models/Experiment';
import cn from 'classnames';
import CustomLegendColorField, { CustomLegendColorItem } from './CustomLegendColorField';
import { FormHelperText } from '@mui/material';

type Props = Pick<AssayDataUnits, 'units' | 'units_display_name'> & {
    className?: string;
    colorEndName?: string;
    colorStartName?: string;
    endBottomOffsetClassname?: string;
    endColor?: string;
    endHint?: string | ReactNode;
    endLabel?: string;
    endLeftOffsetClassname?: string;
    startBottomOffsetClassname?: string;
    startColor?: string;
    startHint?: string | ReactNode;
    startLabel?: string;
    startLeftOffsetClassname?: string;
};
const RangeColorGroup = ({
    className,
    colorEndName,
    colorStartName,
    endBottomOffsetClassname = '-top-96 mb-8',
    endColor,
    endHint,
    endLabel,
    endLeftOffsetClassname = '-translate-x-36',
    startBottomOffsetClassname = '-top-96 mb-8',
    startColor,
    startHint,
    startLabel,
    startLeftOffsetClassname = 'translate-x-6',
}: Props) => {
    const startItems: CustomLegendColorItem[] = [
        {
            id: colorStartName ?? '',
            label: 'Minimum Color',
            labelName: '',
            themeColor: startColor ?? '',
        },
    ];
    const endItems: CustomLegendColorItem[] = [
        {
            id: colorEndName ?? '',
            label: 'Maximum Color',
            labelName: '',
            themeColor: endColor ?? '',
        },
    ];

    return (
        <div className={cn('grid grid-cols-2 gap-x-2', className)}>
            <div className="flex flex-col">
                <p className="field-label">{startLabel}</p>
                <CustomLegendColorField
                    hideLabel
                    items={startItems}
                    bottomOffsetClassname={startBottomOffsetClassname}
                    leftOffsetClassName={startLeftOffsetClassname}
                    fieldValue={colorStartName}
                />
                <FormHelperText className="inline-block pb-1 !leading-4">{startHint}</FormHelperText>
            </div>
            <div className="flex flex-col">
                <p className="field-label">{endLabel}</p>
                <CustomLegendColorField
                    hideLabel
                    items={endItems}
                    bottomOffsetClassname={endBottomOffsetClassname}
                    leftOffsetClassName={endLeftOffsetClassname}
                    fieldValue={colorEndName}
                />
                <FormHelperText className="inline-block pb-1 !leading-4">{endHint}</FormHelperText>
            </div>
        </div>
    );
};

export default RangeColorGroup;

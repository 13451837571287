import Plot from '@models/Plot';
import { AnalysisParameters, SeuratModuleScoreAnalysisParameters } from '@models/AnalysisParameters';
import { SeuratModuleScoreAnalysis } from '@/src/models/analysis/SeuratModuleScoreAnalysis';
import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Bold from '@components/elements/Bold';
import CodeBlockInline from '@components/CodeBlockInline';
import CellScatterPlotDisplayOption, {
    getCellCoordinateTypeDisplayName,
} from '@/src/models/plotDisplayOption/CellScatterPlotDisplayOption';
import { formatStringToNumberWithSeparator } from '@/src/util/StringUtil';
import AnnotationSet from '@/src/models/Annotation';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null; annotationSets: AnnotationSet[] | null };
const SeuratModuleScoreAnalysisMethodsSection = ({ plot, analysisParameters, annotationSets }: Props) => {
    const analysis = plot.analysis as SeuratModuleScoreAnalysis;
    if (!analysis) {
        return <div>No analysis was found.</div>;
    }

    const display = plot.display as CellScatterPlotDisplayOption;
    const parameters = analysisParameters as SeuratModuleScoreAnalysisParameters;
    const groupingVariables: string[] = [];
    if (display.annotation_set_id) {
        const annotationSetName =
            annotationSets?.find((set) => set.uuid === display.annotation_set_id)?.display_name ?? '';
        groupingVariables.push(annotationSetName);
    }
    if (display.variable_ids) {
        display.variable_ids.forEach((variableId) => {
            const variableName = parameters.variables.find((v) => v.uuid === variableId)?.display_name ?? '';
            groupingVariables.push(variableName);
        });
    }
    if (display.latent_variable_id) {
        const latentVariableName =
            parameters.latent_variables.find((v) => v.uuid === display.latent_variable_id)?.display_name ?? '';
        groupingVariables.push(latentVariableName);
    }

    const formatVariables = () => {
        return groupingVariables.join(', ');
    };

    const geneCount = (analysis.targets ?? []).length;
    const geneString = `${geneCount} gene${geneCount > 1 ? 's' : ''}`;
    return (
        <section>
            <section>
                <MethodsSectionTitle>Module score analysis</MethodsSectionTitle>
                <p className="mb-6">
                    Gene module activity for <Bold>{analysis.module_score_name}</Bold> was generated from the Seurat
                    <sup>1</sup> object data slot of the RNA assay, which contains log normalized values, using the{' '}
                    <CodeBlockInline>AddModuleScore()</CodeBlockInline>.
                </p>
                <p className="mb-6">
                    This function calculates a score for each cell that reflects the activity of{' '}
                    <Bold>{analysis.module_score_name}</Bold> by averaging the expression of <Bold>{geneString}</Bold>{' '}
                    within the module and normalizing these scores to ensure comparability across all cells.
                </p>
            </section>
            {display.display_type === 'cell_scatter_plot' ? (
                <section>
                    <MethodsSectionTitle>Cell scatter plot</MethodsSectionTitle>
                    <p className="mb-6">
                        Cell scatter plot showing the activity scores for <Bold>{analysis.module_score_name}</Bold>{' '}
                        across individual cells, using{' '}
                        <Bold>
                            {getCellCoordinateTypeDisplayName(
                                (display as CellScatterPlotDisplayOption).cell_coordinate_type,
                            )}
                        </Bold>
                        -defined coordinates. Points are colored by the module activity score that was computed from{' '}
                        <Bold>{geneString}</Bold>.
                    </p>
                    {parameters?.cell_count > 100_000 ? (
                        <p className="mb-6">
                            To allow for efficient visualization, results data was downsampled to a maximum of{' '}
                            <Bold>{formatStringToNumberWithSeparator(display.downsampling_num as number)}</Bold> cells
                            using a seed of{' '}
                            <Bold>{formatStringToNumberWithSeparator(display.downsampling_seed as number)}</Bold>. Note
                            that downsampling might result in loss of detail or resolution, and can affect the density,
                            separation, and even presence of clusters in a plot. If your resulting cell scatter plot is
                            missing cells/clusters of interest, try re-running the analysis using a different
                            downsampling seed. To plot all cells in your dataset, check out the low-code{' '}
                            <Bold>Module score</Bold> recipe.
                        </p>
                    ) : null}
                </section>
            ) : null}
            {display.display_type === 'violin_plot' ? (
                <section>
                    <MethodsSectionTitle>Violin plot</MethodsSectionTitle>
                    <p className="mb-6">
                        Violin plot showing the activity scores computed from <Bold>{geneString}</Bold> for{' '}
                        <Bold>{analysis.module_score_name}</Bold> on the y-axis. Cells are grouped by{' '}
                        <Bold>{groupingVariables.length ? formatVariables() : null}</Bold>, and these groups are shown
                        on the x-axis. Violins are colored according to group.
                    </p>
                    <p className="mb-6">
                        To enhance the interpretability of the violin plot, vertical compression was used by reducing
                        the height of the bottom of the violins for cells with very low activity of{' '}
                        <Bold>{analysis.module_score_name}</Bold>. The 10th percentile of non-zero activity scores was
                        used as the vertical compression threshold, with a scaling factor applied to the density values
                        for cells below this threshold. This ensures that very low-activity scores are visually
                        compressed while maintaining the overall structure of the data.
                    </p>
                    {display?.custom_options_json?.useHorizontalCompression ? (
                        <p className="mb-6">
                            Additionally, violins are compressed into a vertical line if 75% or more of the cells in
                            that group have zero activity for <Bold>{analysis.module_score_name}</Bold>. This helps to
                            minimize visual clutter from low-activity groups and emphasizes more meaningful differences
                            in module activity patterns.
                        </p>
                    ) : null}
                    <p className="mb-6">
                        Compression and scaling are applied solely for visualization purposes and do not alter the
                        underlying data.
                    </p>
                </section>
            ) : null}
            {display.display_type === 'ridge_plot' ? (
                <section>
                    <MethodsSectionTitle>Ridge plot</MethodsSectionTitle>
                    <p className="mb-6">
                        Ridge plot showing the activity scores computed from <Bold>{geneString}</Bold> for{' '}
                        <Bold>{analysis.module_score_name}</Bold> on the x-axis. Ridge plot showing the activity scores
                        for <Bold>{analysis.module_score_name}</Bold> on the x-axis. Cells are grouped by{' '}
                        <Bold>{groupingVariables.length ? formatVariables() : null}</Bold>, and these groups are shown
                        on the y-axis. Ridges are colored according to group.
                    </p>
                    <p className="mb-6">
                        To enhance the interpretability of the ridge plot, vertical compression was used to adjust the
                        height of the ridges for cells with very low activity of{' '}
                        <Bold>{analysis.module_score_name}</Bold>. The 10th percentile of non-zero activity scores was
                        used as the vertical compression threshold, with a scaling factor applied to the density values
                        for cells below this threshold. This approach visually compresses very low-activity scores while
                        preserving the overall structure of the density distributions. Additionally, the height of each
                        ridge is scaled to ensure consistent visual representation of density across groups.
                    </p>
                    <p className="mb-6">
                        Compression and scaling are applied solely for visualization purposes and do not alter the
                        underlying data.
                    </p>
                </section>
            ) : null}
        </section>
    );
};

export default SeuratModuleScoreAnalysisMethodsSection;

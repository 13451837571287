import LoadingMessage from '@/src/components/LoadingMessage';
import { useSwitchStyles } from '@/src/components/SwitchStyles';
import useExperimentPlotGroupsV3 from '@/src/hooks/useExperimentPlotGroupsV3';
import { SeuratDifferentialExpressionAnalysisFormValues } from '@/src/models/analysis/SeuratDifferentialExpressionAnalysis';
import Experiment from '@/src/models/Experiment';
import Plot from '@/src/models/Plot';
import { afterCharacter, formatStringToNumberWithSeparator } from '@/src/util/StringUtil';
import { Switch } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';

type Props = {
    experiment: Experiment;
    groupOrderName?: string;
    groupsFieldName?: string;
    plot: Plot;
    setDisplaySaveDisabled: (value: boolean) => void;
    setUpdateGroupsDisabled: (value: boolean) => void;
    title?: string;
};
const ManageGroups = ({
    experiment,
    groupsFieldName = 'groups',
    plot,
    setDisplaySaveDisabled,
    setUpdateGroupsDisabled,
    title = 'Manage groups',
}: Props) => {
    const { values } = useFormikContext<SeuratDifferentialExpressionAnalysisFormValues>();
    const [inputProps, , helpers] = useField<Record<string, boolean>>(groupsFieldName);
    const switchStyles = useSwitchStyles();

    const { loading: groupsLoading, groups } = useExperimentPlotGroupsV3({
        annotation_set_id: values.annotation_set_id ?? '',
        experiment,
        latent_variable_id: values.latent_variable_id ?? '',
        plot,
        variable_ids: values.variable_ids ?? [],
    });

    const filteredGroups = useMemo(
        () =>
            (groups ?? [])
                .filter((g) => g.cell_count > 0)
                .sort((g1, g2) => {
                    const g1ClusterNumber = g1.display_name.match(/Cluster (\d+)/)?.[1];
                    const g2ClusterNumber = g2.display_name.match(/Cluster (\d+)/)?.[1];
                    const g1VariableName = afterCharacter(g1.display_name, '-');
                    const g2VariableName = afterCharacter(g2.display_name, '-');
                    const g1startsWithNumber = g1.display_name.match(/^\d+/)?.[0];
                    const g2startsWithNumber = g2.display_name.match(/^\d+/)?.[0];

                    const sortByClusterNumber = Number(g1ClusterNumber) - Number(g2ClusterNumber);
                    const sortByNumber = Number(g1startsWithNumber) - Number(g2startsWithNumber);
                    const sortByDisplayName = g1.display_name
                        .toLowerCase()
                        .localeCompare(g2.display_name.toLowerCase());
                    const sortByVariableName = g1VariableName.toLowerCase().localeCompare(g2VariableName.toLowerCase());

                    // Else, sort by cluster number
                    if (sortByClusterNumber) {
                        return sortByClusterNumber;
                    } else if (sortByNumber) {
                        // Or, sort by number if it begins with a number
                        return sortByNumber;
                    } else if (sortByDisplayName) {
                        // Or, sort by display name
                        return sortByDisplayName;
                    }

                    // Then sort by variable name
                    return sortByVariableName;
                })
                .map((g) => ({
                    id: g.uuid,
                    display_name: g?.display_name,
                    sample_count: g?.sample_count,
                    cell_count: g?.cell_count,
                })),
        [groups],
    );

    useEffect(() => {
        if (groupsLoading) {
            setUpdateGroupsDisabled(true);
        } else {
            setUpdateGroupsDisabled(false);
        }
        if (groupsLoading || filteredGroups.length === 0) {
            setDisplaySaveDisabled(true);
            return;
        }

        const groupsValue: Record<string, boolean> = inputProps.value ?? {};
        const groupIds = filteredGroups.map((g) => g.id);
        const newGroupsValue: Record<string, boolean> = {};

        for (const id of groupIds) {
            newGroupsValue[id] = groupsValue[id] ?? true;
        }
        if (Object.values(groupsValue).length === 0 && filteredGroups.length > 0) {
            helpers.setValue(newGroupsValue);
        }
        if (Object.values(groupsValue).length !== filteredGroups.length) {
            helpers.setValue(newGroupsValue);
        }

        setDisplaySaveDisabled(false);
        setUpdateGroupsDisabled(false);
    }, [filteredGroups, groupsLoading]);

    const legendItems = useMemo(() => {
        const orderedItemIds = filteredGroups?.map((g) => g.id) ?? [];
        return orderedItemIds.flatMap((groupId) => filteredGroups?.find((g) => g.id === groupId) ?? []);
    }, [filteredGroups]);

    return (
        <div className="mb-8 flex flex-col">
            <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">{title}</h4>
            <div className="space-y-1 -mt-[30px]">
                {groupsLoading ? (
                    <div className="mt-[27px]">
                        <LoadingMessage inline immediate message="Loading..." />
                    </div>
                ) : (
                    <>
                        {legendItems.length > 0 ? (
                            <p className="flex-1 flex justify-end text-xs tracking-tight mb-3">Show cells?</p>
                        ) : null}

                        {legendItems.map((group, index) => (
                            <div key={index} className="flex flex-row items-center justify-between space-x-4">
                                <p>
                                    {group.display_name} ({formatStringToNumberWithSeparator(group.cell_count)} cells)
                                </p>
                                <Switch
                                    sx={switchStyles}
                                    checked={inputProps.value?.[group.id] ?? true}
                                    onChange={(e) => {
                                        helpers.setValue({
                                            ...inputProps.value,
                                            [group.id]: e.target.checked,
                                        });
                                    }}
                                />
                            </div>
                        ))}

                        {legendItems.length === 0 && !groupsLoading ? (
                            <p className="mt-[22px]">No groups found</p>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

export default ManageGroups;

import React from 'react';
import { EditPlotPanelName, useOptionalExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import Button from '@components/Button';
import { MinusCircleIcon, PencilIcon } from '@heroicons/react/outline';
import Plot from '@models/Plot';
import { useOptionalActiveExperimentContext } from '@/src/contexts/ActiveExperimentContext';
import Experiment from '@/src/models/Experiment';

type Props = {
    editPanel?: EditPlotPanelName;
    editText?: string;
    experiment?: Experiment | null;
    hideEditForm?: boolean;
    plot: Plot;
};
const CanvasPlotShellActions = ({
    editPanel = 'analysis',
    editText = 'Continue analysis',
    experiment,
    hideEditForm,
    plot,
}: Props) => {
    const experimentContext = useOptionalExperimentDetailViewContext();
    const { setActiveExperiment } = useOptionalActiveExperimentContext();

    const {
        archivePlot,
        disableRemovePlots,
        handleChangeSelectedPlot,
        permissions,
        setCurrentPlotPanel,
        setExperimentModalOpen,
        setHideEditForm,
    } = experimentContext ?? {};

    if (!permissions?.canEdit || disableRemovePlots || !experimentContext) {
        return null;
    }
    return (
        <div className="flex flex-col items-center justify-center space-y-2 text-error ">
            <Button
                size="small"
                color="primary"
                variant="contained"
                startIcon={<PencilIcon width={16} className="mr-1" />}
                onClick={() => {
                    setActiveExperiment?.(experiment as Experiment);
                    handleChangeSelectedPlot?.(plot);
                    setCurrentPlotPanel?.(editPanel);
                    if (hideEditForm) {
                        setHideEditForm?.(true);
                    }
                    setExperimentModalOpen?.(true);
                }}
            >
                {editText}
            </Button>
            <Button
                variant="text"
                color="inherit"
                size="small"
                onClick={() => archivePlot?.(plot.uuid, true, plot?.linked_experiment_id)}
                startIcon={<MinusCircleIcon height={18} width={18} className="text-error" />}
            >
                <span className="text-error">Remove plot</span>
            </Button>
        </div>
    );
};

export default CanvasPlotShellActions;

import { useEffect, useState } from 'react';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import useAuth from './useAuth';
import AnnotationSet, { Annotation, AnnotationSetDetails } from '@models/Annotation';
import Experiment from '../models/Experiment';
import {
    AnnotationFormValues,
    CreateAnnotationFormValues,
    SaveAnnotationFormValues,
} from '../components/experiments/annotations/AnnotationFormTypes';
import { ResultData } from '../models/PreprocessStep';
import { StateSetter } from '../contexts/ContextTypes';
import { supportedExperimentTypes } from '../models/preprocesses/PreprocessType';
import { getErrorDetails } from '../api/ApiTypes';

const useAnnotations = (
    experiment: Experiment | null | undefined,
    selectedAnnotationSet?: AnnotationSet | null,
    setSelectedAnnotationSet?: StateSetter<AnnotationSet | null>,
) => {
    const { authReady } = useAuth();
    const api = useApi();
    const [annotationSets, setAnnotationSets] = useState<AnnotationSet[] | null>(null);
    const [annotationSetsError, setAnnotationSetsError] = useState<string | null>(null);
    const [annotationSetsLoading, setAnnotationSetsLoading] = useState<boolean>(false);
    const [annotationSetDetails, setAnnotationSetDetails] = useState<AnnotationSetDetails | null>(null);
    const [annotationSetDetailsError, setAnnotationSetDetailsError] = useState<string | null>(null);
    const [annotationSetDetailsLoading, setAnnotationSetDetailsLoading] = useState<boolean>(false);
    const [annotations, setAnnotations] = useState<Annotation[] | null>(null);
    const [annotationsError, setAnnotationsError] = useState<string | null>(null);
    const [annotationsLoading, setAnnotationsLoading] = useState<boolean>(false);
    const [archiveError, setArchiveError] = useState<string | null>(null);
    const [archiveLoading, setArchiveLoading] = useState<boolean>(false);
    const [createAnnotationError, setCreateAnnotationError] = useState<string | null>(null);
    const [createAnnotationLoading, setCreateAnnotationLoading] = useState<boolean>(false);
    const [renameAnnotationError, setRenameAnnotationError] = useState<string | null>(null);
    const [renameAnnotationLoading, setRenameAnnotationLoading] = useState<boolean>(false);
    const [copyAnnotationError, setCopyAnnotationError] = useState<string | null>(null);
    const [copyAnnotationLoading, setCopyAnnotationLoading] = useState<boolean>(false);
    const [updateAnnotationSetError, setUpdateAnnotationSetError] = useState<string | null>(null);
    const [updateAnnotationSetLoading, setUpdateAnnotationSetLoading] = useState<boolean>(false);
    const [plotData, setPlotData] = useState<ResultData | null>(null);
    const [plotDataError, setPlotDataError] = useState<Error | null>(null);
    const [plotDataLoading, setPlotDataLoading] = useState<boolean>(false);

    const annotationId = selectedAnnotationSet?.uuid ?? '';
    const experimentId = experiment?.uuid ?? '';

    const getAnnotationSets = async (experimentIdProp: string) => {
        if (!experimentIdProp) return;
        setAnnotationSetsLoading(true);
        setAnnotationSetsError(null);
        setAnnotationSets(null);
        try {
            const data = await api.get<AnnotationSet[]>(
                Endpoints.lab.experiment.annotations.base({ experimentId: experimentIdProp }),
            );

            setAnnotationSets(data);
            setAnnotationSetsLoading(false);
            return data;
        } catch (e) {
            setAnnotationSetsError(getErrorDetails(e).message);
            setAnnotationSetsLoading(false);
        }
    };
    const getAnnotationSetDetails = async () => {
        if (!experimentId) return;
        setAnnotationSetDetailsLoading(true);
        setAnnotationSetDetailsError(null);
        try {
            const data = await api.get<AnnotationSetDetails>(
                Endpoints.lab.experiment.annotationSet.base({ experimentId, annotationSetId: annotationId }),
            );

            setAnnotationSetDetails(data);
            setAnnotationSetDetailsLoading(false);
        } catch (e) {
            setAnnotationSetDetailsError(getErrorDetails(e).message);
            setAnnotationSetDetailsLoading(false);
        }
    };
    const getAnnotations = async () => {
        setAnnotationsLoading(true);
        setAnnotationsError(null);
        try {
            const data = await api.get<Annotation[]>(
                Endpoints.lab.experiment.annotationSet.annotations({ experimentId, annotationSetId: annotationId }),
            );

            setAnnotations(data);
            setAnnotationsLoading(false);
        } catch (e) {
            setAnnotationsError(getErrorDetails(e).message);
            setAnnotationsLoading(false);
        }
    };
    const archiveAnnotation = async () => {
        setArchiveLoading(true);
        setArchiveError(null);
        try {
            const successMessage = await api.post<AnnotationSet>(
                Endpoints.lab.experiment.annotationSet.archive({ experimentId, annotationSetId: annotationId }),
            );
            setArchiveLoading(false);
            const newCurrentAnnotations = (annotationSets as AnnotationSet[])?.filter(
                (annotation) => annotation.uuid !== annotationId,
            );
            setAnnotationSets(newCurrentAnnotations);
            setSelectedAnnotationSet?.(null);
            return successMessage;
        } catch (e) {
            setArchiveError(getErrorDetails(e).message);
            setArchiveLoading(false);
        }
    };
    const createAnnotation = async ({ resolution, display_name }: CreateAnnotationFormValues) => {
        setCreateAnnotationLoading(true);
        setCreateAnnotationError(null);
        try {
            const createdAnnotation = await api.post<AnnotationSet>(
                Endpoints.lab.experiment.annotations.base({ experimentId }),
                {
                    resolution,
                    display_name,
                },
            );
            setCreateAnnotationLoading(false);
            setAnnotationSets((prev) => [...(prev || []), createdAnnotation]);
            return createdAnnotation;
        } catch (e) {
            setCreateAnnotationError(getErrorDetails(e).message);
            setCreateAnnotationLoading(false);
        }
    };
    const renameAnnotation = async (values: SaveAnnotationFormValues) => {
        setRenameAnnotationLoading(true);
        setRenameAnnotationError(null);
        try {
            const updatedAnnotationSet = await api.put<AnnotationSet>(
                Endpoints.lab.experiment.annotationSet.base({ experimentId, annotationSetId: annotationId }),
                values,
            );
            setRenameAnnotationLoading(false);
            const newCurrentAnnotations = annotationSets?.map((annotation) =>
                updatedAnnotationSet.uuid === annotation.uuid ? updatedAnnotationSet : annotation,
            ) as AnnotationSet[];
            setAnnotationSets(newCurrentAnnotations);
            return updatedAnnotationSet;
        } catch (e) {
            setRenameAnnotationLoading(false);
            setRenameAnnotationError(getErrorDetails(e).message);
        }
    };
    const copyAnnotation = async (values: SaveAnnotationFormValues) => {
        setCopyAnnotationLoading(true);
        setCopyAnnotationError(null);
        try {
            const copiedAnnotation = await api.post<AnnotationSet>(
                Endpoints.lab.experiment.annotationSet.copy({ experimentId, annotationSetId: annotationId }),
                values,
            );
            setCopyAnnotationLoading(false);
            setAnnotationSets((prev) => [...(prev || []), copiedAnnotation]);
            return copiedAnnotation;
        } catch (e) {
            setCopyAnnotationLoading(false);
            setCopyAnnotationError(getErrorDetails(e).message);
        }
    };
    const updateAnnotationSet = async (values: AnnotationFormValues) => {
        setUpdateAnnotationSetLoading(true);
        setUpdateAnnotationSetError(null);
        try {
            const updatedAnnotationSet = await api.post<AnnotationSet>(
                Endpoints.lab.experiment.annotationSet.update({ experimentId, annotationSetId: annotationId }),
                values,
            );
            setUpdateAnnotationSetLoading(false);
            const newCurrentAnnotations = annotationSets?.map((annotation) =>
                updatedAnnotationSet.uuid === annotation.uuid ? updatedAnnotationSet : annotation,
            ) as AnnotationSet[];
            setAnnotationSets(newCurrentAnnotations);
            setSelectedAnnotationSet?.(updatedAnnotationSet);
            return updatedAnnotationSet;
        } catch (e) {
            setUpdateAnnotationSetLoading(false);
            setUpdateAnnotationSetError(getErrorDetails(e).message);
        }
    };
    const getPlotData = async (annoId: string) => {
        setPlotDataLoading(true);
        setPlotDataError(null);
        try {
            const plotData = await api.get<ResultData>(
                Endpoints.lab.experiment.annotation.data({
                    experimentId,
                    annotationSetId: annotationId,
                    annotationId: annoId,
                }),
            );
            setPlotData(plotData);
            setPlotDataLoading(false);
            return plotData;
        } catch (e) {
            setPlotDataLoading(false);
            setPlotDataError(e as Error);
        }
    };

    useEffect(() => {
        if (!authReady) return;

        if (experimentId && supportedExperimentTypes.includes(experiment?.type?.shortname ?? '')) {
            setAnnotationSets(null);
            getAnnotationSets(experimentId);
        }
    }, [experimentId, authReady]);

    return {
        annotations,
        annotationsError,
        annotationSetDetails,
        annotationSetDetailsError,
        annotationSetDetailsLoading,
        annotationSets,
        annotationSetsError,
        annotationSetsLoading,
        annotationsLoading,
        archiveAnnotation,
        archiveError,
        archiveLoading,
        copyAnnotation,
        copyAnnotationError,
        copyAnnotationLoading,
        createAnnotation,
        createAnnotationError,
        createAnnotationLoading,
        getAnnotations,
        getAnnotationSetDetails,
        getAnnotationSets,
        getPlotData,
        plotData,
        plotDataError,
        plotDataLoading,
        renameAnnotation,
        renameAnnotationError,
        renameAnnotationLoading,
        setAnnotationSetDetails,
        setAnnotationSets,
        setPlotData,
        updateAnnotationSet,
        updateAnnotationSetError,
        updateAnnotationSetLoading,
    };
};

export default useAnnotations;

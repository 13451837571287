import React from 'react';
import LoadingMessage from '../LoadingMessage';

interface PlotImageThumbnailProps {
    src: string;
    alt: string;
    onError?: () => void;
    error: boolean;
}

const PlotImageThumbnail: React.FC<PlotImageThumbnailProps> = ({ src, alt, onError, error }) => {
    if (src) {
        return (
            <img
                src={src}
                alt={alt}
                className="w-full h-full object-contain"
                onError={() => {
                    onError?.();
                }}
                loading="lazy"
            />
        );
    }
    if (error) {
        <div className="w-full h-full flex items-center justify-center">
            <span className="text-gray-500">No image available</span>
        </div>;
    }
    return (
        <div className="w-full h-full flex items-center justify-center">
            <LoadingMessage immediate />
        </div>
    );
};

export default PlotImageThumbnail;

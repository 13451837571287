import { LegendSVG } from '@components/plots/PlotLegendView';
import GroupColorPickerPopover from '@components/plots/colorPicker/GroupColorPickerPopover';
import EditLegendPopover from '@components/plots/legendEditor/EditLegendPopover';
import { isDefined } from '@util/TypeGuards';
import cn from 'classnames';
import { blankToUndefined, isNotBlank, isWhite } from '@util/StringUtil';
import { PencilIcon } from '@heroicons/react/outline';
import React, { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { BasePlotDisplayOptionFormValues, PlotLegendDisplayFormValues } from '@models/PlotDisplayOption';
import useAuth from '@hooks/useAuth';
import { ThemeStyle } from '@models/PlotConfigs';
import LoadingMessage from '@components/LoadingMessage';
import { OtherIcon } from '@components/icons/custom/OtherIcon';
import { EditIcon } from '@components/icons/custom/EditIcon';

export type CustomLegendColorItem = {
    id: string;
    label: string | ReactNode;
    themeColor: string;
    labelName?: string;
};
type Props = {
    items: CustomLegendColorItem[];
    hideTheme?: boolean;
    loading?: boolean;
    hideLabel?: boolean;
    leftOffsetClassName?: string;
    bottomOffsetClassname?: string;
    fieldValue?: string;
    editable?: boolean;
    onChange?: (color: string | undefined | null) => void;
};
const CustomLegendColorField = ({
    items,
    hideTheme = false,
    loading = false,
    hideLabel = false,
    bottomOffsetClassname,
    leftOffsetClassName,
    fieldValue,
    editable = false,
    onChange,
}: Props) => {
    const { values, setFieldValue } =
        useFormikContext<Partial<PlotLegendDisplayFormValues & BasePlotDisplayOptionFormValues>>();
    const { user } = useAuth();
    const userColors = user?.custom_plot_colors ?? [];
    const orgColors = user?.organization?.custom_plot_colors ?? [];
    const customColors = values.custom_color_json ?? {};
    const customNames = values.custom_legend_json ?? {};

    const setCustomColor = ({
        id,
        color,
        initialColor,
    }: {
        id: number | string;
        color: string | undefined | null;
        initialColor?: string | undefined | null;
    }) => {
        if (onChange) {
            onChange(color);
            return;
        }
        setFieldValue(`custom_color_json.${id}`, blankToUndefined(color));
        if (fieldValue) {
            if (color) {
                setFieldValue(fieldValue, blankToUndefined(color));
            } else {
                setFieldValue(fieldValue, initialColor);
            }
        }
    };

    const setCustomName = ({
        groupId,
        customName,
    }: {
        groupId: number | string;
        customName: string | undefined | null;
    }) => {
        setFieldValue(`custom_legend_json.${groupId}`, blankToUndefined(customName));
    };

    const labelAsString = (label: string | ReactNode) => {
        if (!label || typeof label !== 'string') return '';
        return label;
    };

    if (loading) {
        return (
            <div>
                <LoadingMessage inline immediate message="Loading..." />
            </div>
        );
    }

    return (
        <div>
            {items.map((item) => {
                const jsonName = isDefined(customNames[`${item.id}`]) ? customNames[`${item.id}`] : null;
                const fieldValueColor = fieldValue ? values[fieldValue as keyof typeof values] : null;
                const color = fieldValueColor ?? customColors[`${item.id}`] ?? item.themeColor;
                return (
                    <li
                        key={item.id}
                        className="-mx-2 flex items-center justify-between space-x-1 rounded-lg px-2 py-1 hover:bg-gray-100 hover:text-dark"
                    >
                        <div className="flex w-full items-center space-x-1">
                            <GroupColorPickerPopover
                                title={item.label}
                                labelName={item.labelName}
                                color={
                                    (fieldValue
                                        ? values[fieldValue as keyof typeof values]
                                        : (customColors[item.id] ?? item.themeColor)) as string
                                }
                                onChange={(newColor) => setCustomColor({ id: item.id, color: newColor })}
                                showRemove={isDefined(customColors[item.id])}
                                onRemove={() => {
                                    setCustomColor({ id: item.id, color: null, initialColor: item.themeColor });
                                }}
                                customColors={userColors}
                                customColorsLabel="My favorite colors"
                                secondaryCustomColors={orgColors}
                                secondaryCustomColorsLabel="Organization colors"
                                hideThemes
                                themeColor={hideTheme ? undefined : values.theme_color}
                                bottomOffsetClassname={bottomOffsetClassname}
                                leftOffsetClassName={leftOffsetClassName}
                            >
                                <div className="group relative mt-0.5 flex cursor-pointer items-center justify-center">
                                    <LegendSVG
                                        radius={4}
                                        width={26}
                                        style={{
                                            fill: color as string,
                                            fillOpacity: values.theme_style === ThemeStyle.medium ? 0.75 : 1,
                                            stroke: isWhite(color as string) ? 'rgb(209, 213, 219)' : (color as string),
                                            strokeOpacity: 1,
                                        }}
                                        className={cn('shrink-0 transition-all duration-500')}
                                    />
                                    {isNotBlank(customColors[`${item.id}`]) && (
                                        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center group-hover:hidden">
                                            <OtherIcon className="text-white" width={14} height={14} />
                                        </div>
                                    )}

                                    <div className="absolute bottom-0 left-0 right-0 top-0 hidden items-center justify-center transition group-hover:flex">
                                        <PencilIcon className="h-4 w-4 text-white" strokeWidth="2.5" />
                                    </div>
                                </div>
                            </GroupColorPickerPopover>
                            {editable && (
                                <div className="flex w-full justify-between">
                                    <span>{jsonName || item.label}</span>
                                    <div className="flex items-center">
                                        <EditLegendPopover
                                            id={item.id}
                                            currentName={jsonName || labelAsString(item.label)}
                                            defaultName={labelAsString(item.label)}
                                            onSave={setCustomName}
                                        >
                                            <EditIcon height={14} width={14} className="mr-2 text-slate-500" />
                                        </EditLegendPopover>
                                    </div>
                                </div>
                            )}
                            {!editable && !hideLabel && <span>{item.label}</span>}
                        </div>
                    </li>
                );
            })}
        </div>
    );
};

export default CustomLegendColorField;

import { BaseExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import { AnalysisShortname } from './AnalysisType';
import { Organism } from '../Organism';

export interface OverlapLinkedExperiment extends BaseExperimentAnalysis {
    source_experiment_id?: string;
}
export interface OverlapAnalysisInput {
    adj_pval_threshold: number | undefined;
    differential_analysis_id: string | undefined;
    differential_analysis_shortname: AnalysisShortname | undefined;
    fc_direction: string | undefined;
    fc_lower_threshold: number | null;
    fc_upper_threshold: number | null;
    input_type: string | undefined;
    name: string;
    target_genes_format: string | undefined;
    targets_biomarker_list_id: string | undefined;
    targets_csv_filename: string | undefined;
    targets_csv: Blob | undefined;
    targets: string[] | undefined;
    uuid: string | null;
    organism?: Organism;
}

export interface OverlapAnalysis extends OverlapLinkedExperiment {
    analysis_type: 'overlap';
    list_1_id: string | null;
    list_2_id: string | null;
    list_3_id: string | null;
    list_4_id: string | null;
    list_5_id: string | null;
    organism?: Organism;
}
export interface OverlapAnalysisResponse extends OverlapLinkedExperiment {
    analysis_type: 'overlap' | 'multiomics_overlap_genes';
    list_1: OverlapAnalysisInput;
    list_2: OverlapAnalysisInput;
    list_3: OverlapAnalysisInput;
    list_4: OverlapAnalysisInput;
    list_5: OverlapAnalysisInput;
}

export const OverlapLists = ['list_1', 'list_2', 'list_3', 'list_4', 'list_5'] as const;

import { PipelineStatusAnalysis, PlotMetadata } from '@models/analysis/ExperimentAnalysis';
import { Log2FoldChangeDirection, SimpleComparativeAnalysis } from '@models/AnalysisParameters';

export type TFTargetCollectionShortname =
    | 'integrated_mean'
    | 'integrated_top'
    | 'coexpression_gtex'
    | 'coexpression_archs4'
    | 'chipseq_encode'
    | 'chipseq_remap'
    | 'chipseq_literature'
    | 'coocurrence_enrichr';

export default interface TFTargetCollection {
    id: string;
    shortname: TFTargetCollectionShortname;
    display_name: string;
    description: string | null;
    has_p_value?: boolean;
}

export const getShortDisplayName = (collection: Pick<TFTargetCollection, 'display_name'>): string => {
    return collection.display_name.replace(/:(.*?)-/, ':');
};

export interface TranscriptionFactorEnrichmentAnalysis extends PipelineStatusAnalysis {
    analysis_type: 'transcription_factor_enrichment';
    tf_target_collection: TFTargetCollection;
    adj_pval_threshold: number;
    log2_fc_threshold: number;
    log2_fc_direction: Log2FoldChangeDirection;
    differential_analysis: SimpleComparativeAnalysis;
    plot_metadata?: PlotMetadata;
}

import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import React, { useMemo } from 'react';
import Plot from '@models/Plot';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import Experiment from '@models/Experiment';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import PeakSetSelectField from '../fields/PeakSetSelectField';
import CheckboxField from '@components/forms/CheckboxField';
import usePlot from '@/src/hooks/usePlot';
import { capitalizeWordsAndReplaceUnderscores } from '@/src/util/StringUtil';
import LegendFieldGroup from '../groups/LegendFieldGroup';
import {
    PlotDataHeaderAnalysis,
    functionalAnnotationAnalysisHeaderMapping,
} from '@/src/models/analysis/PlotDataHeaderAnalysis';
import { GenericCellData } from '@/src/models/ExperimentData';
import { useFormikContext } from 'formik';
import { PeakSetConfig } from '@/src/models/PlotConfigs';

type Props = { plot: Plot; experiment: Experiment };
const PieChartDisplayFields = ({ plot, experiment }: Props) => {
    const analysis = plot.analysis as PlotDataHeaderAnalysis | null;
    const { values, setFieldValue } = useFormikContext<PeakSetConfig>();
    const { plotData } = usePlot({
        experiment,
        plot,
        plotId: plot.uuid,
    });
    const identifier = analysis?.plot_data_headers?.[0] ?? 'peak_set';
    const isFunctionalAnnotationAnalysis = plot.analysis_type === 'functional_annotation';

    const peakSetOptions = useMemo(
        () =>
            (plotData?.items as GenericCellData[])?.map((i) => ({
                value: i[identifier] as string,
                label: capitalizeWordsAndReplaceUnderscores(i[identifier] as string),
            })) ?? [],
        [],
    );

    if (!values.peak_set) {
        setFieldValue('peak_set', peakSetOptions[0]?.value);
    }

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name} />
            <PeakSetSelectField options={peakSetOptions} />
            <div className="mb-8">
                <CheckboxField
                    className="flex items-center"
                    offsetCheckbox={false}
                    name="show_percentages"
                    label="Show percentages on chart"
                />
            </div>
            <ThemeFieldGroup hideStyle />
            <LegendFieldGroup
                isSortable
                description="Click and drag the legend items to reorder. Plot will only show peak sets included in the analysis"
                loading={!analysis?.plot_data_headers?.length}
                items={
                    analysis?.plot_data_headers?.slice(1)?.map((id) => ({
                        id,
                        display_name: isFunctionalAnnotationAnalysis
                            ? functionalAnnotationAnalysisHeaderMapping[
                                  id as keyof typeof functionalAnnotationAnalysisHeaderMapping
                              ]
                            : capitalizeWordsAndReplaceUnderscores(id),
                    })) ?? []
                }
                legendPopoverTitle="Peak set display name"
            />
        </>
    );
};

export default PieChartDisplayFields;

import TextInput from '@components/forms/TextInput';
import React from 'react';
import { RefPointLabelConfig } from '@models/PlotConfigs';
import { useFormikContext } from 'formik';

type Props = { name?: string; placeholder?: string | null };
const RefPointLabelField = ({ name = 'ref_point_label', placeholder }: Props) => {
    const { values, handleChange } = useFormikContext<RefPointLabelConfig>();
    return (
        <TextInput
            label="Reference-point Label"
            name={name}
            value={values[name as keyof RefPointLabelConfig] ?? ''}
            placeholder={placeholder ?? ''}
            onChange={handleChange}
            useFormikError
        />
    );
};

export default RefPointLabelField;

import { Form, Formik, FormikHelpers } from 'formik';
import TextInput from '@components/forms/TextInput';
import Button from '@components/Button';
import React from 'react';
import { CreateTeamParams, Team } from '@models/Team';
import * as Yup from 'yup';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import { useSWRConfig } from 'swr';
import { isDefined } from '@util/TypeGuards';
import { blankToNull } from '@util/StringUtil';
import AvatarUploadCircleFormField from '@components/profile/AvatarUploadCircleFormField';
import { Alert } from '@mui/material';
import { ApiError } from '@services/ApiError';
import Logger from '@util/Logger';
import { PaginationResponse } from '@services/EndpointUtil';
import { DialogActions, DialogContent } from '@mui/material';
import { AxiosError } from 'axios';

const logger = Logger.make('TeamForm');

const Schema = Yup.object({
    name: Yup.string().nullable().required('Please enter a name'),
    avatar_url: Yup.string().nullable(),
});

const getInitialValues = (team?: Team | null): CreateTeamParams => ({
    name: team?.name ?? '',
    avatar_url: team?.avatar_url ?? '',
});

type Props = {
    onSaved?: (team: Team | null) => void;
    team?: Team | null;
    submitText?: string | null;
    className?: string;
};
const TeamDialogForm = ({ onSaved, team, submitText, className }: Props) => {
    const api = useApi();
    const { mutate } = useSWRConfig();
    const isEdit = isDefined(team);

    const handleSubmit = async (values: CreateTeamParams, actions: FormikHelpers<CreateTeamParams>) => {
        try {
            actions.setStatus(null);

            const processedValues = {
                ...values,
                avatar_url: blankToNull(values.avatar_url),
            };
            if (isEdit && team) {
                const updatedTeam = await api.put<Team>(Endpoints.team.base({ teamId: team.uuid }), processedValues);
                await mutate<PaginationResponse<Team>>(Endpoints.teams(), (current) =>
                    current
                        ? {
                              ...current,
                              items: [...current.items].map((t) => {
                                  return t.uuid === team.uuid ? updatedTeam : t;
                              }),
                              count: current.count,
                          }
                        : current,
                );
                actions.setSubmitting(false);
                onSaved?.(updatedTeam);
            } else {
                const createdTeam = await api.post<Team>(Endpoints.teams(), processedValues);
                await mutate<PaginationResponse<Team>>(Endpoints.teams(), (current) =>
                    current
                        ? {
                              ...current,
                              items: [...current.items, createdTeam],
                              count: current.count + 1,
                          }
                        : { items: [createdTeam], count: 1 },
                );
                actions.setSubmitting(false);
                onSaved?.(createdTeam);
            }
        } catch (error) {
            actions.setStatus({ error: ApiError.getMessage(error as Error) });
            logger.error(error);
        }
    };

    return (
        <Formik onSubmit={handleSubmit} initialValues={getInitialValues(team)} validationSchema={Schema}>
            {({ handleChange, values, setStatus, status, submitForm, setFieldValue }) => (
                <>
                    <DialogContent>
                        <Form className={className}>
                            {status?.error && (
                                <Alert severity="error" className="mb-4">
                                    {status.error}
                                </Alert>
                            )}
                            <TextInput
                                className="w-full"
                                name="name"
                                type="text"
                                label="Name"
                                useFormikError={true}
                                onChange={handleChange}
                                value={values.name}
                                placeholder="Team name"
                            />
                            <div className="">
                                <AvatarUploadCircleFormField
                                    value={values.avatar_url}
                                    updateResult={(result) => setFieldValue('avatar_url', result)}
                                    endpoint={Endpoints.avatars()}
                                    className="mb-8 flex items-center space-x-4"
                                    avatarId={team?.uuid}
                                    onUploadError={(e) => {
                                        if (e) setStatus({ error: ApiError.getMessage(e as AxiosError) });
                                    }}
                                >
                                    <div>
                                        <p className="text-lg font-semibold text-primary">Team icon</p>
                                        <p className="text-default">Drag in an image or tap to select a file</p>
                                    </div>
                                </AvatarUploadCircleFormField>
                            </div>
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" type="submit" onClick={() => submitForm()}>
                            {(submitText ?? isEdit) ? 'Save Team' : 'Create Team'}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Formik>
    );
};

export default TeamDialogForm;

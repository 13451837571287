import { AnalysisParameters } from '@models/AnalysisParameters';
import AssaySummaryAnalysisFormFields from '@components/experiments/analyses/forms/AssaySummaryAnalysisFormFields';
import DifferentialExpressionAnalysisFormFields from '@components/experiments/analyses/forms/DifferentialExpressionAnalysisFormFields';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import DifferentialExpressionAssaySummaryAnalysisFormFields from '@components/experiments/analyses/forms/DifferentialExpressionAssaySummaryAnalysisFormFields';
import { isSimpleExperimentType } from '@models/ExperimentType';
import ImageAnalysisFormFields from '@/src/components/experiments/analyses/forms/ImageAnalysisFormFields';
import SpreadsheetAnalysisFormFields from '@/src/components/experiments/analyses/forms/SpreadsheetAnalysisFormFields';
import PrismAnalysisFormFields from '@components/experiments/analyses/forms/PrismAnalysisFormFields';
import PrincipalComponentsAnalysisFormFields from '@components/experiments/analyses/forms/PrincipalComponentsAnalysisFormFields';
import {
    isAssaySummaryAnalysisParameters,
    isClusteringAnalysisParameters,
    isCoverageAnalysisParameters,
    isDifferentialExpressionAnalysisParameters,
    isDifferentialExpressionContinuousAnalysisParameters,
    isFunctionalAnnotationAnalysisParameters,
    isGeneSetEnrichmentAnalysisParameters,
    isPeakAnalysisParameters,
    isSampleCorrelationAnalysisParameters,
    isSeuratDifferentialExpressionAnalysisParameters,
    isSeuratOverRepresentationAnalysisParameters,
    isSurvivalAnalysisParameters,
    isTranscriptionFactorEnrichmentAnalysisParameters,
    isProteinProteinInteractionAnalysisParameters,
    isOverlapAnalysisParameters,
    isMultiomicsOverlapGenesAnalysisParameters,
} from '@models/AnalysisParametersHelpers';
import GeneSetEnrichmentAnalysisFormFields from '@components/experiments/analyses/forms/GeneSetEnrichmentAnalysisFormFields';
import DifferentialBindingAnalysisFormFields from '@components/experiments/analyses/forms/DifferentialBindingAnalysisFormFields';
import PeakAnalysisFormFields from '@components/experiments/analyses/forms/PeakAnalysisFormFields';
import SurvivalAnalysisFormFields from '@components/experiments/analyses/forms/SurvivalAnalysisFormFields';
import { isSummaryAnalysisType } from '@models/analysis/AnalysisType';
import UMAPAnalysisFormFields from '@components/experiments/analyses/forms/UMAPAnalysisFormFields';
import TSNEAnalysisFormFields from '@components/experiments/analyses/forms/TSNEAnalysisFormFields';
import LongitudinalAnalysisFormFields from '@components/experiments/analyses/forms/LongitudinalAnalysisFormFields';
import DifferentialExpressionContinuousAnalysisFormFields from '@components/experiments/analyses/forms/DifferentialExpressionContinuousAnalysisFormFields';
import TextAnalysisFormFields from '@components/experiments/analyses/forms/TextAnalysisFormFields';
import ClusteringAnalysisFormFields from '@components/experiments/analyses/forms/ClusteringAnalysisFormFields';
import CoverageAnalysisFormFields from '@components/experiments/analyses/forms/CoverageAnalysisFormFields';
import OverlapAnalysisFormFields from '@components/experiments/analyses/forms/OverlapAnalysisFormFields';
import TranscriptionFactorEnrichmentAnalysisFormFields from '@components/experiments/analyses/forms/TranscriptionFactorEnrichmentAnalysisFormFields';
import ExternalAnalysisFormFields from '@components/experiments/analyses/forms/ExternalAnalysisFormFields';
import SeuratDifferentialExpressionAnalysisFormFields from './forms/SeuratDifferentialExpressionAnalysisFormFields';
import SeuratOverRepresentationAnalysisFormFields from './forms/SeuratOverRepresentationAnalysisFormFields';
import ProteinProteinInteractionAnalysisFormFields from './forms/ProteinProteinInteractionAnalysisFormFields';
import FunctionalAnnotationAnalysisFormFields from './forms/FunctionalAnnotationAnalysisFormFields';
import SampleCorrelationAnalysisFormFields from './forms/SampleCorrelationAnalysisFormFields';
import SeuratMarkerExpressionAnalysisFormFields from './forms/SeuratMarkerExpressionAnalysisFormFields';
import SeuratModuleScoreAnalysisFormFields from './forms/SeuratModuleScoreAnalysisFormFields';
import { useEffect } from 'react';
import { useActiveExperimentContext } from '@/src/contexts/ActiveExperimentContext';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null };

export const AnalysisFormFields = ({ analysisParameters, experiment, plot }: Props) => {
    const { setActiveExperiment, resetToDefaultExperiment } = useActiveExperimentContext();
    useEffect(() => {
        setActiveExperiment(experiment);
        () => resetToDefaultExperiment();
    }, []);

    if (plot.analysis_type === 'image' && analysisParameters) {
        return <ImageAnalysisFormFields plot={plot} experiment={experiment} analysisParameters={analysisParameters} />;
    }

    if (plot.analysis_type === 'spreadsheet' && analysisParameters) {
        return (
            <SpreadsheetAnalysisFormFields
                plot={plot}
                experiment={experiment}
                analysisParameters={analysisParameters}
            />
        );
    }

    if (plot.analysis_type === 'prism' && analysisParameters) {
        return <PrismAnalysisFormFields plot={plot} experiment={experiment} />;
    }

    if (plot.analysis_type === 'external' && analysisParameters) {
        return <ExternalAnalysisFormFields plot={plot} experiment={experiment} />;
    }

    if (plot.analysis_type === 'principal_components' && analysisParameters) {
        return (
            <PrincipalComponentsAnalysisFormFields
                plot={plot}
                experiment={experiment}
                analysisParameters={analysisParameters}
            />
        );
    }

    if (plot.analysis_type === 'umap' && analysisParameters) {
        return <UMAPAnalysisFormFields plot={plot} experiment={experiment} analysisParameters={analysisParameters} />;
    }

    if (plot.analysis_type === 'tsne' && analysisParameters) {
        return <TSNEAnalysisFormFields plot={plot} experiment={experiment} analysisParameters={analysisParameters} />;
    }

    if (plot.analysis_type === 'longitudinal') {
        return (
            <LongitudinalAnalysisFormFields
                plot={plot}
                experiment={experiment}
                analysisParameters={analysisParameters}
            />
        );
    }

    if (
        !isSimpleExperimentType(experiment.type?.shortname) &&
        isSummaryAnalysisType(plot.analysis_type) &&
        isAssaySummaryAnalysisParameters(analysisParameters)
    ) {
        return (
            <DifferentialExpressionAssaySummaryAnalysisFormFields
                experiment={experiment}
                plot={plot}
                analysisParameters={analysisParameters}
            />
        );
    }

    if (isSummaryAnalysisType(plot.analysis_type) && isAssaySummaryAnalysisParameters(analysisParameters)) {
        return (
            <AssaySummaryAnalysisFormFields
                experiment={experiment}
                plot={plot}
                analysisParameters={analysisParameters}
            />
        );
    }

    if (
        plot.analysis_type === 'differential_expression_continuous' &&
        isDifferentialExpressionContinuousAnalysisParameters(analysisParameters)
    ) {
        return (
            <DifferentialExpressionContinuousAnalysisFormFields
                analysisParameters={analysisParameters}
                experiment={experiment}
                plot={plot}
            />
        );
    }

    if (
        plot.analysis_type === 'differential_expression' &&
        isDifferentialExpressionAnalysisParameters(analysisParameters)
    ) {
        return (
            <DifferentialExpressionAnalysisFormFields
                analysisParameters={analysisParameters}
                experiment={experiment}
                plot={plot}
            />
        );
    }

    if (
        plot.analysis_type === 'seurat_differential_expression' &&
        isSeuratDifferentialExpressionAnalysisParameters(analysisParameters)
    ) {
        return (
            <SeuratDifferentialExpressionAnalysisFormFields
                analysisParameters={analysisParameters}
                experiment={experiment}
                plot={plot}
            />
        );
    }

    if (
        plot.analysis_type === 'differential_binding' &&
        isDifferentialExpressionAnalysisParameters(analysisParameters)
    ) {
        return (
            <DifferentialBindingAnalysisFormFields
                analysisParameters={analysisParameters}
                experiment={experiment}
                plot={plot}
            />
        );
    }

    if (plot.analysis_type === 'gene_set_enrichment' && isGeneSetEnrichmentAnalysisParameters(analysisParameters)) {
        return (
            <GeneSetEnrichmentAnalysisFormFields
                plot={plot}
                experiment={experiment}
                analysisParameters={analysisParameters}
            />
        );
    }

    if (
        plot.analysis_type === 'seurat_over_representation' &&
        isSeuratOverRepresentationAnalysisParameters(analysisParameters)
    ) {
        return (
            <SeuratOverRepresentationAnalysisFormFields
                plot={plot}
                experiment={experiment}
                analysisParameters={analysisParameters}
            />
        );
    }

    if (plot.analysis_type === 'peak' && isPeakAnalysisParameters(analysisParameters)) {
        return <PeakAnalysisFormFields plot={plot} experiment={experiment} analysisParameters={analysisParameters} />;
    }

    if (plot.analysis_type === 'survival' && isSurvivalAnalysisParameters(analysisParameters)) {
        return (
            <SurvivalAnalysisFormFields plot={plot} experiment={experiment} analysisParameters={analysisParameters} />
        );
    }

    if (plot.analysis_type === 'text') {
        return <TextAnalysisFormFields plot={plot} experiment={experiment} analysisParameters={analysisParameters} />;
    }

    if (plot.analysis_type === 'clustering' && isClusteringAnalysisParameters(analysisParameters)) {
        return (
            <ClusteringAnalysisFormFields plot={plot} analysisParameters={analysisParameters} experiment={experiment} />
        );
    }

    if (plot.analysis_type === 'coverage' && isCoverageAnalysisParameters(analysisParameters)) {
        return (
            <CoverageAnalysisFormFields plot={plot} analysisParameters={analysisParameters} experiment={experiment} />
        );
    }

    if (
        plot.analysis_type === 'transcription_factor_enrichment' &&
        isTranscriptionFactorEnrichmentAnalysisParameters(analysisParameters)
    ) {
        return (
            <TranscriptionFactorEnrichmentAnalysisFormFields
                plot={plot}
                analysisParameters={analysisParameters}
                experiment={experiment}
            />
        );
    }

    if (
        plot.analysis_type === 'protein_protein_interaction' &&
        isProteinProteinInteractionAnalysisParameters(analysisParameters)
    ) {
        return (
            <ProteinProteinInteractionAnalysisFormFields
                analysisParameters={analysisParameters}
                experiment={experiment}
            />
        );
    }

    if (
        plot.analysis_type === 'functional_annotation' &&
        isFunctionalAnnotationAnalysisParameters(analysisParameters)
    ) {
        return (
            <FunctionalAnnotationAnalysisFormFields experiment={experiment} analysisParameters={analysisParameters} />
        );
    }

    if (plot.analysis_type === 'sample_correlation' && isSampleCorrelationAnalysisParameters(analysisParameters)) {
        return (
            <SampleCorrelationAnalysisFormFields
                experiment={experiment}
                analysisParameters={analysisParameters}
                plot={plot}
            />
        );
    }

    if (plot.analysis_type === 'overlap' && isOverlapAnalysisParameters(analysisParameters)) {
        return (
            <OverlapAnalysisFormFields experiment={experiment} analysisParameters={analysisParameters} plot={plot} />
        );
    }

    if (
        plot.analysis_type === 'multiomics_overlap_genes' &&
        isMultiomicsOverlapGenesAnalysisParameters(analysisParameters)
    ) {
        return (
            <OverlapAnalysisFormFields experiment={experiment} analysisParameters={analysisParameters} plot={plot} />
        );
    }

    if (plot.analysis_type === 'seurat_marker_expression') {
        return <SeuratMarkerExpressionAnalysisFormFields experiment={experiment} />;
    }

    if (plot.analysis_type === 'seurat_module_score') {
        return <SeuratModuleScoreAnalysisFormFields experiment={experiment} />;
    }

    return <div>This type of analysis ({plot.analysis_type}) is not yet supported.</div>;
};

export default AnalysisFormFields;

// hooks/usePlotThumbnail.ts
import { useState, useCallback } from 'react';
import { toPng } from 'html-to-image';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';

interface UsePlotThumbnailProps {
    containerRef: React.RefObject<HTMLDivElement>;
    experiment?: Experiment | null;
    plot?: Plot | null;
    onComplete?: (success: boolean) => void;
}

export const downloadImage = (dataUrl: string, filename: string) => {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const usePlotThumbnail = ({ containerRef, experiment, plot, onComplete }: UsePlotThumbnailProps) => {
    const [loadingPlotThumbnailGeneration, setLoadingPlotThumbnailGeneration] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const api = useApi();
    const debugMode = false; // saves generated image locally instead of calling the API

    const generateAndSaveThumbnail = useCallback(async () => {
        if (!plot || !experiment) {
            const err = new Error(`${!plot ? 'Plot' : 'Experiment'} is not available.`);
            setError(err);
            if (onComplete) onComplete(false);
            return;
        }

        if (!containerRef.current) {
            setError(new Error('Container element not found.'));
            if (onComplete) onComplete(false);
            return;
        }

        // Get the container's bounding rectangle.
        const rect = containerRef.current.getBoundingClientRect();

        // Check if the element is at least partially visible on screen.
        const isVisible =
            rect.bottom > 0 && rect.top < window.innerHeight && rect.right > 0 && rect.left < window.innerWidth;

        if (!isVisible) {
            setError(new Error('Container element out of the bounds of the screen.'));
            if (onComplete) onComplete(false);
            return;
        }

        try {
            setLoadingPlotThumbnailGeneration(true);
            // Generate image as a data URL
            await document.fonts.ready;
            const dataUrl = await toPng(containerRef.current, {
                backgroundColor: '#ffffff',
                skipFonts: false,
            });

            if (debugMode) {
                // Download the image locally
                const filename = `plot-thumbnail-${plot.uuid}.png`;
                downloadImage(dataUrl, filename);
                setLoadingPlotThumbnailGeneration(false);
                setError(null);
                if (onComplete) onComplete(true);
            } else {
                // Prepare payload. For now, we use a hard-coded "rectangle"
                const payload = {
                    thumbnail_data: dataUrl,
                    image_size_ratio_type: 'rectangle',
                };

                // Call the API endpoint to save the thumbnail
                await api.post(
                    Endpoints.lab.experiment.plot.thumbnail({
                        experimentId: experiment.uuid,
                        plotId: plot.uuid,
                    }),
                    payload,
                );

                setLoadingPlotThumbnailGeneration(false);
                setError(null);
                if (onComplete) onComplete(true);
            }
        } catch (err) {
            setError(err as Error);
            setLoadingPlotThumbnailGeneration(false);
            if (onComplete) onComplete(false);
        }
    }, [containerRef, experiment, plot, onComplete]);

    return { generateAndSaveThumbnail, loadingPlotThumbnailGeneration, error };
};

import Plot from '@models/Plot';
import { AnalysisParameters } from '@models/AnalysisParameters';
import React from 'react';
import {
    isMultiomicsOverlapGenesAnalysisParameters,
    isOverlapAnalysisParameters,
} from '@/src/models/AnalysisParametersHelpers';
import OverlapAnalysisMethodsSection from '../analyses/OverlapAnalysisMethodsSection';
import MultiomicsOverlapGenesAnalysisMethodsSection from '../analyses/MultiomicsOverlapGenesAnalysisMethodsSection';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const VennDiagramMethods = ({ plot, analysisParameters }: Props) => {
    if (plot.analysis_type === 'overlap' && isOverlapAnalysisParameters(analysisParameters)) {
        return <OverlapAnalysisMethodsSection plot={plot} />;
    }
    if (
        plot.analysis_type === 'multiomics_overlap_genes' &&
        isMultiomicsOverlapGenesAnalysisParameters(analysisParameters)
    ) {
        return <MultiomicsOverlapGenesAnalysisMethodsSection plot={plot} analysisParameters={analysisParameters} />;
    }
    return <p>Unsupported analysis type for venn diagram, we&apos;re unable to find any methods.</p>;
};

export default VennDiagramMethods;

import React from 'react';
import { useFormikContext } from 'formik';
import { BasePlotDisplayOptionFormValues } from '@models/PlotDisplayOption';

type Props = { name?: keyof BasePlotDisplayOptionFormValues };
const TransposeField = ({ name = 'is_transposed' }: Props) => {
    const { values, setFieldValue } = useFormikContext<BasePlotDisplayOptionFormValues>();
    return (
        <div className="form-field">
            <label className="form-field">
                <input
                    type="checkbox"
                    className="rounded text-indigo-500"
                    name={name}
                    checked={(values[name] as boolean) ?? false}
                    onChange={() => {
                        setFieldValue(name, !values[name]);
                    }}
                />
                <span className="ml-2">
                    Transpose <i>x</i>- and <i>y</i>-axis
                </span>
            </label>
        </div>
    );
};

export default TransposeField;

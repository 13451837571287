import React, { useState, useMemo, MouseEvent } from 'react';
import { Popover, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';

interface CellLineCellProps {
    value: string;
}

interface CellLineData {
    Cellosaurus: {
        'cell-line-list': [
            {
                'name-list': Array<{ type: string; value: string }>;
                'species-list': Array<{
                    accession: string;
                    category: string;
                    database: string;
                    label: string;
                    url: string;
                }>;
                'disease-list': Array<{
                    accession: string;
                    category: string;
                    database: string;
                    label: string;
                    url: string;
                }>;
                'comment-list': Array<{ category: string; value: string }>;
                'accession-list': Array<{ type: string; value: string }>;
            },
        ];
    };
}

// Local cache to store already-fetched data
const cellLineCache: Record<string, CellLineData[]> = {};

const CellLineCell: React.FC<CellLineCellProps> = ({ value }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [cellLineData, setCellLineData] = useState<CellLineData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // Open popover and fetch data only if needed
    const handleClick = async (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);

        // Check cache before making a request
        if (cellLineCache[value]) {
            setCellLineData(cellLineCache[value]);
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`/api/external/cellLine?cellLineName=${value}`);
            if (!response.ok) {
                throw new Error("Couldn't fetch data. Is the cell line name correct?");
            }
            const data = await response.json();
            const formattedData = Array.isArray(data) ? data : [data];

            // Store in cache
            cellLineCache[value] = formattedData;
            setCellLineData(formattedData);
        } catch (error) {
            setError((error as Error).message);
        }
        setLoading(false);
    };

    // Close popover
    const handleClose = () => {
        setTimeout(() => setAnchorEl(null), 0);
    };

    const cellLineDetails = useMemo(() => {
        if (loading) {
            return (
                <div className="p-5 flex justify-center items-center gap-3">
                    <CircularProgress size={20} />
                    Loading from Cellosaurus
                </div>
            );
        }

        if (error) {
            return (
                <div className="p-5">
                    <p>
                        <b>Error:</b> {error}
                    </p>
                </div>
            );
        }

        return cellLineData.map((cellLine, index) => {
            const {
                'name-list': nameList,
                'species-list': speciesList,
                'disease-list': diseaseList,
                'comment-list': commentList,
                'accession-list': accessionList,
            } = cellLine.Cellosaurus['cell-line-list'][0];

            const primaryName = nameList.find(({ type }) => type === 'identifier')?.value ?? 'Not available';
            const species = speciesList[0]?.label ?? 'Not available';
            const disease = diseaseList[0]?.label ?? 'Not available';
            const groupValue =
                commentList.map(({ category, value }) => `${category}: ${value}`).join(', ') || 'Not available';
            const accessionId = accessionList[0]?.value ?? 'Not available';

            return (
                <div className="max-h-[300px] min-h-[200px] w-[350px] space-y-4 overflow-auto p-4" key={index}>
                    <h3 className="flex items-center justify-between">
                        <span className="text-lg font-semibold text-dark">{primaryName}</span>
                        <span className="inline-flex items-center space-x-2">
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                size="small"
                                color="primary"
                                href={`https://www.cellosaurus.org/${accessionId}`}
                                target="_blank"
                                rel="noreferrer nofollow"
                            >
                                <ExternalLinkIcon width={18} />
                            </IconButton>
                        </span>
                    </h3>
                    <span className="text-xs font-semibold">{groupValue}</span>

                    <div>
                        <p className="font-semibold text-dark">Disease</p>
                        <p className="text-dark">{disease}</p>
                    </div>
                    <div>
                        <p className="font-semibold text-dark">Species of origin</p>
                        <p className="text-dark">{species}</p>
                    </div>
                    {accessionId && (
                        <div>
                            <p className="font-semibold text-dark">Accession</p>
                            <p className="text-s">
                                <a href={`https://www.cellosaurus.org/${accessionId}`} target="_blank" rel="noreferrer">
                                    {accessionId}
                                </a>
                            </p>
                        </div>
                    )}
                    <div>
                        <p className="flex items-center space-x-1 text-xs">
                            <span>Annotations by Cellosaurus</span>
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                size="small"
                                color="primary"
                                target="_blank"
                                rel="noreferrer nofollow"
                                href="https://help.pluto.bio/en/articles/6458235-biological-entity-annotations"
                            >
                                <HelpCircleIcon width={14} />
                            </IconButton>
                        </p>
                    </div>
                </div>
            );
        });
    }, [loading, error, cellLineData]);

    return (
        <>
            <div className="text-medium flex items-center" onClick={handleClick}>
                <span className="link">{value}</span>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {cellLineDetails}
            </Popover>
        </>
    );
};

CellLineCell.propTypes = {
    value: PropTypes.string.isRequired,
};

export default CellLineCell;

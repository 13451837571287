import Experiment from '@models/Experiment';
import ButtonGroup, { ButtonGroupItem } from '@components/ButtonGroup';
import React, { useMemo, useRef, useState } from 'react';
import NoSsr from '@components/NoSsr';
import InsightsReportView from '@components/experiments/InsightsReportView';
import DirectionsRunRoundedIcon from '@mui/icons-material/DirectionsRunRounded';
import SampleDataTableView from '@components/experiments/SampleDataTableView';
import AttachmentsView from '@components/experiments/AttachmentsView';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import ResultsDataTableView from '@components/experiments/ResultsDataTableView';
import Plot from '@models/Plot';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import PlutoQCReportView from '@components/experiments/PlutoQCReportView';
import ReportQualityIcon from '@components/ReportQualityIcon';
import useExperimentSettings from '@hooks/useExperimentSettings';
import useExperimentFiles from '@hooks/useExperimentFiles';
import ExperimentDetailAssayDataTabView from '@components/experiments/ExperimentDetailAssayDataTabView';
import { MethodsPlotContent } from './methods/MethodsPlotContent';
import { getCategoryForAnalysis } from '@/src/models/analysis/AnalysisType';
import Copy from '@copy/Copy';
import MethodsReferences from './methods/MethodsReferences';
import CopyTextInput from '../forms/CopyTextInput';
import { Tooltip } from '@mui/material';
import { HelpCircleIcon } from '../icons/custom/HelpCircleIcon';
import { useExperimentDetailViewContext } from '@/src/contexts/ExperimentDetailViewContext';
import useCopyToClipboard from '@/src/hooks/useCopyToClipboard';
import Button from '@components/Button';
import useAuth from '@/src/hooks/useAuth';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import { isSponsor } from '@models/User';
import { isExperimentTypeWithoutData } from '@/src/models/ExperimentType';

export type TabViewName = 'sample' | 'assay' | 'attachments' | 'insights' | 'results_table' | 'qc_report' | 'methods';

type Props = {
    experiment: Experiment;
    initialTab?: TabViewName;
    selectedPlot?: Plot | null;
    tabs?: ButtonGroupItem<TabViewName>[];
};
const ExperimentDetailTabsView = ({ experiment, initialTab, selectedPlot, tabs: tabsProp }: Props) => {
    const permissions = useExperimentPermissions(experiment);
    const { hasAvailableAnalysisTypes, experimentType } = useExperimentSettings(experiment);
    const { plotListItems } = useExperimentDetailViewContext();
    const { data: bamFilesData } = useExperimentFiles({ experiment, data_type: 'bam' });
    const bamFiles = bamFilesData?.bam?.items ?? [];
    const ref = useRef<HTMLDivElement>(null);
    const { copyText, showCopySuccess } = useCopyToClipboard({ ref, successTimeoutDuration: 5000 });
    const { user } = useAuth();
    const isSponsorUser = isSponsor(user);
    const shouldHideDataTabs = isExperimentTypeWithoutData(experiment?.type?.shortname);

    const dataTableViewItems = useMemo<ButtonGroupItem<TabViewName>[]>(() => {
        if (tabsProp) {
            return tabsProp;
        }
        const assayTab: ButtonGroupItem<TabViewName> = {
            value: 'assay',
            label: 'Assay Data',
        };
        const sampleTab: ButtonGroupItem<TabViewName> = {
            value: 'sample',
            label: 'Samples',
        };
        const tabs: ButtonGroupItem<TabViewName>[] = [];

        // Only show 'Assay Data' and 'Samples' tab if user is a not a sponsor
        if (!shouldHideDataTabs && !isSponsorUser) {
            tabs.push(sampleTab);
            tabs.push(assayTab);
        }

        if (permissions.viewReport && (experiment?.qc_report_url || !!bamFiles.length) && !isSponsorUser) {
            tabs.push({
                value: 'qc_report',
                label: (
                    <span className="flex items-center space-x-1">
                        <span>Pipeline</span>
                        <ReportQualityIcon quality={experiment.qc_report_quality} tooltip />
                    </span>
                ),
            });
        }

        if (hasAvailableAnalysisTypes && !!plotListItems && !!plotListItems.length && !isSponsorUser) {
            tabs.push({ value: 'results_table', label: 'Results' });
        }

        if (permissions.viewReport && experiment?.report_url) {
            if (experimentType?.insights_tab_first ?? false) {
                tabs.unshift({ value: 'insights', label: 'Insights' });
            } else {
                tabs.push({ value: 'insights', label: 'Insights' });
            }
        }

        if (permissions.attachments.canView || permissions.attachments.canCreate) {
            tabs.push({ value: 'attachments', label: 'Attachments' });
        }

        return tabs;
    }, [experiment?.report_url, permissions, experimentType, hasAvailableAnalysisTypes, shouldHideDataTabs]);

    const firstTab = dataTableViewItems.find((t) => t.value === initialTab) ?? dataTableViewItems[0];
    const [selectedTab, setTab] = useState<TabViewName>(firstTab?.value ?? 'attachments');
    const getTabView = () => {
        if (!experiment) return;
        switch (selectedTab) {
            case 'sample':
                return <SampleDataTableView experiment={experiment} />;
            case 'assay':
                return <ExperimentDetailAssayDataTabView experiment={experiment} />;
            case 'attachments':
                return <AttachmentsView experiment={experiment} {...permissions.attachments} />;
            case 'results_table':
                return <ResultsDataTableView selectedPlot={selectedPlot} experimentId={experiment?.uuid} />;
            case 'insights':
                return (
                    <>
                        {experiment.report_url ? (
                            <InsightsReportView experiment={experiment} />
                        ) : (
                            <div className="flex flex-col justify-center py-32 text-center">
                                <div className="mx-auto mb-4 rounded-full bg-indigo-100 p-4 text-indigo-800">
                                    <DirectionsRunRoundedIcon height={24} width={24} />
                                </div>
                                <h2 className="text-2xl font-semibold tracking-tight">Results are on the way!</h2>
                                <p className="text-base">Check back in 24 hours for your new insights.</p>
                            </div>
                        )}
                    </>
                );
            case 'qc_report':
                return (
                    <>
                        {experiment.qc_report_url || !!bamFiles.length ? (
                            <PlutoQCReportView experiment={experiment} />
                        ) : (
                            <div className="flex flex-col justify-center py-32 text-center">
                                <div className="mx-auto mb-4 rounded-full bg-indigo-100 p-4 text-indigo-800">
                                    <DirectionsRunRoundedIcon height={24} width={24} />
                                </div>
                                <h2 className="text-2xl font-semibold tracking-tight">QC report is on the way!</h2>
                                <p className="text-base">Check back in 24 hours for your report.</p>
                            </div>
                        )}
                    </>
                );
            case 'methods':
                if (!selectedPlot) return <></>;
                const showPlutoAttribution =
                    selectedPlot.analysis?.category?.shortname !== 'content' &&
                    getCategoryForAnalysis(selectedPlot.analysis_type) !== 'content';

                return (
                    <div className="m-auto flex w-[80%] max-w-3xl flex-col">
                        <div ref={ref} className="mb-8 space-y-4">
                            <MethodsPlotContent plot={selectedPlot} experiment={experiment} />
                            {showPlutoAttribution && <p>{Copy.analysisCitation}</p>}
                            <MethodsReferences plot={selectedPlot} experiment={experiment} className="my-8" />
                        </div>
                        <div className="mb-8">
                            <Button
                                startIcon={<CopyIcon height={16} width={16} />}
                                color="primary"
                                variant="contained"
                                onClick={() => copyText()}
                            >
                                {showCopySuccess ? 'Copied!' : 'Copy'}
                            </Button>
                        </div>
                        <div className="space-y-2">
                            <div className="space-y-1">
                                <p className="flex items-center space-x-2 font-semibold text-gray-900">
                                    <span>Experiment ID</span>
                                    <Tooltip
                                        title={
                                            'Fetch results from this experiment using the Pluto API. Tap to learn more.'
                                        }
                                        arrow
                                    >
                                        <a href={Copy.apiTokenHelpUrl} target="_blank" rel="noreferrer nofollow">
                                            <HelpCircleIcon height={16} width={16} />
                                        </a>
                                    </Tooltip>
                                </p>
                            </div>
                            <CopyTextInput
                                value={experiment.pluto_id}
                                isPrivate={false}
                                inputClasses="!text-xs text-default opacity-60"
                            />
                        </div>

                        <div className="space-y-2">
                            <div className="space-y-1">
                                <p className="flex items-center space-x-2 font-semibold text-gray-900">
                                    <span>Plot ID</span>
                                    <Tooltip
                                        title={'Fetch results from this plot using the Pluto API. Tap to learn more.'}
                                        arrow
                                    >
                                        <a href={Copy.apiTokenHelpUrl} target="_blank" rel="noreferrer nofollow">
                                            <HelpCircleIcon height={16} width={16} />
                                        </a>
                                    </Tooltip>
                                </p>
                            </div>
                            <CopyTextInput
                                value={selectedPlot.uuid}
                                isPrivate={false}
                                inputClasses="!text-xs text-default opacity-60"
                            />
                        </div>
                        {selectedPlot.analysis?.uuid && (
                            <div className="space-y-2">
                                <div className="space-y-1">
                                    <p className="flex items-center space-x-2 font-semibold text-gray-900">
                                        <span>Analysis ID</span>
                                        <Tooltip
                                            title={
                                                'Fetch results from this analysis using the Pluto API. Tap to learn more.'
                                            }
                                            arrow
                                        >
                                            <a href={Copy.apiTokenHelpUrl} target="_blank" rel="noreferrer nofollow">
                                                <HelpCircleIcon height={16} width={16} />
                                            </a>
                                        </Tooltip>
                                    </p>
                                </div>
                                <CopyTextInput
                                    value={selectedPlot.analysis?.uuid}
                                    isPrivate={false}
                                    inputClasses="!text-xs text-default opacity-60"
                                />
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="md:relative md:z-10 md:mx-auto md:flex md:w-min md:items-center md:justify-center">
                <div className="flex overflow-x-auto">
                    <ButtonGroup
                        items={dataTableViewItems}
                        onChange={(screen) => setTab(screen ?? 'sample')}
                        toggle={false}
                        value={selectedTab}
                        className=""
                    />
                </div>
            </div>
            <div className="mt-8 min-h-[400px] space-y-8">
                <PlutoErrorBoundary>
                    <NoSsr>{getTabView()}</NoSsr>
                </PlutoErrorBoundary>
            </div>
        </>
    );
};

export default ExperimentDetailTabsView;

import Plot from '@models/Plot';
import { AnalysisParameters } from '@models/AnalysisParameters';
import {
    isSeuratOverRepresentationAnalysisParameters,
    isSampleCorrelationAnalysisParameters,
} from '@models/AnalysisParametersHelpers';
import React from 'react';
import SeuratOverRepresentationAnalysisMethodsSection from '../analyses/SeuratOverRepresentationAnalysisMethodsSection';
import SampleCorrelationAnalysisMethodsSection from '../analyses/SampleCorrelationAnalysisMethodsSection';
import Experiment from '@/src/models/Experiment';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null };
const DotPlotMethods = ({ plot, experiment, analysisParameters }: Props) => {
    if (
        plot.analysis_type === 'seurat_over_representation' &&
        isSeuratOverRepresentationAnalysisParameters(analysisParameters)
    ) {
        return (
            <SeuratOverRepresentationAnalysisMethodsSection
                experiment={experiment}
                plot={plot}
                analysisParameters={analysisParameters}
            />
        );
    }
    if (plot.analysis_type === 'sample_correlation' && isSampleCorrelationAnalysisParameters(analysisParameters)) {
        return <SampleCorrelationAnalysisMethodsSection plot={plot} />;
    }
    return <p>Unsupported analysis type for stacked barplot, we&apos;re unable to find any methods.</p>;
};

export default DotPlotMethods;

import { memo, useEffect, useMemo } from 'react';
import { Handle, Position, NodeResizer, Node } from 'reactflow';
import PlotCardView from '../plots/PlotCardView';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { ArrowDownIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import { ChatAlt2Icon } from '@heroicons/react/outline';
import { PencilAltIcon } from '@heroicons/react/outline';
import useFetchPlot from '@/src/hooks/useFetchPlot';
import { CircularProgress, Link, Tooltip } from '@mui/material';
import useFetchExperiment from '@/src/hooks/useFetchExperiment';
import cn from 'classnames';
import ConditionalWrapper from '../ConditionalWrapper';
import { defaultEmbeddedExperimentColors } from '@/src/models/EmbeddedExperiment';
import { useExperimentCanvasContext } from '@/src/contexts/ExperimentCanvasContext';
import { useActiveExperimentContext } from '@/src/contexts/ActiveExperimentContext';

export const defaultPlotNodeStyle = {
    height: 470,
    width: 400,
};
const MAX_EXPERIMENT_NAME_LENGTH = 35;
type PlotData = {
    legendIsScrollable?: boolean;
    exportMode?: boolean;
    experimentId?: string; // Guest plot experiment id
    linkedExperimentId?: string; // Linked plot experiment id
};

type PlotNodeProps = {
    data: PlotData;
    selected: boolean;
};

type Props = { data: Node['data']; selected: Node['selected'] };
const PlotNode: React.FC<PlotNodeProps> = ({ data, selected }: Props) => {
    const {
        experiment: experimentFromContext,
        handleChangeSelectedPlot,
        setCurrentPlotPanel,
        setExperimentModalOpen,
        setHideEditForm,
        setOpenOnComments,
        setOpenOnPlot,
        setTargetExperimentId,
    } = useExperimentDetailViewContext();
    const { embeddedExperiments } = useExperimentCanvasContext();
    const { setActiveExperiment } = useActiveExperimentContext();
    const { plot, plotError } = useFetchPlot({
        plotId: data.plotId,
        experimentId: data?.linkedExperimentId ?? data?.experimentId ?? experimentFromContext?.uuid ?? '',
    });
    const { experiment, experimentError, experimentLoading } = useFetchExperiment({
        experimentId: data?.linkedExperimentId ?? data?.experimentId ?? '',
    });

    const activeExperiment = data?.linkedExperimentId || data?.experimentId ? experiment : experimentFromContext;

    useEffect(() => {
        const hash = window.location.hash;
        const plotUUID = `#plot-${plot?.uuid}`;
        if (hash === plotUUID) {
            handleOpenEditModal();
        }
    }, [plot?.uuid]);

    const openEditModalOnComments = () => {
        handleChangeSelectedPlot?.(plot);
        setOpenOnComments(true);
        setCurrentPlotPanel(plot?.analysis?.uuid ? 'plot' : 'analysis');
        setActiveExperiment(activeExperiment);
        if (data?.isExistingAnalysis) {
            setHideEditForm(true);
        }
        if (experiment) {
            setActiveExperiment(experiment);
        }
        setExperimentModalOpen(true);
    };

    const handleOpenEditModal = () => {
        handleChangeSelectedPlot?.(plot);
        if (data?.experimentId) {
            setOpenOnPlot(true);
            setTargetExperimentId(data.experimentId);
        }
        if (data?.isExistingAnalysis) {
            setHideEditForm(true);
        }
        setActiveExperiment(activeExperiment);
        setExperimentModalOpen?.(true);
    };

    const handleOpenExport = () => {
        if (!plot || !activeExperiment) return;
        window.open(`/experiments/${activeExperiment.pluto_id}/plots/${plot.uuid}/export`, '_blank');
    };

    const handleStyle = !selected ? { opacity: 0, zIndex: 10 } : { zIndex: 10 };
    // const showContent = zoomLevel > 0.8;

    // Assuming you have a defined list of icons/actions
    const icons = [
        // Should have fullscreen, edit, chat, and elipse with a menu of all the options
        {
            id: 1,
            icon: (
                <Tooltip title="Edit" arrow placement="top">
                    <PencilAltIcon width={14} height={14} className="mr-1" onClick={handleOpenEditModal} />
                </Tooltip>
            ),
        },
        {
            id: 2,
            icon: (
                <Tooltip title="Export" arrow placement="right">
                    <ArrowDownIcon width={14} height={14} className="mr-1" onClick={handleOpenExport} />
                </Tooltip>
            ),
        },
        {
            id: 3,
            icon: (
                <Tooltip title="Comments" arrow placement="bottom">
                    <ChatAlt2Icon width={14} height={14} className="mr-1" onClick={openEditModalOnComments} />
                </Tooltip>
            ),
        },
        // { id: 4, icon: <ArrowsExpandIcon width={14} height={14} className="mr-1" /> },
    ];
    if (data.isGuest || !!data?.linkedExperimentId) {
        icons.shift();
    }

    const guestColor = useMemo(() => {
        const embeddedExperiment = embeddedExperiments?.find((exp) => exp.uuid === data?.experimentId);
        if (embeddedExperiment?.styles_json?.color) {
            return embeddedExperiment.styles_json.color;
        }
        const indexOfGuestPlot = embeddedExperiments?.findIndex((exp) => exp.uuid === data.experimentId) || 0;
        const defaultColor =
            defaultEmbeddedExperimentColors[indexOfGuestPlot % defaultEmbeddedExperimentColors.length]?.color;
        return defaultColor;
    }, [embeddedExperiments]);

    return (
        <div className="relative w-full h-full">
            <NodeResizer
                handleClassName="text-xl bg-red"
                minWidth={100}
                minHeight={30}
                maxWidth={2000}
                maxHeight={2000}
                isVisible={selected}
            />
            <Handle id={Position.Top} type={'source'} position={Position.Top} style={handleStyle} />
            <Handle id={Position.Left} type={'source'} position={Position.Left} style={handleStyle} />

            <div className="flex flex-col flex-1 w-full h-full">
                <div className="flex-none">
                    <ConditionalWrapper
                        condition={data.isGuest || data.experimentId !== experimentFromContext?.uuid}
                        wrapper={(children) => (
                            <Link
                                className="ml-1 flex cursor-pointer items-center font-semibold text-gray-600 tracking-tight hover:opacity-70"
                                href={`/experiments/${activeExperiment?.pluto_id}`}
                                target="_blank"
                                underline="none"
                                style={{
                                    color: data.isGuest ? guestColor : undefined,
                                }}
                            >
                                {children}
                            </Link>
                        )}
                    >
                        <>
                            {(activeExperiment?.name || '').length > MAX_EXPERIMENT_NAME_LENGTH
                                ? `${activeExperiment?.name.substring(0, MAX_EXPERIMENT_NAME_LENGTH)}...`
                                : activeExperiment?.name || 'Loading experiment...'}
                            {data.isGuest || data.experimentId !== experimentFromContext?.uuid ? (
                                <Tooltip title="Open in new tab" placement="right" arrow enterDelay={700}>
                                    <ExternalLinkIcon className={cn('h-4 w-4 ml-1')} />
                                </Tooltip>
                            ) : null}
                        </>
                    </ConditionalWrapper>
                </div>
                <div
                    className="flex-1 min-h-0 min-w-0"
                    style={{
                        ...(!data?.exportMode
                            ? {
                                  backgroundColor: 'white',
                                  boxShadow: '2px 2px 15px 0.5px rgba(0, 0, 0, 0.2)',
                              }
                            : null),
                        ...(data.isGuest
                            ? {
                                  borderWidth: 2,
                                  borderColor: guestColor,
                              }
                            : null),
                    }}
                    onDoubleClick={handleOpenEditModal}
                >
                    {plot && activeExperiment ? (
                        <PlotCardView
                            disableRoundedCorners
                            dragMode={false}
                            experiment={activeExperiment}
                            hideBorders={data?.exportMode}
                            hideEditButton={data?.exportMode || data?.isGuest || !!data?.linkedExperimentId}
                            hideMoreMenu={data?.exportMode}
                            hidePlotShellButtons={data?.exportMode || data?.isGuest || !!data?.linkedExperimentId}
                            isCanvasNode
                            isLinkedExperiment={!!plot?.linked_experiment_id}
                            legendIsScrollable={data?.legendIsScrollable}
                            legendPosition="right"
                            plot={plot}
                            showExpand
                            hideEditForm={data?.isExistingAnalysis}
                            // disablePlotImages // @TODO: remove this line
                            // useThumbnailImage={false} // @TODO: revert to true
                            useThumbnailImage
                        />
                    ) : (
                        <>
                            {experimentLoading ? (
                                <div className="flex items-center space-x-1 w-full h-full justify-center">
                                    <CircularProgress size={24} />
                                </div>
                            ) : null}
                            {plotError ? <p className="text-default text-red-500">{plotError}</p> : null}
                            {experimentError ? <p className="text-default text-red-500">{experimentError}</p> : null}
                        </>
                    )}

                    <div className="iconsContainer">
                        <div className={`iconsInner ${selected ? 'rotateIn' : 'rotateOut'}`}>
                            {icons.map((icon) => {
                                const animationClass = selected ? 'icon' : 'icon';

                                return (
                                    <div key={icon.id} className={animationClass}>
                                        {icon.icon}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <Handle id={Position.Bottom} type={'source'} position={Position.Bottom} style={handleStyle} />
            <Handle id={Position.Right} type={'source'} position={Position.Right} style={handleStyle} />
        </div>
    );
};

export default memo(PlotNode);

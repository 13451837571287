import React from 'react';
import { EditPlotPanelName, useOptionalExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import Button from '@components/Button';
import { MinusCircleIcon, PencilIcon } from '@heroicons/react/outline';
import Plot from '@models/Plot';
import { useOptionalActiveExperimentContext } from '@/src/contexts/ActiveExperimentContext';
import Experiment from '@/src/models/Experiment';

type Props = { editText?: string; plot: Plot; editPanel?: EditPlotPanelName; experiment?: Experiment | null };
const PlotShellActions = ({ editText = 'Continue analysis', plot, editPanel = 'analysis', experiment }: Props) => {
    const experimentContext = useOptionalExperimentDetailViewContext();
    const { setActiveExperiment } = useOptionalActiveExperimentContext();

    const {
        archivePlot,
        disableRemovePlots,
        handleChangeSelectedPlot,
        permissions,
        setCurrentPlotPanel,
        setExperimentModalOpen,
    } = experimentContext ?? {};

    if (!permissions?.canEdit || disableRemovePlots || !experimentContext) {
        return null;
    }
    return (
        <div className="flex flex-col items-center justify-center space-y-2 text-error ">
            <Button
                size="small"
                color="primary"
                variant="contained"
                startIcon={<PencilIcon width={16} className="mr-1" />}
                onClick={() => {
                    setActiveExperiment?.(experiment as Experiment);
                    handleChangeSelectedPlot?.(plot);
                    setCurrentPlotPanel?.(editPanel);
                    setExperimentModalOpen?.(true);
                }}
            >
                {editText}
            </Button>
            <Button
                variant="text"
                color="inherit"
                size="small"
                onClick={() => archivePlot?.(plot.uuid, false, plot?.linked_experiment_id)}
                startIcon={<MinusCircleIcon height={18} width={18} className="text-error" />}
            >
                <span className="text-error">Remove plot</span>
            </Button>
        </div>
    );
};

export default PlotShellActions;

import { ExperimentAnalysisInput } from '@/src/models/analysis/ExperimentAnalysisInput';
import { OverlapAnalysisInput } from '@/src/models/analysis/OverlapAnalysis';

type ExcludedFormValues = keyof Pick<ExperimentAnalysisInput, 'uuid'>;
type MakeForm<T extends ExperimentAnalysisInput, K extends keyof T = ExcludedFormValues> = Omit<
    T,
    ExcludedFormValues | K
>;

export type OverlapAnalysisInputFormValues = MakeForm<OverlapAnalysisInput>;

export type AnalysisInputFormValues = OverlapAnalysisInputFormValues;

export const OverlapAnalysisInputInitialFormValues = {
    name: '',
    adj_pval_threshold: undefined,
    differential_analysis_id: undefined,
    differential_analysis_shortname: undefined,
    fc_direction: undefined,
    fc_lower_threshold: null,
    fc_upper_threshold: null,
    target_genes_format: 'file',
    targets_biomarker_list_id: undefined,
    targets_csv_filename: undefined,
    targets_csv: undefined,
    targets: undefined,
    input_type: undefined,
    organism: undefined,
};

import { PlotMapping, SeuratGeneSetSample } from '@models/ExperimentData';
import { PlotParams } from 'react-plotly.js';
import PaletteColors from '@components/PaletteColors';
import { ObservedSize } from '@hooks/useDebouncedResizeObserver';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import { AnalysisShortname } from '@/src/models/analysis/AnalysisType';

export type DataPoint = { x: string; y: string } & SeuratGeneSetSample;
export type DragMode = PlotParams['layout']['dragmode'];
export type IndexedDataPoint = DataPoint & { index: number };
export const POINT_SIZE = 9;
export const POINT_OPACITY = 0.75;
export const LABELED_POINT_COLOR = '#7BD2F1';

export type PlotRange = {
    yMin: number;
    yMax: number;
    xMin: number;
    xMax: number;
};

const prettify = (label: string): string => {
    if (label === 'Neg_log10_Adj_P_Value') {
        return '-log<sub>10</sub>(Adj. P Value)';
    }

    return label.replace(/_/g, ' ');
};

export const getHoverTemplate = (d: IndexedDataPoint, dataMap: PlotMapping<SeuratGeneSetSample>) => {
    return `<b>${prettify(dataMap.x_axis)}:</b> ${d[dataMap.x_axis]}<br><b>${prettify(dataMap.y_axis)}:</b> ${
        d[dataMap.y_axis]
    }<br><b>${prettify(dataMap.point_size)}:</b> ${d[dataMap.point_size]}<br><b>${prettify(dataMap.point_value)}:</b> ${
        d[dataMap.point_value]
    }<extra></extra>`;
};

export const getIndexedPoint = ({
    dataMap,
    index,
    item,
}: {
    dataMap: PlotMapping<SeuratGeneSetSample>;
    index: number;
    item: SeuratGeneSetSample;
}): IndexedDataPoint => {
    const point: Partial<IndexedDataPoint> = {
        ...item,
        index,
    };
    point.x = item[dataMap.x_axis];
    point.y = item[dataMap.y_axis];

    return point as IndexedDataPoint;
};

const truncateLabels = (
    label: string,
    isTransposed: boolean,
    publicationMode: boolean,
    analysisShortname: AnalysisShortname,
) => {
    let maxLength = isTransposed ? 40 : 20;

    switch (analysisShortname) {
        case 'sample_correlation':
            maxLength = 20;
        case 'seurat_over_representation':
        default:
            maxLength = isTransposed ? 40 : 20;
    }

    if (label.length > maxLength && !publicationMode) {
        return `${label.substring(0, maxLength - 3)}...`;
    }
    return label;
};

const getMarginForAnalysis = ({
    analysisShortname,
    isTransposed,
    isExportMode,
}: {
    analysisShortname: AnalysisShortname;
    isTransposed: boolean;
    isExportMode: boolean;
}) => {
    const defaultMargin = { l: 0, r: 0, t: 0, b: 0 };

    switch (analysisShortname) {
        case 'seurat_over_representation':
            if (isExportMode && !isTransposed) {
                return { l: 0, r: 0, t: 175, b: 175 };
            }
            return isTransposed ? { l: 250, r: 145, t: 50, b: 50 } : { l: 50, r: 0, t: 200, b: 200 };
        case 'sample_correlation':
            return { l: 75, r: 25, t: 25, b: 75 };
        default:
            return defaultMargin;
    }
};

const getWidthForAnalysis = ({
    analysisShortname,
    isTransposed,
    size,
    specialWidth,
    isExportMode,
}: {
    analysisShortname: AnalysisShortname;
    isTransposed: boolean;
    size?: ObservedSize;
    specialWidth?: number;
    isExportMode?: boolean;
}) => {
    switch (analysisShortname) {
        case 'seurat_over_representation':
            if (isTransposed && !isExportMode) {
                return 450;
            }
            return size?.width;
        case 'sample_correlation':
            return specialWidth ?? size?.width;
        default:
            return size?.width;
    }
};

const getHeightForAnalysis = ({
    analysisShortname,
    isExportMode,
    isTransposed,
    size,
    specialHeight,
}: {
    analysisShortname: AnalysisShortname;
    isExportMode?: boolean;
    isTransposed: boolean;
    size?: ObservedSize;
    specialHeight?: number;
}) => {
    switch (analysisShortname) {
        case 'seurat_over_representation':
            if (isTransposed || isExportMode) {
                return size?.height;
            }
            return 450;
        case 'sample_correlation':
            return specialHeight ?? size?.height;
        default:
            return size?.height;
    }
};

const getMaxAllowed = (analysisShortname: AnalysisShortname) => {
    switch (analysisShortname) {
        case 'sample_correlation':
            return 20;
        case 'seurat_over_representation':
        default:
            return undefined;
    }
};

const getMinAllowed = (analysisShortname: AnalysisShortname) => {
    switch (analysisShortname) {
        case 'sample_correlation':
            return -1;
        case 'seurat_over_representation':
        default:
            return undefined;
    }
};

export const buildPlotlyLayout = ({
    analysisShortname,
    isExportMode,
    isTransposed,
    items,
    publicationMode,
    size,
    stylingOptions,
}: {
    analysisShortname: AnalysisShortname;
    isExportMode: boolean;
    isTransposed: boolean;
    items: IndexedDataPoint[];
    publicationMode: boolean;
    size: ObservedSize | undefined;
    stylingOptions?: CustomPlotStylingOptions;
}) => {
    const ticktextx = [...new Set(items.map((i) => i.x))].map((label) =>
        truncateLabels(label, isTransposed, publicationMode, analysisShortname),
    );
    const ticktexty = [...new Set(items.map((i) => i.y))].map((label) =>
        truncateLabels(label, isTransposed, publicationMode, analysisShortname),
    );
    const tickvalsx = isTransposed ? [...new Set(ticktextx.map((_, i) => i))] : ticktextx.map((_, i) => i);
    const tickvalsy = isTransposed ? ticktexty.map((_, i) => i) : [...new Set(ticktexty.map((_, i) => i))];

    const originalYAxis: PlotParams['layout']['yaxis'] & { maxallowed?: number; minallowed?: number } = {
        automargin: true,
        autorange: true,
        color: publicationMode ? '#000' : PaletteColors.gray500.color,
        dtick: 1,
        linewidth: 1,
        linecolor: publicationMode ? '#000' : undefined,
        showgrid: false,
        tick0: 0,
        tickfont: {
            color: publicationMode ? 'black' : stylingOptions?.yaxis_ticks?.fontColor,
            size: stylingOptions?.yaxis_ticks?.fontSize || 10,
            family: stylingOptions?.yaxis_ticks?.fontFamily || 'Arial',
        },
        ticks: 'outside',
        ticktext: publicationMode ? undefined : ticktexty,
        tickvals: publicationMode ? undefined : tickvalsy,
        titlefont: {
            color: publicationMode ? 'black' : stylingOptions?.yaxis_ticks?.fontColor,
            size: stylingOptions?.yaxis_ticks?.fontSize || 18,
            family: stylingOptions?.yaxis_ticks?.fontFamily || 'Arial',
        },
        zeroline: false,
        maxallowed: getMaxAllowed(analysisShortname),
        minallowed: getMinAllowed(analysisShortname),
    };
    const originalXAxis: PlotParams['layout']['xaxis'] & { maxallowed?: number; minallowed?: number } = {
        angle: 45,
        automargin: true,
        autorange: true,
        color: publicationMode ? '#000' : PaletteColors.gray500.color,
        dtick: 1,
        linewidth: 1,
        linecolor: publicationMode ? '#000' : undefined,
        showgrid: false,
        tick0: 0,
        tickangle: isTransposed ? 0 : -35,
        tickfont: {
            color: publicationMode ? 'black' : stylingOptions?.xaxis_ticks?.fontColor,
            size: stylingOptions?.xaxis_ticks?.fontSize || 10,
            family: stylingOptions?.xaxis_ticks?.fontFamily || 'Arial',
        },
        ticks: 'outside',
        ticktext: publicationMode ? undefined : ticktextx,
        tickvals: publicationMode ? undefined : tickvalsx,
        titlefont: {
            color: publicationMode ? 'black' : stylingOptions?.xaxis_ticks?.fontColor,
            size: stylingOptions?.xaxis_ticks?.fontSize || 18,
            family: stylingOptions?.xaxis_ticks?.fontFamily || 'Arial',
        },
        zeroline: false,
        maxallowed: getMaxAllowed(analysisShortname),
        minallowed: getMinAllowed(analysisShortname),
    };
    const yaxis = isTransposed ? originalXAxis : originalYAxis;
    const xaxis = isTransposed ? originalYAxis : originalXAxis;

    // @TODO: implement better visual for smaller dot plots
    // const dataSize = POINT_SIZE * 8;
    // const dataLengthX = tickvalsx.length * dataSize;
    // const dataLengthY = tickvalsy.length * dataSize;
    // const calculatedDataHeight = dataLengthY > (size?.height ?? 0) ? size?.height : dataLengthY;
    // const calculatedDataWidth = dataLengthX > (size?.width ?? 0) ? size?.width : dataLengthX;

    // const specialWidth = tickvalsx.length < 5 ? calculatedDataWidth : undefined;
    // const specialHeight = tickvalsy.length < 5 ? calculatedDataHeight : undefined;

    const margin = getMarginForAnalysis({ analysisShortname, isTransposed, isExportMode });
    const width = getWidthForAnalysis({ analysisShortname, isTransposed, size, isExportMode });
    const height = getHeightForAnalysis({ analysisShortname, isTransposed, size, isExportMode });

    const layout: PlotParams['layout'] = {
        autosize: false,
        width,
        height,
        showlegend: false,
        margin,
        yaxis,
        xaxis,
        shapes: [],
        dragmode: undefined,
    };

    return layout;
};

export const prepareData = ({
    items,
    dataMap,
}: {
    items: SeuratGeneSetSample[];
    dataMap: PlotMapping<SeuratGeneSetSample>;
}) => {
    const preparedItems: IndexedDataPoint[] = [];

    items.forEach((d: SeuratGeneSetSample, i: number) => {
        const indexedPoint = getIndexedPoint({ index: i, item: d, dataMap });
        preparedItems.push(indexedPoint);
    });

    const xAxisOrder = dataMap.x_axis_order;
    if (xAxisOrder) {
        preparedItems.sort((a, b) => {
            return Number(a[xAxisOrder]) - Number(b[xAxisOrder]);
        });
        preparedItems.sort((a, b) => {
            if (a.x === a.y) {
                return -1;
            } else if (b.x === b.y) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    return { preparedItems };
};

import { FormikFieldError } from '@components/forms/FieldError';
import SelectableItem from '@components/forms/SelectableItem';
import { toggleValue } from '@util/ObjectUtil';
import { VariableIcon } from '@components/experiments/ExperimentIcons';
import { formatTableHeader } from '@util/StringUtil';
import React, { useMemo } from 'react';
import { AnalysisParameters, PeakAnalysisParameters } from '@models/AnalysisParameters';
import { useFormikContext } from 'formik';
import { IGVPlotDisplayOptionFormValues } from '@models/PlotDisplayOption';

type Props = { analysisParameters: AnalysisParameters | null | undefined };
const IGVTracksField = ({ analysisParameters }: Props) => {
    const { values, setFieldValue } = useFormikContext<IGVPlotDisplayOptionFormValues>();
    const variables = (analysisParameters as PeakAnalysisParameters | null)?.variables;

    const { sortedVariables } = useMemo(() => {
        return {
            sortedVariables: (variables ?? []).sort((o1, o2) => {
                return (o1.display_name ?? '').localeCompare(o2?.display_name ?? '');
            }),
        };
    }, [variables]);

    return (
        <div className="flex flex-col">
            <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Tracks</h4>
            <div className="form-field">
                <span className="field-label">Variable(s) to color samples by</span>
                <FormikFieldError name={'color_points_by'} />
                <div className="flex flex-row flex-wrap">
                    {sortedVariables.map((variable, index) => {
                        return (
                            <SelectableItem
                                key={`variable_item_${index}`}
                                row
                                selected={values.color_tracks_by_variable_ids.includes(variable.id)}
                                onSelect={() => {
                                    setFieldValue(
                                        'color_tracks_by_variable_ids',
                                        toggleValue(values.color_tracks_by_variable_ids, variable.id),
                                    );
                                    setFieldValue('group_display_order', []);
                                    setFieldValue('config_json', null);
                                }}
                                className="mb-2 mr-2 items-center justify-center gap-2"
                            >
                                {variable && <VariableIcon variable={variable} />}
                                <span>{formatTableHeader(variable.display_name)}</span>
                            </SelectableItem>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default IGVTracksField;

import { useEffect, useState } from 'react';
import { Edge, Node, useEdgesState, Viewport } from 'reactflow';
import useAuth from '@hooks/useAuth';
import useApi from '@hooks/useApi';
import { CanvasResponse } from '@models/Canvas';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import { ApiError } from '../services/ApiError';
import { CustomEdge } from './useCanvasEdges';
import { DEFAULT_ZOOM_LEVEL } from './useCanvasSettings';

const initialViewportValue: Viewport = {
    x: 0,
    y: 0,
    zoom: DEFAULT_ZOOM_LEVEL,
};

const logger = Logger.make('useInitializeCanvas');

type Props = { experiment_id?: string; isCanvasInUrl: boolean };
const useInitializeCanvas = ({ experiment_id, isCanvasInUrl }: Props) => {
    const { authReady, isLoggedIn } = useAuth();
    const [canvasLoading, setCanvasLoading] = useState(false);
    const [canvasLoaded, setCanvasLoaded] = useState(false);
    const [canvasNodes, setCanvasNodes] = useState<Node[]>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<Edge<CustomEdge[]>[]>([]);
    const [defaultViewport, setDefaultViewport] = useState<Viewport>(initialViewportValue);
    const api = useApi();

    const fetchCanvas = async (skipLoadingState = false) => {
        if (!experiment_id) return;
        if (!skipLoadingState) {
            setCanvasLoaded(false);
            setCanvasLoading(true);
        }

        try {
            const fetchResult: CanvasResponse = await api.get(Endpoints.lab.experiment.canvasFlow(experiment_id));

            const newEdges: Edge[] = fetchResult.edges ?? [];
            const newNodes: Node[] = fetchResult.nodes ?? [];
            const newPort = fetchResult.viewport;

            setEdges(newEdges ?? []);
            setCanvasNodes(newNodes ?? []);
            setDefaultViewport(newPort ?? initialViewportValue);
            setCanvasLoaded(true);
        } catch (error) {
            const errorMessage = ApiError.getMessage(error as Error);
            if (errorMessage === 'Canvasflow data not found.') {
                await api.post(Endpoints.lab.experiment.canvasFlow(experiment_id), {});
                setCanvasLoaded(true);
            } else {
                logger.error(new Error('Failed to fetch canvas: ' + errorMessage));
                setCanvasLoaded(false);
            }
        } finally {
            setCanvasLoading(false);
        }
    };

    useEffect(() => {
        if (!experiment_id || !authReady || !isLoggedIn || !isCanvasInUrl) return;
        fetchCanvas();
    }, [experiment_id, isCanvasInUrl, authReady, isLoggedIn]);

    return {
        canvasLoaded,
        canvasLoading,
        canvasNodes,
        defaultViewport,
        edges,
        onEdgesChange,
        setCanvasNodes,
        setDefaultViewport,
        setEdges,
        fetchCanvas,
    };
};

export default useInitializeCanvas;

import Experiment from '@models/Experiment';
import { CellProps, HeaderProps, Renderer } from 'react-table';
import { GenericCellData } from '@models/ExperimentData';
import DefaultTableCell from '@components/dataTable/DefaultTableCell';
import { OrganismID } from '@models/Organism';
import HumanGeneLinkCell from '@components/dataTable/HumanGeneLinkCell';
import MouseGeneLinkCell from '@components/dataTable/MouseGeneLinkCell';
import RatGeneLinkCell from '@components/dataTable/RatGeneLinkCell';
import FruitflyGeneLinkCell from '@components/dataTable/FruitflyGeneLinkCell';
import React, { ReactElement } from 'react';
import { TableHeaderIcon } from '@components/dataTable/TableHeaderIcon';
import SampleDataFastqFileCell from '@components/dataTable/SampleDataFastqFileCell';
import ZebrafishGeneLinkCell from '@components/dataTable/ZebrafishGeneLinkCell';
import CellLineCell from '@components/dataTable/cellLine';
import { functionalAnnotationAnalysisHeaderMapping } from '@/src/models/analysis/PlotDataHeaderAnalysis';
import { capitalize, IconButton, Tooltip } from '@mui/material';
import { CheckIcon } from '@heroicons/react/outline';
import { CopyIcon } from '../icons/custom/CopyIcon';

export const geneLinkCellRenderer = ({ experiment }: { experiment: Experiment }) => {
    const organism_id = experiment.organism?.shortname;
    return (header: string): Renderer<CellProps<GenericCellData>> => {
        const isGeneHeader = ['gene_id', 'gene_symbol', 'gene'].includes(header?.toLowerCase().trim());

        if (!isGeneHeader) {
            return DefaultTableCell;
        }
        switch (organism_id) {
            case OrganismID.human:
                return HumanGeneLinkCell;
            case OrganismID.mouse:
                return MouseGeneLinkCell;
            case OrganismID.rat:
                return RatGeneLinkCell;
            case OrganismID.fruitfly:
                return FruitflyGeneLinkCell;
            case OrganismID.zebrafish:
                return ZebrafishGeneLinkCell;
            default:
                return DefaultTableCell;
        }
    };
};
export const analysisHeaderRenderer = (
    header: keyof typeof functionalAnnotationAnalysisHeaderMapping,
    _: number,
    analysisType: string,
) => {
    if (analysisType === 'functional_annotation') {
        return functionalAnnotationAnalysisHeaderMapping[header] || capitalize(header);
    }
    return header;
};

export const assayHeaderRenderer = (header: string, index: number): Renderer<HeaderProps<GenericCellData>> => {
    const shouldFormatHeader = index === 0 || ['gene_symbol', 'gene_id'].includes(header.trim().toLowerCase());
    return shouldFormatHeader ? <TableHeaderIcon text={header} /> : header;
};

export const sampleDataFastqFileRenderer = () => {
    return (header: string): Renderer<CellProps<GenericCellData>> | undefined => {
        if (!['r1_fastq', 'r2_fastq'].includes(header?.toLowerCase().trim())) {
            return DefaultTableCell;
        }

        return SampleDataFastqFileCell;
    };
};

const cellLineRenderer = () => () => CellLineCell;

export const combinedCellRenderer = () => {
    return (header: string): Renderer<CellProps<GenericCellData>> | undefined => {
        const formattedHeader = header.toLowerCase().replace(' ', '_');
        const possibleHeaders = ['cell_line', 'cellline', 'celllines'];

        if (['r1_fastq', 'r2_fastq'].includes(formattedHeader)) {
            return sampleDataFastqFileRenderer()(header);
        } else if (possibleHeaders.includes(formattedHeader)) {
            return cellLineRenderer()();
        } else {
            return DefaultTableCell;
        }
    };
};

export const CopyableColumnNames = ['Gene'];

export const copyableHeaderRenderer = (
    name: string,
    hasCopied: boolean,
    columnData: string,
    handleCopy: (name: string, columnData: string) => void,
): string | ReactElement => {
    return (
        <div className="flex items-center justify-between">
            <span>{capitalize(name)}</span>
            <Tooltip title="Copy column" enterDelay={300} enterNextDelay={300}>
                <IconButton
                    sx={{
                        padding: '4px',
                        marginLeft: '2px',
                        '& .MuiIconButton-label': { lineHeight: 1 },
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCopy(name, columnData);
                    }}
                    size="small"
                >
                    {hasCopied ? (
                        <CheckIcon className="h-4 w-4 text-green-500" />
                    ) : (
                        <CopyIcon className="h-4 w-4 text-cyan-700" />
                    )}
                </IconButton>
            </Tooltip>
        </div>
    );
};

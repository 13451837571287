import React, { ReactNode, useMemo } from 'react';
import { FixedSizeGrid as Grid, GridChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { isDefined } from '@util/TypeGuards';
import { programmaticColumns } from '@components/dataTable/DataTable';
import { TableInstance } from 'react-table';

export const DataTableBody = <T extends object>({ tableInstance }: { tableInstance: TableInstance<T> }) => {
    const { page, rows, prepareRow } = tableInstance;
    const currentRows = page ?? rows;

    // Prepare rows on each render
    const preparedRows = useMemo(() => {
        return currentRows.map((row) => {
            prepareRow(row);
            return row;
        });
    }, [currentRows, prepareRow]);

    // Determine number of columns and rows
    const columnCount = preparedRows[0]?.cells?.length || 0;
    const rowCount = preparedRows.length;

    // Dimensions (adjust as needed)
    const columnWidth = 150; // e.g., each column is 150px wide
    const rowHeight = 35; // e.g., each row is 35px high

    // Cell renderer for the grid
    const Cell = ({ columnIndex, rowIndex, style }: GridChildComponentProps) => {
        const row = preparedRows[rowIndex];
        const cell = row.cells[columnIndex];
        const cellContent: ReactNode =
            !isDefined(cell.value) && !programmaticColumns.includes(cell.column.id) ? (
                <i className="text-sm text-gray-300">N/A</i>
            ) : (
                cell.render('Cell')
            );

        return (
            <div
                style={{
                    ...style,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    border: '1px solid #e5e7eb',
                    padding: '0 8px',
                    boxSizing: 'border-box',
                }}
            >
                {cellContent}
            </div>
        );
    };

    // IMPORTANT: Wrap AutoSizer in a div with an explicit height.
    return (
        <div style={{ height: '600px', width: '1200px' }}>
            <AutoSizer>
                {({ height, width }) => (
                    <Grid
                        columnCount={columnCount}
                        columnWidth={columnWidth}
                        height={height}
                        rowCount={rowCount}
                        rowHeight={rowHeight}
                        width={width}
                    >
                        {Cell}
                    </Grid>
                )}
            </AutoSizer>
        </div>
    );
};

export default DataTableBody;

import { GenericCellData } from '@models/ExperimentData';
import { PlotParams } from 'react-plotly.js';
import { ObservedSize } from '@hooks/useDebouncedResizeObserver';
import { getPlotPalette } from '@/src/components/ColorPaletteUtil';
import { ThemeColor } from '@/src/models/PlotConfigs';
import { capitalizeWordsAndReplaceUnderscores } from '@/src/util/StringUtil';
import { PieColor } from 'plotly.js/lib/traces/pie';
import { Datum } from 'plotly.js';
import { functionalAnnotationAnalysisHeaderMapping } from '@/src/models/analysis/PlotDataHeaderAnalysis';

export const buildPlotlyLayout = ({ size }: { size: ObservedSize | undefined }) => {
    const margin = { l: 50, r: 50, t: 50, b: 50 };

    const layout: PlotParams['layout'] = {
        autosize: false,
        width: size?.width,
        height: size?.height,
        showlegend: false,
        margin,
        shapes: [],
        dragmode: undefined,
    };

    return layout;
};
export const getHoverTemplate = (label: string, val: string) => {
    return `<b>${label}</b><br>` + `<b>Frequency</b>: ${parseFloat(val).toFixed(2)}%<br></b>` + '<extra></extra>';
};

export const prepareData = ({
    analysisType,
    customColors,
    customLegend,
    legendDisplayOrder,
    selectedItem,
    showPercentages,
    themeColor,
}: {
    analysisType: string;
    customColors: Record<string, string> | null;
    customLegend: Record<string, string> | null;
    legendDisplayOrder: string[];
    selectedItem: GenericCellData;
    showPercentages: boolean;
    themeColor: ThemeColor;
}) => {
    const entries = Object.entries(selectedItem);
    const sortedEntries = entries.sort((n1, n2) => {
        return legendDisplayOrder.indexOf(n1[0]) - legendDisplayOrder.indexOf(n2[0]);
    });
    const plotPalette = getPlotPalette(themeColor);
    const paletteColors = plotPalette.colors;
    const getColor = (item: string, i: number) => {
        return customColors?.[item] ?? paletteColors[i % paletteColors.length].color;
    };

    // prettier-ignore
    const getLabel = (key: string) => {
        return customLegend?.[key] !== undefined
            ? customLegend[key]
            : (analysisType === 'functional_annotation'
                ? functionalAnnotationAnalysisHeaderMapping[key as keyof typeof functionalAnnotationAnalysisHeaderMapping]
                : capitalizeWordsAndReplaceUnderscores(key));
    };

    const preparedItems: PlotParams['data'] = [
        {
            type: 'pie',
            values: sortedEntries.map(([, val]) => val) as Datum[],
            labels: sortedEntries.map(([key]) => getLabel(key)),
            marker: {
                colors: sortedEntries.map(([key, _], i) => getColor(key, i)) as PieColor[],
            },
            textinfo: showPercentages ? undefined : 'none',
            hovertemplate: sortedEntries.map(([key, val]) => getHoverTemplate(getLabel(key), val as string)),
            hoverlabel: { align: 'left', bgcolor: '#ffffff' },
        },
    ];

    return { preparedItems };
};

import PlutoFile from '@models/PlutoFile';
import React, { ReactNode, useState } from 'react';
import ConfirmModal from '@components/ConfirmModal';
import Experiment from '@models/Experiment';
import Bold from '@components/elements/Bold';
import { ApiError } from '@services/ApiError';
import { isBlank, isNotBlank } from '@util/StringUtil';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';

type Props = {
    file: PlutoFile | null;
    experiment: Experiment;
    open: boolean;
    setOpen: (open: boolean) => void;
    handleReopenFastq?: () => void;
    handleArchiveFinished?: () => void;
};
const ArchiveExperimentFileConfirmModal = ({
    file,
    experiment,
    open,
    setOpen,
    handleReopenFastq,
    handleArchiveFinished,
}: Props) => {
    const api = useApi();
    const [archiving, setArchiving] = useState(false);
    const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
    const handleClose = () => {
        setOpen(false);
        setErrorMessage(null);
    };

    const handleArchive = async () => {
        if (!file) {
            return;
        }

        setArchiving(true);
        try {
            await api.post(
                Endpoints.lab.experiment.fileArchive({
                    experimentId: experiment.uuid,
                    fileId: file.uuid,
                }),
            );
            handleReopenFastq?.();
            setOpen(false);
        } catch (error) {
            const message = ApiError.getMessage(error as Error);
            setErrorMessage(
                <div>
                    <p>
                        Unable to remove file.
                        {!message || (isBlank(message) && ' Please try again later or contact us for assistance')}
                    </p>
                    {message && isNotBlank(message) && <p>{message}</p>}
                </div>,
            );
        } finally {
            handleArchiveFinished ? await handleArchiveFinished() : null;
            setArchiving(false);
        }
    };

    if (!file) {
        return null;
    }

    return (
        <ConfirmModal
            open={open}
            onConfirm={handleArchive}
            onCancel={() => handleClose()}
            title="Remove experiment file"
            message={
                <p>
                    Are you sure you want to remove <Bold>{file.filename}</Bold>?
                </p>
            }
            confirmText="Yes, remove"
            cancelText="Cancel"
            confirmLoading={archiving}
            error={errorMessage}
        />
    );
};

export default ArchiveExperimentFileConfirmModal;

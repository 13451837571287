import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { FormikFieldError } from '@components/forms/FieldError';
import React, { ReactChild, ReactNode } from 'react';
import { useField, useFormikContext } from 'formik';
import { isBlank, isNotBlank } from '@util/StringUtil';
import { isDefined } from '@util/TypeGuards';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { AnalysisTypeQueryParams } from '@services/QueryParams';
import { DifferentialsAnalysisParameter } from '@models/AnalysisParameters';
import ListSubheader from '@/src/components/forms/ListSubheader';
import AnalysisType from '@/src/models/analysis/AnalysisType';

type Options = {
    uuid: string;
    name: string;
    experimentName: string;
    analysis_type: AnalysisType;
}[];

const NONE_VALUE = 'none';
const RenderValue = ({ loading, comparisons, id }: { comparisons?: Options; loading?: boolean; id: string | null }) => {
    const selectedComparison = comparisons?.find((s) => s.uuid === id);
    const selectedValue = selectedComparison?.name;

    if (!isBlank(selectedValue) && selectedValue !== NONE_VALUE) {
        return (
            <div className="flex flex-col">
                <span className="block text-xs opacity-75">{selectedComparison?.experimentName}</span>
                <span className="block">{selectedValue}</span>
            </div>
        );
    }

    return <span className="opacity-70">{loading ? 'Loading...' : 'Select a comparison...'}</span>;
};

type Props = {
    /**
     * The name of the form field
     */
    name: string;
    label?: ReactNode;
    disabled?: boolean;
    tooltip?: { title: NonNullable<ReactNode>; href?: string | null; icon?: ReactChild | null } | null;
    analysisTypeFieldName?: string;
    filter?: AnalysisTypeQueryParams;
    selectSx?: Record<string, unknown>;
    differentials: DifferentialsAnalysisParameter;
    organism?: string | null;
};
const MultiomicsDifferentialExpressionAnalysisPickerField = ({
    name,
    label = 'Comparison',
    disabled,
    tooltip,
    selectSx,
    analysisTypeFieldName,
    differentials,
    organism,
}: Props) => {
    const [helpers, { value, error, touched }] = useField<string>(name);
    const formContext = useFormikContext();
    const formValue = isBlank(value) ? NONE_VALUE : value;

    const flatOptions = differentials
        ?.filter((j) => (organism ? j.organism === organism : j))
        ?.flatMap((d) =>
            d?.results.flatMap((r) => ({
                uuid: r.analysis_id,
                name: r.name,
                analysis_type: r.analysis_type,
                experimentName: d?.name,
            })),
        ) as Options;

    const groupedOptions = flatOptions?.reduce((groups: Record<string, Options>, option) => {
        const { experimentName } = option;
        if (!groups[experimentName]) {
            groups[experimentName] = [];
        }
        groups[experimentName].push(option);
        return groups;
    }, {});

    let $tooltip: ReactNode | null = null;
    if (isDefined(tooltip)) {
        const $icon = tooltip.icon ?? <QuestionMarkCircleIcon className="h-4 w-4" />;
        const $child = isNotBlank(tooltip.href) ? (
            <a href={tooltip.href} target="_blank" rel="nofollow noreferrer">
                {$icon}
            </a>
        ) : (
            $icon
        );
        $tooltip = (
            <Tooltip title={tooltip.title} placement="right" arrow>
                <span>{$child}</span>
            </Tooltip>
        );
    }
    return (
        <label className="form-field">
            <span className="field-label flex items-center space-x-2">
                <span>{label}</span>
                {$tooltip}
            </span>

            <FormControl variant="outlined" fullWidth error={!!error && touched} disabled={disabled}>
                <Select
                    sx={selectSx}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    margin="dense"
                    name={name}
                    value={formValue}
                    onChange={(e) => {
                        helpers.onChange(e);
                        if (analysisTypeFieldName) {
                            const analysisId = e.target.value as string;
                            const analysis = flatOptions?.find((c) => c.uuid === analysisId);
                            formContext.setFieldValue(analysisTypeFieldName, analysis?.analysis_type ?? null);
                        }
                    }}
                    placeholder="Select a comparison"
                    renderValue={(id: string) => <RenderValue comparisons={flatOptions} id={id} />}
                >
                    <MenuItem value={NONE_VALUE} disabled hidden className="!hidden">
                        Nothing selected
                    </MenuItem>

                    {groupedOptions &&
                        Object.entries(groupedOptions).map(([experimentName, comparisons]) => [
                            <ListSubheader key={`header-${experimentName}`}>{experimentName}</ListSubheader>,
                            (comparisons as Options).map((comparison) => (
                                <MenuItem key={comparison.uuid} value={comparison.uuid}>
                                    <span className="block">{comparison.name}</span>
                                </MenuItem>
                            )),
                        ])}
                </Select>
                <FormikFieldError name={name} />
            </FormControl>
        </label>
    );
};

export default MultiomicsDifferentialExpressionAnalysisPickerField;

import { ThemeablePlotChartDisplayOption } from '@models/plotDisplayOption/BasePlotDisplayOption';
import BarPlotDisplayOption from '@models/plotDisplayOption/BarPlotDisplayOption';
import BoxPlotDisplayOption from '@models/plotDisplayOption/BoxPlotDisplayOption';
import VolcanoPlotDisplayOption from '@models/plotDisplayOption/VolcanoPlotDisplayOption';
import HeatmapDisplayOption from '@models/plotDisplayOption/HeatmapDisplayOption';
import ImageDisplayOption from '@models/plotDisplayOption/ImageDisplayOption';
import SpreadsheetDisplayOption from '@models/plotDisplayOption/SpreadsheetDisplayOption';
import PrismGraphsetDisplayOption from '@models/plotDisplayOption/PrismDisplayOption';
import SampleScatterPlotDisplayOption from '@models/plotDisplayOption/SampleScatterPlotDisplayOption';
import EnrichmentPlotDisplayOption from '@models/plotDisplayOption/EnrichmentPlotDisplayOption';
import IGVPlotDisplayOption from '@models/plotDisplayOption/IGVPlotDisplayOption';
import KaplanMeierCurveDisplayOption from '@models/plotDisplayOption/KaplanMeierCurveDisplayOption';
import ScoreBarPlotDisplayOption from '@models/plotDisplayOption/ScoreBarPlotDisplayOption';
import LinePlotDisplayOption from '@models/plotDisplayOption/LinePlotDisplayOption';
import TextDisplayOption from '@models/plotDisplayOption/TextDisplayOption';
import ImageHeatmapDisplayOption from '@models/plotDisplayOption/ImageHeatmapDisplayOption';
import TornadoPlotDisplayOption from '@/src/models/plotDisplayOption/TornadoPlotDisplayOption';
import ProfilePlotDisplayOption from '@/src/models/plotDisplayOption/ProfilePlotDisplayOption';
import ExternalPlotDisplayOption from '@models/plotDisplayOption/ExternalPlotDisplayOption';
import StackedBarPlotDisplayOption from '@models/plotDisplayOption/StackedBarPlotDisplayOption';
import PieChartDisplayOption from '@models/plotDisplayOption/PieChartDisplayOption';
import DotPlotDisplayOption from '@models/plotDisplayOption/DotPlotDisplayOption';
import NetworkGraphDisplayOption from '@models/plotDisplayOption/NetworkGraphDisplayOption';
import VennDiagramDisplayOption from '@models/plotDisplayOption/VennDiagramDisplayOption';
import RidgePlotDisplayOption from './plotDisplayOption/RidgePlotDisplayOption';
import ViolinPlotDisplayOption from './plotDisplayOption/ViolinPlotDisplayOption';
import CellScatterPlotDisplayOption from './plotDisplayOption/CellScatterPlotDisplayOption';

export type PlotDisplayOption =
    | BarPlotDisplayOption
    | BoxPlotDisplayOption
    | DotPlotDisplayOption
    | EnrichmentPlotDisplayOption
    | ExternalPlotDisplayOption
    | HeatmapDisplayOption
    | IGVPlotDisplayOption
    | ImageDisplayOption
    | ImageHeatmapDisplayOption
    | KaplanMeierCurveDisplayOption
    | LinePlotDisplayOption
    | PieChartDisplayOption
    | PieChartDisplayOption
    | PrismGraphsetDisplayOption
    | ProfilePlotDisplayOption
    | SampleScatterPlotDisplayOption
    | ScoreBarPlotDisplayOption
    | SpreadsheetDisplayOption
    | StackedBarPlotDisplayOption
    | TextDisplayOption
    | TornadoPlotDisplayOption
    | VennDiagramDisplayOption
    | VolcanoPlotDisplayOption;

export type AnyPlotDisplayOption = Partial<
    BarPlotDisplayOption &
        BoxPlotDisplayOption &
        VolcanoPlotDisplayOption &
        HeatmapDisplayOption &
        SampleScatterPlotDisplayOption &
        EnrichmentPlotDisplayOption &
        IGVPlotDisplayOption &
        ScoreBarPlotDisplayOption &
        LinePlotDisplayOption
>;

type OmittedFormFields = 'uuid' | 'updated_at' | 'created_at' | 'experiment_id';

export type PlotLegendFields = { group_display_order: number[] };
export type PlotLegendDisplayFormValues = { legend?: PlotLegendFields };
export type AnalysisProxyValues = PlotLegendDisplayFormValues & {
    analysis_values?: {
        gene_set?: string | null;
    };
    source_experiment_id?: string;
};

export type BasePlotDisplayOptionFormValues = Omit<ThemeablePlotChartDisplayOption, OmittedFormFields>;
export type PlotDisplayOptionFormValues = Omit<PlotDisplayOption, OmittedFormFields> & AnalysisProxyValues;

export type DisplayFormValue<T extends PlotDisplayOption> = Omit<T, OmittedFormFields> & AnalysisProxyValues;

export type BarPlotDisplayOptionFormValues = DisplayFormValue<BarPlotDisplayOption>;
export type BoxPlotDisplayOptionFormValues = DisplayFormValue<BoxPlotDisplayOption>;
export type DotPlotDisplayOptionFormValues = DisplayFormValue<DotPlotDisplayOption>;
export type EnrichmentPlotDisplayOptionFormValues = DisplayFormValue<EnrichmentPlotDisplayOption>;
export type IGVPlotDisplayOptionFormValues = DisplayFormValue<IGVPlotDisplayOption>;
export type KaplanMeierCurveDisplayOptionFormValues = DisplayFormValue<KaplanMeierCurveDisplayOption>;
export type LinePlotDisplayOptionFormValues = DisplayFormValue<LinePlotDisplayOption>;
export type PieChartDisplayOptionFormValues = DisplayFormValue<PieChartDisplayOption>;
export type ProfilePlotDisplayOptionFormValues = DisplayFormValue<ProfilePlotDisplayOption>;
export type ScoresBarPlotPlotDisplayOptionFormValues = DisplayFormValue<ScoreBarPlotDisplayOption>;
export type StackedBarPlotDisplayOptionFormValues = DisplayFormValue<StackedBarPlotDisplayOption>;
export type NetworkGraphDisplayOptionFormValues = DisplayFormValue<NetworkGraphDisplayOption>;
export type TornadoPlotDisplayOptionFormValues = DisplayFormValue<TornadoPlotDisplayOption>;
export type VennDiagramDisplayOptionFormValues = DisplayFormValue<VennDiagramDisplayOption>;
export type RidgePlotDisplayOptionFormValues = DisplayFormValue<RidgePlotDisplayOption>;
export type ViolinPlotDisplayOptionFormValues = DisplayFormValue<ViolinPlotDisplayOption>;
export type CellScatterPlotDisplayOptionFormValues = DisplayFormValue<CellScatterPlotDisplayOption>;

export const isBarPlotDisplayOption = (params: PlotDisplayOption): params is BarPlotDisplayOption => {
    return params.display_type === 'bar_plot';
};

export const isBoxPlotDisplayOption = (params: PlotDisplayOption): params is BoxPlotDisplayOption => {
    return params.display_type === 'box_plot';
};

export const isVolcanoPlotDisplayOption = (
    params: PlotDisplayOption | AnyPlotDisplayOption,
): params is VolcanoPlotDisplayOption => {
    return params.display_type === 'volcano_plot';
};

export const isHeatmapDisplayOption = (params: PlotDisplayOption): params is HeatmapDisplayOption => {
    return params.display_type === 'heatmap';
};

export const isImageDisplayOption = (params: PlotDisplayOption | null): params is ImageDisplayOption => {
    return params?.display_type === 'image';
};

export const isSpreadsheetDisplayOption = (params: PlotDisplayOption | null): params is SpreadsheetDisplayOption => {
    return params?.display_type === 'spreadsheet';
};

export const isPrismDisplayOption = (params: PlotDisplayOption | null): params is PrismGraphsetDisplayOption => {
    return params?.display_type === 'prism_graphset';
};

export const isIGVPlotDisplayOption = (params: PlotDisplayOption | null): params is IGVPlotDisplayOption => {
    return params?.display_type === 'igv_plot';
};

export const isEnrichmentPlotDisplayOption = (
    params: PlotDisplayOption | null | undefined,
): params is EnrichmentPlotDisplayOption => {
    return params?.display_type === 'enrichment_plot';
};

export const isExternalDisplayOption = (params: PlotDisplayOption | null): params is ImageDisplayOption => {
    return params?.display_type === 'external';
};

export const isDotPlotDisplayOption = (params: PlotDisplayOption): params is DotPlotDisplayOption => {
    return params.display_type === 'dot_plot';
};

export const isNetworkGraphDisplayOption = (params: PlotDisplayOption): params is NetworkGraphDisplayOption => {
    return params.display_type === 'network_graph';
};

export const isPieChartDisplayOption = (params: PlotDisplayOption): params is PieChartDisplayOption => {
    return params.display_type === 'pie_chart';
};
export const isStackedBarPlotDisplayOption = (params: PlotDisplayOption): params is StackedBarPlotDisplayOption => {
    return params.display_type === 'stacked_bar_plot';
};

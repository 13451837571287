import { useEffect, useState } from 'react';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import { EmbeddedExperiment, EmbeddedExperimentResponse, SourceExperiment } from '../models/EmbeddedExperiment';
import Logger from '../util/Logger';

const logger = Logger.make('useEmbeddedExperiments');
const useEmbeddedExperiments = ({
    experimentId,
    skip = false,
    fetchCanvas,
}: {
    experimentId: string | undefined;
    skip?: boolean;
    fetchCanvas?: (skipLoadingState?: boolean) => void;
}) => {
    const api = useApi();
    const [embeddedExperiments, setEmbeddedExperiments] = useState<EmbeddedExperiment[] | null>(null);
    const [sourceExperiment, setSourceExperiment] = useState<SourceExperiment | null>(null);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const fetchEmbeddedExperiments = async () => {
        if (!experimentId || skip) return setEmbeddedExperiments(null);

        setError('');
        setLoading(true);
        try {
            const fetchedExperiments = await api.get<EmbeddedExperimentResponse>(
                Endpoints.lab.experiment.embeddedExperiments({ experimentId }),
            );
            setEmbeddedExperiments(fetchedExperiments.embedded_experiments);
            setSourceExperiment(fetchedExperiments.source_experiment);
        } catch {
            setError('Failed to fetch embedded experiments! Please contact support.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEmbeddedExperiments();
    }, [experimentId, skip]);

    const linkEmbeddedExperiments = async (embeddedExperimentIds: string[]) => {
        if (!experimentId) return;
        try {
            const updatedEmbeddedExperiments = await api.post<EmbeddedExperimentResponse>(
                Endpoints.lab.experiment.embeddedExperiments({ experimentId }),
                {
                    embedded_experiment_ids: embeddedExperimentIds,
                },
            );
            setEmbeddedExperiments(updatedEmbeddedExperiments.embedded_experiments);
        } catch {
            logger.error(new Error('Failed to embed experiment'));
            setError('Failed to save nodes to canvas');
            alert('Failed to embed experiment. Please try again.');
        }
    };

    const updateEmbeddedExperiment = async (payload: any) => {
        if (!experimentId) return;
        try {
            const updatedEmbeddedExperiments = await api.patch<EmbeddedExperimentResponse>(
                Endpoints.lab.experiment.embeddedExperiments({ experimentId }),
                {
                    embedded_experiments: [payload],
                },
            );
            setEmbeddedExperiments(updatedEmbeddedExperiments.embedded_experiments);
            fetchCanvas?.(true);
        } catch {
            logger.error(new Error('Failed to update embedded experiment'));
            setError('Failed to update embedded experiment');
            alert('Failed to update embedded experiment. Please try again.');
        }
    };

    const unlinkEmbeddedExperiments = async (experimentIdsToUnlink: string[]) => {
        if (!experimentId) return;
        try {
            const updatedEmbeddedExperiments = await api.doDelete<EmbeddedExperimentResponse>(
                Endpoints.lab.experiment.embeddedExperiments({ experimentId }),
                {
                    embedded_experiment_ids: experimentIdsToUnlink,
                },
            );
            setEmbeddedExperiments(updatedEmbeddedExperiments.embedded_experiments);
        } catch {
            logger.error(new Error('Failed to unlink embedded experiment'));
            setError('Failed to unlink embedded experiment');
            alert('Failed to unlink embedded experiment. Please try again.');
        }
    };

    const unlinkAllEmbeddedExperiments = async () => {
        if (!experimentId) return;
        const allEmbeddedExperimentIds = embeddedExperiments?.map((e) => e.uuid);
        try {
            const updatedEmbeddedExperiments = await api.doDelete<EmbeddedExperimentResponse>(
                Endpoints.lab.experiment.embeddedExperiments({ experimentId }),
                {
                    embedded_experiment_ids: allEmbeddedExperimentIds,
                },
            );
            setEmbeddedExperiments(updatedEmbeddedExperiments.embedded_experiments);
        } catch {
            logger.error(new Error('Failed to unlink all embedded experiments'));
            setError('Failed to unlink all embedded experiments');
        }
    };

    return {
        embeddedExperiments,
        error,
        fetchEmbeddedExperiments,
        linkEmbeddedExperiments,
        loading,
        sourceExperiment,
        unlinkAllEmbeddedExperiments,
        unlinkEmbeddedExperiments,
        updateEmbeddedExperiment,
    };
};

export default useEmbeddedExperiments;

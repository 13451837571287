import AnalysisType from '@/src/models/analysis/AnalysisType';
import StatisticalSymbolLabel from '../../../StatisticalSymbolLabel';
import SurvivalAnalysisTooltipHelpIcon from '../../SurvivalAnalysisTooltipHelpIcon';
import AnalysisIcon from '../../analyses/AnalysisIcon';
import { defaultTextLightClass } from '@components/icons/CustomIcons';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { ContentfulAnalysis, fetchAnalysis } from '@contentful/analysisInfo';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { CSSTransition } from 'react-transition-group';
import { CustomCodeTag, CustomPreTag } from '../../../PlutoMarkdownContentView';
import CreateAnalysisButton from '../../CreateAnalysisButton';

const preview = process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_MODE === 'true';
const ViewCodeLabel = 'View Code';
const BinderLabel = 'Binder';
const ColabLabel = 'Google Colab';

export type ContentfulLinkTypes = 'r' | 'python';
type Link = {
    name: string;
    link: string;
    type: ContentfulLinkTypes;
};

type Props = {
    analysis?: AnalysisType | ContentfulAnalysis | null;
    category: string;
    onViewCode?: (url: string, type?: ContentfulLinkTypes) => void;
    onAnalysisSelect?: () => void;
};
const ContentfulSidebarView = ({ analysis, category, onAnalysisSelect, onViewCode }: Props) => {
    const [analysisInfo, setAnalysisInfo] = useState<ContentfulAnalysis | null>(null);

    useEffect(() => {
        const getInfo = async () => {
            const contentfulData = await fetchAnalysis({
                preview: preview,
                shortname: (analysis as AnalysisType)?.shortname ?? '',
            });
            if (contentfulData) {
                setAnalysisInfo(contentfulData);
            }
        };
        if (analysis && category === 'nocode') {
            getInfo();
        }
        if (analysis && category === 'lowcode') {
            setAnalysisInfo(analysis as ContentfulAnalysis);
        }
    }, [analysis, category]);

    const renderLink = (linkObject: Link, isLastItem: boolean) => {
        const { link, name, type } = linkObject;
        return (
            <Fragment key={name}>
                {name === ViewCodeLabel ? (
                    <a onClick={() => onViewCode?.(link, type)}>{name}</a>
                ) : (
                    <a href={link} target="_blank" className="cursor-pointer" rel="noreferrer">
                        {name}
                    </a>
                )}
                {!isLastItem && <span className="ml-2">|</span>}{' '}
            </Fragment>
        );
    };

    const getContent = () => {
        if (!analysis) {
            return <div className="my-6 text-default">No analysis selected.</div>;
        }
        switch (category) {
            case 'lowcode':
                const typedAnalysisInfo = analysis as ContentfulAnalysis;

                const rScriptLinks: Link[] = [];
                if (typedAnalysisInfo?.rCodeLink)
                    rScriptLinks.push({ name: ViewCodeLabel, link: typedAnalysisInfo.rCodeLink, type: 'r' });
                if (typedAnalysisInfo?.rColabLink)
                    rScriptLinks.push({ name: ColabLabel, link: typedAnalysisInfo.rColabLink, type: 'r' });
                if (typedAnalysisInfo?.rBinderLink)
                    rScriptLinks.push({ name: BinderLabel, link: typedAnalysisInfo.rBinderLink, type: 'r' });

                const pythonScriptLinks: Link[] = [];
                if (typedAnalysisInfo?.pythonCodeLink)
                    pythonScriptLinks.push({
                        name: ViewCodeLabel,
                        link: typedAnalysisInfo.pythonCodeLink,
                        type: 'python',
                    });
                if (typedAnalysisInfo?.pythonColabLink)
                    pythonScriptLinks.push({
                        name: ColabLabel,
                        link: typedAnalysisInfo.pythonColabLink,
                        type: 'python',
                    });
                if (typedAnalysisInfo?.pythonBinderLink)
                    pythonScriptLinks.push({
                        name: BinderLabel,
                        link: typedAnalysisInfo.pythonBinderLink,
                        type: 'python',
                    });

                return (
                    <div className="mb-3 flex h-full flex-col overflow-auto pb-8">
                        <p className="flex items-center space-x-2 text-base font-semibold">
                            <StatisticalSymbolLabel name={analysisInfo?.name} />
                        </p>
                        <div className="mt-1">{analysisInfo?.shortDescription}</div>
                        <div className="my-6 space-y-3">
                            <div className="flex items-center space-x-2 font-semibold">
                                {rScriptLinks.length ? (
                                    <div className="flex flex-col">
                                        <p>R script</p>
                                        <span className="space-x-2">
                                            {rScriptLinks.map((o, i) => renderLink(o, i === rScriptLinks.length - 1))}
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                            <div className="flex items-center justify-between space-x-2 font-semibold">
                                {pythonScriptLinks.length ? (
                                    <div className="flex flex-col">
                                        <p>Python script</p>
                                        <span className="space-x-2">
                                            {pythonScriptLinks.map((o, i) =>
                                                renderLink(o, i === pythonScriptLinks.length - 1),
                                            )}
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        {analysisInfo?.description ? (
                            <ReactMarkdown
                                className="prose mt-5 text-sm"
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    code: CustomCodeTag,
                                    pre: CustomPreTag,
                                }}
                            >
                                {analysisInfo?.description ?? ''}
                            </ReactMarkdown>
                        ) : (
                            <div className="my-6 text-default">No analysis information retrieved.</div>
                        )}
                    </div>
                );
            case 'nocode':
            default:
                const typedAnalysis = analysis as AnalysisType;
                return (
                    <div className="flex h-full flex-col overflow-auto pb-6">
                        <div className="flex items-center justify-center space-x-2">
                            <AnalysisIcon
                                shortname={typedAnalysis.shortname}
                                width={24}
                                height={24}
                                className={cn({
                                    'text-gray-500': !typedAnalysis.is_enabled,
                                    [defaultTextLightClass]: typedAnalysis.is_enabled,
                                })}
                            />
                            <p className="center flex items-center space-x-2 text-base font-semibold">
                                <StatisticalSymbolLabel name={typedAnalysis.display_name} />

                                {typedAnalysis.shortname === 'survival' && <SurvivalAnalysisTooltipHelpIcon />}
                            </p>
                        </div>
                        <div className="mb-5 mt-1 text-center">{typedAnalysis.description}</div>
                        <CreateAnalysisButton
                            label="Create analysis"
                            onClick={onAnalysisSelect}
                            cy="create_analysis_btn_static_modal"
                        />

                        {analysisInfo?.description ? (
                            <ReactMarkdown
                                className="prose mt-5 text-sm"
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    code: CustomCodeTag,
                                    pre: CustomPreTag,
                                }}
                            >
                                {analysisInfo?.description ?? ''}
                            </ReactMarkdown>
                        ) : (
                            <div className="my-6 text-default">No analysis information retrieved.</div>
                        )}
                    </div>
                );
        }
    };

    return (
        <CSSTransition timeout={300} classNames="fade" in={true} unmountOnExit>
            {getContent()}
        </CSSTransition>
    );
};

export default ContentfulSidebarView;

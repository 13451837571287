import { useEffect, useState } from 'react';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import { LinkedExperiment, LinkedExperimentResponse, SourceExperiment } from '../models/LinkedExperiment';
import Logger from '../util/Logger';

const logger = Logger.make('useLinkedExperiments');
const useLinkedExperiments = ({ experimentId, skip = false }: { experimentId: string | undefined; skip?: boolean }) => {
    const api = useApi();
    const [linkedExperiments, setLinkedExperiments] = useState<LinkedExperiment[]>([]);
    const [sourceExperiment, setSourceExperiment] = useState<SourceExperiment | null>(null);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const fetchLinkedExperiments = async () => {
        if (!experimentId || skip) return setLinkedExperiments([]);

        setError('');
        setLoading(true);
        try {
            const fetchedExperiments = await api.get<LinkedExperimentResponse>(
                Endpoints.lab.experiment.linkedExperiments({ experimentId }),
            );
            setLinkedExperiments(fetchedExperiments.linked_experiments);
            setSourceExperiment(fetchedExperiments.source_experiment);
        } catch {
            setError('Failed to fetch linked experiments! Please contact support.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLinkedExperiments();
    }, [experimentId, skip]);

    const linkExperiments = async (linkedExperimentIds: string[]) => {
        if (!experimentId) return;
        try {
            const updatedLinkedExperiments = await api.post<LinkedExperimentResponse>(
                Endpoints.lab.experiment.linkedExperiments({ experimentId }),
                {
                    linked_experiment_ids: linkedExperimentIds,
                },
            );
            setLinkedExperiments(updatedLinkedExperiments.linked_experiments);
        } catch {
            logger.error(new Error('Failed to link experiment'));
            setError('Failed to link experiment');
            alert('Failed to link experiment. Please try again.');
        }
    };

    const updateLinkedExperiment = async (payload: any) => {
        if (!experimentId) return;
        try {
            const updatedLinkedExperiments = await api.patch<LinkedExperimentResponse>(
                Endpoints.lab.experiment.linkedExperiments({ experimentId }),
                {
                    linked_experiments: [payload],
                },
            );
            setLinkedExperiments(updatedLinkedExperiments.linked_experiments);
        } catch {
            logger.error(new Error('Failed to update linked experiment'));
            setError('Failed to update linked experiment');
            alert('Failed to update linked experiment. Please try again.');
        }
    };

    const unlinkExperiments = async (experimentIdsToUnlink: string[]) => {
        if (!experimentId) return;
        try {
            const updatedLinkedExperiments = await api.doDelete<LinkedExperimentResponse>(
                Endpoints.lab.experiment.linkedExperiments({ experimentId }),
                {
                    linked_experiment_ids: experimentIdsToUnlink,
                },
            );
            setLinkedExperiments(updatedLinkedExperiments.linked_experiments);
        } catch {
            logger.error(new Error('Failed to unlink linked experiment'));
            setError('Failed to unlink linked experiment');
            alert('Failed to unlink linked experiment. Please try again.');
        }
    };

    const unlinkAllLinkedExperiments = async () => {
        if (!experimentId) return;
        const allLinkedExperimentIds = linkedExperiments?.map((e) => e.uuid);
        try {
            const updatedLinkedExperiments = await api.doDelete<LinkedExperimentResponse>(
                Endpoints.lab.experiment.linkedExperiments({ experimentId }),
                {
                    linked_experiment_ids: allLinkedExperimentIds,
                },
            );
            setLinkedExperiments(updatedLinkedExperiments.linked_experiments);
        } catch {
            logger.error(new Error('Failed to unlink all linked experiments'));
            setError('Failed to unlink all linked experiments');
        }
    };

    return {
        error,
        fetchLinkedExperiments,
        linkedExperiments,
        linkExperiments,
        loading,
        sourceExperiment,
        unlinkAllLinkedExperiments,
        unlinkExperiments,
        updateLinkedExperiment,
    };
};

export default useLinkedExperiments;

import AnalysisType, {
    AnalysisCategoryShortname,
    AnalysisShortname,
    KnownAnalysisShortnames,
} from '@models/analysis/AnalysisType';
import { ExperimentType, ExperimentTypeShortname, SelectableExperimentType, TargetType } from '@models/ExperimentType';
import { LabSettingsResponse } from '@api/lab/LabApiTypes';
import { SelectableOrganism } from '@models/Organism';
import { StatisticalTestType } from '@models/SummaryStatisticalTestResult';
import PlotDisplayType, { PlotDisplayShortname } from '@models/PlotDisplayType';
import { useCallback, useMemo } from 'react';
import { AnalysisCategory, AnalysisCategoryInfo } from '@models/analysis/AnalysisCategory';
import { isDefined } from '@util/TypeGuards';

export type LabSettings = {
    organisms: SelectableOrganism[];
    experimentTypes: SelectableExperimentType[];
    analysisTypes: AnalysisType[];
    chartTypes: PlotDisplayType[];
    targetTypes: TargetType[];
    loading: boolean;
    getAnalysisInfo: (type: AnalysisShortname, experimentType?: ExperimentTypeShortname | null) => AnalysisType | null;
    statisticalTestTypes: StatisticalTestType[];
    getAnalysisTypes: (shortname?: ExperimentTypeShortname | null | undefined) => AnalysisType[];
    isImageAnalysisEnabled: (shortname?: ExperimentTypeShortname | null | undefined) => boolean;
    getDisplayTypeInfo: (options: {
        displayType?: PlotDisplayShortname | null;
        analysisType?: AnalysisShortname | null;
    }) => PlotDisplayType | null;
    analysisTypesByCategory: Partial<Record<AnalysisCategoryShortname, AnalysisType[]>>;
    sortedAnalysisCategories: AnalysisCategoryShortname[];
    sortedAnalysisCategoryInfo: AnalysisCategoryInfo[];
    analysisCategoriesByShortname: Partial<Record<AnalysisCategoryShortname, AnalysisCategory>>;
    getAnalysisCategoryInfo: (shortname?: AnalysisCategoryShortname | null) => AnalysisCategoryInfo | null;
    categoryInfoByExperimentType: Partial<
        Record<ExperimentTypeShortname, Partial<Record<AnalysisCategoryShortname, AnalysisType[]>>>
    >;
    getExperimentType: (shortname: ExperimentTypeShortname | null | undefined) => ExperimentType | null;
};
export const useSettingsHelpers = ({
    data,
    error,
}: {
    data: LabSettingsResponse | undefined | null;
    error: unknown;
}): LabSettings => {
    const loading = !data && !error;
    const organisms = data?.organisms ?? [];
    const targetTypes = data?.target_types ?? [];
    const experimentTypes = data?.experiment_types ?? [];
    const statisticalTestTypes = data?.test_types ?? [];

    const getAnalysisTypesByCategory = (
        analysisTypes: AnalysisType[],
    ): Partial<Record<AnalysisCategoryShortname, AnalysisType[]>> => {
        return analysisTypes.reduce<Partial<Record<AnalysisCategoryShortname, AnalysisType[]>>>((map, analysisType) => {
            const categoryShortname = analysisType.category.shortname;
            const categoryList = map[categoryShortname] ?? [];
            categoryList.push(analysisType);
            map[categoryShortname] = categoryList;
            return map;
        }, {});
    };

    const {
        sortedAnalysisTypes,
        sortedChartTypes,
        analysisTypesByCategory,
        sortedAnalysisCategories,
        analysisCategoriesByShortname,
        sortedAnalysisCategoryInfo,
        categoryInfoByExperimentType,
    } = useMemo(() => {
        const allAnalysisTypesMap = experimentTypes.reduce<Partial<Record<AnalysisShortname, AnalysisType>>>(
            (map, expType) => {
                expType?.analysis_types?.forEach((analysisType) => {
                    map[analysisType.shortname] = analysisType;
                });
                return map;
            },
            {},
        );

        const allSortedAnalysisTypes = Object.values(allAnalysisTypesMap).sort((a1, a2) =>
            a1.display_name.localeCompare(a2.display_name),
        );

        const allAnalysisTypesByCategory = getAnalysisTypesByCategory(allSortedAnalysisTypes);

        const allSortedAnalysisCategories = Object.keys(allAnalysisTypesByCategory).sort((c1, c2) =>
            c1.toLowerCase().localeCompare(c2.toLowerCase()),
        ) as AnalysisCategoryShortname[];

        const allAnalysisCategoriesByShortname: Partial<Record<AnalysisCategoryShortname, AnalysisCategory>> =
            Object.values(allAnalysisTypesMap).reduce<Partial<Record<AnalysisCategoryShortname, AnalysisCategory>>>(
                (map, analysisType) => {
                    map[analysisType.category.shortname as AnalysisCategoryShortname] = analysisType.category;
                    return map;
                },
                {},
            );

        const allSortedAnalysisCategoryInfo: AnalysisCategoryInfo[] = allSortedAnalysisCategories
            .map((categoryShortname) => {
                const category = allAnalysisCategoriesByShortname[categoryShortname];
                const analysisTypes = allAnalysisTypesByCategory[categoryShortname] ?? [];
                if (!category) {
                    return undefined;
                }
                return { category, analysisTypes };
            })
            .filter(isDefined);

        const allChartTypesMap = experimentTypes.reduce<Partial<Record<PlotDisplayShortname, PlotDisplayType>>>(
            (map, expType) => {
                expType?.analysis_types?.forEach((analysis) => {
                    analysis.display_types.forEach((display) => {
                        map[display.shortname] = display;
                    });
                });
                return map;
            },
            {},
        );

        const allSortedChartTypes = Object.values(allChartTypesMap).sort((c1, c2) => {
            return c1.display_name.toLowerCase().trim().localeCompare(c2.display_name.toLowerCase().trim());
        });

        const categoryInfoByExperimentType = experimentTypes.reduce<
            Partial<Record<ExperimentTypeShortname, Partial<Record<AnalysisCategoryShortname, AnalysisType[]>>>>
        >((map, experimentType) => {
            map[experimentType.shortname] = getAnalysisTypesByCategory(experimentType.analysis_types ?? []);
            return map;
        }, {});

        return {
            sortedAnalysisTypes: allSortedAnalysisTypes,
            sortedChartTypes: allSortedChartTypes,
            analysisTypesByCategory: allAnalysisTypesByCategory,
            sortedAnalysisCategories: allSortedAnalysisCategories,
            analysisCategoriesByShortname: allAnalysisCategoriesByShortname,
            sortedAnalysisCategoryInfo: allSortedAnalysisCategoryInfo,
            categoryInfoByExperimentType,
        };
    }, [experimentTypes]);

    const getExperimentType = (shortname: ExperimentTypeShortname | null | undefined): ExperimentType | null => {
        if (!shortname) {
            return null;
        }
        return experimentTypes?.find((t) => t.shortname === shortname) ?? null;
    };

    /**
     * Will filter so that only "known" analysis types are returned.
     * @param {ExperimentTypeShortname | null | undefined} shortname
     * @param {[boolean=true]} filterUnknowns Set to TRUE if you want to only return "known" analysis types
     * @return {AnalysisType[]}
     */
    const getAnalysisTypes = (shortname?: ExperimentTypeShortname | null | undefined, filterUnknowns = true) => {
        const experimentType = experimentTypes.find((type) => type.shortname === shortname);
        const allTypes = experimentType?.analysis_types ?? [];
        if (filterUnknowns) {
            return allTypes.filter((t) => KnownAnalysisShortnames.includes(t.shortname));
        } else {
            return allTypes;
        }
    };

    const isImageAnalysisEnabled = (shortname?: ExperimentTypeShortname | null | undefined) => {
        return getAnalysisTypes(shortname).some((a) => a.shortname === 'image');
    };

    const getAnalysisInfo = (
        type: AnalysisShortname,
        experimentTypeShortname?: ExperimentTypeShortname | null,
    ): AnalysisType | null => {
        let analysisTypes = experimentTypes.flatMap<AnalysisType>((et) => et.analysis_types ?? []);
        if (experimentTypeShortname) {
            const experimentType = getExperimentType(experimentTypeShortname);
            analysisTypes = experimentType?.analysis_types ?? analysisTypes;
        }

        return analysisTypes.find((t) => t.shortname === type) ?? null;
    };
    const getDisplayTypeInfo = ({
        displayType,
        analysisType,
    }: {
        displayType?: PlotDisplayShortname | null;
        analysisType?: AnalysisShortname | null;
    }) => {
        if (!analysisType || !displayType) {
            return null;
        }
        const analysisInfo = getAnalysisInfo(analysisType);
        if (!analysisInfo) {
            return null;
        }

        return analysisInfo.display_types.find((display) => display.shortname === displayType) ?? null;
    };

    const getAnalysisCategoryInfo = useCallback(
        (
            shortname?: AnalysisCategoryShortname | null,
        ): { category: AnalysisCategory; analysisTypes: AnalysisType[] } | null => {
            if (!shortname) {
                return null;
            }
            const category = analysisCategoriesByShortname[shortname];
            if (!category) {
                return null;
            }
            const analysisTypes = analysisTypesByCategory[shortname] ?? [];
            return { category, analysisTypes };
        },
        [analysisTypesByCategory, analysisCategoriesByShortname],
    );

    return {
        loading,
        organisms,
        experimentTypes,
        targetTypes: targetTypes,
        analysisTypes: sortedAnalysisTypes,
        chartTypes: sortedChartTypes,
        getAnalysisInfo,
        statisticalTestTypes,
        getAnalysisTypes,
        isImageAnalysisEnabled,
        getDisplayTypeInfo,
        getExperimentType,
        analysisTypesByCategory,
        sortedAnalysisCategories,
        analysisCategoriesByShortname,
        getAnalysisCategoryInfo,
        sortedAnalysisCategoryInfo,
        categoryInfoByExperimentType,
    };
};

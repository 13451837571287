import { useEffect } from 'react';

export const Blobs = () => {
    return (
        <>
            <div className="blob -z-25 absolute left-0 top-0 h-[300px] w-[300px] rounded-full bg-indigo-400/50 opacity-0 blur-2xl transition-all duration-300" />
            <div className="fakeblob -z-25 absolute left-0 top-0 h-[250px] w-[250px] opacity-0" />
        </>
    );
};

export const useMouseMoveHoverEffect = () => {
    useEffect(() => {
        const handleMouseMove = (ev: { clientX: number; clientY: number }) => {
            const all = document.querySelectorAll('.card');
            all.forEach((e) => {
                const blob = e.querySelector('.blob') as HTMLElement;
                const fblob = e.querySelector('.fakeblob') as HTMLElement;
                if (blob && fblob) {
                    const rec = fblob.getBoundingClientRect();
                    blob.style.opacity = '1';
                    blob.animate(
                        [
                            {
                                transform: `translate(${ev.clientX - rec.left - rec.width / 2}px, ${
                                    ev.clientY - rec.top - rec.height / 2
                                }px)`,
                            },
                        ],
                        {
                            duration: 300,
                            fill: 'forwards',
                        },
                    );
                }
            });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
};

import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import React, { useState } from 'react';
import Plot from '@models/Plot';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import Experiment from '@models/Experiment';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import { useFormikContext } from 'formik';
import SeuratDifferentialVariablesField from '../../analyses/fields/SeuratDifferentialVariablesField';
import SeuratDifferentialLatentVariablesField from '../../analyses/fields/SeuratDifferentialLatentVariablesField';
import { AnalysisParameters, SeuratDifferentialExpressionAnalysisParameters } from '@models/AnalysisParameters';
import { useSwitchStyles } from '@components/SwitchStyles';
import { Switch } from '@mui/material';
import SimpleSelectField, { ChoiceItem } from '../fields/SimpleSelectField';
import { useExperimentAnnotationContext } from '@contexts/ExperimentAnnotationContext';
import { RidgePlotDisplayOptionFormValues } from '@/src/models/PlotDisplayOption';
import AssaySummaryLegendFieldGroupV3 from '../groups/AssaySummaryLegendFieldGroupV3';
import Button from '@/src/components/Button';
import { mutate } from 'swr';
import Endpoints from '@/src/services/Endpoints';
import cn from 'classnames';

type Props = {
    analysisParameters?: AnalysisParameters | null;
    experiment: Experiment;
    plot: Plot;
    setDisplaySaveDisabled: (value: boolean) => void;
    setUpdateGroupsDisabled: (value: boolean) => void;
    updateGroupsDisabled: boolean;
};
const RidgePlotDisplayFields = ({
    analysisParameters,
    experiment,
    plot,
    setDisplaySaveDisabled,
    setUpdateGroupsDisabled,
    updateGroupsDisabled,
}: Props) => {
    const { values, setFieldValue } = useFormikContext<RidgePlotDisplayOptionFormValues>();
    const [splitByCluster, setSplitByCluster] = useState(Boolean(values?.annotation_set_id) ?? false);
    const switchStyles = useSwitchStyles();
    const { annotationSets } = useExperimentAnnotationContext();
    const typedParameters = analysisParameters as SeuratDifferentialExpressionAnalysisParameters;
    const isCleanedWorkflow = experiment?.is_cleaned_workflow ?? false;

    const ClusterAnnotationSetItems: ChoiceItem[] =
        annotationSets
            ?.sort((a, b) => a.resolution - b.resolution)
            .map((c) => {
                return {
                    label: (
                        <div className="flex flex-col">
                            <p className="text-dark">{c.display_name}</p>
                            <div className="flex flex-row text-sm text-gray-500">
                                {isCleanedWorkflow ? null : (
                                    <>
                                        <p>Resolution: {c.resolution.toFixed(1)}</p>{' '}
                                    </>
                                )}
                                <p
                                    className={cn({
                                        'ml-4': !isCleanedWorkflow,
                                    })}
                                >
                                    Clusters: {c.clusters.length}
                                </p>
                            </div>
                        </div>
                    ),
                    value: c.uuid,
                };
            }) ?? [];

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name} />
            <section className="mb-8 space-y-3">
                <h4 className="text-dark text-lg font-semibold tracking-tight">Cell groups</h4>
                <p>Group your cells by combining cluster annotation sets, variables, and/or latent variables</p>
                <div className="form-field !flex flex-row items-center justify-between">
                    <span className="field-label">Group by cluster</span>
                    <Switch
                        sx={switchStyles}
                        checked={splitByCluster}
                        name="split_by_cluster"
                        onChange={(e) => {
                            setSplitByCluster((prev) => !prev);
                            if (!e.target.checked) {
                                setFieldValue('annotation_set_id', null);
                            }
                        }}
                    />
                </div>
                {splitByCluster && (
                    <SimpleSelectField
                        name="annotation_set_id"
                        label="Cluster annotation set"
                        // @Post-MVP: Adding expand button and modal
                        // label={
                        //     <>
                        //         <span>Cluster annotation set</span>
                        //         <Button
                        //             variant="text"
                        //             color="primary"
                        //             onClick={() => setExpanded(true)}
                        //             endIcon={<ArrowsExpandIcon className="h-4 w-4" />}
                        //         >
                        //             Expand
                        //         </Button>
                        //     </>
                        // }
                        // labelClassName="w-full flex items-center justify-between"
                        value={values.annotation_set_id}
                        onChange={(e) => setFieldValue('annotation_set_id', e.target.value)}
                        items={ClusterAnnotationSetItems}
                        placeholder="Select an annotation set..."
                    />
                )}
                <SeuratDifferentialVariablesField
                    plot={plot}
                    analysisParameters={typedParameters}
                    experiment={experiment}
                />
                <SeuratDifferentialLatentVariablesField
                    plot={plot}
                    analysisParameters={typedParameters}
                    experiment={experiment}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        mutate(
                            Endpoints.lab.experiment.plot.groupsV3({
                                experimentId: experiment.uuid,
                                plotId: plot.uuid,
                            }),
                        );
                    }}
                    disabled={updateGroupsDisabled}
                >
                    Update groups
                </Button>
            </section>
            <ThemeFieldGroup hideStyle />
            <AssaySummaryLegendFieldGroupV3
                description="Click and drag the legend items to reorder. Plot will only show groups included in the analysis"
                experiment={experiment}
                ignoreEmpty
                isSortable={true}
                plot={plot}
                setDisplaySaveDisabled={setDisplaySaveDisabled}
                setUpdateGroupsDisabled={setUpdateGroupsDisabled}
            />
        </>
    );
};

export default RidgePlotDisplayFields;

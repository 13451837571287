import React from 'react';
import LoadingMessage from '@components/LoadingMessage';

const PlotDisplayLoadingView = () => {
    return (
        <div className="flex h-full w-full flex-col items-center justify-center px-8 py-16 text-center">
            <div className="mx-auto mb-4 rounded-full bg-indigo-100 p-0 text-indigo-800">
                <LoadingMessage size={24} immediate />
            </div>
            <h2 className="text-xl font-semibold tracking-tight">Switching plot displays...</h2>
            <p className="text-base">Your plot will appear here shortly.</p>
        </div>
    );
};

export default PlotDisplayLoadingView;

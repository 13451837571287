import { useEffect, useRef, useState } from 'react';
import IGVBrowserConfig from '@models/igv/IGVBrowserConfig';
import IGVBrowser from '@models/igv/IGVBrowser';
import { useOptionalExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import Plot from '@models/Plot';
import IGVModule from '@models/igv/IGVModule';
import igv from 'igv/dist/igv.esm.min.js';
import Logger from '@util/Logger';
import { debounce } from 'debounce';

const logger = Logger.make('useIGV');

type Props = { config: IGVBrowserConfig; plot?: Plot };

const useIGV = ({ config, plot }: Props) => {
    const igvRef = useRef<HTMLDivElement>(null);
    const plotId = plot?.uuid;
    const [igvModule, setIgvModule] = useState<IGVModule | null>(null);
    const [igvBrowser, setIgvBrowser] = useState<IGVBrowser | null>(null);
    const experimentContext = useOptionalExperimentDetailViewContext();
    const lastSizeRef = useRef<{ width: number; height: number } | null>(null);

    const igvModuleRef = useRef(igvModule);
    const igvBrowserRef = useRef(igvBrowser);

    useEffect(() => {
        igvModuleRef.current = igvModule;
    }, [igvModule]);

    useEffect(() => {
        igvBrowserRef.current = igvBrowser;
    }, [igvBrowser]);

    // Load the IGV module if not already loaded.
    const loadIGV = async () => {
        if (igvModule) return igvModule;
        if (typeof window === 'undefined') return;
        const container = igvRef.current;
        if (!container) return;
        container.innerHTML = 'Loading IGV browser...';
        const igvLib: igv = await import('igv/dist/igv.esm.js');
        setIgvModule(igvLib);
        return igvLib;
    };

    // Draw the browser. Clear the container before creating a new instance.
    const drawIGV = async (container: HTMLDivElement, config: IGVBrowserConfig) => {
        const igv = await loadIGV();
        if (!igv || !igv.default) {
            logger.log('No IGV module was loaded');
            return;
        }
        // Clear the container to prevent duplicate rendering.
        container.innerHTML = '';
        const browser = await igv.default.createBrowser(container, { ...config });
        if (plotId) {
            experimentContext?.setIGVBrowserByPlotId?.(plotId, browser);
        }
        setIgvBrowser(browser);
        return browser;
    };

    const handleResize = () => {
        requestAnimationFrame(async () => {
            if (!igvRef.current || !igvBrowserRef.current) return;
            const browser = igvBrowserRef.current;
            // Check if the trackViews array exists and has items.
            if (!browser.trackViews || browser.trackViews.length === 0) return;

            const { offsetWidth: width, offsetHeight: height } = igvRef.current;
            const lastSize = lastSizeRef.current;
            // Skip if size hasn't changed
            if (lastSize && lastSize.width === width && lastSize.height === height) return;
            lastSizeRef.current = { width, height };
            // Resize the IGV browser
            try {
                igvModuleRef.current?.default?.visibilityChange();
            } catch (_) {}
        });
    };

    // Debounce our handler to prevent excessive calls
    const debouncedResize = debounce(handleResize, 200);

    // Create or re-create the IGV browser whenever the config changes.
    useEffect(() => {
        if (!igvRef.current) return;

        // If an IGV browser instance already exists, remove it.
        if (igvBrowser && igvModule) {
            igvModule?.default?.removeBrowser(igvBrowser);
            setIgvBrowser(null);
        }
        // Create a new browser.
        drawIGV(igvRef.current, config);

        const resizeObserver = new ResizeObserver(() => {
            try {
                debouncedResize();
            } catch (e) {}
        });

        resizeObserver.observe(igvRef.current);

        return () => {
            resizeObserver.disconnect();
            debouncedResize.clear && debouncedResize.clear();
        };
    }, [config]);

    return { igvRef, igvModule, igvBrowser, loadIGV };
};

export default useIGV;

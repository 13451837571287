import Experiment from '@models/Experiment';
import SampleDataTableView from './SampleDataTableView';
import { MethodsPlotContent } from './methods/MethodsPlotContent';
import ResultsDataTableView from './ResultsDataTableView';
import ExperimentDetailAssayDataTabView from './ExperimentDetailAssayDataTabView';
import { Node } from 'reactflow';
import FetchExperimentWrapper from './FetchExperimentWrapper';

export type Props = {
    experiment: Experiment;
    selectedDataNode?: Node['data'];
};

const DataTableModalView = ({ experiment, selectedDataNode }: Props) => {
    const renderData = (fetchedExperiment?: Experiment | null) => {
        if (selectedDataNode?.experimentId ? !fetchedExperiment : !experiment) return;
        switch (selectedDataNode.selectType) {
            case 'sample':
                return (
                    <SampleDataTableView
                        experiment={(selectedDataNode?.experimentId ? fetchedExperiment : experiment) as Experiment}
                    />
                );
            case 'assay':
                return (
                    <ExperimentDetailAssayDataTabView
                        experiment={(selectedDataNode?.experimentId ? fetchedExperiment : experiment) as Experiment}
                    />
                );
            case 'results':
                return <ResultsDataTableView selectedPlot={selectedDataNode.plot} />;
            case 'methods':
                return (
                    <MethodsPlotContent
                        plot={selectedDataNode.plot}
                        experiment={(selectedDataNode?.experimentId ? fetchedExperiment : experiment) as Experiment}
                    />
                );
        }
    };

    return (
        <FetchExperimentWrapper experimentId={selectedDataNode?.experimentId}>
            {(fetchedExperiment) => <div className="h-full w-full p-8">{renderData(fetchedExperiment)}</div>}
        </FetchExperimentWrapper>
    );
};

export default DataTableModalView;
